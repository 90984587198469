import {
  FETCH_BUSINESS_AWAITING_APPROVAL,
  FETCH_BUSINESS_APPROVED,
  FETCH_REGIONS_RECORDS,
  FETCH_USERS_RECORDS,
  FETCH_BUSINESS_APPROVED_CAMPAIGN,
  FETCH_BUSINESS_REJECTED,
  FETCH_BUSINESS_DISABLED,
  FETCH_REPORTED_BUSINESS,
  PATCH_REPORTED_SUCCESS_MESSAGE,
  PATCH_REPORTED_ERROR_MESSAGE,
  SET_REPORTED_BUSINESS_DETAILS,
  SHOW_REPORTED_BUSINESS_DETAILS,
  BUSI_STATE_CHG_SUCCESS,
  BUSI_STATE_CHG_ERR,
  SET_PAN_CARD,
  SET_AADHAR_CARD,
  SET_SHOP_ACT,
  SET_QR_CODE_IMAGE,
  SET_BUSINESS_STATS,
  SET_BUSINESS_SUCCESS_MESSAGE,
  SET_BUSINESS_ERROR_MESSAGE,
  SET_TOP_PERFORMING_BUSINESSES,
  REQUEST_BUSINESSES,
  SET_BUSINESS_REPORTS,
  SET_BUSINESS_PERFORMANCE_REPORTS,
  BUSI_DET_UPDATE_SUCCESS,
  BUSI_DET_UPDATE_ERR,
  BUSI_PERSONAL_DET_UPDATE_SUCCESS,
  BUSI_PERSONAL_DET_UPDATE_ERR,
  BUSINESS_MEMBERS_DETAILS,
  UPDATE_SHOPACT_ERROR_MESSAGE,
  UPDATE_SHOPACT_SUCCESS_MESSAGE,
  ADD_HEAD_ERROR,
  ADD_HEAD_SUCCESS,
  GET_HEAD_BY_BUSINESSID,
  GET_HEADANDSUBHEAD_BY_BUSINESSID,
  UPDATE_HEAD_SUBHEAD,
  ADD_SERVICE_FAILURE,
  ADD_SERVICE_SUCCESS,
  GET_ALL_SERVICES,
  GET_SUBHEAD_BY_HEAD_ID,
  UPDATE_SERVICE,
  SERVICES_BULK_UPLOAD_SUCCESS,
  SERVICES_BULK_UPLOAD_ERROR,
  SEND_PUSH_NOTIFICATION,
  GET_PUSH_NOTIFICATION,
  GET_ACTIVEBUSINESSUSER_REPORTS,
  GET_PUSH_NOTIFICATION_CONSUMERDATA,
  DELETE_SUBCATEGORY,
  SHOW_APPROVED_BUSINESS_DETAILS,
  FETCH_BUSINESS_EXCEL,
  GET_HEAD_WITH_SERVICES_BY_BUSINESSID,
  SERVICES_MENU_DOWNLOAD_EXCEL,
  SERVICES_MENU_DOWNLOAD_EXCEL_COMPLETED,
  PETPOOJA_MENU_DOWNLOAD_EXCEL,
  PETPOOJA_MENU_DOWNLOAD_EXCEL_COMPLETED,
  SET_ALL_BUSINESS_VIDEO_LINK_LIST_SUCCESS_MESSAGE,
  SET_ALL_BUSINESS_VIDEO_LINK_LIST_ERROR_MESSAGE,
  EDIT_BUSINESS_VIDEO_LINK_LIST_SUCCESS_MESSAGE,
  EDIT_BUSINESS_VIDEO_LINK_LIST_ERROR_MESSAGE,
  SET_ALL_EXOTEL_NUMBERS_SUCCESS_MESSAGE,
  SET_ALL_EXOTEL_NUMBERS_ERROR_MESSAGE,
  DELETE_BUSINESS_VIDEO_LINK_LIST_SUCCESS_MESSAGE,
  DELETE_BUSINESS_VIDEO_LINK_LIST_ERROR_MESSAGE,
  AADHAR_CARD_VALUE,
  PAN_CARD_VALUE,
  REQUEST_APPROVED_BUSINESS_RECORDS,
  REQUEST_APPROVED_BUSINESS_RECORDS_FALSE,
  REQUEST_BUSINESS_DETAILS_RECORDS,
  REQUEST_BUSINESS_DETAILS_RECORDS_FALSE,
  REQUEST_VENDOR_SETTLEMENTS,
  GET_ALL_VENDOR_SETTLEMENT_DATA,
  REQUEST_VENDOR_SETTLEMENTS_RESET,
  DELETE_SUCCESS_MESSAGE,
  DELETE_ERROR_MESSAGE,
  ADD_VENDOR_SETTLEMENTS,
  REMOVE_DUPLICATE_VENDOR_SETTLEMENTS,
  REMOVE_DUPLICATE_VENDOR_SETTLEMENTS_ERROR_MESSAGE,
  UNSET_LINKED_ADDONS_SERVICESEC_SUCCESS,
  UNSET_LINKED_ADDONS_SERVICESEC_ERROR,
  DELETE_ALLSERVICES_SUCCESS_MESSAGE,
  DELETE_ALLSERVICES_ERROR_MESSAGE,
  SUCCESS_MESSAGE_EMAIL_SENT,
  ERROR_MESSAGE_EMAIL_SENT,
  GET_BUSINESS_AGREEMENT_DETAILS,
  GET_BUSINESS_AGREEMENT_UPDATE_HISTORY_DETAILS_BY_BUSINESSID,
  ADD_BUSINESS_AGREEMENT,
  ADD_BUSINESS_AGREEMENT_ERROR,
  REQUEST_BUSINESS_AGREEMENT,
  REQUEST_BUSINESS_AGREEMENT_RESET,
  SHOW_CONSUMER_COUNT,
  ADD_PENDING_BUSINESS_TAGS,
  ADD_DISABLED_BUSINESS_TAGS,
  ADD_APPROVED_BUSINESS_TAGS
} from "../actions/types";

const INITIAL_STATE = {
  busAwaitApprovalList: [],
  busApprovedList: [],
  busApprovedListCampaign: [],
  vendorSettlementList: [],
  busRejectedList: [],
  busDisabledList: [],
  busRptList: [],
  busAwaitApprovalListCount: 0,
  busApprovedListCount: 0,
  busRejectedListCount: 0,
  busDisabledListCount: 0,
  busRptListCount: 0,
  page: 0,
  skip: 0,
  limit: 10,
  sucessMsg: null,
  errorMsg: null,
  showRpd: false,
  reportedBusiness: null,
  panCard: null,
  aadharCard: null,
  shopAct: null,
  panCardImage:null,
  aadharCardImage:null,
  qrCodeImage: null,
  busStateChgSuccess: null,
  busStateChgErr: null,
  busDetUpdateSuccess: null,
  busDetUpdateErr: null,
  revenueTillNow: {
    totalRevenue: 0,
    count: 0
  },
  revenueToday: {
    totalRevenue: 0,
    count: 0
  },
  revenueYesterday: {
    totalRevenue: 0,
    count: 0
  },
  revenueWeekly: {
    totalRevenue: 0,
    count: 0
  },
  revenueMonthly: {
    totalRevenue: 0,
    count: 0
  },
  lastReceivedOrder: {},
  topPerformingBusinesses: {},
  isFetching: false,
  businessReports: {},
  businessPerformanceReport: {},
  businessMembersDetails: {},
  updateShopAct: false,
  addHeadSubheadStatus: null,
  Heads: [],
  headSubheadHeirarchy: {},
  updatemsg: "",
  addService: false,
  allServices: {},
  subHeads: [],
  bulkUploadSuccess: null,
  bulkUploadError: null,
  servicesMenuDownloadExcelSuccess: null,
  servicesMenuDownloadExcelError: null,
  reviewReport: {},
  pushNotificationMsg: "",
  pushNotifications: {},
  activeBusinessUserReport: [],
  pushNotificationsConsumerData:[],
  deleteSubcategoryMsg: "",
  updatedBusinessData: {},
  businessExcelRecords:[],
  HeadsWithServices:[],
  servicesMenuExcelRecords: [],
  petPoojaMenuExcelRecords: [],
  deleteSettlementDataSucessMsg: "",
  deleteSettlementDataErrorMsg: "",
  addSettlementDataSucessMsg: "",
  removeDuplicateSettlementDataSucessMsg: "",
  removeDuplicateSettlementDataErrorMsg: "",
  regionsData:[],
  usersData:[],
  exotelNumber:[],
  sucessMsgAddon : null,
  errorMsgAddon : null,

  deleteAllServices:[],
  // areasData:[]
  emailNotificationSentSucessMsg: "",
  emailNotificationSentErrorMsg: "",
  businessAgreementDetails: [],
  businessAgreementUpdateHistoryDetails: [],
  addBusinessAgreementDataSucessMsg: "",
  addBusinessAgreementDataErrorMsg: "",
  currentConsumerCount: 0
};

export default function businessReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_BUSINESSES:
      return { ...state, isFetching: action.payload };
    case REQUEST_VENDOR_SETTLEMENTS:
      return { ...state, isFetching: action.payload };
    case REQUEST_VENDOR_SETTLEMENTS_RESET:
      return { ...state, isFetching: action.payload };
    case REQUEST_APPROVED_BUSINESS_RECORDS:
      return { ...state, isFetching: action.payload };
    case REQUEST_APPROVED_BUSINESS_RECORDS_FALSE:
      return { ...state, isFetching: action.payload };
    case REQUEST_BUSINESS_DETAILS_RECORDS:
      return { ...state, isFetching: action.payload };
    case REQUEST_BUSINESS_DETAILS_RECORDS_FALSE:
      return { ...state, isFetching: action.payload };
    case FETCH_BUSINESS_AWAITING_APPROVAL:
      return {
        ...state,
        busAwaitApprovalListCount: action.payload.count,
        busAwaitApprovalList: action.payload.data
      };
    case FETCH_BUSINESS_APPROVED:
      return {
        ...state,
        busApprovedListCount: action.payload.count,
        busApprovedList: action.payload.data
      };
    case FETCH_REGIONS_RECORDS:
      return {
        ...state,
        regionsData: action.payload,
        // usersData:action.payload.areaManagersData,
        // areasData:action.payload.areasData
      };
    case FETCH_USERS_RECORDS:
      return {
        ...state,
        // regionsData: action.payload.regionsData,
        usersData:action.payload,
        // areasData:action.payload.areasData
      };
    case FETCH_BUSINESS_APPROVED_CAMPAIGN:
      return {
        ...state,
        busApprovedListCampaign: action.payload.data
      };  
    case GET_ALL_VENDOR_SETTLEMENT_DATA:
      return {
        ...state,
        vendorSettlementList: action.payload
      };  
    case FETCH_BUSINESS_DISABLED:
      return {
        ...state,
        busDisabledListCount: action.payload.count,
        busDisabledList: action.payload.data
      };
    case FETCH_BUSINESS_REJECTED:
      return {
        ...state,
        busRejectedListCount: action.payload.count,
        busRejectedList: action.payload.data
      };
    case FETCH_REPORTED_BUSINESS:
      return {
        ...state,
        busRptListCount: action.payload.count,
        busRptList: action.payload.data,
        page: action.payload.pagi.page,
        skip: action.payload.pagi.skip,
        limit: action.payload.pagi.limit,
      };
    case PATCH_REPORTED_SUCCESS_MESSAGE:
      return { ...state, sucessMsg: action.payload };
    case PATCH_REPORTED_ERROR_MESSAGE:
      return { ...state, errorMsg: action.payload };
    case SET_ALL_BUSINESS_VIDEO_LINK_LIST_SUCCESS_MESSAGE:
      return {
          ...state,
          sucessMsg: action.payload.msg,
          ops: action.payload.ops
      };
    case DELETE_SUCCESS_MESSAGE:
      return {
          ...state,
          deleteSettlementDataSucessMsg: action.payload
      };
    case ADD_VENDOR_SETTLEMENTS:
      return {
          ...state,
          addSettlementDataSucessMsg: action.payload
      };
    case SUCCESS_MESSAGE_EMAIL_SENT:
      return {
          ...state,
          emailNotificationSentSucessMsg: action.payload
      };
    case ERROR_MESSAGE_EMAIL_SENT:
      return {
          ...state,
          emailNotificationSentErrorMsg: action.payload
      };
    case DELETE_ERROR_MESSAGE:
      return {
          ...state,
          deleteSettlementDataErrorMsg: action.payload
      };
    case REMOVE_DUPLICATE_VENDOR_SETTLEMENTS:
      return {
          ...state,
          removeDuplicateSettlementDataSucessMsg: action.payload
      };
    case REMOVE_DUPLICATE_VENDOR_SETTLEMENTS_ERROR_MESSAGE:
      return {
          ...state,
          removeDuplicateSettlementDataErrorMsg: action.payload
    };
    case SET_ALL_BUSINESS_VIDEO_LINK_LIST_ERROR_MESSAGE:
      return {
          ...state,
          errorMsg: action.payload.msg,
          ops: action.payload.ops
      };
    case EDIT_BUSINESS_VIDEO_LINK_LIST_SUCCESS_MESSAGE:
      return {
          ...state,
          sucessMsg: action.payload.msg,
          ops: action.payload.ops
      };
    case SET_ALL_EXOTEL_NUMBERS_SUCCESS_MESSAGE:
      return {
          ...state,
          errorMsg: action.payload.msg,
          ops: action.payload.ops,
      };   
       case SET_ALL_EXOTEL_NUMBERS_ERROR_MESSAGE:
      return {
          ...state,
          sucessMsg: action.payload.msg,
          ops: action.payload.ops
      };
    case EDIT_BUSINESS_VIDEO_LINK_LIST_ERROR_MESSAGE:
      return {
          ...state,
          errorMsg: action.payload.msg,
          ops: action.payload.ops,
      };
    case DELETE_BUSINESS_VIDEO_LINK_LIST_SUCCESS_MESSAGE:
      return {
          ...state,
          sucessMsg: action.payload.msg,
          ops: action.payload.ops
      };
    case DELETE_BUSINESS_VIDEO_LINK_LIST_ERROR_MESSAGE:
      return {
          ...state,
          errorMsg: action.payload.msg,
          ops: action.payload.ops,
      };
    case BUSI_STATE_CHG_SUCCESS:
      return { ...state, busStateChgSuccess: action.payload };
    case BUSI_STATE_CHG_ERR:
      return { ...state, busStateChgErr: action.payload };
    case AADHAR_CARD_VALUE:
      return { ...state, aadharCard: action.payload };
    case PAN_CARD_VALUE:
      return { ...state, panCard: action.payload };
    case SET_REPORTED_BUSINESS_DETAILS:
      return { ...state, reportedBusiness: action.payload };
    case SHOW_REPORTED_BUSINESS_DETAILS:
      return { ...state, showRpd: action.payload };
    case SET_PAN_CARD:
      return { ...state, panCardImage: action.payload };
    case SET_AADHAR_CARD:
      return { ...state, aadharCardImage: action.payload };
    case SET_SHOP_ACT:
      return { ...state, shopAct: action.payload };
    case SET_QR_CODE_IMAGE:
      console.log("action.payload in reducer",action.payload);
      return { ...state, qrCodeImage: action.payload };
    case SET_BUSINESS_SUCCESS_MESSAGE:
      return {
        ...state,
        sucessMsg: action.payload.msg,
        ops: action.payload.ops,
        // isFetching: false
      };
    case SET_BUSINESS_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: action.payload.msg,
        ops: action.payload.ops,
        // isFetching: false
      };
    case SET_TOP_PERFORMING_BUSINESSES:
      return {
        ...state,
        topPerformingBusinesses: action.payload,
        isFetching: false
      };
    case SET_BUSINESS_REPORTS:
      return {
        ...state,
        businessReports: action.payload,
        isFetching: false
      };
    case SET_BUSINESS_PERFORMANCE_REPORTS:
      return {
        ...state,
        businessPerformanceReport: action.payload
      }
    case SET_BUSINESS_STATS:
      if (action.payload.revenuetype === "tillnow") {
        return { ...state, revenueTillNow: action.payload };
      } else
        if (action.payload.revenuetype === "today") {
          return { ...state, revenueToday: action.payload };
        } else
          if (action.payload.revenuetype === "yesterday") {
            return { ...state, revenueYesterday: action.payload };
          } else
            if (action.payload.revenuetype === "weekly") {
              return { ...state, revenueWeekly: action.payload };
            } else
              if (action.payload.revenuetype === "monthly") {
                return { ...state, revenueMonthly: action.payload };
              } else {
                return { ...state, lastReceivedOrder: action.payload };
              }
    case REQUEST_BUSINESS_AGREEMENT:
      return { ...state, isFetching: action.payload };
    case REQUEST_BUSINESS_AGREEMENT_RESET:
      return { ...state, isFetching: action.payload };
    case GET_BUSINESS_AGREEMENT_DETAILS:
      return { ...state, businessAgreementDetails: action.payload };
    case GET_BUSINESS_AGREEMENT_UPDATE_HISTORY_DETAILS_BY_BUSINESSID:
      return { ...state, businessAgreementUpdateHistoryDetails: action.payload };
    case ADD_BUSINESS_AGREEMENT:
      return {
        ...state,
        addBusinessAgreementDataSucessMsg: action.payload
      };
    case ADD_BUSINESS_AGREEMENT_ERROR:
      return {
          ...state,
          addBusinessAgreementDataErrorMsg: action.payload
      };
    case BUSINESS_MEMBERS_DETAILS:
      return { ...state, businessMembersDetails: action.payload };
    case BUSI_DET_UPDATE_SUCCESS:
      return { ...state, busDetUpdateSuccess: action.payload };
    case BUSI_DET_UPDATE_ERR:
      return { ...state, busDetUpdateErr: action.payload };
      case BUSI_PERSONAL_DET_UPDATE_SUCCESS:
        return { ...state, busDetUpdateSuccess: action.payload };
      case BUSI_PERSONAL_DET_UPDATE_ERR:
        return { ...state, busDetUpdateErr: action.payload };
    case UPDATE_SHOPACT_ERROR_MESSAGE:
      return { ...state, updateShopAct: false };
    case UPDATE_SHOPACT_SUCCESS_MESSAGE:
      return { ...state, updateShopAct: true };
    case ADD_HEAD_SUCCESS:
      return { ...state, addHeadSubheadStatus: action.payload };
    case ADD_HEAD_ERROR:
      return { ...state, addHeadSubheadStatus: action.payload };
    case GET_HEAD_BY_BUSINESSID:
      return { ...state, Heads: action.payload };
    case GET_HEADANDSUBHEAD_BY_BUSINESSID:
      return { ...state, headSubheadHeirarchy: action.payload };
    case UPDATE_HEAD_SUBHEAD:
      return { ...state, updatemsg: action.payload };
    case ADD_SERVICE_SUCCESS:
      return { ...state, addService: action.payload };
    case ADD_SERVICE_FAILURE:
      return { ...state, addService: action.payload };
    case GET_ALL_SERVICES:
      return { ...state, allServices: action.payload };
    case UPDATE_SERVICE:
      return { ...state, updatemsg: action.payload };
    case GET_SUBHEAD_BY_HEAD_ID:
      return { ...state, subHeads: action.payload };
    case SERVICES_BULK_UPLOAD_SUCCESS:
      return { ...state, bulkUploadSuccess: action.payload.msg };
    // case GET_REVIEW_REPORT:
    //   return { ...state, reviewReport: action.payload };
    case SERVICES_BULK_UPLOAD_ERROR:
      return { ...state, bulkUploadError: action.payload.msg };
    case SEND_PUSH_NOTIFICATION:
      return {
        ...state, pushNotificationMsg : action.payload.msg, ops: action.payload.ops, isFetching: false
    };
    case GET_PUSH_NOTIFICATION:
      return { ...state, pushNotifications: action.payload };
    case GET_ACTIVEBUSINESSUSER_REPORTS:
      return { ...state, activeBusinessUserReport: action.payload };
    case GET_PUSH_NOTIFICATION_CONSUMERDATA:
        return {...state,pushNotificationsConsumerData:action.payload};
    case DELETE_SUBCATEGORY:
      return { ...state, deleteSubcategoryMsg: action.payload };
    case SHOW_APPROVED_BUSINESS_DETAILS:
      return { ...state, updatedBusinessData: action.payload };    
    case FETCH_BUSINESS_EXCEL:
      return { ...state, businessExcelRecords: action.payload };
    case GET_HEAD_WITH_SERVICES_BY_BUSINESSID:
      return { ...state, HeadsWithServices: action.payload };
    case SERVICES_MENU_DOWNLOAD_EXCEL:
      return {...state, servicesMenuExcelRecords: action.payload, isFetching: true};
    case SERVICES_MENU_DOWNLOAD_EXCEL_COMPLETED:
        return {...state, servicesMenuExcelRecords: action.payload, isFetching: false}; 
    case PETPOOJA_MENU_DOWNLOAD_EXCEL:
      return {...state, petPoojaMenuExcelRecords: action.payload, isFetching: true};
    case PETPOOJA_MENU_DOWNLOAD_EXCEL_COMPLETED:
      return { ...state, petPoojaMenuExcelRecords: action.payload, isFetching: false };
    case UNSET_LINKED_ADDONS_SERVICESEC_SUCCESS:
      return {
        ...state,
        sucessMsgAddon: action.payload.msg,
      
      };
    case UNSET_LINKED_ADDONS_SERVICESEC_ERROR:
      return {
        ...state,
        errorMsgAddon: action.payload.msg,
       
      };
    case  DELETE_ALLSERVICES_SUCCESS_MESSAGE:
          return {...state,  deleteAllServices: action.payload};
    case  DELETE_ALLSERVICES_ERROR_MESSAGE:
          return {...state,  deleteAllServices: action.payload};
    case SHOW_CONSUMER_COUNT:      
          return { ...state, currentConsumerCount: action.payload };
    case ADD_PENDING_BUSINESS_TAGS:
            return {
              ...state,
              busAwaitApprovalList:state.busAwaitApprovalList.map((eachBusiness)=>{
                if(eachBusiness._id===action.payload?._id){
                  if(action.payload.businessTagIds){
                    eachBusiness['businessTagIds']=action.payload?.businessTagIds;
                  }
                  return eachBusiness;
                }
                return eachBusiness
              })
            };     
    
              case ADD_DISABLED_BUSINESS_TAGS:
                return {
                  ...state,
                  busDisabledList:state.busDisabledList.map((eachBusiness)=>{
                    if(eachBusiness._id===action.payload?._id){
                      if(action.payload.businessTagIds){
                        eachBusiness['businessTagIds']=action.payload?.businessTagIds;
                      }
                      return eachBusiness;
                    }
                    return eachBusiness
                  })
                }; 
              
              case ADD_APPROVED_BUSINESS_TAGS:
                  return {
                    ...state,
                    busApprovedList:state.busApprovedList.map((eachBusiness)=>{
                      if(eachBusiness._id===action.payload?._id){
                        if(action.payload.businessTagIds){
                          eachBusiness['businessTagIds']=action.payload?.businessTagIds;
                        }
                        return eachBusiness;
                      }
                      return eachBusiness
                    })
                  };  
                
                
              
    
    default:
      return state;
  }
}
