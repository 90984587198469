import React, { useEffect, useState } from "react";
import { Modal, Box, IconButton, CircularProgress, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

// Styled Close Button
const CloseButton = styled(IconButton)({
  position: "absolute",
  top: "12px",
  right: "12px",
  padding: "6px",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
  },
});

export const MpinGenerationModal = ({ open, onClose, width = "400px", height = "350px", mpin, businessId, businessName, isSuccess = false }) => {
  const [timeLeft, setTimeLeft] = useState(120);

  useEffect(() => {
    if (!open) {
      setTimeLeft(120);
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          onClose();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [open, onClose]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const progress = (timeLeft / 120) * 100;

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    boxShadow: 24,
    padding: "24px",
    borderRadius: "12px",
    outline: "none",
    width,
    height,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    position: "relative",
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="mpin-modal">
      <Box sx={modalStyle}>
        {/* Close Button */}
        <CloseButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </CloseButton>

        {/* Circular Timer with Padding */}
        <Box position="relative" display="inline-flex" sx={{ mt: 2, mb: 2 }}>
          <CircularProgress variant="determinate" value={progress} size={90} thickness={5} />
          <Box
            position="absolute"
            top="50%"
            left="50%"
            sx={{
              transform: "translate(-50%, -50%)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6" fontWeight="bold">{`${minutes}:${seconds.toString().padStart(2, "0")}`}</Typography>
          </Box>
        </Box>

        {/* Conditional UI */}
        {isSuccess ? (
          <>
            <Typography variant="h5" fontWeight="bold" sx={{ mt: 2 }}>
              Code: {mpin}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#f4f4f4",
                padding: "12px",
                borderRadius: "8px",
                mt: 3,
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Typography variant="body2" color="textSecondary">
                📌 <strong>This Code will be encrypted</strong> and cannot be retrieved again.
                <br />
                <strong>Business ID:</strong> {businessId} | <strong>Business Name:</strong> {businessName}
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h6" fontWeight="bold" sx={{ mt: 2, mb: 2 }}>
              Generating Code...
            </Typography>
            <CircularProgress size={50} thickness={4} />
          </>
        )}
      </Box>
    </Modal>
  );
};
