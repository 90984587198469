import React from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import { CSVLink } from "react-csv";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from '@material-ui/core/IconButton';
import {ReactComponent as NoteIconeTable} from "../../images/TRNoteIcon.svg";
import {ReactComponent as TRTableDeleteIcone} from "../../images/TRTableDeleteIcon.svg"
import {ReactComponent as TRTableEditIcon} from "../../images/TRTableEditIcon.svg"
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const headCells = [
  { id: "requestDate", disablePadding: true, label: "Order Date (IST)"},
  { id: "serviceRequestId", disablePadding: true, label: "Order Id" },
  { id: "consumerId", disablePadding: true, label: "Consumer Id" },
  { id: "consumerName", disablePadding: true, label: "Consumer Name"},
  { id: "cashfreeVendorId", disablePadding: true, label: "Vendor Id" },
  { id: "businessId", disablePadding: true, label: "Business Id" },
  { id: "businessName", disablePadding: true, label: "Business Name" },
  { id: "location", disablePadding: true, label: "City/Location" },
  { id: "currentStatus", disablePadding: true, label: "Current Status" },
  { id: "orderTotalBeforeDisc", disablePadding: true, label: "Order Total Before Discount (₹)" },
  { id: "itemLevelDisc", disablePadding: true, label: "Item Level Discount (₹)" },
  { id: "cartDisc", disablePadding: true, label: "Cart Discount (₹)"},
  // { id: "couponAmount", disablePadding: true, label: "Coupon Amount (₹)" },
  { id: "businessCoupon", disablePadding: true, label: "Business Sponsored Coupon Amount (₹)" },
  { id: "SRCouponStatus", disablePadding: true, label: "SR Coupon Status" },
  { id: "couponCode", disablePadding: true, label: "Coupon Code" },
  { id: "netDiscPrice", disablePadding: true, label: "Net Discounted Price (₹)" },
  { id: "totalAdjustments", disablePadding: true, label: "Total Business Adjustments (₹)" },
  // { id: "totalXirifyAdjustments", disablePadding: true, label: "Total Xirify Adjustments (₹)" },
  { id: "totalXirifyAdjustmentsBusiness", disablePadding: true, label: "Total Xirify Adjustments Business (₹)" },
  { id: "totalXirifyAdjustmentsConsumer", disablePadding: true, label: "Total Xirify Adjustments Consumer (₹)" },
  { id: "taxableValue", disablePadding: true, label: "Taxable Value (₹)" },
  { id: "gstRate", disablePadding: true, label: "GST Rate (%)" },
  { id: "cgst", disablePadding: true, label: "CGST (₹)" },
  { id: "sgst", disablePadding: true, label: "SGST (₹)" },
  { id: "igst", disablePadding: true, label: "IGST (₹)" },
  { id: "totalPackagingCharges", disablePadding: true, label: "Packaging Charges" },
  { id: "consumerConvFees", disablePadding: true, label: "Consumer Convenience Charges (₹)" },
  { id: "selfDeliveryCharges", disablePadding: true, label: "Self Delivery Charges (₹)" },
  { id: "deliveryCharges", disablePadding: true, label: "Delivery Charges (₹)" },
  { id: "discountOnDelivery", disablePadding: true, label: "Delivery Discount (₹)" },
  { id: "invoiceValToCust", disablePadding: true, label: "Invoice Value to consumer (₹)" },
  { id: "xirifyCoupon", disablePadding: true, label: "Xirify Sponsored Coupon (₹)" },
  { id: "grossInvoiceValueBusiness", disablePadding: true, label: "Gross Invoice Value Business (₹)" },
  { id: "businessConvFees", disablePadding: true, label: "Convenience Charges (Business) (₹)" },
  { id: "totalChargesToXirify", disablePadding: true, label: "Total Charges To Xirify (₹)" },
  { id: "orderCancelation", disablePadding: true, label: "Order Cancellation" },
  { id: "totalRefundAmount", disablePadding: true, label: "Refund (₹)" },
  { id: "finalPayToBusiness", disablePadding: true, label: "Payable To Business (₹)" }, // old Final Payable to business
  { id: "totalFinalPayToBusiness", disablePadding: true, label: "Final Payable To Business (₹)" }, // new calculations for final payable to business = totalFinalPayToBusiness
  { id: "revenueOfBusiness", disablePadding: true, label: "Revenue of Business (₹)" },
  { id: "isAdvancePaymentToBusiness", disablePadding: true, label: "Advance Payment to Business (₹)" },
  { id: "netPayableToBusiness", disablePadding: true, label: "Net Payable to Business (₹)" },
  { id: "paymentStatus", disablePadding: true, label: "Payment Status" },
  { id: "paymentMethod", disablePadding: true, label: "Payment Method" },
  { id: "paidMarkedBy", disablePadding: true, label: "Paid Marked by (Support/Business)" },
  { id: "paymentDate", disablePadding: true, label: "Payment Date (IST)" },
  { id: "advancePaymentDoneToBusinessTime", disablePadding: true, label: " Advance Payment Date (IST)" },
  { id: "paymentMode", disablePadding: true, label: "Payment Mode" },
  { id: "xpId", disablePadding: true, label: "Xirify Payment Id" },
  { id: "comments", disablePadding: true, label: "Comments(If Added)" },
  { id: "settlementDate", disablePadding: true, label: "Settlement Date (IST)" },
  { id: "pgServiceCharge", disablePadding: true, label: "Pg Service Charge (₹)" },
  { id: "pgServiceTax", disablePadding: true, label: "Pg Service Tax (₹)" },
  { id: "pgSplitServiceCharge", disablePadding: true, label: "Pg Split Service Charge (₹)" },
  { id: "pgSplitServiceTax", disablePadding: true, label: "Pg Split Service Tax (₹)" },
  { id: "netSettlementAmount", disablePadding: true, label: "Net Settlement Amount (₹)" },
  { id: "utrId", disablePadding: true, label: "UTR ID (Transaction ID)" },
  { id: "balPayToXirify", disablePadding: true, label: "Balance Payable To Xirify From Business (₹)" },
  { id: "balPayToBusiness", disablePadding: true, label: "Balance Receivable From Xirify To Business (₹)" },
  { id: "GOVValue", disablePadding: true, label: "GOV (₹)" },
  { id: "source", disablePadding: true, label: "Source" },
  { id: "note", disablePadding: true, label: "Notes" },
  
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy } = props; 

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.tableCellSmall}
          >
            {/* <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            > */}
              {headCell.label}
              {/* {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel> */}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 50%",
  },
}));

const EnhancedTableToolbar = (props) => {
  console.log(" props in table ---->", props)
  

  const classes = useToolbarStyles();
  return (
    
    <Toolbar style={{ paddingLeft: "16px" }}>
      {props.handleGMVFlag ?  <Typography
        className={classes.title}
        variant="subtitle2"
        id="tableTitle"
        component="div"
      >
        Transaction Report ({props.totalCount})<br/>
        GMV({props.totalGOVValue})

      </Typography> :  <Typography
        className={classes.title}
        variant="subtitle2"
        id="tableTitle"
        component="div"
      >
        Transaction Report ({props.totalCount})
    

      </Typography> }
     
      
      <Grid xs = {2}>
      <Grid >
    
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked = {props.handleGMVFlag}
                        onChange={props.handleGMVCall}
                        color="primary"
                      />
                    }
                  /> 
      </Grid>
      <Grid>
    <Typography> GMV</Typography>
    
</Grid>
</Grid>
      
      <Grid xs = {1}>
      <Checkbox
        edge="end" 
        checked={props.isChecked}
        onChange={props.isCheckedHandleChange}
        inputProps={{ 'aria-label': 'controlled' }}
        disabled = {props.APOrdersLengthCheck  === false}
      />
      </Grid>
<Grid xs = {3}>
<Typography style={{fontSize : "13px"}}>{`Select All Unpaid Advance Payment to Buisness`}</Typography>
</Grid>
<Grid xs={3}>
<Button
        variant="contained"
        margin="normal"
        width = "auto"
        color="primary"
        style={{
          color: "white",
          fontFamily: "bold",
          marginRight: "10px",
          textAlign: "center",
          whiteSpace: "nowrap",
        }}
         disabled={props.confirmAdvancePaymentFlag && props.APOrdersLengthCheck  === true ? false : true}
         onClick={props.confirmAdvancePayment}
      >
        CONFIRM ADVANCE PAYMENT
      </Button>
</Grid>
<Grid xs={3}>     
      <Button
        margin="normal"
        style={{
          backgroundColor: "#14B7DD",
          color: "white",
          fontFamily: "bold",
          marginRight: "10px",
          textAlign: "center",
          whiteSpace: "nowrap",
        }}
        onClick={props.fetchCashFreeSettlement}
      >
        Fetch Cashfree Settlement
      </Button>
      </Grid>
      <Typography component="div">
        {props.showDownloadIcon ? (
          <CSVLink
            data={props.data}
            headers={props.headers}
            filename={"Transaction_report.csv"}
            className="btn btn"
            target="_blank"
          >
            <Tooltip title="Export to Excel">
              <img
                alt="Export To Excel"
                src="../../images/exportToExcel.png"
                width="25"
                height="25"
              />
            </Tooltip>
          </CSVLink>
        ) : (
          <Button
            variant="contained"
            margin="normal"
            style={{
              backgroundColor: "green",
              color: "white",
              fontFamily: "bold",
            }}
            onClick={props.fetchPaymentSummaryExcelFile}
          >
            Export
          </Button>
        )}
      </Typography>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  tableCellSmall: {
    fontSize: "12px",
    padding: "6px 0px 6px 16px",
  },
  tableRow: {
    // cursor: 'pointer'
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

export default function EnhancedTable(props) {
  const classes = useStyles();

  const handlePaymentSummarySort = (event, property) => {
    const isAsc = props.orderBy === property && props.order === "asc";
    props.setOrder(isAsc ? -1 : 1);
    props.setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
  };

  const handleAddNotesModal = (data) => {
    props.openAddNotesModal(data);
}
  const handleDeleteNotes = (data)=>{
    props.deleteTRNote();
  }
  const handleDeleteOpen = (e,row) => {props.handleDeleteOpen(e,row) };
  const handleDeleteClose = () => { props.handleDeleteClose() };
  
  let headers = [
    { key: "requestDate", label: "Order Date (IST)" },
    { key: "serviceRequestId", label: "Order Id" },
    { key: "consumerId", label: "Consumer Id" },
    { key: "consumerName", label: "Consumer Name" },
    { key: "cashfreeVendorId", label: "Vendor Id" },
    { key: "businessId", label: "Business Id" },
    { key: "businessName", label: "Business Name" },
    { key: "location", label: "City/Location" },
    { key: "currentStatus", label: "Current Status" },
    { key: "orderTotalBeforeDisc", label: "Order Total Before Discount (₹)" },
    { key: "itemLevelDisc", label: "Item Level Discount (₹)" },
    { key: "cartDisc", label: "Cart Discount (₹)" },
    // { key: "couponAmount", label: "Coupon Amount (₹)" },
    { key: "businessCoupon", label: "Business Sponsored Coupon Amount (₹)" },
    { key: "SRCouponStatus", label: "SR Coupon Status" },
    { key: "couponCode", label: "Coupon Code" },
    { key: "netDiscPrice", label: "Net Discounted Price (₹)" },
    { key: "totalAdjustments", label: "Total Business Adjustments (₹)" },
    // { key: "totalXirifyAdjustments", label: " Total Xirify Adjustments (₹)" },
    { key: "totalXirifyAdjustmentsBusiness", label: "Total Xirify Adjustments Business (₹)" },
    { key: "totalXirifyAdjustmentsConsumer", label: "Total Xirify Adjustments Consumer (₹)" },
    { key: "taxableValue", label: "Taxable Value (₹)" },
    { key: "gstRate", label: "GST Rate (%)" },
    { key: "cgst", label: "CGST (₹)" },
    { key: "sgst", label: "SGST (₹)" },
    { key: "igst", label: "IGST (₹)" },
    { key: "totalPackagingCharges", label: "Packaging Charges" },
    { key: "consumerConvFees", label: "Consumer Convenience Charges (₹)" },
    { key: "selfDeliveryCharges", label: "Self Delivery Charges (₹)" },
    { key: "deliveryCharges", label: "Delivery Charges (₹)" },
    { key: "discountOnDelivery",  label: "Delivery Discount (₹)" },
    { key: "invoiceValToCust", label: "Invoice Value to consumer (₹)" },
    { key: "xirifyCoupon", label: "Xirify Sponsored Coupon (₹)" },
    { key: "grossInvoiceValueBusiness", label: "Gross Invoice Value Business (₹)" },
    { key: "businessConvFees", label: "Convenience Charges (Business) (₹)" },
    { key: "totalChargesToXirify", label: "Total Charges To Xirify (₹)" },
    { key: "orderCancelation", label: "Order Cancellation" },
    { key: "totalRefundAmount", label: "Refund (₹)" },
    { key: "finalPayToBusiness", label: "Payable To Business (₹)" }, // old Final Payable to business
    { key: "totalFinalPayToBusiness", label: "Final Payable To Business (₹)" }, // new calculations for final payable to business = totalFinalPayToBusiness
    { key: "revenueOfBusiness", label: "Revenue of Business (₹)" },
    { key: "isAdvancePaymentToBusiness", label: "Advance Payment to Business (₹)" },
    { key: "isAdvancePaymentDoneToBusiness", label: "Advance Payment To Business Status" },
    { key: "netPayableToBusiness", label: "Net Payable to Business (₹)" },
    { key: "paymentStatus", label: "Payment Status" },
    { key: "paymentMethod", label: "Payment Method" },
    { key: "paidMarkedBy", label: "Paid Marked by (Support/Business)" },
    { key: "paymentDate", label: "Payment Date (IST)" },
    { key: "advancePaymentDoneToBusinessTime", label: " Advance Payment Date (IST)" },
    { key: "paymentMode", label: "Payment Mode" },
    { key: "xpId", label: "Xirify Payment ID" },
    // { key: "paymentMethod", label: "Method(Net Banking/Wallet/CC/DC)" },
    { key: "comments", label: "Comments(If Added)" },
    { key: "settlementDate", label: "Settlement Date (IST)" },
    { key: "pgServiceCharge", label: "Pg Service Charge (₹)" },
    { key: "pgServiceTax", label: "Pg Service Tax (₹)" },
    { key: "pgSplitServiceCharge", label: "Pg Split Service Charge (₹)" },
    { key: "pgSplitServiceTax", label: "Pg Split Service Tax (₹)" },
    { key: "netSettlementAmount", label: "Net Settlement Amount (₹)" },
    { key: "utrId", label: "UTR ID (Transaction ID)" },
    { key: "balPayToXirify", label: "Balance Payable To Xirify From Business (₹)" },
    { key: "balPayToBusiness", label: "Balance Receivable From Xirify To Business (₹)" },
    { key: "GOVValue", label: "GOV (₹)" },
    { key: "source", disablePadding: true, label: "Source" },
    { key: "trNote", label: "Notes" }
    // { key: "orderDateFrom", label: "Order date From" },
    // { key: "orderDateTo", label: "Order date To" }
  ];

  let data = props.exportToexcel;  

  data &&
    data.paymentsDoneList &&
    data.paymentsDoneList.forEach((item) => {
      /* eslint-disable no-self-assign */
      item.requestDate = item.requestDate;
      item.serviceRequestId = item.serviceRequestId;
      item.consumerId = item.consumerId;
      item.consumerName = item.consumerName;
      item.cashfreeVendorId = item.cashfreeVendorId;
      item.businessId = item.businessId;
      item.businessName = item.businessName;
      item.location = item.location;
      item.currentStatus = item.currentStatus;
      item.orderTotalBeforeDisc = item.orderTotalBeforeDisc;
      item.itemLevelDisc = item.itemLevelDisc;
      item.cartDisc = item.cartDisc;
      // item.couponAmount = item.couponAmount;
      item.businessCoupon = item.businessCoupon;
      item.SRCouponStatus = item.SRCouponStatus;
      item.couponCode = item.couponCode;
      item.netDiscPrice = item.netDiscPrice;
      item.totalAdjustments = item.totalAdjustments;
      // item.totalXirifyAdjustments = item.totalXirifyAdjustments;
      item.totalXirifyAdjustmentsBusiness = item.totalXirifyAdjustmentsBusiness;
      item.totalXirifyAdjustmentsConsumer = item.totalXirifyAdjustmentsConsumer;
      item.taxableValue = item.taxableValue;
      item.gstRate = item.gstRate;
      item.cgst = item.cgst;
      item.sgst = item.sgst;
      item.igst = item.igst;
      item.totalPackagingCharges = item.totalPackagingCharges;
      item.consumerConvFees = item.consumerConvFees;
      item.selfDeliveryCharges = item.selfDeliveryCharges;
      item.deliveryCharges = item.deliveryCharges;
      item.discountOnDelivery = item.discountOnDelivery
      item.invoiceValToCust = item.invoiceValToCust;
      item.xirifyCoupon = item.xirifyCoupon;
      item.grossInvoiceValueBusiness = item.grossInvoiceValueBusiness;
      item.businessConvFees = item.businessConvFees;
      item.totalChargesToXirify = item.totalChargesToXirify;
      item.orderCancelation = item.orderCancelation;
      item.totalRefundAmount = item.totalRefundAmount;
      item.finalPayToBusiness = item.finalPayToBusiness;
      item.isAdvancePaymentToBusiness = item.isAdvancePaymentToBusiness;
      item.isAdvancePaymentDoneToBusiness = item.isAdvancePaymentDoneToBusiness;
      item.netPayableToBusiness = item.netPayableToBusiness;
      item.totalFinalPayToBusiness = item.totalFinalPayToBusiness;
      item.revenueOfBusiness = item.revenueOfBusiness;
      item.paymentStatus = item.paymentStatus;
      item.paymentMethod = item.paymentMethod && item.paymentMethod === "PAIDBYXIRIFY" ? "Paid By Xirify" : item.paymentMethod === "GIFTFROMXIRIFY" ? "Gift From Xirify" :  item.paymentMethod === "COMPENSATEDBYXIRIFY" ? "Compensated By Xirify":  item.paymentMethod === "ADVANCEPAYMENTTOBUSINESS" ? "Advance Payment To Business": item.paymentMethod === "BANKTRANSFER" ? "Bank Transfer" : item.paymentMethod === "CASH" ? "Cash":item.paymentMethod === "CHEQUE" ? "Cheque": item.paymentMethod === "OTHER" ? "Other": item.paymentMethod === "UPI" ? "UPI" :  item.paymentMethod === "UPIDIRECT" ? "UPI_Direct" : item.paymentMethod === "WALLET" ? "WALLET": item.paymentMethod === "NET_BANKING" ? "Net Banking": item.paymentMethod === "DEBIT_CARD" ? "Debit Card":  item.paymentMethod === "CREDIT_CARD" ? "Credit Card"  : item.paymentMode === "RESETPAYMENT" ? "Reset Payment" :item.paymentMethod;
      item.paidMarkedBy = item.paidMarkedBy;
      item.paymentDate = item.paymentDate;
      item.advancePaymentDoneToBusinessTime = item.advancePaymentDoneToBusinessTime;
      item.paymentMode =  item.paymentMode === "PAIDBYXIRIFY" ? "Xirify Payments" : item.paymentMode === "GIFTFROMXIRIFY" ? "Xirify Payments" :  item.paymentMode === "COMPENSATEDBYXIRIFY" ? "Xirify Payments":  item.paymentMode === "ADVANCEPAYMENTTOBUSINESS" ? "Xirify Payments": item.paymentMode === "BANKTRANSFER" ? "Direct Payment" : item.paymentMode === "CASH" ? "Direct Payment":item.paymentMode === "CHEQUE" ? "Direct Payment": item.paymentMode === "OTHER" ? "Direct Payment": item.paymentMode === "UPI" && item.pgMethod === "CashFree" ? "Online Payment" : item.paymentMode === "UPIDIRECT" ? "Direct Payment" : item.paymentMode === "WALLET" ? "Online Payment": item.paymentMode === "NET_BANKING" ? "Online Payment": item.paymentMode === "DEBIT_CARD" ? "Online Payment": item.paymentMode === "CREDIT_CARD" ? "Online Payment"  : item.paymentMode === "RESETPAYMENT" ? "Xirify Payments" : item.paymentMode; 
      // item.paymentMode = item.paymentMethod;
      item.comments = item.comments;
      item.settlementDate = item.settlementDate;
      item.pgServiceCharge = item.pgServiceCharge;
      item.pgServiceTax = item.pgServiceTax;
      item.pgSplitServiceCharge = item.pgSplitServiceCharge;
      item.pgSplitServiceTax = item.pgSplitServiceTax;
      item.netSettlementAmount = item.netSettlementAmount;
      item.utrId = item.utrId;
      item.balPayToXirify = item.balPayToXirify;
      item.balPayToBusiness = item.balPayToBusiness;
      item.xpId = item.xpId;
      item.GOVValue = item.GOVValue;
      item.trNote = item.trNote;
      item.source = item?.source;
      /* eslint-enable no-self-assign */
    
    });

  if (!data) data = { paymentsDoneList: [] };
  const emptyRows = props.rowsPerPage - Math.min( props.rowsPerPage, props.data.totalCount - props.page * props.rowsPerPage );
  
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          totalCount={props.data.totalCount}
          headers={headers}
          // data={data?.exportData}
          data={data.paymentsDoneList}
          showDownloadIcon={props.showDownloadIcon}
          fetchPaymentSummaryExcelFile={props.fetchPaymentSummaryExcelFile}
          fetchCashFreeSettlement={props.fetchCashFreeSettlement}
          confirmAdvancePayment={props.confirmAdvancePayment}
          handleSelectedAP = {props.handleSelectedAP}
          setSelected={props.setSelected}
          isChecked = {props.isCheckedFlag}
          handleSelectAllClick = {props.handleSelectAllClick}
          isCheckedHandleChange = {props.isCheckedHandleChange}
          confirmAdvancePaymentFlag = {props.confirmAdvancePaymentFlag}
          APOrdersLengthCheck = {props.APOrdersLengthCheck}
          totalGOVValue = {props.totalGMV}
          handleGMVCall = {props.handleGMVCall}
          handleGMVFlag = {props.handleGMVFlag}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={props.order}
              orderBy={props.orderBy}
              onRequestSort={handlePaymentSummarySort}
              rowCount={props.data.totalCount}
              
            />         
            <TableBody>
              {/* {stableSort(props.data.data, getComparator(props.order, props.orderBy)) */}
              {props.data.paymentsDoneList.map((row, index) => {             
                  const isOrderSelected = props.isCheckedFlag;    
                const labelId = `enhanced-table-checkbox-${index}`;
            
                return (
                  <TableRow
                    hover
                    className={classes.tableRow}
                    role="checkbox"
                    tabIndex={-1}
                    key={index + row._id + index}
                  >
                    <TableCell align="left"> {row.requestDate} </TableCell>
                    <TableCell align="left"> {row.serviceRequestId} </TableCell>
                    {/* <TableCell align="left"> {row?.consumerData[0]?.consumerId || "NA"} </TableCell>
                    <TableCell align="left"> {row?.consumerData[0]?.firstName || "NA"} </TableCell>
                    <TableCell align="left"> {row?.consumerData[0]?.lastName || "NA"} </TableCell> */}
                    <TableCell align="left"> {row.consumerId} </TableCell>
                    <TableCell align="left"> {row.consumerName} </TableCell>
                    <TableCell align="left"> {row.cashfreeVendorId} </TableCell>
                    <TableCell align="left"> {row.businessId} </TableCell>
                    <TableCell align="left"> {row.businessName} </TableCell>
                    <TableCell align="left"> {row.location} </TableCell>
                    <TableCell align="left"> {row.currentStatus} </TableCell>
                    <TableCell align="left"> {row.orderTotalBeforeDisc} </TableCell>
                    <TableCell align="left"> {row.itemLevelDisc} </TableCell>
                    <TableCell align="left"> {row.cartDisc} </TableCell>
                    {/* <TableCell align="left"> {row.couponAmount} </TableCell> */}
                    <TableCell align="left"> {row.businessCoupon} </TableCell>
                    <TableCell align="left"> {row.SRCouponStatus} </TableCell>
                    <TableCell align="left"> {row.couponCode} </TableCell>
                    <TableCell align="left"> {row.netDiscPrice} </TableCell>
                    <TableCell align="left"> {row.totalAdjustments} </TableCell>
                    {/* <TableCell align="left"> {row.totalXirifyAdjustments} </TableCell> */}
                    <TableCell align="left"> {row.totalXirifyAdjustmentsBusiness} </TableCell>
                    <TableCell align="left"> {row.totalXirifyAdjustmentsConsumer} </TableCell>
                    <TableCell align="left"> {row.taxableValue} </TableCell>
                    <TableCell align="left"> {row.gstRate} </TableCell>
                    <TableCell align="left"> {row.cgst} </TableCell>
                    <TableCell align="left"> {row.sgst} </TableCell>
                    <TableCell align="left"> {row.igst} </TableCell>
                    <TableCell align="left"> {row.totalPackagingCharges} </TableCell>
                    <TableCell align="left"> {row.consumerConvFees} </TableCell>
                    <TableCell align="left"> {row.selfDeliveryCharges} </TableCell>
                    <TableCell align="left"> {row.deliveryCharges} </TableCell>
                    <TableCell align="left"> {row.discountOnDelivery} </TableCell>
                    <TableCell align="left"> {row.invoiceValToCust} </TableCell>
                    <TableCell align="left"> {row.xirifyCoupon} </TableCell>
                    <TableCell align="left"> {row.grossInvoiceValueBusiness} </TableCell>
                    <TableCell align="left"> {row.businessConvFees} </TableCell>
                    <TableCell align="left"> {row.totalChargesToXirify} </TableCell>
                    <TableCell align="left"> {row.orderCancelation} </TableCell>
                    <TableCell align="left"> {row.totalRefundAmount} </TableCell>
                    <TableCell align="left"> {row.finalPayToBusiness} </TableCell>
                    <TableCell align="left"> {row.totalFinalPayToBusiness} </TableCell>
                    <TableCell align="left"> {row.revenueOfBusiness} </TableCell>
                    {row.paymentStatus === "Advance Paid" && row.currentStatus !== "CANCELLED" && row.isAdvancePaymentDoneToBusiness === "No" ? <TableCell align="left">
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        {row.isAdvancePaymentToBusiness}
                        <Checkbox
                          checked={props.isSelected(row.serviceRequestId) && isOrderSelected ? isOrderSelected : props.isSelected(row.serviceRequestId)}
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={(event) => props.handleSelectedAP(row, index, event.target.checked)}
                        />
                      </span>
                    </TableCell> : row.isAdvancePaymentDoneToBusiness === "Yes" ? <TableCell align="left" style={{ color: "#00CC00" }}> {row.isAdvancePaymentToBusiness}</TableCell> : <TableCell align="left"> {row.isAdvancePaymentToBusiness}</TableCell>}

                    <TableCell align="left"> {row.netPayableToBusiness} </TableCell>
                    <TableCell align="left"> {row.paymentStatus} </TableCell>
                    <TableCell align="left"> {row.paymentMethod && row.paymentMethod === "PAIDBYXIRIFY" ? "Paid By Xirify" : row.paymentMethod === "GIFTFROMXIRIFY" ? "Gift From Xirify" :  row.paymentMethod === "COMPENSATEDBYXIRIFY" ? "Compensated By Xirify"  :  row.paymentMethod === "ADVANCEPAYMENTTOBUSINESS" ? "Advance Payment To Business": row.paymentMethod === "BANKTRANSFER" ? "Bank Transfer" : row.paymentMethod === "CASH" ? "Cash":row.paymentMethod === "CHEQUE" ? "Cheque": row.paymentMethod === "OTHER" ? "Other": row.paymentMethod === "UPI" ? "UPI" :  row.paymentMethod === "UPIDIRECT" ? "UPI_Direct" : row.paymentMethod === "WALLET" ? "WALLET": row.paymentMethod === "NET_BANKING" ? "Net Banking": row.paymentMethod === "DEBIT_CARD" ? "Debit Card":  row.paymentMethod === "CREDIT_CARD" ? "Credit Card" :  row.paymentMethod === "RESETPAYMENT" ? "Xirify Payments":row.paymentMethod} </TableCell>            
                    <TableCell align="left"> {row.paidMarkedBy} </TableCell>
                    <TableCell align="left"> {row.paymentDate} </TableCell>                    
                    <TableCell align="left"> {row.advancePaymentDoneToBusinessTime} </TableCell>                    
                    <TableCell align="left"> {row.paymentMethod && row.paymentMethod === "PAIDBYXIRIFY" ? "Xirify Payments" : row.paymentMethod === "GIFTFROMXIRIFY" ? "Xirify Payments" :  row.paymentMethod === "COMPENSATEDBYXIRIFY" ? "Xirify Payments" :  row.paymentMethod === "ADVANCEPAYMENTTOBUSINESS" ? "Xirify Payments": row.paymentMethod === "BANKTRANSFER" ? "Direct Payment" : row.paymentMethod === "CASH" ? "Direct Payment":row.paymentMethod === "CHEQUE" ? "Direct Payment": row.paymentMethod === "OTHER" ? "Direct Payment": row.paymentMethod === "UPI" && row.pgMethod === "CashFree" ? "Online Payment" : row.paymentMethod === "UPIDIRECT" ? "Direct Payment" : row.paymentMethod === "WALLET" ? "Online Payment": row.paymentMethod === "NET_BANKING" ? "Online Payment": row.paymentMethod === "DEBIT_CARD" ? "Online Payment": row.paymentMode} </TableCell>   
                    <TableCell align="left"> {row.xpId} </TableCell>
                    <TableCell align="left"> {row.comments} </TableCell>
                    <TableCell align="left"> {row.settlementDate} </TableCell>
                    <TableCell align="left"> {row.pgServiceCharge} </TableCell>
                    <TableCell align="left"> {row.pgServiceTax} </TableCell>
                    <TableCell align="left"> {row.pgSplitServiceCharge} </TableCell>
                    <TableCell align="left"> {row.pgSplitServiceTax} </TableCell>
                    <TableCell align="left"> {row.netSettlementAmount} </TableCell>
                    <TableCell align="left"> {row.utrId} </TableCell>
                    <TableCell align="left"> {row.balPayToXirify} </TableCell>
                    <TableCell align="left"> {row.balPayToBusiness} </TableCell>
                    <TableCell align="left"> {row.GOVValue} </TableCell>
                    <TableCell align="left"> {row?.source} </TableCell>
                    <TableCell align="left" colSpan={2}  style={{ width: '180px' }} >
                    {row.trNote && row.trNote.trim() !== '' ? 
                       <>
                        <div style={{ width: '95%', display: 'flex', justifyContent: 'flex-end' }}>
                            <div style={{ width: '50%' }}>
                              <p style={{ width: '180px',whiteSpace: 'pre-line' }}>{row.trNote}</p>
                            </div>
                        <div style={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end',paddingLeft:"40px" }}>
                              <IconButton
                                aria-label="edit"
                                onClick={() => handleAddNotesModal(row)}
                                style={{ height: '30px' }}
                              >
                                 <TRTableEditIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                aria-label="delete"
                               // onClick={() => handleDeleteNotes(row)}
                               onClick={(e)=>{handleDeleteOpen(e,row)}} 
                                style={{ height: '30px' }}
                              >
                                 <TRTableDeleteIcone fontSize="small" />
                              </IconButton>
                            </div>
                        </div></>
                         :
                        <IconButton aria-label="edit"
                          onClick={() => handleAddNotesModal(row)}
                          style={{ height: '30px' }}>
                            <NoteIconeTable fontSize="small" />
                        </IconButton>
                        }
                          
                    </TableCell>
                    
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50, 100]}
          component="div"
          count={props.data.totalCount}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <Dialog open={props.TRNoteconfirmationFlag} onClose={handleDeleteClose}  >
                    <DialogTitle>Delete Note</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        Do you want to delete the selected Note?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteClose} color="primary" autoFocus> No </Button>
                        <Button onClick={handleDeleteNotes} color="primary"> Yes </Button>
                    </DialogActions>
                </Dialog> 
      </Paper>
    </div>
  );
}