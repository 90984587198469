import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { 
  Container,
  CssBaseline,
  Typography,
  TextField,
  Button,
  Grid,
  Link,
  Avatar,
  Card,
  CardContent,
  Paper,
  Fade,
  Box
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { forgotPassword, loadForgot, fetchBrandName } from "../../actions/authActions";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#f5f9ff',
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
    h5: {
      fontWeight: 600,
    }
  }
});

const styles = (theme) => ({
  root: {
    minHeight: '100vh',
    background: 'linear-gradient(to right top,rgb(194, 226, 214),rgb(151, 208, 216),rgb(169, 191, 192))',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    boxShadow: '0 8px 30px rgba(0,0,0,0.08)',
    width: '100%',
    maxWidth: 450,
    transition: 'all 0.3s ease',
    background: 'linear-gradient(to right top,rgb(212, 212, 212),rgb(255, 255, 255),rgb(228, 228, 228))',
    backdropFilter: 'blur(5px)',
    '&:hover': {
      boxShadow: '0 12px 40px rgba(0,0,0,0.12)',
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(1),
    fontWeight: 600,
    letterSpacing: 0.5,
    fontSize: '1rem',
    background: 'linear-gradient(90deg, #3f51b5 0%, #2196f3 100%)',
  },
  inputField: {
    '& label.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.spacing(1),
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
  },
  logo: {
    maxHeight: 60,
    width: 'auto',
  },
  card: {
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3),
    textAlign: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  },
  successMessage: {
    color: '#4caf50',
    fontSize: '1rem',
    fontWeight: 500,
  },
  errorMessage: {
    color: '#f44336',
    fontSize: '1rem',
    fontWeight: 500,
  },
  footer: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
});

class Forgot extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      email: ""
    };
  }

  componentDidMount() {
    this.props.loadForgot();
    this.props.fetchBrandName();
  }

  onChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  onSubmit = event => {
    event.preventDefault();
    const forgotPassData = {
      userName: this.state.username,
      email: this.state.email
    };
    this.props.forgotPassword(forgotPassData);
  };

  clickLoginPage = e => {
    e.preventDefault();
    this.props.history.push("/");
  };
  
  render() {
    const { classes } = this.props;
    let webLogo = this.props?.dashboard?.data && this.props?.dashboard?.data.length>0 && this.props?.dashboard?.data[0]?.webLogo 
      ? this.props.dashboard.data[0].webLogo 
      : "../../images/xirify_logo.png";

    let middleDiv;

    if (this.props.auth.passwordReset === null) {
      middleDiv = (
        <form className={classes.form} noValidate onSubmit={this.onSubmit}>
          <TextField
            className={classes.inputField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={this.state.username}
            onChange={this.onChange}
          />
          <TextField
            className={classes.inputField}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={this.state.email}
            onChange={this.onChange}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Reset Password
          </Button>
        </form>
      );
    } else if (this.props.auth.passwordReset === true) {
      middleDiv = (
        <Card className={classes.card}>
          <CardContent>
            <Typography className={classes.successMessage}>
              An email with your new password has been sent. Please check your inbox and use the new password to login.
            </Typography>
          </CardContent>
        </Card>
      );
    } else {
      middleDiv = (
        <Card className={classes.card}>
          <CardContent>
            <Typography className={classes.errorMessage}>
              Error: {this.props.auth.passResetErr}
            </Typography>
          </CardContent>
        </Card>
      );
    }

    return (
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Fade in={true} timeout={500}>
              <Paper className={classes.paper} elevation={3}>
                <div className={classes.logoContainer}>
                  <img 
                    src={webLogo} 
                    alt="E-Commerce Admin" 
                    className={classes.logo}
                  />
                </div>
                <Typography component="h1" variant="h5" align="center" gutterBottom>
                  Forgot Password
                </Typography>
                <Typography variant="body2" color="textSecondary" align="center" paragraph>
                  Enter your username and email to reset your password
                </Typography>
                
                {middleDiv}
                
                <Box mt={3} className={classes.footer}>
                  <Link 
                    href="#" 
                    variant="body2" 
                    onClick={this.clickLoginPage}
                    color="textSecondary"
                  >
                    Back to login
                  </Link>
                </Box>
              </Paper>
            </Fade>
          </Container>
        </div>
      </ThemeProvider>
    );
  }
}

Forgot.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  dashboard: state.dashboard.getMasterOTP,
  brand: state.auth.brand
});

export default connect(
  mapStateToProps, 
  { forgotPassword, loadForgot, fetchBrandName }
)(withStyles(styles)(Forgot));