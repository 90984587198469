import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { grey } from "@material-ui/core/colors";
import MessageView from './MessageView';
import XirifyAdjustmentView from "./XirifyAdjustmentView";
import { changeHeaderName } from '../../actions/dashboardActions';
import { 
    getServiceRequestDetailsById, 
    requestServiceRequests, 
    changeServiceStatus, 
    changePaymentStatus, 
    getRequestDocumentForPaymentDetails,
    createDunzoId, 
    callDunzoIdReset, 
    callDunzoId, 
    cancelDeliveryPartnerTask,
    clearMsg,
    changeInProgressPaymentStatus,
    createRefundRequest,
    createShadowfaxId,
    createPorterId
} from '../../actions/serviceRequestActions';
import Stepper from './OrderProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { getAllMasterOtp, getCancellationReasonForAdminBusiness, callBusinessDashboardCron, callBusinessDashboardCronReset} from '../../actions/dashboardActions';
import { fetchEnvs } from '../../actions/authActions';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ReactComponent as BusinessSVG } from '../../images/ShowtoVender.svg';
import { ReactComponent as ShopperSVG } from '../../images/ShowtoShopper.svg';
import { ReactComponent as VegSVG } from '../../images/veg.svg'
import { ReactComponent as NonvegSVG } from '../../images/Nonveg.svg'
import { ReactComponent as EggSVG } from '../../images/Egg.svg'
import { ReactComponent as RefundSVG } from '../../images/refundSVG.svg'
import IconButton from '@material-ui/core/IconButton';
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CountdownTimer from "./CountdowmTimer";
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'lodash';
import SupportNoteView from './SupportNoteView';
import {ReactComponent as BusinessNote} from "../../images/businessNote.svg";
import {Badge} from "@material-ui/core";
import {ReactComponent as SUPPORTSVG} from '../../images/SupportNotes.svg';
import {ReactComponent as MsgSvgNew} from '../../images/Message1.svg';
import {ReactComponent as AdvanceNew} from "../../images/AdvancePayment1.svg";
import {ReactComponent as XirifyPaymentNew} from "../../images/XirifyPayment1.svg";
import {ReactComponent as AdvGreyNew} from "../../images/Advance_New.svg";
import {ReactComponent as ResGreyNew} from "../../images/Xirify_Adj_New.svg"
import { ReactComponent as CancelDeliveryTask} from "../../images/cancelDeliveryTask.svg";
import { ReactComponent as XirifyIcon} from "../../images/XirifyIcon.svg";
import { ReactComponent as DeliveryCancelNotesIcon} from "../../images/DeliveryCancelNotesIcon.svg";
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import { dunzoreasons } from './DunzoTaskIdCancellationReasonsData';
import {ReactComponent as GreenAP} from "../../images/Green_AP.svg";
import RefundsDetailsView from './RefundsDetailsView';
import ManualDeliveryModal from './ManualDeliveryModal';
import {DunzoStyleDIcon }from "../../images/DeliveryPartnersSvg.js";
import{ ShadowfaxStyleSIcon} from "../../images/DeliveryPartnersSvg.js";
import { PorterStylePIcon  }from "../../images/DeliveryPartnersSvg.js";

const styles = theme => ({
    container: {
        width: '100%',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    tableRow: {
        backgroundColor: grey[200],
        height: 1,
    },

    avatarGrey: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    content: {
        flexGrow: 1,
        // height: '100%',
        overflow: 'auto',
        width: '100%',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },

    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    }, tableCellSmall: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px", //TRBL
        width: '100%',
        textAlign: 'center',
        marginRight: "25px",
        marginLeft: "25px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    root: {
        flexDirection: "row",
        justifyContent: "flex-start",
        width: '100%',
        backgroundColor: "white"
    },
    dialogTitle: {
        backgroundColor: theme.palette.primary.main
    },
    gridItem: {
        padding: "10px",
        // marginBottom: "1rem",
    },
    imgmodal: {
        width: "100px",
        height: "100px",
    },
    hoverZoom: {
        transform: [{ scaleX: .8 }, { scaleY: .8 }]
    },
    text: {
        fontSize: "11px",
        marginTop: "6px",
    },
    buttonPadding :{        
        paddingBottom : "10px",
        float: "right"
    },
    canceldelivery: {
        bottom: 20,
    },
    headGridTwo: {
        float: 'right',
        marginRight: '2px',
        cursor: "pointer"
    },
    cancelNotesContainer: {
        // backgroundColor: "#EEEEEE", 
        padding: "0px 10px", 
        borderRadius: "10px", 
        marginBottom: "10px", 
        paddingTop: "11px", 
        // border: "0.5px solid #FF5764",
        background: "#EEEEEE 0% 0% no-repeat padding-box"
        // opacity: 1
    },
    cancelNoteIcon: {
        padding: "9.5px 1032px 9.5px 22.02px"
    }
});

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messageModalOpen: false,
            xirifyAdjustmentModalOpen: false,
            cancelRequestModalOpen: false,
            advancePaymentModalOpen: false,
            acceptRequestModalOpen: false,
            acceptResetPaymentModalOpen: false,
            completeRequestModalOpen: false,
            completeAdvancePaymentModalOpen: false,
            paidRequestModalOpen: false,
            attachmentModalOpen: false,
            sucessMsg: "",
            errorMsg: "",
            modalOpen: false,
            reason: "",
            flag: "",
            receivedBy: "",
            paymentMethodsFromWeb: "",
            description: "",
            serviceMode: "",
            transaction: "",
            cartDiscount: "",
            xirifyAdjustment: "",
            isRadioSelected: true,
            othersReason: "",
            reasonError: false,
            disableButton: false,
            acceptBtn: false,
            resetBtn: false,
            completeBtn: false,
            paidBtn: false,
            acceptDisabled:false,
            isDisabled: false,
            resetPayment: false,
            prepTime: 0,
            incrementBtn: false,
            supportNoteModalOpen: false,
            cancelDeliveryModalOpenFlag: false,
            cancelDeliveryFlag: false,
            cancelMsg: null,
            cancelErrorMsg: null,
            cancellationReason: "",
            cancelledResponse: null,
            refundDetailsModal: false,
            getLastManualDeliveryInfo: null,
            sucessMsg2 :""
        };
    }
 
    async componentDidMount() {
        this.props.fetchEnvs();
        if(this.props && this.props.dashboard && this.props.dashboard.headerName === "Service Requests"){
          this.props.changeHeaderName("Service Request Details");
        }
        if(this.props && this.props.dashboard && this.props.dashboard.headerName === "Preservice Requests"){
          this.props.changeHeaderName("Preservice Request Details");
        }
        this.props.requestServiceRequests();
        await this.props.getServiceRequestDetailsById(this.props.match.params.serviceRequestId);
        this.props.getAllMasterOtp();
        this.props.getCancellationReasonForAdminBusiness();
       
        

    }

    componentDidUpdate(prevProps,prevState) {
      console.log("this.props ----->", this.props)
        if(this.props && this.props.serviceRequest && this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.business && this.props.serviceRequest.serviceRequestDetails.business.businessPreparationTime !== null && this.props.serviceRequest.serviceRequestDetails.business.businessPreparationTime !== this.state.prepTime && this.state.prepTime === 0){
            this.setState({prepTime: this.props.serviceRequest.serviceRequestDetails.business.businessPreparationTime})
        }

        if (this.props.serviceRequest.cancelMsg) {
		    this.setState({ cancelMsg: this.props.serviceRequest.cancelMsg })
		    this.setState({ snackOpen: true });
		    this.props.clearMsg();
            this.props.getServiceRequestDetailsById(this.props.match.params.serviceRequestId);
		}
        if (this.props.serviceRequest.cancelErrorMsg) {
		    this.setState({ cancelErrorMsg: this.props.serviceRequest.cancelErrorMsg })
		    this.setState({ snackOpen: true });
		    this.props.clearMsg();
            this.props.getServiceRequestDetailsById(this.props.match.params.serviceRequestId);
		}
        if(this.props?.serviceRequest?.sucessMsg && (this.props.serviceRequest.sucessMsg === "Manual delivery data set successfully" || this.props.serviceRequest.sucessMsg === "Manual delivery data set failed")){
          this.setState({ sucessMsg2: this.props.serviceRequest.sucessMsg })
          this.setState({ snackOpen: true });
          this.props.clearMsg();
              this.props.getServiceRequestDetailsById(this.props.match.params.serviceRequestId);
        }
     if(this.props?.serviceRequest?.sucessMsg && (this.props.serviceRequest.sucessMsg !== "Manual delivery data set successfully" || this.props.serviceRequest.sucessMsg !== "Manual delivery data set failed")){
          this.setState({ sucessMsg: this.props.serviceRequest.sucessMsg })
          this.setState({ snackOpen: true });
          this.props.clearMsg();
              this.props.getServiceRequestDetailsById(this.props.match.params.serviceRequestId);
        }    

        
    }

    openCancelRequestModal = () => {
        this.setState({ cancelRequestModalOpen: true });
    }
    openAdvancePaymentModal = () => {
        this.setState({ completeAdvancePaymentModalOpen : true });
    }
    openAcceptRequestModal = () => {
        this.setState({ acceptRequestModalOpen: true });
    }
    openResetPaymentModal = () => {
        this.setState({ acceptResetPaymentModalOpen: true, resetBtn : false });
    }
    openCompleteRequestModal = () => {
        this.setState({ completeRequestModalOpen: true });
    }
    openCompleteAdvancePaymentModal = () => {
        this.setState({ completeAdvancePaymentOpen: true });
    }

    openPaidRequestModal = () => {
        this.setState({ paidRequestModalOpen: true, paymentMethodsFromWeb: "", receivedBy: "", description: "" });
    }

    openAttachmentModal = () => {
        this.setState({ attachmentModalOpen: true });
    }

    openRefundDetailsModal = () => {
        this.setState({ refundDetailsModal: true });
    }
    closeRefundDetailsModal = () => {
        this.setState({ refundDetailsModal: false });
    }
    closeAttachmentModal = () => {
        this.setState({ attachmentModalOpen: false });
    }
    openManualDeliveryModal = () => {  
   
      this.setState({ openManualDeliveryModal: true });
      }
    closeManualDeliveryModal = () => {
        this.setState({ openManualDeliveryModal: false });
    }

    closeCancelRequestModal = () => {
        this.setState({
            cancelRequestModalOpen: false,
            reason: "",
            isRadioSelected: true,
            othersReason: "",
            reasonError: false,
            paymentMethodsFromWeb: "",
            receivedBy: "",
            description: ""
        });
    }

    closeAcceptRequestModal = () => {
        this.setState({ acceptRequestModalOpen: false,prepTime: this.props && this.props.serviceRequest && this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.business && this.props.serviceRequest.serviceRequestDetails.business.businessPreparationTime });
    }
    closeResetPaymentModal = () => {
        this.setState({ acceptResetPaymentModalOpen: false });
    }

    closeCompleteRequestModal = () => {
        this.setState({ completeRequestModalOpen: false });
    }

    closeAdvancePaymentModal = () => {
        this.setState({ completeAdvancePaymentModalOpen : false });
    }
    closePaidRequestModal = () => {
        this.setState({ paidRequestModalOpen: false });
    }

    openMessageModal = () => {
        this.setState({ messageModalOpen: true });
    }
    openSupportNoteModal = () => {
        this.setState({ supportNoteModalOpen: true });
    }
    openXirifyAdjustmentModalOpen = () => {
     
        this.setState({ xirifyAdjustmentModalOpen: true });
    }

    closeMessageModal = () => {
        this.setState({ messageModalOpen: false });
    }

    closeSupportNoteModal = () => {
        this.setState({ supportNoteModalOpen: false });
    }
    closeXirifyAdjustmentModalOpen = () => {
        this.setState({ xirifyAdjustmentModalOpen: false });
    }

    cancelDeliveryModalOpen = () => {
        this.setState({ cancelDeliveryModalOpenFlag: true });
    };

    cancelDeliveryModalCloseFlag = () => {
        this.setState({ cancelDeliveryModalOpenFlag: false, cancelDeliveryFlag: false, cancellationReason: "", isDunzoReasonSelected: false });
    };

    handleSnackClose = () => {
		this.setState({ 
            snackOpen: false,
            cancelMsg: null,
            cancelErrorMsg: null
        });
	};

    handleReasonChange = (event) => {
        this.setState({
            reason: event.target.value,
            // isRadioSelected: true
            reasonError: false,
        })
    };

    handleReasonChangeRadio = (event) => {
        this.setState({
            reason: event.target.value,
            isRadioSelected: true,
            othersReason: "",
            reasonError: false,
        })
    };

    changeHandler = () => {
        this.setState({ isRadioSelected: false, reason: "", othersReason: "Others" });
    };

    handlePaymentReceivedChange = (event) => {
        // if (!/\s/.test(event.target.value)) {
        //     this.setState({
        //         receivedBy: event.target.value
        //     });
        //   } 
     
            this.setState({
                receivedBy: event.target.value
            })
     
    }
    handlePaymentMethodsFromWebChange = (event) => {
       
     
            this.setState({
                paymentMethodsFromWeb: event.target.value
            })
        
        
    }

    handlePaymentDescriptionChange = (event) => {
        
        // if (!/\s/.test(event.target.value)) {
        //     this.setState({
        //         description: event.target.value
        //     });
        //   }
        this.setState({
            description: event.target.value
        })
    }

    handleFilterChange = (event) => {
        this.setState({
            serviceMode: event.target.value
        })
    }

    handleDunzoCancelReasons = (event) => {
        this.setState({ 
            isDunzoReasonSelected: true, 
            cancellationReason: event.target.value
        });
    };

    cancelDeliveryPartnerTask = async () => {
        console.log("==== inside cancelDeliveryPartnerTask =====");
        await this.setState({ cancelDeliveryFlag: true });
       

        let data = {            
            serviceRequestId: this.props.serviceRequest.serviceRequestDetails._id,
            deliveryType: this.props.serviceRequest.serviceRequestDetails.deliveryType,
            deliveryCharges: this.props.serviceRequest.serviceRequestDetails.deliveryCharges, // no change here
            discountOnDelivery: this.props.serviceRequest.serviceRequestDetails.discountOnDelivery,
            previousPartnerTaskId: this.props.serviceRequest.serviceRequestDetails.partnerTaskId, // previous id should be deleted/removed ie should be an empty string or field to be removed from db
            preferredServiceMode: this.props.serviceRequest.serviceRequestDetails.preferredServiceMode,
            serviceMode: this.props.serviceRequest.serviceRequestDetails.serviceMode,
            cancellation_reason: this.state.cancellationReason && this.state.cancellationReason !== null && this.state.cancellationReason !== undefined ? this.state.cancellationReason : (this.props.serviceRequest.serviceRequestDetails.deliveryType && this.props.serviceRequest.serviceRequestDetails.deliveryType === 'dunzo' ? 'Raised order by mistake' : "")

        };

    

        let response = await this.props.cancelDeliveryPartnerTask(data);
       

        this.setState({cancelDeliveryModalOpenFlag: false, cancellationReason: "", isDunzoReasonSelected: false, cancelledResponse: response });
        // this.props.getServiceRequestDetailsById(this.props.match.params.serviceRequestId);
    };

    cancelRequest = async () => {
      this.props.callBusinessDashboardCron();
      this.setState({ disableButton: true, cancelRequestModalOpen: false});
         

        let hasError = false;

        if (!this.state.reason || this.state.reason.trim().length === 0) {
            hasError = true;
            this.setState({ reasonError: true });
        }

        if (this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.serviceMode && this.props.serviceRequest.serviceRequestDetails.serviceMode === 'DELIVERY' && this.props.serviceRequest.serviceRequestDetails.deliveryType && (this.props.serviceRequest.serviceRequestDetails.deliveryType === 'dunzo' || this.props.serviceRequest.serviceRequestDetails.deliveryType === 'borzo' || this.props.serviceRequest.serviceRequestDetails.deliveryType === 'porter' || this.props.serviceRequest.serviceRequestDetails.deliveryType === 'shadowfax') && this.props.serviceRequest.serviceRequestDetails.isCancelDeliveryPartnerTask !== null && this.props.serviceRequest.serviceRequestDetails.isCancelDeliveryPartnerTask !== undefined && !this.props.serviceRequest.serviceRequestDetails.isCancelDeliveryPartnerTask) {
            await this.cancelDeliveryPartnerTask();
        }

        if (!hasError) {
            let trimedReason = this.state.reason.trim();
            let payload = {
                serviceRequestId: this.props.serviceRequest.serviceRequestDetails._id,
                reason: trimedReason,
                flag: 'cancel'
            };

            await this.props.changeServiceStatus(payload);
            this.setState({
              reason: "",
              cancelRequestModalOpen: false,
              othersReason: ""
          })
            if (this.props?.serviceRequest?.serviceRequestDetails?.isOrderWithPrePayment && this.props?.serviceRequest?.serviceRequestDetails?.paymentStatus === "PAID") {
                let totalRefundAmount = 0;
                if (this.props?.serviceRequest?.serviceRequestDetails?.cfRefundResponses.length) {
                    let uniqueRefunds = new Map();
                    (this.props?.serviceRequest?.serviceRequestDetails?.cfRefundResponses || []).forEach(item => {
                        const existingItem = uniqueRefunds.get(item.refund_id);
                        if (!existingItem || item.refund_status === "SUCCESS" || existingItem.refund_status === "PENDING") {
                            uniqueRefunds.set(item.refund_id, item);
                        }
                    });

                    let result = Array.from(uniqueRefunds.values());
                    if (result?.length) {
                        result.forEach(element => {
                            totalRefundAmount = totalRefundAmount + element.refund_amount

                        })
                    }
                }
               
                let payloadForRefund = {
                    id: this.props.serviceRequest.serviceRequestDetails._id,
                    amount: this.props?.serviceRequest?.serviceRequestDetails?.amount ? this.props.serviceRequest.serviceRequestDetails.amount - (this.props?.serviceRequest?.serviceRequestDetails?.couponAmount ? this.props.serviceRequest.serviceRequestDetails.couponAmount : 0 + totalRefundAmount) : 0,
                    refund_note: trimedReason
                }
                await this.props.createRefundRequest(payloadForRefund);
            }
            this.props.requestServiceRequests();
            this.props.callBusinessDashboardCronReset()
            this.props.getServiceRequestDetailsById(this.props.match.params.serviceRequestId);

           
        }
    };

    acceptRequest = async () => {
        this.setState({ acceptBtn: true });
        let payload1 = {
            serviceRequestId: this.props.serviceRequest.serviceRequestDetails._id,
            flag: 'accept',
            // serviceMode: this.state.serviceMode ? this.state.serviceMode : this.props.serviceRequest.serviceRequestDetails.preferredServiceMode
            serviceMode: this.props.serviceRequest.serviceRequestDetails.preferredServiceMode,
            deliveryType: this.props.serviceRequest.serviceRequestDetails.deliveryType ? this.props.serviceRequest.serviceRequestDetails.deliveryType : null,
            cartDiscount: this.state.cartDiscount,
            isDeliveryDiscountedAmount : true,
            prepTime: this.state.prepTime
        };

        let payload2 = {
            serviceRequestId: this.props.serviceRequest.serviceRequestDetails._id,
            flag: 'accept',
            // serviceMode: this.state.serviceMode ? this.state.serviceMode : this.props.serviceRequest.serviceRequestDetails.preferredServiceMode
            serviceMode: this.props.serviceRequest.serviceRequestDetails.preferredServiceMode,
            deliveryType: this.props.serviceRequest.serviceRequestDetails.deliveryType ? this.props.serviceRequest.serviceRequestDetails.deliveryType : null,
            cartDiscount: this.state.cartDiscount,
            isDeliveryDiscountedAmount : true,
        };

        this.setState({ acceptDisabled: false })
        if(this.props && this.props.serviceRequest && this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.business && this.props.serviceRequest.serviceRequestDetails.business.isBusinessPreparationTimerEnabled){
            await this.props.changeServiceStatus(payload1);
        }else{
            await this.props.changeServiceStatus(payload2);
        }

        this.props.requestServiceRequests();
        this.props.getServiceRequestDetailsById(this.props.match.params.serviceRequestId);
        this.setState({ acceptRequestModalOpen: false })
    }

     resetPayment = async () => {
        this.setState({ resetBtn: true });           
                     
              let payload = {
                serviceRequestId: this.props.serviceRequest.serviceRequestDetails._id,
                flag: 'initiated',
                paymentMethod: this.props.serviceRequest.serviceRequestDetails.paymentDetails.paymentMethod,
                button: 'resetPayment',
                resetPaymentBy : `${this.props.user.firstName ? this.props.user.firstName : ""} ${this.props.user.lastName ? this.props.user.lastName : ""}`                
                };    
           
            await this.props.changePaymentStatus(payload);
            this.props.requestServiceRequests();
            this.props.getServiceRequestDetailsById(this.props.match.params.serviceRequestId);
            this.setState({ acceptResetPaymentModalOpen: false})
        
    }

    completeRequest = async () => {       
        let payload = {
            serviceRequestId: this.props.serviceRequest.serviceRequestDetails._id,
            flag: 'complete',
            directcomplete: true
        };
        await this.props.changeServiceStatus(payload);
        this.props.requestServiceRequests();
        this.props.getServiceRequestDetailsById(this.props.match.params.serviceRequestId);
        this.setState({ completeRequestModalOpen: false })
    }

    completeAdvancePayment = async ()=>{        
        let payload = {
            serviceRequestId: this.props.serviceRequest.serviceRequestDetails._id,    
            paymentMethod: "ADVANCEPAYMENTTOBUSINESS", 
            advancePaidBy : `${this.props.user.firstName ? this.props.user.firstName : ""} ${this.props.user.lastName ? this.props.user.lastName : ""}`                
            
        };
        await this.props.changePaymentStatus(payload);
        this.props.requestServiceRequests();
        this.props.getServiceRequestDetailsById(this.props.match.params.serviceRequestId);      
        this.setState({ completeAdvancePaymentModalOpen : false })

    }
    paidRequest = async () => {
        this.setState({ paidBtn: true });
          // payment methods= cod, bank transfer, upi, cheque, other(gpay, phone pe, paytm, amazon pay)
        if (this.props.serviceRequest.serviceRequestDetails.paymentStatus === "INITIATED") {            
            let payload = {
                serviceRequestId: this.props.serviceRequest.serviceRequestDetails._id,
                flag: 'initiated',
                paymentMethod: this.props.serviceRequest.serviceRequestDetails.paymentDetails.paymentMethod
            };

            await this.props.changePaymentStatus(payload);
            this.props.requestServiceRequests();
            this.props.getServiceRequestDetailsById(this.props.match.params.serviceRequestId);
            this.setState({ paidRequestModalOpen: false })

        } else {
            console.log("first else")
        }

        if (this.props.serviceRequest.serviceRequestDetails.paymentStatus === "UNPAID" || this.props.serviceRequest.serviceRequestDetails.paymentStatus === "INITIATED" || this.props.serviceRequest.serviceRequestDetails.paymentStatus === "ADVANCEPAID") {
        // if (this.props.serviceRequest.serviceRequestDetails.paymentStatus === "UNPAID" || this.props.serviceRequest.serviceRequestDetails.paymentStatus === "ADVANCEPAID") {
            let payload = {
                serviceRequestId: this.props.serviceRequest.serviceRequestDetails._id,
                flag: 'unpaid',
                paymentMethod: this.state.paymentMethodsFromWeb,
                receivedBy: this.state.receivedBy.trim(),
                notes: this.state.description.trim()                   
                
            };
            await this.props.changePaymentStatus(payload);
            this.props.requestServiceRequests();
            this.props.getServiceRequestDetailsById(this.props.match.params.serviceRequestId);
            this.setState({ paidRequestModalOpen: false });
        } else {
            console.log("second else")
        }

        if(this.props.serviceRequest.serviceRequestDetails.paymentStatus === "INPROGRESS") {
            

            let payload = {
                serviceRequestId: this.props.serviceRequest.serviceRequestDetails._id,
                flag: 'inprogress',
                xpId: this.props.serviceRequest.serviceRequestDetails.pendingPaymentxpId
            };

            await this.props.changeInProgressPaymentStatus(payload);
            this.props.requestServiceRequests();
            this.props.getServiceRequestDetailsById(this.props.match.params.serviceRequestId);
            this.setState({ paidRequestModalOpen: false })
        }
    }

    getTransactionImages = async (transactionMethod) => {
                let payload = {
            serviceRequestId: this.props.match.params.serviceRequestId,
            transactionMethod: transactionMethod,
        }
        let imageresponse = await this.props.getRequestDocumentForPaymentDetails(payload);
        let answer = "data:image/png;base64," + imageresponse;

        this.setState({ transaction: answer ? answer : "" });
       
    }

    calculateSubtraction = (totalAmount, couponAmount) => {
        let amount = totalAmount - couponAmount;
        amount = amount.toFixed(2);
        return amount;
    };

    calDiscount = (off, quantityOfService) => {
        let discountAmount = off * quantityOfService;
        discountAmount = discountAmount.toFixed(2);
        return discountAmount;
    };
     
    createDuzoId = async () => {
        this.props.callDunzoId();
        this.setState({ isDisabled: true, cancelDeliveryFlag: false })
        let payload = {
            _id: this.props.serviceRequest.serviceRequestDetails._id, 
        };
        await this.props.createDunzoId(payload);
        
        this.props.getServiceRequestDetailsById(this.props.match.params.serviceRequestId);
        this.setState({ isDisabled: false });
        // this.setState({ completeRequestModalOpen: false })
        // this.props.callDunzoIdReset();
    }
    createShadowfaxId = async () => {
      this.props.callDunzoId();
      this.setState({ isDisabled: true, cancelDeliveryFlag: false })
      let payload = {
          _id: this.props.serviceRequest.serviceRequestDetails._id, 
      };
      await this.props.createShadowfaxId(payload);
      
      this.props.getServiceRequestDetailsById(this.props.match.params.serviceRequestId);
      this.setState({ isDisabled: false });
      // this.setState({ completeRequestModalOpen: false })
      // this.props.callDunzoIdReset();
  }
    createPorterId = async () => {
      this.props.callDunzoId();
      this.setState({ isDisabled: true, cancelDeliveryFlag: false })
      let payload = {
          _id: this.props.serviceRequest.serviceRequestDetails._id, 
      };
      await this.props.createPorterId(payload);
      
      this.props.getServiceRequestDetailsById(this.props.match.params.serviceRequestId);
      this.setState({ isDisabled: false });
      // this.setState({ completeRequestModalOpen: false })
      // this.props.callDunzoIdReset();
  }

    incrementPrepTime = async () => {
        if(this.props && this.props.serviceRequest && this.props.serviceRequest && this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.business && this.props.serviceRequest.serviceRequestDetails.business.businessPreparationTimeMax === this.state.prepTime){
            this.setState({
                incrementBtn: true
            })
        }else{
            this.setState({
                prepTime: this.state.prepTime + 5
            });
        }
        

        if(this.state.prepTime === 60){
            this.setState({
                prepTime: 60
            })
        }
    }

    decrementPrepTime = async () => {
        this.setState({
            prepTime: this.state.prepTime - 5
        });

        if(this.state.prepTime === 5){
            this.setState({
                prepTime: 5
            })
        }
    }

    render() {

      
      let brandName =this.props && this.props.brand && this.props.brand.brandName ?this.props.brand.brandName :process.env.BRAND_NAME;
      let getLastManualDeliveryInfoObj = null;
      if (
        this.props?.serviceRequest?.serviceRequestDetails?.events &&
        this.props?.serviceRequest?.serviceRequestDetails?.events?.length
      ) {
        for (
          let i =
            this.props.serviceRequest.serviceRequestDetails.events.length - 1;
          i >= 0;
          i--
        ) {
          let obj = this.props.serviceRequest.serviceRequestDetails.events[i];
          if (obj.isManualDelivery ) {
            getLastManualDeliveryInfoObj = obj;
            break; // Exit the loop once the last matching event is found
          }
        }
      }
        
     
     
        if (this.props.serviceRequest.serviceRequestDetails.paymentDetails
            // && this.props.serviceRequest.serviceRequestDetails.paymentDetails.paymentMethod && this.props.serviceRequest.serviceRequestDetails.paymentDetails.dateOfTransaction && this.props.serviceRequest.serviceRequestDetails.paymentDetails.receivedBy && this.props.serviceRequest.serviceRequestDetails.paymentDetails.notes 
            && this.props.serviceRequest.serviceRequestDetails.paymentDetails.fileName && this.props.serviceRequest.serviceRequestDetails.paymentDetails.paymentMethod) {
            this.getTransactionImages(this.props.serviceRequest.serviceRequestDetails.paymentDetails.paymentMethod)
        }
       
        // let Messages = "Messages " + ((this.props.serviceRequest && this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.conversations) ? "(" +this.props.serviceRequest.serviceRequestDetails.conversations.length +")"  : 0  );

        let Messages = "Messages " 
        let messageCount = ((this.props.serviceRequest && this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.conversations) ?this.props.serviceRequest.serviceRequestDetails.conversations.length  : 0  );
        let SupportNote = "Support Note ";
        let SupportNoteCount = ((this.props.serviceRequest && this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.supportConversations !== null && this.props.serviceRequest.serviceRequestDetails.supportConversations !== undefined  && this.props.serviceRequest.serviceRequestDetails.supportConversations) ? this.props.serviceRequest.serviceRequestDetails.supportConversations.length  : 0  )


        let showOnlyCartDiscount = false
        let subTotal = 0;
        let subTotalWithoutDiscount = 0;
        let subTotalWithDiscount = 0;
        let cartDiscount = 0;
        let isASAPOrder = false;
        isASAPOrder = this.props.serviceRequest?.serviceRequestDetails?.isASAPOrder;
        let accountManagerOfBusiness = {};
        let currentTime = (new Date().toISOString());
        accountManagerOfBusiness =  this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.accountManagerDetails ? this.props.serviceRequest.serviceRequestDetails.accountManagerDetails:"";
        if (this.props.serviceRequest?.serviceRequestDetails?.businessOffers?.discount?.discountWithServiceOffers === false) {
            this.props.serviceRequest.serviceRequestDetails.services.forEach((service, index) => {
                let discount = service.appliedServiceOffer ? (((service.appliedServiceOffer.offerType === "discount") ? ((service.appliedServiceOffer.discountType === "FLAT") ? ((service.looseQuantityOfService && service.looseQuantityOfService !== 0) ? (((service.appliedServiceOffer.off * service.looseQuantityOfService) > service.appliedServiceOffer.maxDiscountCap) ? (service.appliedServiceOffer.maxDiscountCap ? service.appliedServiceOffer.maxDiscountCap : (service.appliedServiceOffer.off * service.looseQuantityOfService)) : (service.appliedServiceOffer.off * service.looseQuantityOfService)) : (((service.appliedServiceOffer.off * service.quantityOfService) > service.appliedServiceOffer.maxDiscountCap) ? (service.appliedServiceOffer.maxDiscountCap ? service.appliedServiceOffer.maxDiscountCap : (service.appliedServiceOffer.off * service.quantityOfService)) : (service.appliedServiceOffer.off * service.quantityOfService))) : service.appliedServiceOffer.discountAmount) : 0)) : 0;
                let costWithoutDiscount = (service && service.cost && service.cost.fixedCost && service.quantityOfService) ? ((service.looseQuantityOfService && service.looseQuantityOfService !== 0) ? (service.cost.fixedCost * service.looseQuantityOfService) : (service.cost.fixedCost * service.quantityOfService)) : 0;
                subTotalWithoutDiscount = service.isAvailable ? (subTotalWithoutDiscount + costWithoutDiscount) : subTotalWithoutDiscount;
                let costWithDiscount = (service && service.cost && service.cost.fixedCost && service.quantityOfService) ? ((service.looseQuantityOfService && service.looseQuantityOfService !== 0) ? ((service.cost.fixedCost * service.looseQuantityOfService) - discount) : ((service.cost.fixedCost * service.quantityOfService) - discount)) : 0;
                subTotalWithDiscount = service.isAvailable ? (subTotalWithDiscount + costWithDiscount) : subTotalWithDiscount;
            })
            if (this.props.serviceRequest.serviceRequestDetails.appliedBusinessOffer && subTotalWithoutDiscount >= this.props.serviceRequest.serviceRequestDetails.businessOffers.discount.discountMinCartVal) {
                showOnlyCartDiscount = true
            } else {
                showOnlyCartDiscount = false
            }
        }

        let orderReadyByTime = "NA"
       

      



        ///////way when order is accepted past the consumer preferred time
        if (this.props?.serviceRequest?.serviceRequestDetails?.preferredServiceMode === "DELIVERY") {
            // console.log("prefered service mode");
            if (this.props?.serviceRequest?.serviceRequestDetails?.orderReadyBy && this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate) {
                orderReadyByTime = moment(this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate).subtract(10, 'minutes').format('DD MMM YYYY hh:mm A')
            }
            else {
                if (this.props.serviceRequest.serviceRequestDetails.deliveryType === 'dunzo' || this.props.serviceRequest.serviceRequestDetails.deliveryType === 'borzo') {
                    if (this.props.serviceRequest.serviceRequestDetails.orderReadyBy && this.props?.serviceRequest?.serviceRequestDetails?.orderReadyByMinutes) {
                        if (this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.preferredServiceDate && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.from && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.to) {
                            orderReadyByTime = moment(this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.from).subtract(this.props?.serviceRequest?.serviceRequestDetails?.orderReadyByMinutes, 'minutes').format('DD MMM YYYY hh:mm A')
                        }
                        if (this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.preferredServiceDate && !this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot) {
                            orderReadyByTime = moment(this.props.serviceRequest.serviceRequestDetails.preferredServiceDate).subtract(this.props?.serviceRequest?.serviceRequestDetails?.orderReadyByMinutes, 'minutes').format('DD MMM YYYY hh:mm A')
                        }
                    }
                    else if (!this.props.serviceRequest.serviceRequestDetails.orderReadyBy && this.props?.serviceRequest?.serviceRequestDetails?.orderReadyByMinutes) {
                        if (this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.preferredServiceDate && !this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot) {
                            orderReadyByTime = moment(this.props.serviceRequest.serviceRequestDetails.preferredServiceDate).subtract(this.props?.serviceRequest?.serviceRequestDetails?.orderReadyByMinutes, 'minutes').format('DD MMM YYYY hh:mm A')
                        }
                    }
                }
                if (this.props.serviceRequest.serviceRequestDetails.deliveryType === 'self') {
                    if (this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.preferredServiceDate && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.from && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.to) {
                        orderReadyByTime = moment(this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.from).subtract(10, 'minutes').format('DD MMM YYYY hh:mm A')
                    }
                    else {
                        if (this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.preferredServiceDate && !this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot) {
                            orderReadyByTime = "NA";
                        } else {
                            orderReadyByTime = moment(this.props.serviceRequest.serviceRequestDetails.orderReadyBy).format('DD MMM YYYY hh:mm A');
                        }
                    }
                }
                if (isASAPOrder) {
                    orderReadyByTime = "ASAP"
                } else if (isASAPOrder && this.props.serviceRequest.serviceRequestDetails.orderReadyTime && (this.props.serviceRequest.serviceRequestDetails.deliveryType === 'dunzo' || this.props.serviceRequest.serviceRequestDetails.deliveryType === 'borzo')) {
                    orderReadyByTime = moment(this.props.serviceRequest.serviceRequestDetails.preparationTimer > 0 && this.props.serviceRequest.serviceRequestDetails.orderReadyTime).format('DD MMM YYYY hh:mm A')
                }
            }
        }
        if (this.props?.serviceRequest?.serviceRequestDetails?.preferredServiceMode === "PICKUP") {
            // console.log("preferredServiceMode");
            if (this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.preferredServiceDate && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.from && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.to) {
                orderReadyByTime = moment(this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.from).subtract(10, 'minutes').format('DD MMM YYYY hh:mm A')
            } else if (this.props.serviceRequest.serviceRequestDetails.orderReadyTime) {
                orderReadyByTime = moment(this.props.serviceRequest.serviceRequestDetails.preparationTimer > 0 && this.props.serviceRequest.serviceRequestDetails.orderReadyTime).format('DD MMM YYYY hh:mm A')
            } else {
                orderReadyByTime = "NA"
            }


        }
        if (this.props?.serviceRequest?.serviceRequestDetails?.serviceMode === "DELIVERY") {
            // console.log("service mode");
            if (this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate) {
                if (this.props.serviceRequest.serviceRequestDetails.orderReadyBy) {
                    if (this.props.serviceRequest.serviceRequestDetails.deliveryType === 'dunzo' || this.props.serviceRequest.serviceRequestDetails.deliveryType === 'borzo') {
                        if (this.props?.serviceRequest?.serviceRequestDetails?.orderReadyByMinutes) {
                            orderReadyByTime = (moment(this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate).subtract(this.props.serviceRequest.serviceRequestDetails.orderReadyByMinutes, 'minutes').format('DD MMM YYYY hh:mm A'))
                        }
                    }
                    if (this.props.serviceRequest.serviceRequestDetails.deliveryType === 'self') {
                        orderReadyByTime = moment(this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate).subtract(10, 'minutes').format('DD MMM YYYY hh:mm A')
                    }
                }
                // else if(!this.props.serviceRequest.serviceRequestDetails.orderReadyBy){
                //     orderReadyByTime = moment(this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate).subtract(10,'minutes').format('DD MMM YYYY hh:mm A');
                // }
                //when ASAP and is rescheduled - no orderreadyby and preferred delivery slots
                else if (!this.props.serviceRequest.serviceRequestDetails.orderReadyBy && !this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot) {
                    // orderReadyByTime = "NA";
                    if (this.props.serviceRequest.serviceRequestDetails.deliveryType === 'dunzo' || this.props.serviceRequest.serviceRequestDetails.deliveryType === 'borzo') {
                        if (this.props?.serviceRequest?.serviceRequestDetails?.orderReadyByMinutes) {
                            orderReadyByTime = (moment(this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate).subtract(this.props.serviceRequest.serviceRequestDetails.orderReadyByMinutes, 'minutes').format('DD MMM YYYY hh:mm A'))
                        }
                    }
                    if (this.props.serviceRequest.serviceRequestDetails.deliveryType === 'self') {
                        orderReadyByTime = moment(this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate).subtract(10, 'minutes').format('DD MMM YYYY hh:mm A')
                    }
                }
            }
            else {
                if (this.props.serviceRequest.serviceRequestDetails.deliveryType === 'dunzo' || this.props.serviceRequest.serviceRequestDetails.deliveryType === 'borzo') {
                    if (this.props.serviceRequest.serviceRequestDetails.orderReadyBy && this.props?.serviceRequest?.serviceRequestDetails?.orderReadyByMinutes) {
                        //check if accept time is > consumer preferred
                        if (this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.preferredServiceDate && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot && this.props?.serviceRequest?.serviceRequestDetails?.currentStatus === 'ACCEPTED' && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.from && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.to && (this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.to < this.props.serviceRequest.serviceRequestDetails.statusHistory[1].time)) { // then order ready by time should be based in accept req time
                            // orderReadyByTime = moment(this.props.serviceRequest.serviceRequestDetails.statusHistory[1].time).subtract(this.props?.serviceRequest?.serviceRequestDetails?.orderReadyByMinutes,'minutes').format('DD MMM YYYY hh:mm A')
                            orderReadyByTime = "ASAP";
                        } else { // as usual flow
                            if (this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.preferredServiceDate && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.from && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.to) {
                                orderReadyByTime = moment(this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.from).subtract(this.props?.serviceRequest?.serviceRequestDetails?.orderReadyByMinutes, 'minutes').format('DD MMM YYYY hh:mm A')
                            }
                            if (this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.preferredServiceDate && !this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot) {
                                orderReadyByTime = moment(this.props.serviceRequest.serviceRequestDetails.preferredServiceDate).subtract(this.props?.serviceRequest?.serviceRequestDetails?.orderReadyByMinutes, 'minutes').format('DD MMM YYYY hh:mm A')
                            }
                        }
                    } else if (!this.props.serviceRequest.serviceRequestDetails.orderReadyBy && this.props?.serviceRequest?.serviceRequestDetails?.orderReadyByMinutes) {
                        if (this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.preferredServiceDate && !this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot) {
                            orderReadyByTime = moment(this.props.serviceRequest.serviceRequestDetails.preferredServiceDate).subtract(this.props?.serviceRequest?.serviceRequestDetails?.orderReadyByMinutes, 'minutes').format('DD MMM YYYY hh:mm A')
                        }
                    }
                }
                if (this.props.serviceRequest.serviceRequestDetails.deliveryType === 'self') {
                    if (this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.preferredServiceDate && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot && this.props.serviceRequest.serviceRequestDetails.currentStatus === "ACCEPTED" && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.from && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.to && (this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.to < this.props.serviceRequest.serviceRequestDetails.statusHistory[1].time)) {
                        // then order ready by time should be based in accept req time
                        // orderReadyByTime = moment(this.props.serviceRequest.serviceRequestDetails.statusHistory[1].time).subtract(10,'minutes').format('DD MMM YYYY hh:mm A')
                        orderReadyByTime = "ASAP";
                    } else {
                        if (this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.preferredServiceDate && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.from && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.to) {
                            orderReadyByTime = moment(this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.from).subtract(10, 'minutes').format('DD MMM YYYY hh:mm A')
                        }
                        else {
                            if (this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.preferredServiceDate && !this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot) {
                                // orderReadyByTime = "NA";
                                orderReadyByTime = moment(this.props.serviceRequest.serviceRequestDetails.preferredServiceDate).subtract(10, 'minutes').format('DD MMM YYYY hh:mm A')
                            } else {
                                orderReadyByTime = moment(this.props.serviceRequest.serviceRequestDetails.orderReadyBy).format('DD MMM YYYY hh:mm A');
                            }
                        }
                    }
                }
                if (isASAPOrder && !this.props.serviceRequest.serviceRequestDetails.orderReadyTime) {
                    orderReadyByTime = "ASAP"
                } else if (isASAPOrder && this.props.serviceRequest.serviceRequestDetails.preparationTimer > 0 && this.props.serviceRequest.serviceRequestDetails.orderReadyTime && (this.props.serviceRequest.serviceRequestDetails.deliveryType === 'dunzo' || this.props.serviceRequest.serviceRequestDetails.deliveryType === 'borzo')) {
                    orderReadyByTime = moment(this.props.serviceRequest.serviceRequestDetails.orderReadyTime).format('DD MMM YYYY hh:mm A')
                }
            }
        }
        if (this.props?.serviceRequest?.serviceRequestDetails?.serviceMode === "PICKUP") {
            // console.log("serviceMode");
            if (this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.preferredServiceDate && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot && this.props?.serviceRequest.serviceRequestDetails.currentStatus === "ACCEPTED" && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.from && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.to && (this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.to < this.props.serviceRequest.serviceRequestDetails.statusHistory[1].time)) {// then order ready by time should be based in accept req time
                // orderReadyByTime = moment(this.props.serviceRequest.serviceRequestDetails.statusHistory[1].time).subtract(10,'minutes').format('DD MMM YYYY hh:mm A');
                orderReadyByTime = "ASAP";

            } else {// as usual flow
                if (this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.preferredServiceDate && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.from && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.to) {
                    orderReadyByTime = moment(this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.from).subtract(10, 'minutes').format('DD MMM YYYY hh:mm A')
                } else if (this.props.serviceRequest.serviceRequestDetails.preparationTimer > 0 && this.props.serviceRequest.serviceRequestDetails.orderReadyTime) {
                    orderReadyByTime = moment(this.props.serviceRequest.serviceRequestDetails.orderReadyTime).format('DD MMM YYYY hh:mm A')
                } else {
                    orderReadyByTime = "NA"
                }
            }
        }


        let partnerTaskIdText = "";        
        
        let dunzoArrayRadius = [];
        let dunzoArray = [];

        if(
            this.props.serviceRequest.serviceRequestDetails && 
            this.props?.serviceRequest?.serviceRequestDetails?.serviceMode === "DELIVERY" &&
            !this.props.serviceRequest.serviceRequestDetails.partnerTaskId && 
            (this.props.serviceRequest.serviceRequestDetails.deliveryType === "porter" || this.props.serviceRequest.serviceRequestDetails.deliveryType === "shadowfax") && 
            this.props.serviceRequest.serviceRequestDetails.currentStatus === 'ACCEPTED' &&
            this.props.serviceRequest.serviceRequestDetails.business && this.props.serviceRequest.serviceRequestDetails.business.serviceDeliveryPriority && this.props.serviceRequest.serviceRequestDetails.business.serviceDeliveryPriority !== null && this.props.serviceRequest.serviceRequestDetails.business.serviceDeliveryPriority !== undefined && this.props.serviceRequest.serviceRequestDetails.business.serviceDeliveryPriority.length > 0
        ) {
            dunzoArray = this.props.serviceRequest.serviceRequestDetails.business.serviceDeliveryPriority.map(d=>d.delivery_partner);
            
            if (this.props.serviceRequest.serviceRequestDetails.business.deliveryPartnerPriorityAsKm && this.props.serviceRequest.serviceRequestDetails.business.deliveryPartnerPriorityAsKm !== null && this.props.serviceRequest.serviceRequestDetails.business.deliveryPartnerPriorityAsKm !== undefined && this.props.serviceRequest.serviceRequestDetails.business.deliveryPartnerPriorityAsKm.length > 0) {
                dunzoArrayRadius = this.props.serviceRequest.serviceRequestDetails.business.deliveryPartnerPriorityAsKm.map(d=>d.delivery_partner);
            }

           _.uniq(dunzoArray.concat(dunzoArrayRadius));
         

        }

        if(
            this.props.serviceRequest.serviceRequestDetails && 
            this.props?.serviceRequest?.serviceRequestDetails?.serviceMode === "DELIVERY" &&
            !this.props.serviceRequest.serviceRequestDetails.partnerTaskId && 
            (this.props.serviceRequest.serviceRequestDetails.deliveryType === "porter" || this.props.serviceRequest.serviceRequestDetails.deliveryType === "shadowfax") && 
            this.props.serviceRequest.serviceRequestDetails.currentStatus === 'ACCEPTED') {
                let currentTime = new Date().getTime();
               
                 if (this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot && (this.props.serviceRequest.serviceRequestDetails.isSheduleDeliveyLater === true || this.props.serviceRequest.serviceRequestDetails.isSheduleDeliveyLater === false )) {
                    console.log("inside else if----")
                    let orderMinTime = new Date((moment(this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate).subtract(this.props.serviceRequest.serviceRequestDetails.orderReadyByMinutes, 'minutes').add(3, 'minutes').format('YYYY-MM-DD HH:mm:ss'))).getTime();
                    if (currentTime > orderMinTime) {
                        console.log("currentTime > orderMinTime so no text1");
                        
                    }
                    else if (this.props?.serviceRequest?.serviceRequestDetails?.orderReadyByMinutes ) {
                       
                        partnerTaskIdText = "Partner task id will get created at " + (moment(this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate).subtract(this.props.serviceRequest.serviceRequestDetails.orderReadyByMinutes, 'minutes').add(3, 'minutes').format('hh:mm A')) + ' (according to the scheduled time)';
                    } else {
                        partnerTaskIdText = "Partner task id will get created at " + (moment(this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate).subtract(10, 'minutes').add(3, 'minutes').format('hh:mm A')) + ' (according to the scheduled time)';
                    }
                }else if (this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate && !this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot && (this.props.serviceRequest.serviceRequestDetails.isSheduleDeliveyLater === true || this.props.serviceRequest.serviceRequestDetails.isSheduleDeliveyLater === false )) {
                    console.log("inside else if1----")
                  
                    let orderMinTime = new Date((moment(this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate).subtract(this.props.serviceRequest.serviceRequestDetails.orderReadyByMinutes, 'minutes').add(3, 'minutes').format('YYYY-MM-DD HH:mm:ss'))).getTime();
                    if (currentTime > orderMinTime) {
                        console.log("currentTime > orderMinTime so no text1");
                        
                        
                    }
                    else if (this.props?.serviceRequest?.serviceRequestDetails?.orderReadyByMinutes ) {
                       
                        partnerTaskIdText = "Partner task id will get created at " + (moment(this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate).subtract(this.props.serviceRequest.serviceRequestDetails.orderReadyByMinutes, 'minutes').add(3, 'minutes').format('hh:mm A')) + ' (according to the scheduled time)';
                    } else {
                        partnerTaskIdText = "Partner task id will get created at " + (moment(this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate).subtract(10, 'minutes').add(3, 'minutes').format('hh:mm A')) + ' (according to the scheduled time)';
                    }
                } else if (this.props.serviceRequest.serviceRequestDetails.preferredServiceDate && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.from && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.to && (this.props.serviceRequest.serviceRequestDetails.isSheduleDeliveyLater === false) && !this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate) {
                    console.log("inside else if2----")

                    let orderMinTime = new Date((moment(this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.from).subtract(this.props.serviceRequest.serviceRequestDetails.orderReadyByMinutes, 'minutes').format('YYYY-MM-DD HH:mm:ss'))).getTime();
                    if (currentTime > orderMinTime) {
                        console.log("currentTime > orderMinTime so no text2");
                      
                    }
                    else if (this.props?.serviceRequest?.serviceRequestDetails?.orderReadyByMinutes && this.props.serviceRequest.serviceRequestDetails.isSheduleDeliveyLater === false) {
                        // partnerTaskIdText = "Partner task id will get created at " + moment(this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.from).subtract(this.props?.serviceRequest?.serviceRequestDetails?.orderReadyByMinutes, 'minutes').add(3, 'minutes').format('hh:mm A')
                        partnerTaskIdText = "Partner task id will get created at " + moment(this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.from).subtract(this.props?.serviceRequest?.serviceRequestDetails?.orderReadyByMinutes, 'minutes').format('hh:mm A')
                    } else {
                        // partnerTaskIdText = "Partner task id will get created at " + moment(this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.from).subtract(10, 'minutes').add(3, 'minutes').format('hh:mm A')
                        partnerTaskIdText = "Partner task id will get created at " + moment(this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.from).subtract(10, 'minutes').format('hh:mm A')
                    }
                } 
        }


        // console.log("this.props in details ---->", this.props);


        const { classes } = this.props;
        let completedSteps = new Set();
        let activeStep = 1;
        let serviceCostType = "Fixed";
        let cancelledOrRejected = {
            isCancelledOrRejected: false,
            status: "",
            actionDate: moment().format('DD MMM YYYY hh:mm A')
        }
        let preferredServiceMode = "";
        let serviceMode = "";
        let preferredServiceDateTypeHead = 'Delivery by'
        let scheduledDate = !this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot && currentTime > this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.from && !this.props.serviceRequest.serviceRequestDetails.partnerTaskId && !this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate;
           
        let rescheduleDate = !this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot && this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate && (this.props.serviceRequest.serviceRequestDetails.isSheduleDeliveyLater === true || this.props.serviceRequest.serviceRequestDetails.isSheduleDeliveyLater === false) && currentTime > this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate && !this.props.serviceRequest.serviceRequestDetails.partnerTaskId;
        //   console.log("rescheduleDate",rescheduleDate)
        let schReshDate = this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot && this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot.from && this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate && (this.props.serviceRequest.serviceRequestDetails.isSheduleDeliveyLater === true || this.props.serviceRequest.serviceRequestDetails.isSheduleDeliveyLater === false) && currentTime > this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate && !this.props.serviceRequest.serviceRequestDetails.partnerTaskId;
        if (this.props.serviceRequest.serviceRequestDetails.preferredServiceMode) {
            preferredServiceMode = this.props.serviceRequest.serviceRequestDetails.preferredServiceMode;
        }
        if (this.props.serviceRequest.serviceRequestDetails.serviceMode) {
            serviceMode = this.props.serviceRequest.serviceRequestDetails.serviceMode;
        }

        if (serviceMode) {
            preferredServiceDateTypeHead = serviceMode === "PICKUP" ? 
            "Pickup By" : 
            serviceMode === "DELIVERY" ? 
            "Delivery By" : 
            serviceMode === "SERVICE_AT_CONSUMER_LOCATION" ?
             "Service on" :
              serviceMode === "SERVICE_AT_BUSINESS_LOCATION" ?
               "Service on" :
                "Delivery by"
        } else {
            preferredServiceDateTypeHead = preferredServiceMode === "PICKUP" ?
             "Pickup By" : 
             preferredServiceMode === "DELIVERY" ? 
             "Delivery By" : 
             preferredServiceMode === "SERVICE_AT_CONSUMER_LOCATION" ? 
             "Service on" : 
             preferredServiceMode === "SERVICE_AT_BUSINESS_LOCATION" ? 
             "Service on" : 
             "Delivery by"
        }

        if (this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.statusHistory) {
            /*  serviceCostType = this.props.serviceRequest.serviceRequestDetails.services.length > 1 ? 
             this.props.serviceRequest.serviceRequestDetails.services[0].cost.costType : ""; */
            if (this.props.serviceRequest.serviceRequestDetails.services.length === 0) {
                serviceCostType = "Approx";
            }
            if (this.props.serviceRequest.serviceRequestDetails.services.length === 1) {
                serviceCostType = this.props.serviceRequest.serviceRequestDetails.services[0].cost.costType;
            }
            if (this.props.serviceRequest.serviceRequestDetails.services.length > 1) {
                let res = this.props.serviceRequest.serviceRequestDetails.services.filter(ser => {
                    return ser.cost.costType === "Approx";
                })
                if (res && res.length > 0) {
                    serviceCostType = "Approx";
                } else {
                    serviceCostType = "Fixed";
                }
            }

            let mapAllSteps = [];
           
            let lastCreatedIndex = -1;
            
            this.props.serviceRequest.serviceRequestDetails.statusHistory.forEach((obj, index, arr) => {
              if (obj.status === "CREATED") {
                lastCreatedIndex = index; // Keep track of the last "CREATED" status
              }
            });
            
            this.props.serviceRequest.serviceRequestDetails.statusHistory.forEach((obj, index, arr) => {
              if (obj.status !== "AWAITING PAYMENT") {
                // Skip all "CREATED" statuses except the last one
                if (obj.status === "CREATED" && index !== lastCreatedIndex) {
                  return; // Skip earlier "CREATED" statuses
                }
                // Add to mapAllSteps if not a duplicate (by checking if the status already exists in mapAllSteps)
                if (!mapAllSteps.some(step => step.status === obj.status)) {
                  mapAllSteps.push(obj);
                }
              }
            });
            activeStep = mapAllSteps.length - 1;
            mapAllSteps.forEach((status, index) => {
                if (activeStep >= index) {
                    completedSteps.add(index);
                }
                if (status.status === "REJECTED" || status.status === "CANCELLED") {
                    cancelledOrRejected.isCancelledOrRejected = true;
                    cancelledOrRejected.status = status.status;
                    cancelledOrRejected.actionDate = moment(status.time).format('DD MMM YYYY hh:mm A');
                    cancelledOrRejected.by = status.updatedBy.userType;
                    activeStep = null;
                }
                if (status.status === "REJECTED_BY_CONSUMER") {
                    cancelledOrRejected.isCancelledOrRejected = true;
                    cancelledOrRejected.status = status.status;
                    cancelledOrRejected.actionDate = moment(status.time).format('DD MMM YYYY hh:mm A');
                    cancelledOrRejected.by = status.updatedBy.userType;
                    // activeStep = activeStep;
                }

            });
        }
     
        let quickServiceSubtotal;
        if (this.props && this.props.serviceRequest && this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.amount && this.props.serviceRequest.serviceRequestDetails.amount !== null) {
          quickServiceSubtotal = this.props.serviceRequest.serviceRequestDetails.amount;
       

      
          // Check if deliveryCharges is a valid number before using toFixed
          if (this.props.serviceRequest.serviceRequestDetails.deliveryCharges !== undefined && this.props.serviceRequest.serviceRequestDetails.deliveryCharges !== null) {
              quickServiceSubtotal = quickServiceSubtotal - parseFloat(this.props.serviceRequest.serviceRequestDetails.deliveryCharges.toFixed(2));
          }
      
          // Check if consumerConvFees is a valid number before using toFixed
          if (this.props.serviceRequest.serviceRequestDetails.consumerConvFees !== undefined && this.props.serviceRequest.serviceRequestDetails.consumerConvFees !== null) {
              quickServiceSubtotal = quickServiceSubtotal - parseFloat(this.props.serviceRequest.serviceRequestDetails.consumerConvFees.toFixed(2));
          }
      
          // Check if totalPackagingCharges is a valid number before using toFixed
          if (this.props.serviceRequest.serviceRequestDetails.totalPackagingCharges !== undefined && this.props.serviceRequest.serviceRequestDetails.totalPackagingCharges !== null) {
              quickServiceSubtotal = quickServiceSubtotal - parseFloat(this.props.serviceRequest.serviceRequestDetails.totalPackagingCharges.toFixed(2));
          }
      
          // Check if discountOnDelivery is a valid number before adding
          if (this.props.serviceRequest.serviceRequestDetails.discountOnDelivery !== undefined && this.props.serviceRequest.serviceRequestDetails.discountOnDelivery !== null) {
              quickServiceSubtotal = Number(quickServiceSubtotal) + Number(this.props.serviceRequest.serviceRequestDetails.discountOnDelivery);
          }
      
          // Final toFixed to round the result
          quickServiceSubtotal = quickServiceSubtotal.toFixed(2);
      }
      
        // console.log("quickServiceSubtotal ", quickServiceSubtotal);
        // console.log("amount ", this.props.serviceRequest.serviceRequestDetails.amount);
        // console.log("deliveryCharges ", this.props.serviceRequest.serviceRequestDetails.deliveryCharges);

        let muiAlert = null;
        if (this.state.cancelMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
				{this.state.cancelMsg}
			</MuiAlert>
		} else if (this.state.cancelErrorMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
				{this.state.cancelErrorMsg}
			</MuiAlert>
		} else if (this.state.sucessMsg && this.state.sucessMsg !== null && this.state.sucessMsg === "Manual delivery data set successfully") {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
				{this.state.sucessMsg2}
			</MuiAlert>
		}
    else if (this.state.sucessMsg && this.state.sucessMsg !== null && this.state.sucessMsg === "Manual delivery data set failed") {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
				{this.state.sucessMsg2}
			</MuiAlert>
		}
    else if (this.state.sucessMsg && this.state.sucessMsg !== null && (this.state.sucessMsg !== "Manual delivery data set successfully" || this.state.sucessMsg !== "Manual delivery data set failed")) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
				{this.state.sucessMsg}
			</MuiAlert>
    }
        return (
          <Container>
            <div className={classes.appBarSpacer} />
            <Container className={classes.content}>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Grid item>
                    <Typography variant="caption" display="block" gutterBottom>
                      Service Request Id
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2" gutterBottom>
                      {
                        this.props.serviceRequest.serviceRequestDetails
                          .serviceRequestId
                      }
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Grid item>
                    <Typography variant="caption" display="block" gutterBottom>
                      Request Date
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2" gutterBottom>
                      {moment(
                        this.props.serviceRequest.serviceRequestDetails
                          .creationDate
                      ).format("DD MMM YYYY hh:mm A")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid item>
                    {/* <Avatar imgProps={{ style: { "object-fit": "contain", width: "10px", height: "10px" } }} variant="square" className={classes.avatarGrey} src={rupeeIcon} /> */}
                    <Typography variant="caption" display="block" gutterBottom>
                      Amount
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      style={{ display: "flex", alignItems: "center" }}
                    >
                     
                      {this.props.serviceRequest.serviceRequestDetails &&
                      this.props.serviceRequest.serviceRequestDetails
                        .couponId !== null &&
                      this.props.serviceRequest.serviceRequestDetails
                        .couponId &&
                      this.props.serviceRequest.serviceRequestDetails.couponId
                        .amount &&
                      typeof this.props.serviceRequest.serviceRequestDetails
                        .SRCouponStatus !== "undefined" &&
                      this.props.serviceRequest.serviceRequestDetails
                        .currentStatus &&
                      this.props.serviceRequest.serviceRequestDetails.amount &&
                      (this.props.serviceRequest.serviceRequestDetails.amount !=
                        null ||
                        this.props.serviceRequest.serviceRequestDetails
                          .amount !== "Not Set" ||
                        this.props.serviceRequest.serviceRequestDetails
                          .amount !== undefined)
                        ? //  && (this.props.serviceRequest.serviceRequestDetails.amount >= this.props.serviceRequest.serviceRequestDetails.couponId.minOrderValue)
                          "\u20B9" +
                          " " +
                          this.calculateSubtraction(
                            this.props.serviceRequest.serviceRequestDetails
                              .amount,
                            this.props.serviceRequest.serviceRequestDetails
                              .couponId.amount
                          )
                        : this.props.serviceRequest.serviceRequestDetails.amount
                        ? "\u20B9" +
                          " " +
                          this.props?.serviceRequest?.serviceRequestDetails?.amount?.toFixed(
                            2
                          )
                        : "Not Set"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid item>
                    <Typography variant="caption" display="block" gutterBottom>
                      Payment Status
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2" gutterBottom>
                      {this.props.serviceRequest.serviceRequestDetails
                        .paymentStatus &&
                      this.props.serviceRequest.serviceRequestDetails
                        .paymentStatus === "ADVANCEPAID"
                        ? "UNPAID"
                        : this.props.serviceRequest.serviceRequestDetails
                            .paymentStatus}
                      <span style={{ display: "flex" }}>
                        {this.props.serviceRequest.serviceRequestDetails
                          .paymentDetails &&
                        this.props.serviceRequest.serviceRequestDetails
                          .paymentDetails.dateOfTransaction
                          ? moment(
                              this.props.serviceRequest.serviceRequestDetails
                                .paymentDetails.dateOfTransaction
                            ).format("DD MMM YYYY hh:mm A")
                          : null}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid item>
                    <Typography variant="caption" display="block" gutterBottom>
                      Is Advance Paid
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2" gutterBottom>
                      {(this.props.serviceRequest.serviceRequestDetails &&
                        this.props.serviceRequest.serviceRequestDetails
                          .paymentStatus === "ADVANCEPAID") ||
                      this.props.serviceRequest.serviceRequestDetails
                        .isAdvancePaymentToBusiness ||
                      this.props.serviceRequest.serviceRequestDetails
                        ?.isAdvancePaymentDoneToBusiness
                        ? "True"
                        : "False"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Grid item>
                    <Typography variant="caption" display="block" gutterBottom>
                      Category
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2" gutterBottom>
                      {this.props.serviceRequest.serviceRequestDetails &&
                      this.props.serviceRequest.serviceRequestDetails.business
                        ? this.props.serviceRequest.serviceRequestDetails
                            .business.category.name
                        : null}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Grid item>
                    <Typography variant="caption" display="block" gutterBottom>
                      Service Mode
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2" gutterBottom>
                      {serviceMode
                        ? serviceMode === "SERVICE_AT_BUSINESS_LOCATION"
                          ? "Business Location"
                          : serviceMode === "SERVICE_AT_CONSUMER_LOCATION"
                          ? "My Location"
                          : serviceMode +
                            (serviceMode === "DELIVERY"
                              ? this.props &&
                                this.props.serviceRequest &&
                                this.props.serviceRequest
                                  .serviceRequestDetails &&
                                this.props.serviceRequest.serviceRequestDetails
                                  .deliveryType
                                ? " - (" +
                                  (this.props.serviceRequest
                                    .serviceRequestDetails.deliveryType ===
                                  "xirify_delivery"
                                    ? `${brandName} Delivery`
                                    : this.props.serviceRequest
                                        .serviceRequestDetails.deliveryType) +
                                  ")"
                                : null
                              : " ")
                        : preferredServiceMode ===
                          "SERVICE_AT_CONSUMER_LOCATION"
                        ? "My Location"
                        : preferredServiceMode ===
                          "SERVICE_AT_BUSINESS_LOCATION"
                        ? "Business Location"
                        : preferredServiceMode}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid item>
                    <Typography variant="caption" display="block" gutterBottom>
                      {"Order ready by"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2" gutterBottom>
                      {/* to check first whther SR has schedule later. if yes, set the orderbytime based on schedule later time, else set the normal orderreadybytime */}
                      {orderReadyByTime}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid item>
                    <Typography variant="caption" display="block" gutterBottom>
                      {preferredServiceDateTypeHead}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2" gutterBottom>
                      {/* {this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate ? moment(this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate).format('DD MMM YYYY hh:mm A') : (this.props.serviceRequest.serviceRequestDetails.preferredServiceDate ? moment(this.props.serviceRequest.serviceRequestDetails.preferredServiceDate).format('DD MMM YYYY hh:mm A') : "--")} */}
                      {this.props.serviceRequest.serviceRequestDetails
                        .rescheduledServiceDate
                        ? moment(
                            this.props.serviceRequest.serviceRequestDetails
                              .rescheduledServiceDate
                          ).format("DD MMM YYYY hh:mm A")
                        : // (preferredServiceDateTypeHead==="Delivery By" || preferredServiceDateTypeHead==="Pickup By") &&
                        this.props.serviceRequest.serviceRequestDetails &&
                          this.props.serviceRequest.serviceRequestDetails
                            .preferredServiceDate &&
                          this.props.serviceRequest.serviceRequestDetails
                            .preferredDeliverySlot &&
                          this.props?.serviceRequest?.serviceRequestDetails
                            ?.currentStatus === "ACCEPTED" &&
                          this.props.serviceRequest.serviceRequestDetails
                            .preferredDeliverySlot.from &&
                          this.props.serviceRequest.serviceRequestDetails
                            .preferredDeliverySlot.to &&
                          this.props.serviceRequest.serviceRequestDetails
                            .preferredDeliverySlot.to <
                            this.props.serviceRequest.serviceRequestDetails
                              .statusHistory[1].time
                        ? moment(
                            this.props.serviceRequest.serviceRequestDetails
                              .statusHistory[1].time
                          ).format("DD MMM YYYY hh:mm A")
                        : this.props.serviceRequest.serviceRequestDetails
                            .preferredServiceDate
                        ? moment(
                            this.props.serviceRequest.serviceRequestDetails
                              .preferredServiceDate
                          ).format("DD MMM YYYY hh:mm A")
                        : "--"
                      }
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid item>
                    <Typography variant="caption" display="block" gutterBottom>
                      Payment Method
                    </Typography>
                  </Grid>
                  <Grid item>
                    {this.props.serviceRequest.serviceRequestDetails
                      .paymentDetails &&
                    this.props.serviceRequest.serviceRequestDetails
                      .paymentDetails.paymentMethod ? (
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {this.props.serviceRequest.serviceRequestDetails
                          .paymentDetails.paymentMethod === "BANKTRANSFER"
                          ? "Bank Transfer"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "CHEQUE"
                          ? "Cheque"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "CASH"
                          ? "Cash"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "COD"
                          ? "Cod"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "UPI"
                          ? "UPI"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "UPIDIRECT"
                          ? "UPI _Direct"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "OTHER"
                          ? "Other"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "NET_BANKING"
                          ? "Net Banking"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "CREDIT_CARD"
                          ? "Credit Card"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "WALLET"
                          ? "Wallet"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "DEBIT_CARD"
                          ? "Debit Card"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod ===
                            "COMPENSATEDBYXIRIFY"
                          ? "Compensated By Xirify"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "PAIDBYXIRIFY"
                          ? "Paid By Xirify"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "GIFTFROMXIRIFY"
                          ? "Gift From Xirify"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod ===
                            "ADVANCEPAYMENTTOBUSINESS"
                          ? "Advance Paid To Business"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "RESETPAYMENT"
                          ? `${brandName} Payments`
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod}
                      </Typography>
                    ) : (
                      ""
                    )}
                    <Typography variant="subtitle2" gutterBottom>
                      <span style={{ display: "flex" }}>
                        {this.props.serviceRequest.serviceRequestDetails
                          .paymentDetails &&
                        this.props.serviceRequest.serviceRequestDetails
                          .paymentDetails.dateOfTransaction
                          ? moment(
                              this.props.serviceRequest.serviceRequestDetails
                                .paymentDetails.dateOfTransaction
                            ).format("DD MMM YYYY hh:mm A")
                          : null}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
               
                <Grid item xs={3}>
                  <Grid item>
                    <Typography variant="caption" display="block" gutterBottom>
                      Consumer Preferred Service Date
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2" gutterBottom>
                      {isASAPOrder
                        ? "ASAP"
                        : this.props.serviceRequest.serviceRequestDetails &&
                          this.props.serviceRequest.serviceRequestDetails
                            .preferredServiceDate &&
                          this.props.serviceRequest.serviceRequestDetails
                            .preferredDeliverySlot &&
                          this.props.serviceRequest.serviceRequestDetails
                            .preferredDeliverySlot.from &&
                          this.props.serviceRequest.serviceRequestDetails
                            .preferredDeliverySlot.to
                        ? moment(
                            this.props.serviceRequest.serviceRequestDetails
                              .preferredDeliverySlot.from
                          ).format("DD MMM YYYY hh:mm A") +
                          " - " +
                          moment(
                            this.props.serviceRequest.serviceRequestDetails
                              .preferredDeliverySlot.to
                          ).format("DD MMM YYYY hh:mm A")
                        : this.props.serviceRequest.serviceRequestDetails &&
                          this.props.serviceRequest.serviceRequestDetails
                            .preferredServiceDate
                        ? moment(
                            this.props.serviceRequest.serviceRequestDetails
                              .preferredServiceDate
                          ).format("DD MMM YYYY hh:mm A")
                        : "--"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Grid item>
                    <Typography variant="caption" display="block" gutterBottom>
                      Business Rescheduled Service Date
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2" gutterBottom>
                      {this.props.serviceRequest.serviceRequestDetails &&
                      this.props.serviceRequest.serviceRequestDetails
                        .rescheduledServiceDate &&
                      this.props.serviceRequest.serviceRequestDetails
                        .preferredServiceDate &&
                      !this.props.serviceRequest.serviceRequestDetails
                        .preferredDeliverySlot
                        ? this.props.serviceRequest.serviceRequestDetails
                            .serviceMode === "DELIVERY" &&
                          this.props.serviceRequest.serviceRequestDetails
                            .rescheduledServiceDate &&
                          !this.props.serviceRequest.serviceRequestDetails
                            .orderReadyBy
                          ? moment(
                              this.props.serviceRequest.serviceRequestDetails
                                .rescheduledServiceDate
                            ).format("DD MMM YYYY hh:mm A") +
                            " - " +
                            moment(
                              this.props.serviceRequest.serviceRequestDetails
                                .rescheduledServiceDate
                            )
                              .add(30, "minutes")
                              .format("DD MMM YYYY hh:mm A")
                          : moment(
                              this.props.serviceRequest.serviceRequestDetails
                                .rescheduledServiceDate
                            ).format("DD MMM YYYY hh:mm A")
                        : this.props.serviceRequest.serviceRequestDetails &&
                          this.props.serviceRequest.serviceRequestDetails
                            .rescheduledServiceDate &&
                          this.props.serviceRequest.serviceRequestDetails
                            .preferredServiceDate &&
                          this.props.serviceRequest.serviceRequestDetails
                            .preferredDeliverySlot &&
                          !this.props?.serviceRequest?.serviceRequestDetails
                            ?.orderReadyBy
                        ? moment(
                            this.props.serviceRequest.serviceRequestDetails
                              .rescheduledServiceDate
                          ).format("DD MMM YYYY hh:mm A")
                        : this.props.serviceRequest.serviceRequestDetails &&
                          this.props.serviceRequest.serviceRequestDetails
                            .rescheduledServiceDate &&
                          this.props.serviceRequest.serviceRequestDetails
                            .preferredServiceDate &&
                          this.props.serviceRequest.serviceRequestDetails
                            .preferredDeliverySlot &&
                          this.props?.serviceRequest?.serviceRequestDetails
                            ?.orderReadyBy
                        ? moment(
                            this.props.serviceRequest.serviceRequestDetails
                              .rescheduledServiceDate
                          ).format("DD MMM YYYY hh:mm A") +
                          " - " +
                          moment(
                            this.props.serviceRequest.serviceRequestDetails
                              .rescheduledServiceDate
                          )
                            .add(30, "minutes")
                            .format("DD MMM YYYY hh:mm A")
                        : "--"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid item>
                    <Typography variant="caption" display="block" gutterBottom>
                      Schedule Later Flag
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2" gutterBottom>
                      {this.props.serviceRequest.serviceRequestDetails &&
                      this.props.serviceRequest.serviceRequestDetails
                        .isSheduleDeliveyLater
                        ? "True"
                        : "False"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid item>
                    <Typography variant="caption" display="block" gutterBottom>
                      Pre-order
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2" gutterBottom>
                      {this?.props?.serviceRequest?.serviceRequestDetails
                        ?.isPreOrder
                        ? "True"
                        : "False"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid item>
                    <Typography variant="caption" display="block" gutterBottom>
                     Source
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2" gutterBottom>
                      {this?.props?.serviceRequest?.serviceRequestDetails
                        ?.source === "WEB"
                        ? "Web"
                        : "App"}
                    </Typography>
                  </Grid>
                </Grid>
                 <Grid item xs={3}>
                  <Grid item>
                    <Typography variant="caption" display="block" gutterBottom>
                      Partner Task Id
                    </Typography>
                  </Grid>
                  <Grid item style={{ display : "flex"}}>
                                {  
                                    (this.props.serviceRequest.serviceRequestDetails.orderReadyTime > currentTime) && (this.props.serviceRequest.serviceRequestDetails.isASAPOrder === true) &&
                                    (!this.props.serviceRequest.serviceRequestDetails.partnerTaskId) && 
                                    (this.props.serviceRequest.serviceRequestDetails.currentStatus === 'ACCEPTED' && this.props.serviceRequest.serviceRequestDetails.business.isBusinessPreparationTimerEnabled === true && 
                                    ((this.props.serviceRequest.serviceRequestDetails.serviceMode === 'DELIVERY' || this.props.serviceRequest.serviceRequestDetails.preferredServiceMode === 'DELIVERY') && this.props.serviceRequest.serviceRequestDetails.deliveryType !== 'self' ) ) ? 
                                        <Container style={{ display : "flex" ,paddingLeft:"4px"}}>
                                        <Typography  style={{ fontWeight : "bold"}} className={classes.text}> NA </Typography>
                                            <Typography >{<CountdownTimer date={this.props.serviceRequest.serviceRequestDetails.orderReadyTime} serviceRequestId={this.props.match.params.serviceRequestId}/>
                                                        // this.props.serviceRequest.serviceRequestDetails.partnerTaskId
                                                        }</Typography>
                                        <Typography className={classes.text}> Mins left to ready </Typography>
                                        </Container> 
                                    : this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.partnerTaskId ? (
                                        ( this.props.serviceRequest && this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.isCancelDeliveryPartnerTask !== null && this.props.serviceRequest.serviceRequestDetails.isCancelDeliveryPartnerTask !== undefined && this.props.serviceRequest.serviceRequestDetails.isCancelDeliveryPartnerTask && this.props.user.role === "admin" 
                                        // && !(['COMPLETED','CANCELLED'].includes(this.props.serviceRequest.serviceRequestDetails.currentStatus))
                                        // && (this.props.serviceRequest.serviceRequestDetails.currentStatus !== "CANCELLED" || this.props.serviceRequest.serviceRequestDetails.currentStatus !== "COMPLETED" )
                                        ) 
                                        // || (this.state.cancelDeliveryFlag && this.state.cancelDeliveryFlag === true) 
                                        ? (
                                            <div style={{display : "flex", flexDirection: "row"}}>
                                                 <Typography variant="subtitle2" gutterBottom>
                                                <XirifyIcon /> 
                                                <span style={{ color: "#FF5764", marginLeft: "4px", position: "relative", bottom: 10 }}>
                                                    CANCELLED
                                                    { (['CANCELLED'].includes(this.props.serviceRequest.serviceRequestDetails.currentStatus)) ? null :
                                                   <Tooltip title="Dunzo">
                                                   <Button
                                                      
                                                       disabled={this.state.isDisabled}
                                                       onClick={this.createDuzoId}
                                                       style={{  marginTop: "5px" }}
                                                       size="small"
                                                   >
                                                          <DunzoStyleDIcon />
                                                   </Button>
                                               </Tooltip>
                                                    }
                                                    
                                                </span>
                                            </Typography>
                                            <Typography variant="subtitle2" gutterBottom>
                                                <XirifyIcon /> 
                                                <span style={{ color: "#FF5764", marginLeft: "4px", position: "relative", bottom: 10 }}>
                                                    CANCELLED
                                                    { (['CANCELLED'].includes(this.props.serviceRequest.serviceRequestDetails.currentStatus)) ? null :
                                                    
                                                    <Tooltip title="Shadowfax">
                                                    <Button
  
                                                        disabled={this.state.isDisabled}
                                                        onClick={this.createShadowfaxId}
                                                        style={{marginLeft:"5px", marginTop: "5px"}}
                                                        size="small"
                                                    >
                                                       <ShadowfaxStyleSIcon  />
                                                    </Button>
                                                    </Tooltip>
                                                    }
                                                </span>
                                            </Typography>
                                            <Typography variant="subtitle2" gutterBottom>
                                                <XirifyIcon /> 
                                                <span style={{ color: "#FF5764", marginLeft: "4px", position: "relative", bottom: 10 }}>
                                                    CANCELLED
                                                    { (['CANCELLED'].includes(this.props.serviceRequest.serviceRequestDetails.currentStatus)) ? null :
                                                    <Tooltip title="Porter">
                                                    <Button
  
                                                        disabled={this.state.isDisabled}
                                                        onClick={this.createPorterId}
                                                        style={{ marginLeft: "5px", marginTop: "5px" }}
                                                        size="small"
                                                    >
                                                              <PorterStylePIcon  />     
                                                    </Button>
                                                </Tooltip>
                                                    }
                                                </span>
                                            </Typography>
                                            </div>
                                           
                                            
                                        // ) : ( this.props.user.role === "admin" && !(['COMPLETED','CANCELLED'].includes(this.props.serviceRequest.serviceRequestDetails.currentStatus)) ? (
                                        ) : ( this.props.user.role === "admin" ? (
                                                this.props.serviceRequest && this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.isCannotCancelDpt !== null && this.props.serviceRequest.serviceRequestDetails.isCannotCancelDpt !== undefined && this.props.serviceRequest.serviceRequestDetails.isCannotCancelDpt ? 
                                                        <>
                                                            <Grid item xs={1}>
                                                                <Typography variant="subtitle2" gutterBottom style={{ color: "#FF8000", flex: 1 }}>
                                                                    <XirifyIcon /> 
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={11}>
                                                                <Typography variant="subtitle2" gutterBottom style={{ marginLeft: "10px", color: "#FF8000", flex: 1 }}>
                                                                        {this.props.serviceRequest.serviceRequestDetails.partnerTaskId}
                                                                </Typography>
                                                                <Typography variant="subtitle2" gutterBottom style={{ marginLeft: "10px", color: "#FF8000", flex: 1 }}>
                                                                    Delivery can't be cancelled
                                                                </Typography>
                                                            </Grid>
                                                        </>
                                                : 
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        {this.props.serviceRequest.serviceRequestDetails.partnerTaskId} <CancelDeliveryTask style={{position:"relative", top: this.props.serviceRequest.serviceRequestDetails.deliveryType === 'dunzo' ? 5 : 5, cursor: "pointer"}} onClick={this.cancelDeliveryModalOpen} />
                                                    </Typography>
                                            )
                                             : this.props.serviceRequest.serviceRequestDetails.partnerTaskId
                                        )
                                    )
                                        :
                                        ((this.props.serviceRequest.serviceRequestDetails.deliveryType === "dunzo" && (!this.props.serviceRequest.serviceRequestDetails.partnerTaskId || this.props.serviceRequest.serviceRequestDetails.taskIdCancelledBy))|| 
                                        (this.props.serviceRequest.serviceRequestDetails.deliveryType === "porter" && !this.props.serviceRequest.serviceRequestDetails.partnerTaskId && !this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate && !this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot ) || 
                                        (this.props.serviceRequest.serviceRequestDetails.deliveryType === "shadowfax" && !this.props.serviceRequest.serviceRequestDetails.partnerTaskId && !this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate && !this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot) || 
                                        (this.props.serviceRequest.serviceRequestDetails.deliveryType === "borzo" && !this.props.serviceRequest.serviceRequestDetails.partnerTaskId) || 
                                        (this.props.serviceRequest.serviceRequestDetails.deliveryType === "xirify_delivery" && this.props.serviceRequest.serviceRequestDetails.partnerTaskId === undefined) ||
                                        (this.props.serviceRequest.serviceRequestDetails.deliveryType === "self" && this.props.serviceRequest.serviceRequestDetails.partnerTaskId === undefined)) && this.props.serviceRequest.serviceRequestDetails.currentStatus !== 'CREATED' && this.props.serviceRequest.serviceRequestDetails.currentStatus !== 'INITIATED' &&
                                        this.props.serviceRequest.serviceRequestDetails.serviceMode === "DELIVERY" && this.props.user.role === "admin"  &&
                                        (this.props.serviceRequest?.serviceRequestDetails?.isASAPOrder || (this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot && (this.props.serviceRequest.serviceRequestDetails.isSheduleDeliveyLater === false || this.props.serviceRequest.serviceRequestDetails.isSheduleDeliveyLater === true) && (this.props.serviceRequest.serviceRequestDetails.deliveryType === "xirify_delivery"
                                         || this.props.serviceRequest.serviceRequestDetails.deliveryType === "self" || this.props.serviceRequest.serviceRequestDetails.deliveryType === 'borzo' || this.props.serviceRequest.serviceRequestDetails.deliveryType === 'dunzo') ) || (this.props.serviceRequest.serviceRequestDetails.preferredDeliverySlot && this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate && (this.props.serviceRequest.serviceRequestDetails.isSheduleDeliveyLater === true || this.props.serviceRequest.serviceRequestDetails.isSheduleDeliveyLater === false)) ||
                                         (this.props.serviceRequest.serviceRequestDetails.rescheduledServiceDate &&(this.props.serviceRequest.serviceRequestDetails.isSheduleDeliveyLater === false || this.props.serviceRequest.serviceRequestDetails.isSheduleDeliveyLater === true) && (this.props.serviceRequest.serviceRequestDetails.deliveryType === "xirify_delivery" || this.props.serviceRequest.serviceRequestDetails.deliveryType === "self" 
                                         ) )) ? <div>
                                           <Tooltip title="Dunzo">
                                                   <Button                                                     
                                                       disabled={this.state.isDisabled}
                                                       onClick={this.createDuzoId}
                                                       style={{  marginTop: "5px" }}
                                                       size="small"
                                                   >
                                                         <DunzoStyleDIcon />
                                                   </Button>
                                               </Tooltip>
                                            
                                               <Tooltip title="Shadowfax">
                                                    <Button 
                                                       disabled={this.state.isDisabled}
                                                        onClick={this.createShadowfaxId}
                                                        style={{marginLeft:"5px", marginTop: "5px"}}
                                                        size="small"
                                                    >
                                                        <ShadowfaxStyleSIcon  />
                                                    </Button>
                                                    </Tooltip>
                                                    <Tooltip title="Porter">
                                                    <Button 
                                                        disabled={this.state.isDisabled}
                                                        onClick={this.createPorterId}
                                                        style={{marginLeft: "5px", marginTop: "5px"}}
                                                         size="small"
                                                    >
                                                          <PorterStylePIcon  />     
                                                    </Button>
                                                   </Tooltip>
                                         </div>
                                             
                                           :
                                            scheduledDate && this.props.serviceRequest.serviceRequestDetails.serviceMode === "DELIVERY" && 
                                            ((this.props.serviceRequest.serviceRequestDetails.deliveryType === "dunzo" && !this.props.serviceRequest.serviceRequestDetails.partnerTaskId )|| 
                                            (this.props.serviceRequest.serviceRequestDetails.deliveryType === "porter" && !this.props.serviceRequest.serviceRequestDetails.partnerTaskId ) || 
                                            (this.props.serviceRequest.serviceRequestDetails.deliveryType === "shadowfax" && !this.props.serviceRequest.serviceRequestDetails.partnerTaskId ) || 
                                            (this.props.serviceRequest.serviceRequestDetails.deliveryType === "borzo" && !this.props.serviceRequest.serviceRequestDetails.partnerTaskId ) || 
                                            (this.props.serviceRequest.serviceRequestDetails.deliveryType === "xirify_delivery" && this.props.serviceRequest.serviceRequestDetails.partnerTaskId === undefined) ||
                                            (this.props.serviceRequest.serviceRequestDetails.deliveryType === "self" && this.props.serviceRequest.serviceRequestDetails.partnerTaskId === undefined)) && this.props.serviceRequest.serviceRequestDetails.currentStatus !== 'CREATED' && this.props.serviceRequest.serviceRequestDetails.currentStatus !== 'INITIATED' &&this.props.serviceRequest.serviceRequestDetails.isSheduleDeliveyLater === false && this.props.user.role === "admin" && !this.props.serviceRequest.serviceRequestDetails.partnerTaskId? 
                                            <div>
                                           <Tooltip title="Dunzo">
                                                   <Button
                                                       disabled={this.state.isDisabled}
                                                       onClick={this.createDuzoId}
                                                       style={{  marginTop: "5px" }}
                                                       size="small"
                                                   >
                                                      <DunzoStyleDIcon />
                                                   </Button>
                                               </Tooltip>
                                            
                                               <Tooltip title="Shadowfax">
                                                    <Button
                                                        disabled={this.state.isDisabled}
                                                        onClick={this.createShadowfaxId}
                                                        style={{marginLeft:"5px", marginTop: "5px"}}
                                                        size="small"
                                                    >
                                                        <ShadowfaxStyleSIcon  />
                                                    </Button>
                                                    </Tooltip>
                                                    <Tooltip title="Porter">
                                                    <Button
                                                        disabled={this.state.isDisabled}
                                                        onClick={this.createPorterId}
                                                        style={{marginLeft: "5px", marginTop: "5px"}}
                                                         size="small"
                                                    >
                                                        <PorterStylePIcon  />    
                                                    </Button>
                                                   </Tooltip>
                                        </div>:
                                            rescheduleDate && this.props.serviceRequest.serviceRequestDetails.serviceMode === "DELIVERY" &&
                                            ((this.props.serviceRequest.serviceRequestDetails.deliveryType === "dunzo" && !this.props.serviceRequest.serviceRequestDetails.partnerTaskId )|| 
                                        (this.props.serviceRequest.serviceRequestDetails.deliveryType === "porter" && !this.props.serviceRequest.serviceRequestDetails.partnerTaskId ) || 
                                        (this.props.serviceRequest.serviceRequestDetails.deliveryType === "shadowfax" && !this.props.serviceRequest.serviceRequestDetails.partnerTaskId ) || 
                                        (this.props.serviceRequest.serviceRequestDetails.deliveryType === "borzo" && !this.props.serviceRequest.serviceRequestDetails.partnerTaskId ) || 
                                        (this.props.serviceRequest.serviceRequestDetails.deliveryType === "xirify_delivery" && this.props.serviceRequest.serviceRequestDetails.partnerTaskId === undefined) ||
                                        (this.props.serviceRequest.serviceRequestDetails.deliveryType === "self" && this.props.serviceRequest.serviceRequestDetails.partnerTaskId === undefined)) && this.props.serviceRequest.serviceRequestDetails.currentStatus !== 'CREATED' && this.props.serviceRequest.serviceRequestDetails.currentStatus !== 'INITIATED'&& (this.props.serviceRequest.serviceRequestDetails.isSheduleDeliveyLater === true || this.props.serviceRequest.serviceRequestDetails.isSheduleDeliveyLater === false) && this.props.user.role === "admin" && !this.props.serviceRequest.serviceRequestDetails.partnerTaskId? 
                                        <div>
                                        <Tooltip title="Dunzo">
                                                   <Button
                                                       disabled={this.state.isDisabled}
                                                       onClick={this.createDuzoId}
                                                       style={{  marginTop: "5px" }}
                                                       size="small"
                                                   >
                                                       <DunzoStyleDIcon />
                                                   </Button>
                                               </Tooltip>
                                       
                                               <Tooltip title="Shadowfax">
                                                    <Button
                                                        disabled={this.state.isDisabled}
                                                        onClick={this.createShadowfaxId}
                                                        style={{marginLeft:"5px", marginTop: "5px"}}
                                                        size="small"
                                                    >
                                                        <ShadowfaxStyleSIcon  />
                                                    </Button>
                                                    </Tooltip>
                                                    <Tooltip title="Porter">
                                                    <Button
                                                        disabled={this.state.isDisabled}
                                                        onClick={this.createPorterId}
                                                        style={{marginLeft: "5px", marginTop: "5px"}}
                                                         size="small"
                                                    >
                                                      <PorterStylePIcon  /> 
                                                    </Button>
                                                   </Tooltip>
                                    </div> :
                                          schReshDate && this.props.serviceRequest.serviceRequestDetails.serviceMode === "DELIVERY" && 
                                          ((this.props.serviceRequest.serviceRequestDetails.deliveryType === "dunzo" && !this.props.serviceRequest.serviceRequestDetails.partnerTaskId )|| 
                                          (this.props.serviceRequest.serviceRequestDetails.deliveryType === "porter" && !this.props.serviceRequest.serviceRequestDetails.partnerTaskId ) || 
                                          (this.props.serviceRequest.serviceRequestDetails.deliveryType === "shadowfax" && !this.props.serviceRequest.serviceRequestDetails.partnerTaskId ) || 
                                          (this.props.serviceRequest.serviceRequestDetails.deliveryType === "borzo" && !this.props.serviceRequest.serviceRequestDetails.partnerTaskId ) || 
                                          (this.props.serviceRequest.serviceRequestDetails.deliveryType === "xirify_delivery" && this.props.serviceRequest.serviceRequestDetails.partnerTaskId === undefined) ||
                                          (this.props.serviceRequest.serviceRequestDetails.deliveryType === "self" && this.props.serviceRequest.serviceRequestDetails.partnerTaskId === undefined)) && this.props.serviceRequest.serviceRequestDetails.currentStatus !== 'CREATED' && this.props.serviceRequest.serviceRequestDetails.currentStatus !== 'INITIATED' && (this.props.serviceRequest.serviceRequestDetails.isSheduleDeliveyLater === true || this.props.serviceRequest.serviceRequestDetails.isSheduleDeliveyLater === false) && this.props.user.role === "admin" && !this.props.serviceRequest.serviceRequestDetails.partnerTaskId? 
                                          <div>
                                         <Tooltip title="Dunzo">
                                                   <Button
                                                       disabled={this.state.isDisabled}
                                                       onClick={this.createDuzoId}
                                                       style={{  marginTop: "5px" }}
                                                       size="small"
                                                   >
                                                        <DunzoStyleDIcon />
                                                   </Button>
                                               </Tooltip>
                                          
                                               <Tooltip title="Shadowfax">
                                                    <Button
                                                        disabled={this.state.isDisabled}
                                                        onClick={this.createShadowfaxId}
                                                        style={{marginLeft:"5px", marginTop: "5px"}}
                                                        size="small"
                                                    >
                                                        <ShadowfaxStyleSIcon  />
                                                    </Button>
                                                    </Tooltip>
                                                    <Tooltip title="Porter">
                                                    <Button
                                                        disabled={this.state.isDisabled}
                                                        onClick={this.createPorterId}
                                                        style={{marginLeft: "5px", marginTop: "5px"}}
                                                         size="small"
                                                    >
                                                          <PorterStylePIcon  /> 
                                                    </Button>
                                                   </Tooltip>
                                      </div>
                                        :
                                        "NA"
                                }
                            </Grid>
                  <Grid item style={{ display: "flex" }}>
                    {partnerTaskIdText &&
                    partnerTaskIdText !== undefined &&
                    partnerTaskIdText !== null
                      ? partnerTaskIdText
                      : null}
                  </Grid>
                </Grid>
                
            
                

                <Grid item xs={4}>
                  {this.props.serviceRequest.serviceRequestDetails
                    .currentStatus !== null &&
                  this.props.serviceRequest.serviceRequestDetails
                    .currentStatus !== undefined &&
                  this.props.serviceRequest.serviceRequestDetails
                    .currentStatus !== "COMPLETED" ? (
                    this.props.serviceRequest.serviceRequestDetails
                      .runnerDetails !== null &&
                    this.props.serviceRequest.serviceRequestDetails
                      .runnerDetails !== undefined &&
                    this.props.serviceRequest.serviceRequestDetails
                      .runnerDetails ? (
                      <>
                        <Grid item>
                          <Typography gutterBottom>
                            <span style={{ fontSize: " 0.875rem" }}>
                              Runner Name :{" "}
                            </span>
                            <span
                              style={{
                                fontSize: " 0.875rem",
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              {
                                this.props.serviceRequest.serviceRequestDetails
                                  .runnerDetails?.name
                              }
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography gutterBottom>
                            <span style={{ fontSize: " 0.875rem" }}>
                              Runner Number :
                            </span>
                            <span
                              style={{
                                fontSize: " 0.875rem",
                                fontWeight: "bold",
                              }}
                            >
                              {" "}
                              {
                                this.props.serviceRequest.serviceRequestDetails
                                  .runnerDetails?.phone_number
                              }{" "}
                            </span>
                          </Typography>
                        </Grid>
                      </>
                    ) : null
                  ) : null}
                </Grid>
                <Grid item xs={4}></Grid>
                {this.props.serviceRequest &&
                this.props.serviceRequest.serviceRequestDetails &&
                this.props.serviceRequest.serviceRequestDetails
                  .isCancelDeliveryPartnerTask !== null &&
                this.props.serviceRequest.serviceRequestDetails
                  .isCancelDeliveryPartnerTask !== undefined &&
                this.props.serviceRequest.serviceRequestDetails
                  .isCancelDeliveryPartnerTask ? (
                  this.props.serviceRequest?.serviceRequestDetails
                    ?.taskIdCancelledBy ? (
                    <Grid
                      item
                      xs={9}
                      className={classes.cancelNotesContainer}
                      style={{ border: "0.5px solid #FF5764" }}
                    >
                      <>
                        <Grid item xs={12}>
                          <Grid item>
                            <Typography
                              display="block"
                              gutterBottom
                              style={{ margin: "6px 0px 6px 14px" }}
                            >
                              <DeliveryCancelNotesIcon />
                              <span
                                style={{
                                  marginLeft: "20px",
                                  position: "relative",
                                  top: -10,
                                }}
                              >
                                Delivery is cancelled by{" "}
                                {(this.props.serviceRequest
                                  ?.serviceRequestDetails?.taskIdCancelledBy
                                  ?.firstName
                                  ? this.props.serviceRequest
                                      .serviceRequestDetails.taskIdCancelledBy
                                      .firstName
                                  : "") +
                                  " " +
                                  (this.props.serviceRequest
                                    ?.serviceRequestDetails?.taskIdCancelledBy
                                    ?.lastName
                                    ? this.props.serviceRequest
                                        .serviceRequestDetails.taskIdCancelledBy
                                        .lastName
                                    : "")}{" "}
                                from{" "}
                                {this.props.serviceRequest
                                  ?.serviceRequestDetails?.taskIdCancelledBy
                                  ?.userRole
                                  ? this.props.serviceRequest
                                      .serviceRequestDetails.taskIdCancelledBy
                                      .userRole === "admin"
                                    ? `${brandName} Support`
                                    : [
                                        "porter",
                                        "dunzo",
                                        "borzo",
                                        "shadowfax",
                                      ].includes(
                                        this.props.serviceRequest
                                          .serviceRequestDetails
                                          .taskIdCancelledBy.userRole
                                      )
                                    ? this.props.serviceRequest
                                        .serviceRequestDetails.taskIdCancelledBy
                                        .userRole
                                    : "Business"
                                  : "--"}
                              </span>
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    </Grid>
                  ) : null
                ) : this.props.serviceRequest.serviceRequestDetails
                    .isCannotCancelDpt !== null &&
                  this.props.serviceRequest.serviceRequestDetails
                    .isCannotCancelDpt !== undefined &&
                  this.props.serviceRequest.serviceRequestDetails
                    .isCannotCancelDpt &&
                  this.props.serviceRequest.serviceRequestDetails
                    .partnerTaskId ? (
                  <Grid
                    item
                    xs={9}
                    className={classes.cancelNotesContainer}
                    style={{ border: "0.5px solid #FF860D" }}
                  >
                    <>
                      <Grid item xs={12}>
                        <Grid item>
                          <Typography
                            display="block"
                            gutterBottom
                            style={{ margin: "6px 0px 6px 14px" }}
                          >
                            <DeliveryCancelNotesIcon />
                            <span
                              style={{
                                marginLeft: "20px",
                                position: "relative",
                                top: -10,
                                color: "#FF8000",
                              }}
                            >
                              {(this.props.serviceRequest &&
                              this.props.serviceRequest.serviceRequestDetails &&
                              this.props.serviceRequest.serviceRequestDetails
                                .taskIdCancelledBy !== null &&
                              this.props.serviceRequest.serviceRequestDetails
                                .taskIdCancelledBy !== undefined &&
                              this.props.serviceRequest.serviceRequestDetails
                                .taskIdCancelledBy.firstName &&
                              this.props.serviceRequest.serviceRequestDetails
                                .taskIdCancelledBy.firstName !== null &&
                              this.props.serviceRequest.serviceRequestDetails
                                .taskIdCancelledBy.firstName !== undefined
                                ? this.props.serviceRequest
                                    .serviceRequestDetails.taskIdCancelledBy
                                    .firstName
                                : "") +
                                " " +
                                (this.props.serviceRequest &&
                                this.props.serviceRequest
                                  .serviceRequestDetails &&
                                this.props.serviceRequest.serviceRequestDetails
                                  .taskIdCancelledBy !== null &&
                                this.props.serviceRequest.serviceRequestDetails
                                  .taskIdCancelledBy !== undefined &&
                                this.props.serviceRequest.serviceRequestDetails
                                  .taskIdCancelledBy.lastName &&
                                this.props.serviceRequest.serviceRequestDetails
                                  .taskIdCancelledBy.lastName !== null &&
                                this.props.serviceRequest.serviceRequestDetails
                                  .taskIdCancelledBy.lastName !== undefined
                                  ? this.props.serviceRequest
                                      .serviceRequestDetails.taskIdCancelledBy
                                      .lastName
                                  : "")}{" "}
                              is trying to cancel the delivery
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  </Grid>
                ) : null}

                <Grid
                  item
                  xs={9}
                  style={{
                    backgroundColor: "white",
                    padding: "0px 10px",
                    borderRadius: "5px",
                    marginBottom: "10px",
                    paddingTop: "11px",
                  }}
                >
                  {this.props &&
                  this.props.serviceRequest &&
                  this.props.serviceRequest.serviceRequestDetails &&
                  this.props.serviceRequest.serviceRequestDetails
                    .businessNote !== null &&
                  this.props.serviceRequest.serviceRequestDetails
                    .businessNote !== undefined &&
                  this.props.serviceRequest.serviceRequestDetails.businessNote
                    .length > 0 ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        className={classes.container}
                        style={{ paddingTop: "15px" }}
                      >
                        <Grid item>
                          <Typography
                            style={{
                              backgroundColor: "#FAFAFA",
                              padding: "8px 10px 0px 10px",
                              borderRadius: "5px",
                            }}
                            variant="subtitle2"
                            display="block"
                            gutterBottom
                          >
                            <BusinessNote />
                            <span
                              style={{
                                paddingLeft: "15px",
                                verticalAlign: "top",
                              }}
                            >
                              {
                                this.props.serviceRequest.serviceRequestDetails
                                  .businessNote
                              }{" "}
                            </span>
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={1}></Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{
                    backgroundColor: "white",
                    paddingTop: "20px",
                    marginBottom: "10px",
                  }}
                >
                  <Badge
                    badgeContent={SupportNoteCount}
                    color="error"
                    size="small"
                    anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    style={{ margin: "4px" }}
                  >
                    <IconButton
                      className={classes.icon}
                      style={{ float: "right", padding: "0px" }}
                      onClick={this.openSupportNoteModal}
                    >
                      <Tooltip title={SupportNote}>
                        <Avatar style={{ background: "none" }}>
                          {" "}
                          <SUPPORTSVG />
                        </Avatar>
                      </Tooltip>
                    </IconButton>
                  </Badge>

                  <Badge
                    badgeContent={messageCount}
                    color="error"
                    size="small"
                    anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    style={{ margin: "4px" }}
                  >
                    <IconButton
                      className={classes.icon}
                      style={{ float: "right", padding: "0px" }}
                      onClick={this.openMessageModal}
                    >
                      <Tooltip title={Messages}>
                        <Avatar style={{ background: "none" }}>
                          {" "}
                          <MsgSvgNew />
                        </Avatar>
                      </Tooltip>
                    </IconButton>
                  </Badge>

                  {this.props.user.role === "admin" ? (
                    this.props?.serviceRequest?.serviceRequestDetails
                      ?.isAdvancePaymentToBusiness === true &&
                    this.props?.serviceRequest?.serviceRequestDetails
                      ?.isAdvancePaymentDoneToBusiness === true ? (
                      <Badge
                        color="error"
                        size="small"
                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                        style={{ margin: "4px" }}
                      >
                        <IconButton
                          className={classes.icon}
                          style={{ float: "right", padding: "0px" }}
                        >
                          <Tooltip title="Advance payment done">
                            <Avatar style={{ backgroundColor: "#84de87" }}>
                              {" "}
                              <GreenAP />
                            </Avatar>
                          </Tooltip>
                        </IconButton>
                      </Badge>
                    ) : (this.props.serviceRequest.serviceRequestDetails
                        .paymentStatus === "PAID" ||
                        this.props.serviceRequest.serviceRequestDetails
                          .paymentStatus === "ADVANCEPAID" ||
                        this.props.serviceRequest.serviceRequestDetails
                          .currentStatus === "CREATED" ||
                        this.props.serviceRequest.serviceRequestDetails
                          .currentStatus === "CANCELLED") &&
                      this.props.serviceRequest.serviceRequestDetails
                        .isAdvancePaymentToBusiness === true &&
                      !this.props.serviceRequest.serviceRequestDetails
                        .isAdvancePaymentDoneToBusiness ? (
                      <Badge
                        color="error"
                        size="small"
                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                        style={{ margin: "4px" }}
                      >
                        <IconButton
                          className={classes.icon}
                          style={{ float: "right", padding: "0px" }}
                        >
                          <Tooltip title="Advance Payment to business">
                            <Avatar style={{ backgroundColor: "#ddd" }}>
                              {" "}
                              <AdvGreyNew />
                            </Avatar>
                          </Tooltip>
                        </IconButton>
                      </Badge>
                    ) : (this.props.serviceRequest.serviceRequestDetails
                        .paymentStatus === "UNPAID" ||
                        this.props.serviceRequest.serviceRequestDetails
                          .paymentStatus === "INITIATED") &&
                      [
                        "ACCEPTED",
                        "IN_PROGRESS",
                        "OUT_FOR_DELIVERY",
                        "DELIVERY_DONE",
                        "READY_FOR_PICKUP",
                        "PICKUP_DONE",
                        "COMPLETED",
                      ].includes(
                        this.props.serviceRequest.serviceRequestDetails
                          .currentStatus
                      ) &&
                      !this.props.serviceRequest.serviceRequestDetails
                        .isAdvancePaymentDoneToBusiness ? (
                      <Badge
                        color="error"
                        size="small"
                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                        style={{ margin: "4px" }}
                      >
                        <IconButton
                          className={classes.icon}
                          style={{ float: "right", padding: "0px" }}
                          onClick={this.openAdvancePaymentModal}
                        >
                          <Tooltip title="Advance Payment to business">
                            <Avatar style={{ background: "none" }}>
                              {" "}
                              <AdvanceNew/>
                            </Avatar>
                          </Tooltip>
                        </IconButton>
                      </Badge>
                    ) : (
                      <Badge
                        color="error"
                        size="small"
                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                        style={{ margin: "4px" }}
                      >
                        <IconButton
                          className={classes.icon}
                          style={{ float: "right", padding: "0px" }}
                        >
                          <Tooltip title="Advance Payment to business">
                            <Avatar style={{ backgroundColor: "#ddd" }}>
                              {" "}
                              <AdvGreyNew />
                            </Avatar>
                          </Tooltip>
                        </IconButton>
                      </Badge>
                    )
                  ) : null}

                  {this.props.user.role === "admin" ? (
                    !(
                      this.props.serviceRequest.serviceRequestDetails
                        .currentStatus === "INITIATED" ||
                      this.props.serviceRequest.serviceRequestDetails
                        .currentStatus === "QUOTED" ||
                      this.props.serviceRequest.serviceRequestDetails
                        .currentStatus === "QUOTE_DECLINED" ||
                      this.props.serviceRequest.serviceRequestDetails
                        .currentStatus === "CREATED"
                    ) &&
                    !this.state.acceptDisabled &&
                    this.props.serviceRequest.serviceRequestDetails
                      .paymentStatus !== "UNPAID" &&
                    this.props.serviceRequest.serviceRequestDetails
                      .paymentStatus !== "ADVANCEPAID" ? (
                      // <Tooltip title="Xirify Adjustment" onClick={this.openXirifyAdjustmentModalOpen}>
                      //     <Reset/>
                      // </Tooltip>
                      <Badge
                        color="error"
                        size="small"
                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                        style={{ margin: "4px" }}
                      >
                        <IconButton
                          className={classes.icon}
                          style={{ float: "right", padding: "0px" }}
                          onClick={this.openXirifyAdjustmentModalOpen}
                        >
                          <Tooltip title={`${brandName} Adjustment`}>
                            <Avatar style={{ background: "none" }}>
                              <XirifyPaymentNew />
                            </Avatar>
                          </Tooltip>
                        </IconButton>
                      </Badge>
                    ) : (
                      // <Tooltip title="Xirify Adjustment">
                      //     <ResGrey/>
                      // </Tooltip>
                      <Badge
                        color="error"
                        size="small"
                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                        style={{ margin: "4px" }}
                      >
                        <IconButton
                          className={classes.icon}
                          style={{ float: "right", padding: "0px" }}
                        >
                          <Tooltip title={"Xirify Adjustment"}>
                            <Avatar style={{ background: "none" }}>
                              <ResGreyNew />
                            </Avatar>
                          </Tooltip>
                        </IconButton>
                      </Badge>
                    )
                  ) : null}
                </Grid>
            
                <Grid item xs={12} className={classes.container}>
                  {this.props.serviceRequest.serviceRequestDetails &&
                  this.props.serviceRequest.serviceRequestDetails
                    .statusHistory ? (
                    <Stepper
                      serviceRequestDetails={
                        this.props.serviceRequest.serviceRequestDetails
                      }
                      activeStep={activeStep}
                      completedSteps={completedSteps}
                      costType={serviceCostType}
                      preferredServiceMode={preferredServiceMode}
                      serviceMode={serviceMode}
                    />
                  ) : null}
                </Grid>
                {(this.props.serviceRequest.serviceRequestDetails
                  .currentStatus === "CANCELLED" ||
                  this.props.serviceRequest.serviceRequestDetails
                    .currentStatus === "REJECTED") &&
                cancelledOrRejected.isCancelledOrRejected ? (
                  <Grid
                    item
                    xs={12}
                    style={{
                      marginTop: "7px",
                      marginBottom: "7px",
                      background: "white",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      display="block"
                      color="error"
                      gutterBottom
                    >
                      Request {cancelledOrRejected.status}
                    </Typography>
                    <Typography
                      variant="body2"
                      display="block"
                      color="error"
                      gutterBottom
                    >
                      This order is {cancelledOrRejected.status} by{" "}
                      {cancelledOrRejected.by} on{" "}
                      {cancelledOrRejected.actionDate}
                    </Typography>
                    {this.props.serviceRequest.serviceRequestDetails &&
                    this.props.serviceRequest.serviceRequestDetails.option ? (
                      <>
                        <Typography
                          variant="subtitle2"
                          display="inline"
                          gutterBottom
                        >
                          Reason for cancellation:
                        </Typography>
                        <Typography
                          variant="body2"
                          display="inline"
                          gutterBottom
                        >
                          {` ${this.props.serviceRequest.serviceRequestDetails.option}`}
                        </Typography>
                      </>
                    ) : null}
                  </Grid>
                ) : null}

                {this.props.serviceRequest.serviceRequestDetails &&
                this.props.serviceRequest.serviceRequestDetails.services &&
                this.props.serviceRequest.serviceRequestDetails.services
                  .length > 0 ? (
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ width: "1px" }} align="left">
                            {" "}
                          </TableCell>
                          <TableCell>Request Item</TableCell>
                          <TableCell align="left">Quantity</TableCell>
                          <TableCell align="left">Unit</TableCell>
                          <TableCell align="left">Rate per Unit</TableCell>
                          <TableCell align="left">Discount/Offer</TableCell>
                          <TableCell align="left">Qty Added</TableCell>

                          <TableCell align="left">Price</TableCell>
                          <TableCell align="left">IsAvailable</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.serviceRequest?.serviceRequestDetails
                          ?.businessOffers?.discount
                          ?.discountWithServiceOffers === false
                          ? //only if discountWithServiceOffers===false
                            this.props.serviceRequest.serviceRequestDetails.services.map(
                              (service, index) => {
                                let discountToDisplay =
                                  service.appliedServiceOffer
                                    ? service.appliedServiceOffer.offerType ===
                                      "discount"
                                      ? service.appliedServiceOffer
                                          .discountType === "FLAT"
                                        ? service.looseQuantityOfService &&
                                          service.looseQuantityOfService !== 0
                                          ? service.appliedServiceOffer.off *
                                              service.looseQuantityOfService >
                                            service.appliedServiceOffer
                                              .maxDiscountCap
                                            ? service.appliedServiceOffer
                                                .maxDiscountCap
                                              ? service.appliedServiceOffer
                                                  .maxDiscountCap
                                              : service.appliedServiceOffer
                                                  .off *
                                                service.looseQuantityOfService
                                            : service.appliedServiceOffer.off *
                                              service.looseQuantityOfService
                                          : service.appliedServiceOffer.off *
                                              service.quantityOfService >
                                            service.appliedServiceOffer
                                              .maxDiscountCap
                                          ? service.appliedServiceOffer
                                              .maxDiscountCap
                                            ? service.appliedServiceOffer
                                                .maxDiscountCap
                                            : service.appliedServiceOffer.off *
                                              service.quantityOfService
                                          : service.appliedServiceOffer.off *
                                            service.quantityOfService
                                        : service.appliedServiceOffer
                                            .discountAmount
                                      : "--"
                                    : "--";
                                let discount = service.appliedServiceOffer
                                  ? service.appliedServiceOffer.offerType ===
                                    "discount"
                                    ? service.appliedServiceOffer
                                        .discountType === "FLAT"
                                      ? service.looseQuantityOfService &&
                                        service.looseQuantityOfService !== 0
                                        ? service.appliedServiceOffer.off *
                                            service.looseQuantityOfService >
                                          service.appliedServiceOffer
                                            .maxDiscountCap
                                          ? service.appliedServiceOffer
                                              .maxDiscountCap
                                            ? service.appliedServiceOffer
                                                .maxDiscountCap
                                            : service.appliedServiceOffer.off *
                                              service.looseQuantityOfService
                                          : service.appliedServiceOffer.off *
                                            service.looseQuantityOfService
                                        : service.appliedServiceOffer.off *
                                            service.quantityOfService >
                                          service.appliedServiceOffer
                                            .maxDiscountCap
                                        ? service.appliedServiceOffer
                                            .maxDiscountCap
                                          ? service.appliedServiceOffer
                                              .maxDiscountCap
                                          : service.appliedServiceOffer.off *
                                            service.quantityOfService
                                        : service.appliedServiceOffer.off *
                                          service.quantityOfService
                                      : service.appliedServiceOffer
                                          .discountAmount
                                    : 0
                                  : 0;
                                let costWithoutDiscount =
                                  service &&
                                  service.cost &&
                                  service.cost.fixedCost &&
                                  service.quantityOfService
                                    ? service.looseQuantityOfService &&
                                      service.looseQuantityOfService !== 0
                                      ? service.cost.fixedCost *
                                        service.looseQuantityOfService
                                      : service.cost.fixedCost *
                                        service.quantityOfService
                                    : 0;
                                let costWithDiscount =
                                  service &&
                                  service.cost &&
                                  service.cost.fixedCost &&
                                  service.quantityOfService
                                    ? service.looseQuantityOfService &&
                                      service.looseQuantityOfService !== 0
                                      ? service.cost.fixedCost *
                                          service.looseQuantityOfService -
                                        discount
                                      : service.cost.fixedCost *
                                          service.quantityOfService -
                                        discount
                                    : 0;
                                let foodType =
                                  service.foodType !== null &&
                                  service.foodType !== undefined &&
                                  service.foodType !== "" ? (
                                    service.foodType === "NonVeg" ? (
                                      <NonvegSVG />
                                    ) : service.foodType === "Veg" ? (
                                      <VegSVG />
                                    ) : service.foodType === "Egg" ? (
                                      <EggSVG />
                                    ) : service.foodType === "NA" ? (
                                      "--"
                                    ) : service.foodType === "Blank" ? (
                                      " "
                                    ) : (
                                      " "
                                    )
                                  ) : (
                                    "--"
                                  );

                                let cost = 0;
                                if (showOnlyCartDiscount) {
                                  if (
                                    this.props.serviceRequest
                                      .serviceRequestDetails
                                      .appliedBusinessOffer.discountType ===
                                    "FLAT"
                                  ) {
                                    cartDiscount =
                                      this.props.serviceRequest
                                        .serviceRequestDetails
                                        .appliedBusinessOffer.off;
                                  } else if (
                                    this.props.serviceRequest
                                      .serviceRequestDetails
                                      .appliedBusinessOffer.discountType ===
                                    "PERCENTAGE"
                                  ) {
                                    cartDiscount =
                                      subTotalWithoutDiscount *
                                      (this.props.serviceRequest
                                        .serviceRequestDetails
                                        .appliedBusinessOffer.off /
                                        100);
                                    if (
                                      this.props.serviceRequest
                                        .serviceRequestDetails
                                        .appliedBusinessOffer.maxDiscountCap !==
                                        null &&
                                      this.props.serviceRequest
                                        .serviceRequestDetails
                                        .appliedBusinessOffer.maxDiscountCap <
                                        cartDiscount
                                    ) {
                                      cartDiscount =
                                        this.props.serviceRequest
                                          .serviceRequestDetails
                                          .appliedBusinessOffer.maxDiscountCap;
                                    }
                                  }
                                  discountToDisplay = "--";
                                  subTotal = subTotalWithoutDiscount;
                                  cost = costWithoutDiscount;
                                } else {
                                  cartDiscount = 0.0;
                                  subTotal = subTotalWithDiscount;
                                  cost = costWithDiscount;
                                }
                          
                               

                                return (
                                  <TableRow key={index}>
                                    {foodType !== null &&
                                    foodType !== undefined &&
                                    foodType !== "" ? (
                                      <TableCell
                                        scope="row"
                                        style={{ width: "1px" }}
                                        align="center"
                                      >
                                        {" "}
                                        {foodType}
                                      </TableCell>
                                    ) : null}
                                    <TableCell component="th" scope="row">
                                      {service.service}
                                    </TableCell>
                                    <TableCell align="left">
                                      {service &&
                                      service.cost &&
                                      service.cost !== null &&
                                      service.cost !== undefined &&
                                      service.cost.quantity &&
                                      service.cost.quantity !== null &&
                                      service.cost.quantity !== undefined &&
                                      service.cost.costType &&
                                      service.cost.costType === "Fixed"
                                        ? service.cost.quantity
                                        : "NA"}
                                    </TableCell>
                                    <TableCell align="left">
                                      {service &&
                                      service.cost &&
                                      service.cost.unit
                                        ? service.cost.unit
                                        : "NA"}
                                    </TableCell>
                                    <TableCell align="left">
                                      {service.cost.fixedCost
                                        ? service?.cost?.fixedCost?.toFixed(2)
                                        : "-"}
                                    </TableCell>
                                    <TableCell>
                                      {discountToDisplay !== "--" &&
                                      discountToDisplay !== null &&
                                      discountToDisplay !== undefined &&
                                      discountToDisplay !== ""
                                        ? discountToDisplay.toFixed(2)
                                        : discountToDisplay}
                                    </TableCell>
                                    <TableCell align="left">
                                      {service.looseQuantityOfService &&
                                      service.looseQuantityOfService !== 0
                                        ? service.looseQuantityOfService
                                        : service.quantityOfService}
                                    </TableCell>
                                    <TableCell align="left">
                                      {cost.toFixed(2)}
                                    </TableCell>
                                    <TableCell align="left">
                                      {service && service.isAvailable
                                        ? "true"
                                        : "false"}
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )
                          : this.props.serviceRequest.serviceRequestDetails.services.map(
                              (service, index) => {
                                let discount = service.appliedServiceOffer
                                  ? service.appliedServiceOffer.offerType ===
                                    "discount"
                                    ? service.appliedServiceOffer
                                        .discountType === "FLAT"
                                      ? service.looseQuantityOfService &&
                                        service.looseQuantityOfService !== 0
                                        ? service.appliedServiceOffer.off *
                                            service.looseQuantityOfService >
                                          service.appliedServiceOffer
                                            .maxDiscountCap
                                          ? service.appliedServiceOffer
                                              .maxDiscountCap
                                            ? service.appliedServiceOffer
                                                .maxDiscountCap
                                            : service.appliedServiceOffer.off *
                                              service.looseQuantityOfService
                                          : service.appliedServiceOffer.off *
                                            service.looseQuantityOfService
                                        : service.appliedServiceOffer.off *
                                            service.quantityOfService >
                                          service.appliedServiceOffer
                                            .maxDiscountCap
                                        ? service.appliedServiceOffer
                                            .maxDiscountCap
                                          ? service.appliedServiceOffer
                                              .maxDiscountCap
                                          : service.appliedServiceOffer.off *
                                            service.quantityOfService
                                        : service.appliedServiceOffer.off *
                                          service.quantityOfService
                                      : service.appliedServiceOffer
                                          .discountAmount
                                    : 0
                                  : 0;
                                let cost =
                                  service &&
                                  service.cost &&
                                  service.cost.fixedCost &&
                                  service.quantityOfService
                                    ? service.looseQuantityOfService &&
                                      service.looseQuantityOfService !== 0
                                      ? service.cost.fixedCost *
                                          service.looseQuantityOfService -
                                        discount
                                      : service.cost.fixedCost *
                                          service.quantityOfService -
                                        discount
                                    : 0;
                                subTotal = service.isAvailable
                                  ? subTotal + cost
                                  : subTotal;
                                if (
                                  this.props.serviceRequest
                                    .serviceRequestDetails
                                    .appliedBusinessOffer &&
                                  subTotal >=
                                    this.props.serviceRequest
                                      .serviceRequestDetails.businessOffers
                                      .discount.discountMinCartVal
                                ) {
                                  if (
                                    this.props.serviceRequest
                                      .serviceRequestDetails
                                      .appliedBusinessOffer.discountType ===
                                    "FLAT"
                                  ) {
                                    cartDiscount =
                                      this.props.serviceRequest
                                        .serviceRequestDetails
                                        .appliedBusinessOffer.off;
                                  } else if (
                                    this.props.serviceRequest
                                      .serviceRequestDetails
                                      .appliedBusinessOffer.discountType ===
                                    "PERCENTAGE"
                                  ) {
                                    cartDiscount =
                                      subTotal *
                                      (this.props.serviceRequest
                                        .serviceRequestDetails
                                        .appliedBusinessOffer.off /
                                        100);
                                    if (
                                      this.props.serviceRequest
                                        .serviceRequestDetails
                                        .appliedBusinessOffer.maxDiscountCap !==
                                        null &&
                                      this.props.serviceRequest
                                        .serviceRequestDetails
                                        .appliedBusinessOffer.maxDiscountCap <
                                        cartDiscount
                                    ) {
                                      cartDiscount =
                                        this.props.serviceRequest
                                          .serviceRequestDetails
                                          .appliedBusinessOffer.maxDiscountCap;
                                    }
                                  }
                                } else {
                                  cartDiscount = 0.0;
                                }
                               

                                let foodType =
                                  service.foodType !== null &&
                                  service.foodType !== undefined &&
                                  service.foodType !== "" ? (
                                    service.foodType === "NonVeg" ? (
                                      <NonvegSVG />
                                    ) : service.foodType === "Veg" ? (
                                      <VegSVG />
                                    ) : service.foodType === "Egg" ? (
                                      <EggSVG />
                                    ) : service.foodType === "NA" ? (
                                      "--"
                                    ) : service.foodType === "Blank" ? (
                                      " "
                                    ) : (
                                      " "
                                    )
                                  ) : (
                                    "--"
                                  );

                                return (
                                  <TableRow key={index}>
                                    {foodType !== null &&
                                    foodType !== undefined &&
                                    foodType !== "" ? (
                                      <TableCell
                                        scope="row"
                                        style={{ width: "1px" }}
                                        align="center"
                                      >
                                        {" "}
                                        {foodType}
                                      </TableCell>
                                    ) : null}
                                    <TableCell component="th" scope="row">
                                      {service.service}
                                    </TableCell>
                                    <TableCell align="left">
                                      {service &&
                                      service.cost &&
                                      service.cost !== null &&
                                      service.cost !== undefined &&
                                      service.cost.quantity &&
                                      service.cost.quantity !== null &&
                                      service.cost.quantity !== undefined &&
                                      service.cost.costType &&
                                      service.cost.costType === "Fixed"
                                        ? service.cost.quantity
                                        : "NA"}
                                    </TableCell>
                                    <TableCell align="left">
                                      {service &&
                                      service.cost &&
                                      service.cost.unit
                                        ? service.cost.unit
                                        : "NA"}
                                    </TableCell>
                                    <TableCell align="left">
                                      {service.cost.fixedCost
                                        ? service.cost.fixedCost.toFixed(2)
                                        : "-"}
                                    </TableCell>
                                    <TableCell>
                                      {service.appliedServiceOffer
                                        ? service.appliedServiceOffer
                                            .offerType === "discount"
                                          ? service.appliedServiceOffer
                                              .discountType === "FLAT"
                                            ? service.looseQuantityOfService &&
                                              service.looseQuantityOfService !==
                                                0
                                              ? service.appliedServiceOffer
                                                  .off *
                                                  service.looseQuantityOfService >
                                                service.appliedServiceOffer
                                                  .maxDiscountCap
                                                ? service.appliedServiceOffer
                                                    .maxDiscountCap
                                                  ? service.appliedServiceOffer.maxDiscountCap.toFixed(
                                                      2
                                                    )
                                                  : this.calDiscount(
                                                      service
                                                        .appliedServiceOffer
                                                        .off,
                                                      service.looseQuantityOfService
                                                    )
                                                : this.calDiscount(
                                                    service.appliedServiceOffer
                                                      .off,
                                                    service.looseQuantityOfService
                                                  )
                                              : service.appliedServiceOffer
                                                  .off *
                                                  service.quantityOfService >
                                                service.appliedServiceOffer
                                                  .maxDiscountCap
                                              ? service.appliedServiceOffer
                                                  .maxDiscountCap
                                                ? service.appliedServiceOffer.maxDiscountCap.toFixed(
                                                    2
                                                  )
                                                : this.calDiscount(
                                                    service.appliedServiceOffer
                                                      .off,
                                                    service.quantityOfService
                                                  )
                                              : this.calDiscount(
                                                  service.appliedServiceOffer
                                                    .off,
                                                  service.quantityOfService
                                                )
                                            : service.appliedServiceOffer
                                                .discountAmount &&
                                              service.appliedServiceOffer
                                                .discountAmount > 0
                                            ? service.appliedServiceOffer.discountAmount.toFixed(
                                                2
                                              )
                                            : "--"
                                          : "--"
                                        : "--"}
                                    </TableCell>
                                    {/* <TableCell>{service.appliedServiceOffer ? (((service.appliedServiceOffer.offerType === "discount") ? ((service.appliedServiceOffer.discountType === "FLAT") ? ((service.looseQuantityOfService && service.looseQuantityOfService !== 0) ? (((service.appliedServiceOffer.off * service.looseQuantityOfService) > service.appliedServiceOffer.maxDiscountCap) ? (service.appliedServiceOffer.maxDiscountCap ? service.appliedServiceOffer.maxDiscountCap : (service.appliedServiceOffer.off * service.looseQuantityOfService)) : (service.appliedServiceOffer.off * service.looseQuantityOfService)) : (((service.appliedServiceOffer.off * service.quantityOfService) > service.appliedServiceOffer.maxDiscountCap) ? (service.appliedServiceOffer.maxDiscountCap ? service.appliedServiceOffer.maxDiscountCap : (service.appliedServiceOffer.off * service.quantityOfService)) : (service.appliedServiceOffer.off * service.quantityOfService))) : service.appliedServiceOffer.discountAmount) : "--")) : "--"}</TableCell> */}
                                    <TableCell align="left">
                                      {service.looseQuantityOfService &&
                                      service.looseQuantityOfService !== 0
                                        ? service.looseQuantityOfService
                                        : service.quantityOfService}
                                    </TableCell>
                                    <TableCell align="left">
                                      {cost.toFixed(2)}
                                    </TableCell>
                                    <TableCell align="left">
                                      {service && service.isAvailable
                                        ? "true"
                                        : "false"}
                                    </TableCell>
                                  </TableRow>
                                );
                              }
                            )}
                      </TableBody>
                      {this.props.serviceRequest.serviceRequestDetails
                        .additionalCharges &&
                      this.props.serviceRequest.serviceRequestDetails
                        .additionalCharges.length > 0
                        ? this.props.serviceRequest.serviceRequestDetails.additionalCharges.map(
                            (adjustment) => {
                              subTotal = subTotal + adjustment.amount;
                              return (
                                <TableRow>
                                  <TableCell component="th" scope="row">
                                    {adjustment.description}(adjustment)
                                  </TableCell>
                                  <TableCell align="left"></TableCell>
                                  <TableCell align="left"></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell align="left"></TableCell>
                                  <TableCell align="left"></TableCell>
                                  <TableCell align="left"></TableCell>
                                  <TableCell align="left">
                                    {adjustment.amount.toFixed(2)}
                                  </TableCell>
                                  <TableCell></TableCell>
                                </TableRow>
                              );
                            }
                          )
                        : null}
                      {this.props.serviceRequest.serviceRequestDetails
                        .posItemNotFoundError &&
                      this.props.serviceRequest.serviceRequestDetails
                        .posItemNotFoundError !== null &&
                      this.props.serviceRequest.serviceRequestDetails
                        .posItemNotFoundError !== undefined ? (
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              float: "left",
                              paddingLeft: "10px",
                              width: "773%",
                              color: "red",
                            }}
                          >
                            {
                              this.props.serviceRequest.serviceRequestDetails
                                .posItemNotFoundError
                            }
                          </TableCell>
                        </TableRow>
                      ) : null}
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <b>Sub Total</b>
                        </TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell></TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left">
                          <b>{subTotal.toFixed(2)}</b>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>

                  <TableRow>
                        <TableCell component="th" scope="row">
                          Cart/Business Discount
                        </TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell></TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left">
                          {cartDiscount.toFixed(2) > 0
                            ? "- " + cartDiscount.toFixed(2)
                            : cartDiscount.toFixed(2)}
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>

                     
                      {this.props.serviceRequest.serviceRequestDetails &&
                      this.props.serviceRequest.serviceRequestDetails
                        .couponId !== null &&
                      this.props.serviceRequest.serviceRequestDetails
                        .couponId &&
                      this.props.serviceRequest.serviceRequestDetails.couponId
                        .amount &&
                      this.props.serviceRequest.serviceRequestDetails.amount &&
                      (this.props.serviceRequest.serviceRequestDetails.amount !=
                        null ||
                        this.props.serviceRequest.serviceRequestDetails
                          .amount !== "Not Set" ||
                        this.props.serviceRequest.serviceRequestDetails
                          .amount !== undefined) ? (
                        // && (this.props.serviceRequest.serviceRequestDetails.amount >= this.props.serviceRequest.serviceRequestDetails.couponId.minOrderValue)
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Coupon (
                            {
                              this.props.serviceRequest.serviceRequestDetails
                                .couponId.couponName
                            }
                            ){" "}
                            {
                              this.props.serviceRequest.serviceRequestDetails
                                .couponId.couponCode
                            }{" "}
                            {<br></br>}{" "}
                            {typeof this.props.serviceRequest
                              .serviceRequestDetails.SRCouponStatus ===
                            "undefined"
                              ? " "
                              : this.props.serviceRequest.serviceRequestDetails
                                  .currentStatus &&
                                this.props.serviceRequest.serviceRequestDetails
                                  .currentStatus === "CANCELLED"
                              ? "(Inactive)"
                              : "(Active)"}
                          </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">
                            {" "}
                            {"- " +
                              this.props.serviceRequest.serviceRequestDetails.couponId.amount.toFixed(
                                2
                              )}
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      ) : null}

                      {this.props.serviceRequest.serviceRequestDetails.cgst >
                        0 && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            CGST
                          </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">
                            {this.props.serviceRequest.serviceRequestDetails
                              .cgst
                              ? this.props.serviceRequest.serviceRequestDetails.cgst.toFixed(
                                  2
                                )
                              : 0}
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      )}

                      {this.props.serviceRequest.serviceRequestDetails.sgst >
                        0 && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            SGST
                          </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">
                            {this.props.serviceRequest.serviceRequestDetails
                              .sgst
                              ? this.props.serviceRequest.serviceRequestDetails.sgst.toFixed(
                                  2
                                )
                              : 0}
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      )}

                      {this.props.serviceRequest.serviceRequestDetails.igst >
                        0 && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            IGST
                          </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">
                            {this.props.serviceRequest.serviceRequestDetails
                              .igst
                              ? this.props.serviceRequest.serviceRequestDetails.igst.toFixed(
                                  2
                                )
                              : 0}
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      )}

                      {this.props.serviceRequest.serviceRequestDetails
                        .consumerConvFees > 0 && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Consumer convenience charges
                          </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">
                            {this.props.serviceRequest.serviceRequestDetails
                              .consumerConvFees
                              ? this.props.serviceRequest.serviceRequestDetails.consumerConvFees.toFixed(
                                  2
                                )
                              : 0}
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      )}

                      <TableRow>
                        <TableCell component="th" scope="row">
                          Delivery Charges
                        </TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell></TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left">
                          {this.props.serviceRequest.serviceRequestDetails
                            .deliveryCharges
                            ? this.props.serviceRequest.serviceRequestDetails.deliveryCharges.toFixed(
                                2
                              )
                            : 0}
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          Delivery Discount
                        </TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell></TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left"></TableCell>
                        <TableCell align="left">
                          {" "}
                          {this.props.serviceRequest.serviceRequestDetails
                              .discountOnDelivery &&
                            this.props.serviceRequest.serviceRequestDetails
                              .discountOnDelivery > 0
                            ? "- " +
                              this.props.serviceRequest.serviceRequestDetails.discountOnDelivery.toFixed(
                                2
                              )
                            : 0.0}
                        </TableCell>
                        <TableCell align="left"></TableCell>
                      </TableRow>
                      {this.props.serviceRequest.serviceRequestDetails
                        .totalPackagingCharges > 0 && (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Packaging Charges
                          </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">
                            {this.props.serviceRequest.serviceRequestDetails
                              .totalPackagingCharges
                              ? this.props.serviceRequest.serviceRequestDetails.totalPackagingCharges.toFixed(
                                  2
                                )
                              : 0}
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      )}

                      {/* <TableRow>
                                            <TableCell component="th" scope="row">Total Amount</TableCell>
                                            <TableCell align="left"></TableCell>
                                            <TableCell align="left"></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell align="left"></TableCell>
                                            <TableCell align="left">{this.props.serviceRequest.serviceRequestDetails.amount ? this.props.serviceRequest.serviceRequestDetails.amount.toFixed(2) : 0}</TableCell>
                                        </TableRow> */}

                      {
                        //old condition
                        // (this.props.serviceRequest.serviceRequestDetails && (this.props.serviceRequest.serviceRequestDetails.couponId !== null) && this.props.serviceRequest.serviceRequestDetails.couponId && this.props.serviceRequest.serviceRequestDetails.couponId.amount && (this.props.serviceRequest.serviceRequestDetails.couponId.status === 'Consumed' || this.props.serviceRequest.serviceRequestDetails.couponId.status === 'PayBackSeller') && this.props.serviceRequest.serviceRequestDetails.amount && (this.props.serviceRequest.serviceRequestDetails.amount !== null || this.props.serviceRequest.serviceRequestDetails.amount !== 'Not Set' || this.props.serviceRequest.serviceRequestDetails.amount !== undefined) && (this.props.serviceRequest.serviceRequestDetails.amount > this.props.serviceRequest.serviceRequestDetails.couponId.minOrderValue))
                        // (this.props.serviceRequest.serviceRequestDetails && (this.props.serviceRequest.serviceRequestDetails.couponId !== null) && this.props.serviceRequest.serviceRequestDetails.couponId && this.props.serviceRequest.serviceRequestDetails.couponId.amount && typeof (this.props.serviceRequest.serviceRequestDetails.SRCouponStatus) !== 'undefined' && this.props.serviceRequest.serviceRequestDetails.currentStatus && this.props.serviceRequest.serviceRequestDetails.currentStatus !== "CANCELLED" && this.props.serviceRequest.serviceRequestDetails.amount && (this.props.serviceRequest.serviceRequestDetails.amount !== null || this.props.serviceRequest.serviceRequestDetails.amount !== 'Not Set' || this.props.serviceRequest.serviceRequestDetails.amount !== undefined) && (this.props.serviceRequest.serviceRequestDetails.amount > this.props.serviceRequest.serviceRequestDetails.couponId.minOrderValue)) ?
                        this.props.serviceRequest.serviceRequestDetails &&
                        this.props.serviceRequest.serviceRequestDetails
                          .couponId !== null &&
                        this.props.serviceRequest.serviceRequestDetails
                          .couponId &&
                        this.props.serviceRequest.serviceRequestDetails.couponId
                          .amount &&
                        typeof this.props.serviceRequest.serviceRequestDetails
                          .SRCouponStatus !== "undefined" &&
                        this.props.serviceRequest.serviceRequestDetails
                          .currentStatus &&
                        this.props.serviceRequest.serviceRequestDetails
                          .amount &&
                        (this.props.serviceRequest.serviceRequestDetails
                          .amount !== null ||
                          this.props.serviceRequest.serviceRequestDetails
                            .amount !== "Not Set" ||
                          this.props.serviceRequest.serviceRequestDetails
                            .amount !== undefined) ? (
                          //  && (this.props.serviceRequest.serviceRequestDetails.amount >= this.props.serviceRequest.serviceRequestDetails.couponId.minOrderValue)
                          <TableRow>
                            <TableCell
                              component="th"
                              aria-label="a dense table"
                              scope="row"
                            >
                              <b>Total Amount</b>
                            </TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">
                              <b>
                                {this.calculateSubtraction(
                                  this.props.serviceRequest
                                    .serviceRequestDetails.amount,
                                  this.props.serviceRequest
                                    .serviceRequestDetails.couponId.amount
                                )}
                              </b>
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell
                              component="th"
                              aria-label="a dense table"
                              scope="row"
                            >
                              <b>Total Amount</b>
                            </TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">
                              <b>
                                {this.props.serviceRequest.serviceRequestDetails
                                  .amount
                                  ? this.props.serviceRequest.serviceRequestDetails.amount.toFixed(
                                      2
                                    )
                                  : 0}
                              </b>
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        )
                      }
                      {this.props.serviceRequest.serviceRequestDetails
                        .additionalChargesByAdmin &&
                      this.props.serviceRequest.serviceRequestDetails
                        .additionalChargesByAdmin.length > 0 ? (
                        <TableRow className={classes.tableRow}>
                          <TableCell component="th" scope="row">
                            <b>Xirify Adjustments</b>
                          </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                        </TableRow>
                      ) : null}
                      {this.props.serviceRequest.serviceRequestDetails
                        .additionalChargesByAdmin &&
                      this.props.serviceRequest.serviceRequestDetails
                        .additionalChargesByAdmin.length > 0
                        ? this.props.serviceRequest.serviceRequestDetails.additionalChargesByAdmin.map(
                            (adjustment) => (
                              <TableRow className={classes.tableRow}>
                                <TableCell component="th" scope="row">
                                  {adjustment.description}
                                  {<br></br>} (
                                  {moment(adjustment.addedOn).format(
                                    "DD-MMM-YYYY; hh:mm A"
                                  )}
                                  )
                                </TableCell>
                                <TableCell align="left">
                                  {adjustment.showToBusiness ? (
                                    <IconButton disabled="true">
                                      <Avatar>
                                        <BusinessSVG className={classes.icon} />
                                      </Avatar>
                                    </IconButton>
                                  ) : null}
                                  {adjustment.showToShopper ? (
                                    <IconButton disabled="true">
                                      <Avatar>
                                        <ShopperSVG className={classes.icon} />
                                      </Avatar>
                                    </IconButton>
                                  ) : null}
                                </TableCell>
                                <TableCell align="left"></TableCell>{" "}
                                <TableCell align="left"></TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left">
                                  {adjustment.amount}
                                </TableCell>
                                <TableCell align="left"></TableCell>
                              </TableRow>
                            )
                          )
                        : null}
                      {this.props.serviceRequest.serviceRequestDetails
                        .totalAmountForBusiness !== null &&
                      this.props.serviceRequest.serviceRequestDetails
                        .totalAmountForBusiness !== undefined &&
                      (this.props.serviceRequest.serviceRequestDetails
                        .totalAmountForBusiness >= 0 ||
                        this.props.serviceRequest.serviceRequestDetails
                          .totalAmountForBusiness <= 0) ? (
                        <TableRow className={classes.tableRow}>
                          <TableCell component="th" scope="row">
                            <b>Total Amount for Business</b>{" "}
                          </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">
                            <b>
                              {(
                                this.props.serviceRequest.serviceRequestDetails.totalAmountForBusiness.toFixed(
                                  2
                                ) -
                                (this.props.serviceRequest.serviceRequestDetails
                                  .couponAmount
                                  ? this.props.serviceRequest
                                      .serviceRequestDetails.couponAmount
                                  : 0)
                              ).toFixed(2)}
                            </b>{" "}
                          </TableCell>
                          <TableCell align="left"></TableCell>
                        </TableRow>
                      ) : null}
                      {this.props.serviceRequest.serviceRequestDetails
                        .totalAmountForShopper !== null &&
                      this.props.serviceRequest.serviceRequestDetails
                        .totalAmountForShopper !== undefined &&
                      (this.props.serviceRequest.serviceRequestDetails
                        .totalAmountForShopper >= 0 ||
                        this.props.serviceRequest.serviceRequestDetails
                          .totalAmountForShopper <= 0) ? (
                        <TableRow className={classes.tableRow}>
                          <TableCell component="th" scope="row">
                            <b>Total Amount for Shopper </b>{" "}
                          </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">
                            <b>
                              {(
                                this.props.serviceRequest.serviceRequestDetails.totalAmountForShopper.toFixed(
                                  2
                                ) -
                                (this.props.serviceRequest.serviceRequestDetails
                                  .couponAmount
                                  ? this.props.serviceRequest
                                      .serviceRequestDetails.couponAmount
                                  : 0)
                              ).toFixed(2)}
                            </b>
                          </TableCell>
                          <TableCell align="left"></TableCell>
                        </TableRow>
                      ) : null}
                    </Table>
                  </TableContainer>
                ) : null}

                {this.props.serviceRequest.serviceRequestDetails &&
                this.props.serviceRequest.serviceRequestDetails.items &&
                this.props.serviceRequest.serviceRequestDetails.items.length >
                  0 ? (
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Request Item</TableCell>
                          <TableCell align="left">Quantity</TableCell>
                          <TableCell align="left">Unit</TableCell>
                          <TableCell align="left">Rate per Unit</TableCell>
                          <TableCell align="left">Discount/Offer</TableCell>
                          <TableCell align="left">Qty Added</TableCell>

                          <TableCell align="left">Price</TableCell>
                          <TableCell align="left">IsAvailable</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.serviceRequest.serviceRequestDetails.items.map(
                          (item, index) => (
                            <TableRow key={item.index}>
                              <TableCell component="th" scope="row">
                                {item.name}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                NA
                              </TableCell>
                              <TableCell align="left">{item.unit}</TableCell>
                              {item.price ? (
                                <TableCell align="left">
                                  {(item.price / item.quantity).toFixed(2)}
                                </TableCell>
                              ) : (
                                <TableCell align="left">Not Set</TableCell>
                              )}
                              <TableCell align="left">--</TableCell>
                              <TableCell align="left">
                                {item.quantity}
                              </TableCell>
                              {item.price ? (
                                <TableCell align="left">
                                  {item.price.toFixed(2)}
                                </TableCell>
                              ) : (
                                <TableCell align="left">Not Set</TableCell>
                              )}
                              {item.isAvailable ? (
                                <TableCell align="left">true</TableCell>
                              ) : (
                                <TableCell align="left">false</TableCell>
                              )}
                            </TableRow>
                          )
                        )}
                        {this.props.serviceRequest.serviceRequestDetails
                          .additionalCharges &&
                        this.props.serviceRequest.serviceRequestDetails
                          .additionalCharges.length > 0
                          ? this.props.serviceRequest.serviceRequestDetails.additionalCharges.map(
                              (adjustment) => (
                                <TableRow>
                                  <TableCell component="th" scope="row">
                                    {adjustment.description}(adjustment)
                                  </TableCell>
                                  <TableCell align="left"></TableCell>
                                  <TableCell align="left"></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell align="left"></TableCell>
                                  <TableCell align="left"></TableCell>
                                  <TableCell align="left">
                                    {adjustment.amount.toFixed(2)}
                                  </TableCell>
                                  <TableCell align="left">--</TableCell>
                                </TableRow>
                              )
                            )
                          : null}
                        {this.props.serviceRequest.serviceRequestDetails
                          .posItemNotFoundError &&
                        this.props.serviceRequest.serviceRequestDetails
                          .posItemNotFoundError !== null &&
                        this.props.serviceRequest.serviceRequestDetails
                          .posItemNotFoundError !== undefined ? (
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{
                                float: "left",
                                paddingLeft: "10px",
                                width: "773%",
                                color: "red",
                              }}
                            >
                              {
                                this.props.serviceRequest.serviceRequestDetails
                                  .posItemNotFoundError
                              }
                            </TableCell>
                          </TableRow>
                        ) : null}
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <b>Sub Total</b>
                          </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          {/* <TableCell align="left">{this.props.serviceRequest.serviceRequestDetails.amount - this.props.serviceRequest.serviceRequestDetails.deliveryCharges.toFixed(2)}</TableCell> */}
                          <TableCell align="left">
                            {this.props.serviceRequest.serviceRequestDetails
                              .amount !== null ? (
                              <b>
                                (
                                {(
                                  this.props.serviceRequest
                                    .serviceRequestDetails.amount -
                                  (this.props.serviceRequest
                                    .serviceRequestDetails.deliveryCharges ||
                                    0) -
                                  (this.props.serviceRequest
                                    .serviceRequestDetails.consumerConvFees ||
                                    0) -
                                  (this.props.serviceRequest
                                    .serviceRequestDetails
                                    .totalPackagingCharges || 0) +
                                  (this.props.serviceRequest
                                    .serviceRequestDetails.discountOnDelivery &&
                                  this.props.serviceRequest
                                    .serviceRequestDetails.discountOnDelivery >
                                    0
                                    ? this.props.serviceRequest
                                        .serviceRequestDetails
                                        .discountOnDelivery
                                    : 0)
                                ).toFixed(2)}
                                )
                              </b>
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell align="left">--</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Cart/Business Discount
                          </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">--</TableCell>
                          <TableCell align="left">--</TableCell>
                        </TableRow>
                        {/* {
                                                (this.props.serviceRequest.serviceRequestDetails && (this.props.serviceRequest.serviceRequestDetails.couponId !== null) && this.props.serviceRequest.serviceRequestDetails.couponId && this.props.serviceRequest.serviceRequestDetails.couponId.amount && (this.props.serviceRequest.serviceRequestDetails.couponId.status === 'Consumed' || this.props.serviceRequest.serviceRequestDetails.couponId.status === 'PayBackSeller') && this.props.serviceRequest.serviceRequestDetails.amount && (this.props.serviceRequest.serviceRequestDetails.amount !== null || this.props.serviceRequest.serviceRequestDetails.amount !== 'Not Set' || this.props.serviceRequest.serviceRequestDetails.amount !== undefined) && (this.props.serviceRequest.serviceRequestDetails.amount > this.props.serviceRequest.serviceRequestDetails.couponId.minOrderValue)) ?
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">Coupon ({this.props.serviceRequest.serviceRequestDetails.couponId.couponName}) {this.props.serviceRequest.serviceRequestDetails.couponId.couponCode}</TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="left"> {"- " + this.props.serviceRequest.serviceRequestDetails.couponId.amount.toFixed(2)}</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                    : null
                                            } */}
                        {this.props.serviceRequest.serviceRequestDetails &&
                        this.props.serviceRequest.serviceRequestDetails
                          .couponId !== null &&
                        this.props.serviceRequest.serviceRequestDetails
                          .couponId &&
                        this.props.serviceRequest.serviceRequestDetails.couponId
                          .amount &&
                        this.props.serviceRequest.serviceRequestDetails
                          .amount &&
                        (this.props.serviceRequest.serviceRequestDetails
                          .amount !== null ||
                          this.props.serviceRequest.serviceRequestDetails
                            .amount !== "Not Set" ||
                          this.props.serviceRequest.serviceRequestDetails
                            .amount !== undefined) ? (
                          // && (this.props.serviceRequest.serviceRequestDetails.amount >= this.props.serviceRequest.serviceRequestDetails.couponId.minOrderValue)
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Coupon (
                              {
                                this.props.serviceRequest.serviceRequestDetails
                                  .couponId.couponName
                              }
                              ){" "}
                              {
                                this.props.serviceRequest.serviceRequestDetails
                                  .couponId.couponCode
                              }{" "}
                              {<br></br>}{" "}
                              {typeof this.props.serviceRequest
                                .serviceRequestDetails.SRCouponStatus ===
                              "undefined"
                                ? " "
                                : this.props.serviceRequest
                                    .serviceRequestDetails.currentStatus &&
                                  this.props.serviceRequest
                                    .serviceRequestDetails.currentStatus ===
                                    "CANCELLED"
                                ? "(Inactive)"
                                : "(Active)"}
                            </TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">
                              {" "}
                              {"- " +
                                this.props.serviceRequest.serviceRequestDetails.couponId.amount.toFixed(
                                  2
                                )}
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        ) : null}

                        {this.props.serviceRequest.serviceRequestDetails
                          .consumerConvFees > 0 && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Consumer convenience charges
                            </TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">
                              {this.props.serviceRequest.serviceRequestDetails
                                .consumerConvFees
                                ? this.props.serviceRequest.serviceRequestDetails.consumerConvFees.toFixed(
                                    2
                                  )
                                : 0}
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Delivery Charges
                          </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">
                            {this.props.serviceRequest.serviceRequestDetails
                              .deliveryCharges
                              ? this.props.serviceRequest.serviceRequestDetails.deliveryCharges.toFixed(
                                  2
                                )
                              : 0}
                          </TableCell>
                          <TableCell align="left">--</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Delivery Discount
                          </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">
                            {" "}
                            {this.props.serviceRequest.serviceRequestDetails
                              .discountOnDelivery &&
                            this.props.serviceRequest.serviceRequestDetails
                              .discountOnDelivery > 0
                              ? "- " +
                                this.props.serviceRequest.serviceRequestDetails.discountOnDelivery.toFixed(
                                  2
                                )
                              : 0.0}
                          </TableCell>
                          <TableCell align="left"></TableCell>
                        </TableRow>
                        {this.props.serviceRequest.serviceRequestDetails
                          .totalPackagingCharges > 0 && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Packaging Charges
                            </TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">
                              {this.props.serviceRequest.serviceRequestDetails
                                .totalPackagingCharges
                                ? this.props.serviceRequest.serviceRequestDetails.totalPackagingCharges.toFixed(
                                    2
                                  )
                                : 0}
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        )}

                        {/* <TableRow>
                                                <TableCell component="th" scope="row">Total Amount</TableCell>
                                                <TableCell align="left"></TableCell>
                                                <TableCell align="left"></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell align="left"></TableCell>
                                                <TableCell align="left">{this.props.serviceRequest.serviceRequestDetails.amount}</TableCell>
                                                <TableCell align="left">--</TableCell>
                                            </TableRow> */}
                        {
                          // (this.props.serviceRequest.serviceRequestDetails && (this.props.serviceRequest.serviceRequestDetails.couponId !== null) && this.props.serviceRequest.serviceRequestDetails.couponId && this.props.serviceRequest.serviceRequestDetails.couponId.amount && (this.props.serviceRequest.serviceRequestDetails.couponId.status === 'Consumed' || this.props.serviceRequest.serviceRequestDetails.couponId.status === 'PayBackSeller') && this.props.serviceRequest.serviceRequestDetails.amount && (this.props.serviceRequest.serviceRequestDetails.amount !== null || this.props.serviceRequest.serviceRequestDetails.amount !== 'Not Set' || this.props.serviceRequest.serviceRequestDetails.amount !== undefined) && (this.props.serviceRequest.serviceRequestDetails.amount > this.props.serviceRequest.serviceRequestDetails.couponId.minOrderValue))
                          // (this.props.serviceRequest.serviceRequestDetails && (this.props.serviceRequest.serviceRequestDetails.couponId !== null) && this.props.serviceRequest.serviceRequestDetails.couponId && this.props.serviceRequest.serviceRequestDetails.couponId.amount && typeof (this.props.serviceRequest.serviceRequestDetails.SRCouponStatus) !== 'undefined' && this.props.serviceRequest.serviceRequestDetails.currentStatus && this.props.serviceRequest.serviceRequestDetails.currentStatus !== "CANCELLED" && this.props.serviceRequest.serviceRequestDetails.amount && (this.props.serviceRequest.serviceRequestDetails.amount !== null || this.props.serviceRequest.serviceRequestDetails.amount !== 'Not Set' || this.props.serviceRequest.serviceRequestDetails.amount !== undefined) && (this.props.serviceRequest.serviceRequestDetails.amount > this.props.serviceRequest.serviceRequestDetails.couponId.minOrderValue)) ?
                          this.props.serviceRequest.serviceRequestDetails &&
                          this.props.serviceRequest.serviceRequestDetails
                            .couponId !== null &&
                          this.props.serviceRequest.serviceRequestDetails
                            .couponId &&
                          this.props.serviceRequest.serviceRequestDetails
                            .couponId.amount &&
                          typeof this.props.serviceRequest.serviceRequestDetails
                            .SRCouponStatus !== "undefined" &&
                          this.props.serviceRequest.serviceRequestDetails
                            .currentStatus &&
                          this.props.serviceRequest.serviceRequestDetails
                            .amount &&
                          (this.props.serviceRequest.serviceRequestDetails
                            .amount !== null ||
                            this.props.serviceRequest.serviceRequestDetails
                              .amount !== "Not Set" ||
                            this.props.serviceRequest.serviceRequestDetails
                              .amount !== undefined) ? (
                            //  && (this.props.serviceRequest.serviceRequestDetails.amount >= this.props.serviceRequest.serviceRequestDetails.couponId.minOrderValue)
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <b>Total Amount </b>
                              </TableCell>
                              <TableCell align="left"></TableCell>
                              <TableCell align="left"></TableCell>
                              <TableCell></TableCell>
                              <TableCell align="left"></TableCell>
                              <TableCell align="left"></TableCell>
                              <TableCell align="left">
                                <b>
                                  {this.calculateSubtraction(
                                    this.props.serviceRequest
                                      .serviceRequestDetails.amount,
                                    this.props.serviceRequest
                                      .serviceRequestDetails.couponId.amount
                                  )}
                                </b>
                              </TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          ) : (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <b>Total Amount</b>
                              </TableCell>
                              <TableCell align="left"></TableCell>
                              <TableCell align="left"></TableCell>
                              <TableCell></TableCell>
                              <TableCell align="left"></TableCell>
                              <TableCell align="left"></TableCell>
                              <TableCell align="left">
                                <b>
                                  {this.props.serviceRequest
                                    .serviceRequestDetails.amount !== null
                                    ? this.props.serviceRequest.serviceRequestDetails.amount.toFixed(
                                        2
                                      )
                                    : 0}
                                </b>
                              </TableCell>
                              <TableCell align="left">--</TableCell>
                            </TableRow>
                          )
                        }
                        {this.props.serviceRequest.serviceRequestDetails
                          .additionalChargesByAdmin &&
                        this.props.serviceRequest.serviceRequestDetails
                          .additionalChargesByAdmin.length > 0 ? (
                          <TableRow className={classes.tableRow}>
                            <TableCell component="th" scope="row">
                              <b>Xirify Adjustments</b>
                            </TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                          </TableRow>
                        ) : null}
                        {this.props.serviceRequest.serviceRequestDetails
                          .additionalChargesByAdmin &&
                        this.props.serviceRequest.serviceRequestDetails
                          .additionalChargesByAdmin.length > 0
                          ? this.props.serviceRequest.serviceRequestDetails.additionalChargesByAdmin.map(
                              (adjustment) => (
                                <TableRow className={classes.tableRow}>
                                  <TableCell component="th" scope="row">
                                    {adjustment.description}
                                    {<br></br>} (
                                    {moment(adjustment.addedOn).format(
                                      "DD-MMM-YYYY; hh:mm A"
                                    )}
                                    )
                                  </TableCell>
                                  <TableCell align="left">
                                    {adjustment.showToBusiness ? (
                                      <IconButton disabled="true">
                                        <Avatar>
                                          <BusinessSVG
                                            className={classes.icon}
                                          />
                                        </Avatar>
                                      </IconButton>
                                    ) : null}
                                    {adjustment.showToShopper ? (
                                      <IconButton disabled="true">
                                        <Avatar>
                                          <ShopperSVG
                                            className={classes.icon}
                                          />
                                        </Avatar>
                                      </IconButton>
                                    ) : null}
                                  </TableCell>
                                  <TableCell align="left"></TableCell>{" "}
                                  <TableCell align="left"></TableCell>
                                  <TableCell align="left"></TableCell>
                                  <TableCell align="left"></TableCell>
                                  <TableCell align="left">
                                    {adjustment.amount}
                                  </TableCell>
                                  <TableCell align="left"></TableCell>
                                </TableRow>
                              )
                            )
                          : null}
                        {this.props.serviceRequest.serviceRequestDetails
                          .totalAmountForBusiness !== null &&
                        this.props.serviceRequest.serviceRequestDetails
                          .totalAmountForBusiness !== undefined &&
                        (this.props.serviceRequest.serviceRequestDetails
                          .totalAmountForBusiness >= 0 ||
                          this.props.serviceRequest.serviceRequestDetails
                            .totalAmountForBusiness <= 0) ? (
                          <TableRow className={classes.tableRow}>
                            <TableCell component="th" scope="row">
                              <b>Total Amount for Business</b>{" "}
                            </TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">
                              <b>
                                {(
                                  this.props.serviceRequest.serviceRequestDetails.totalAmountForBusiness.toFixed(
                                    2
                                  ) -
                                  (this.props.serviceRequest
                                    .serviceRequestDetails.couponAmount
                                    ? this.props.serviceRequest
                                        .serviceRequestDetails.couponAmount
                                    : 0)
                                ).toFixed(2)}
                              </b>{" "}
                            </TableCell>
                            <TableCell align="left"></TableCell>
                          </TableRow>
                        ) : null}
                        {this.props.serviceRequest.serviceRequestDetails
                          .totalAmountForShopper !== null &&
                        this.props.serviceRequest.serviceRequestDetails
                          .totalAmountForShopper !== undefined &&
                        (this.props.serviceRequest.serviceRequestDetails
                          .totalAmountForShopper >= 0 ||
                          this.props.serviceRequest.serviceRequestDetails
                            .totalAmountForShopper <= 0) ? (
                          <TableRow className={classes.tableRow}>
                            <TableCell component="th" scope="row">
                              <b>Total Amount for Shopper </b>{" "}
                            </TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">
                              <b>
                                {(
                                  this.props.serviceRequest.serviceRequestDetails.totalAmountForShopper.toFixed(
                                    2
                                  ) -
                                  (this.props.serviceRequest
                                    .serviceRequestDetails.couponAmount
                                    ? this.props.serviceRequest
                                        .serviceRequestDetails.couponAmount
                                    : 0)
                                ).toFixed(2)}
                              </b>
                            </TableCell>
                            <TableCell align="left"></TableCell>
                          </TableRow>
                        ) : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : null}

                {this.props.serviceRequest.serviceRequestDetails &&
                this.props.serviceRequest.serviceRequestDetails.services &&
                this.props.serviceRequest.serviceRequestDetails.items &&
                this.props.serviceRequest.serviceRequestDetails.services
                  .length === 0 &&
                this.props.serviceRequest.serviceRequestDetails.items.length ===
                  0 ? (
                  <TableContainer component={Paper}>
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Request Item</TableCell>
                          <TableCell align="left">Quantity</TableCell>
                          <TableCell align="left">Unit</TableCell>
                          <TableCell align="left">Rate per Unit</TableCell>
                          <TableCell align="left">Discount/Offer</TableCell>
                          <TableCell align="left">Qty Added</TableCell>

                          <TableCell align="left">Price</TableCell>
                          <TableCell align="left">IsAvailable</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.serviceRequest.serviceRequestDetails
                          .additionalCharges &&
                        this.props.serviceRequest.serviceRequestDetails
                          .additionalCharges.length > 0
                          ? this.props.serviceRequest.serviceRequestDetails.additionalCharges.map(
                              (adjustment) => (
                                <TableRow>
                                  <TableCell component="th" scope="row">
                                    {adjustment.description}(adjustment)
                                  </TableCell>
                                  <TableCell align="left"></TableCell>
                                  <TableCell align="left"></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell align="left"></TableCell>
                                  <TableCell align="left"></TableCell>
                                  <TableCell align="left">
                                    {adjustment.amount.toFixed(2)}
                                  </TableCell>
                                  <TableCell align="left">--</TableCell>
                                </TableRow>
                              )
                            )
                          : null}
                        {this.props.serviceRequest.serviceRequestDetails
                          .posItemNotFoundError &&
                        this.props.serviceRequest.serviceRequestDetails
                          .posItemNotFoundError !== null &&
                        this.props.serviceRequest.serviceRequestDetails
                          .posItemNotFoundError !== undefined ? (
                          <TableRow>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{
                                float: "left",
                                paddingLeft: "10px",
                                width: "773%",
                                color: "red",
                              }}
                            >
                              {
                                this.props.serviceRequest.serviceRequestDetails
                                  .posItemNotFoundError
                              }
                            </TableCell>
                          </TableRow>
                        ) : null}
                        <TableRow>
                          <TableCell component="th" scope="row">
                            <b>Sub Total</b>
                          </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell></TableCell>
                          <TableCell align="left"></TableCell>
                          {/* <TableCell align="left">{(this.props.serviceRequest.serviceRequestDetails.amount.toFixed(2) - this.props.serviceRequest.serviceRequestDetails.deliveryCharges).toFixed(2)}</TableCell> */}
                          <TableCell align="left">
                            {quickServiceSubtotal ? quickServiceSubtotal : ""}
                          </TableCell>
                          <TableCell align="left">--</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Cart/Business Discount
                          </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">--</TableCell>
                          <TableCell align="left">--</TableCell>
                        </TableRow>
                        {/* {
                                                (this.props.serviceRequest.serviceRequestDetails && (this.props.serviceRequest.serviceRequestDetails.couponId !== null) && this.props.serviceRequest.serviceRequestDetails.couponId && this.props.serviceRequest.serviceRequestDetails.couponId.amount && (this.props.serviceRequest.serviceRequestDetails.couponId.status === 'Consumed' || this.props.serviceRequest.serviceRequestDetails.couponId.status === 'PayBackSeller') && this.props.serviceRequest.serviceRequestDetails.amount && (this.props.serviceRequest.serviceRequestDetails.amount !== null || this.props.serviceRequest.serviceRequestDetails.amount !== 'Not Set' || this.props.serviceRequest.serviceRequestDetails.amount !== undefined) && (this.props.serviceRequest.serviceRequestDetails.amount > this.props.serviceRequest.serviceRequestDetails.couponId.minOrderValue)) ?
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">Coupon ({this.props.serviceRequest.serviceRequestDetails.couponId.couponName}) {this.props.serviceRequest.serviceRequestDetails.couponId.couponCode}</TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell align="left"></TableCell>
                                                        <TableCell align="left"> {"- " + this.props.serviceRequest.serviceRequestDetails.couponId.amount.toFixed(2)}</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                    : null
                                            } */}
                        {this.props.serviceRequest.serviceRequestDetails &&
                        this.props.serviceRequest.serviceRequestDetails
                          .couponId !== null &&
                        this.props.serviceRequest.serviceRequestDetails
                          .couponId &&
                        this.props.serviceRequest.serviceRequestDetails.couponId
                          .amount &&
                        this.props.serviceRequest.serviceRequestDetails
                          .amount &&
                        (this.props.serviceRequest.serviceRequestDetails
                          .amount !== null ||
                          this.props.serviceRequest.serviceRequestDetails
                            .amount !== "Not Set" ||
                          this.props.serviceRequest.serviceRequestDetails
                            .amount !== undefined) ? (
                          // && (this.props.serviceRequest.serviceRequestDetails.amount >= this.props.serviceRequest.serviceRequestDetails.couponId.minOrderValue)
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Coupon (
                              {
                                this.props.serviceRequest.serviceRequestDetails
                                  .couponId.couponName
                              }
                              ){" "}
                              {
                                this.props.serviceRequest.serviceRequestDetails
                                  .couponId.couponCode
                              }{" "}
                              {<br></br>}{" "}
                              {typeof this.props.serviceRequest
                                .serviceRequestDetails.SRCouponStatus ===
                              "undefined"
                                ? " "
                                : this.props.serviceRequest
                                    .serviceRequestDetails.currentStatus &&
                                  this.props.serviceRequest
                                    .serviceRequestDetails.currentStatus ===
                                    "CANCELLED"
                                ? "(Inactive)"
                                : "(Active)"}
                            </TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">
                              {" "}
                              {"- " +
                                this.props.serviceRequest.serviceRequestDetails.couponId.amount.toFixed(
                                  2
                                )}
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        ) : null}

                        {this.props.serviceRequest.serviceRequestDetails
                          .consumerConvFees > 0 && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Consumer convenience charges
                            </TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">
                              {this.props.serviceRequest.serviceRequestDetails
                                .consumerConvFees
                                ? this.props.serviceRequest.serviceRequestDetails.consumerConvFees.toFixed(
                                    2
                                  )
                                : 0}
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Delivery Charges
                          </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">
                            {this.props.serviceRequest.serviceRequestDetails
                              .deliveryCharges
                              ? this.props.serviceRequest.serviceRequestDetails.deliveryCharges.toFixed(
                                  2
                                )
                              : 0}
                          </TableCell>
                          <TableCell align="left">--</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            Delivery Discount
                          </TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell></TableCell>
                          <TableCell align="left"></TableCell>
                          <TableCell align="left">
                            {" "}
                            {this.props.serviceRequest.serviceRequestDetails
                              .discountOnDelivery &&
                            this.props.serviceRequest.serviceRequestDetails
                              .discountOnDelivery > 0
                              ? "- " +
                                this.props.serviceRequest.serviceRequestDetails.discountOnDelivery.toFixed(
                                  2
                                )
                              : 0.0}
                          </TableCell>
                          <TableCell align="left"></TableCell>
                        </TableRow>

                        {this.props.serviceRequest.serviceRequestDetails
                          .totalPackagingCharges > 0 && (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              Packaging Charges
                            </TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">
                              {this.props.serviceRequest.serviceRequestDetails
                                .totalPackagingCharges
                                ? this.props.serviceRequest.serviceRequestDetails.totalPackagingCharges.toFixed(
                                    2
                                  )
                                : 0}
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        )}

                        
                        {
                          // (this.props.serviceRequest.serviceRequestDetails && (this.props.serviceRequest.serviceRequestDetails.couponId !== null) && this.props.serviceRequest.serviceRequestDetails.couponId && this.props.serviceRequest.serviceRequestDetails.couponId.amount && (this.props.serviceRequest.serviceRequestDetails.couponId.status === 'Consumed' || this.props.serviceRequest.serviceRequestDetails.couponId.status === 'PayBackSeller') && this.props.serviceRequest.serviceRequestDetails.amount && (this.props.serviceRequest.serviceRequestDetails.amount !== null || this.props.serviceRequest.serviceRequestDetails.amount !== 'Not Set' || this.props.serviceRequest.serviceRequestDetails.amount !== undefined) && (this.props.serviceRequest.serviceRequestDetails.amount > this.props.serviceRequest.serviceRequestDetails.couponId.minOrderValue))
                          // (this.props.serviceRequest.serviceRequestDetails && (this.props.serviceRequest.serviceRequestDetails.couponId !== null) && this.props.serviceRequest.serviceRequestDetails.couponId && this.props.serviceRequest.serviceRequestDetails.couponId.amount && typeof (this.props.serviceRequest.serviceRequestDetails.SRCouponStatus) !== 'undefined' && this.props.serviceRequest.serviceRequestDetails.currentStatus && this.props.serviceRequest.serviceRequestDetails.currentStatus !== "CANCELLED" && this.props.serviceRequest.serviceRequestDetails.amount && (this.props.serviceRequest.serviceRequestDetails.amount !== null || this.props.serviceRequest.serviceRequestDetails.amount !== 'Not Set' || this.props.serviceRequest.serviceRequestDetails.amount !== undefined) && (this.props.serviceRequest.serviceRequestDetails.amount > this.props.serviceRequest.serviceRequestDetails.couponId.minOrderValue)) ?
                          this.props.serviceRequest.serviceRequestDetails &&
                          this.props.serviceRequest.serviceRequestDetails
                            .couponId !== null &&
                          this.props.serviceRequest.serviceRequestDetails
                            .couponId &&
                          this.props.serviceRequest.serviceRequestDetails
                            .couponId.amount &&
                          typeof this.props.serviceRequest.serviceRequestDetails
                            .SRCouponStatus !== "undefined" &&
                          this.props.serviceRequest.serviceRequestDetails
                            .currentStatus &&
                          this.props.serviceRequest.serviceRequestDetails
                            .amount &&
                          (this.props.serviceRequest.serviceRequestDetails
                            .amount !== null ||
                            this.props.serviceRequest.serviceRequestDetails
                              .amount !== "Not Set" ||
                            this.props.serviceRequest.serviceRequestDetails
                              .amount !== undefined) ? (
                            // && (this.props.serviceRequest.serviceRequestDetails.amount >= this.props.serviceRequest.serviceRequestDetails.couponId.minOrderValue)
                            <TableRow>
                              <TableCell
                                aria-label="a dense table"
                                component="th"
                                scope="row"
                              >
                                <b>Total Amount</b>
                              </TableCell>
                              <TableCell align="left"></TableCell>
                              <TableCell align="left"></TableCell>
                              <TableCell align="left"></TableCell>
                              <TableCell></TableCell>
                              <TableCell align="left"></TableCell>
                              <TableCell align="left">
                                <b>
                                  {this.calculateSubtraction(
                                    this.props.serviceRequest
                                      .serviceRequestDetails.amount,
                                    this.props.serviceRequest
                                      .serviceRequestDetails.couponId.amount
                                  )}
                                </b>
                              </TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          ) : (
                            <TableRow>
                              <TableCell
                                component="th"
                                aria-label="a dense table"
                                scope="row"
                              >
                                <b>Total Amount</b>
                              </TableCell>
                              <TableCell align="left"></TableCell>
                              <TableCell align="left"></TableCell>
                              <TableCell align="left"></TableCell>
                              <TableCell></TableCell>
                              <TableCell align="left"></TableCell>
                              <TableCell align="left">
                                {this.props.serviceRequest.serviceRequestDetails
                                  .amount
                                  ? this.props.serviceRequest.serviceRequestDetails.amount.toFixed(
                                      2
                                    )
                                  : 0}
                              </TableCell>
                              <TableCell align="left">--</TableCell>
                            </TableRow>
                          )
                        }
                        {this.props.serviceRequest.serviceRequestDetails
                          .additionalChargesByAdmin &&
                        this.props.serviceRequest.serviceRequestDetails
                          .additionalChargesByAdmin.length > 0 ? (
                          <TableRow className={classes.tableRow}>
                            <TableCell component="th" scope="row">
                              <b>Xirify Adjustments</b>
                            </TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                          </TableRow>
                        ) : null}
                        {this.props.serviceRequest.serviceRequestDetails
                          .additionalChargesByAdmin &&
                        this.props.serviceRequest.serviceRequestDetails
                          .additionalChargesByAdmin.length > 0
                          ? this.props.serviceRequest.serviceRequestDetails.additionalChargesByAdmin.map(
                              (adjustment) => (
                                <TableRow className={classes.tableRow}>
                                  <TableCell component="th" scope="row">
                                    {adjustment.description}
                                    {<br></br>} (
                                    {moment(adjustment.addedOn).format(
                                      "DD-MMM-YYYY; hh:mm A"
                                    )}
                                    )
                                  </TableCell>
                                  <TableCell align="left">
                                    {adjustment.showToBusiness ? (
                                      <IconButton disabled="true">
                                        <Avatar>
                                          <BusinessSVG
                                            className={classes.icon}
                                          />
                                        </Avatar>
                                      </IconButton>
                                    ) : null}
                                    {adjustment.showToShopper ? (
                                      <IconButton disabled="true">
                                        <Avatar>
                                          <ShopperSVG
                                            className={classes.icon}
                                          />
                                        </Avatar>
                                      </IconButton>
                                    ) : null}
                                  </TableCell>
                                  <TableCell align="left"></TableCell>
                                  <TableCell align="left"></TableCell>{" "}
                                  <TableCell align="left"></TableCell>
                                  <TableCell align="left"></TableCell>
                                  <TableCell align="left">
                                    {adjustment.amount}
                                  </TableCell>
                                  <TableCell align="left"></TableCell>
                                </TableRow>
                              )
                            )
                          : null}
                        {this.props.serviceRequest.serviceRequestDetails
                          .totalAmountForBusiness !== null &&
                        this.props.serviceRequest.serviceRequestDetails
                          .totalAmountForBusiness !== undefined &&
                        (this.props.serviceRequest.serviceRequestDetails
                          .totalAmountForBusiness >= 0 ||
                          this.props.serviceRequest.serviceRequestDetails
                            .totalAmountForBusiness <= 0) ? (
                          <TableRow className={classes.tableRow}>
                            <TableCell component="th" scope="row">
                              <b>Total Amount for Business</b>{" "}
                            </TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">
                              <b>
                                {(
                                  this.props.serviceRequest.serviceRequestDetails.totalAmountForBusiness.toFixed(
                                    2
                                  ) -
                                  (this.props.serviceRequest
                                    .serviceRequestDetails.couponAmount
                                    ? this.props.serviceRequest
                                        .serviceRequestDetails.couponAmount
                                    : 0)
                                ).toFixed(2)}
                              </b>{" "}
                            </TableCell>
                            <TableCell align="left"></TableCell>
                          </TableRow>
                        ) : null}
                        {this.props.serviceRequest.serviceRequestDetails
                          .totalAmountForShopper !== null &&
                        this.props.serviceRequest.serviceRequestDetails
                          .totalAmountForShopper !== undefined &&
                        (this.props.serviceRequest.serviceRequestDetails
                          .totalAmountForShopper >= 0 ||
                          this.props.serviceRequest.serviceRequestDetails
                            .totalAmountForShopper <= 0) ? (
                          <TableRow className={classes.tableRow}>
                            <TableCell component="th" scope="row">
                              <b>Total Amount for Shopper </b>{" "}
                            </TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">
                              <b>
                                {(
                                  this.props.serviceRequest.serviceRequestDetails.totalAmountForShopper.toFixed(
                                    2
                                  ) -
                                  (this.props.serviceRequest
                                    .serviceRequestDetails.couponAmount
                                    ? this.props.serviceRequest
                                        .serviceRequestDetails.couponAmount
                                    : 0)
                                ).toFixed(2)}
                              </b>
                            </TableCell>
                            <TableCell align="left"></TableCell>
                          </TableRow>
                        ) : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : null}
                {this.props.serviceRequest.serviceRequestDetails.instruction ? (
                  <Grid item xs={12}>
                    <Paper style={{ margin: "10px -5px", padding: "10px" }}>
                      <Typography
                        variant="subtitle2"
                        display="block"
                        gutterBottom
                      >
                        Order Instruction
                      </Typography>
                      {this.props.serviceRequest.serviceRequestDetails.instruction
                        .split("\n")
                        .map((ele) => {
                          return (
                            <Typography
                              variant="subtitle4"
                              display="block"
                              gutterBottom
                            >
                              {ele}
                            </Typography>
                          );
                        })}
                    </Paper>
                  </Grid>
                ) : null}
                {this.props.user.role === "admin" &&
                this.props.serviceRequest.serviceRequestDetails
                  .isRefundApplied ? (
                  <Grid
                    item
                    xs={12}
                    className={classes.container}
                    style={{
                      height: "70px",
                      backgroundColor: "#FFFFFF",
                      paddingTop: "8px",
                      marginTop: "12px",
                      marginBottom: "12px",
                      border: "1.5px solid #CCCCCC",
                      borderRadius: "4px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        position: "",
                      }}
                    >
                      <Avatar
                        style={{
                          
                          height: "50px",
                          paddingBottom: "5px",
                          backgroundColor: "white",
                          paddingLeft: "10px",
                        }}
                      >
                        <RefundSVG height="25px" />
                      </Avatar>
                      <Typography
                        style={{
                          paddingLeft: "20px",
                          paddingTop: "15px",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                      >
                        This Order is cancelled/items changed by business &
                        eligible for the refund
                      </Typography>
                      <Button
                        fullWidth
                        variant="contained"
                        margin="normal"
                        color="primary"
                        onClick={this.openRefundDetailsModal}
                        style={{
                          width: "150px",
                          marginLeft: "250px",
                          height: "30px",
                          marginTop: "10px",
                        }}
                        // disabled={["ACCEPTED", "QUOTED", "QUOTE_DECLINED", "IN_PROGRESS", "OUT_FOR_DELIVERY", "DELIVERY_DONE", "READY_FOR_PICKUP", "PICKUP_DONE", "COMPLETED"].includes(this.props.serviceRequest.serviceRequestDetails.currentStatus)}
                      >
                        REFUND DETAILS
                      </Button>
                    </div>
                  </Grid>
                ) : null}

                {/* Business Details */}
                <Grid item xs={4}>
                  <Grid item>
                    <Typography variant="caption" display="block" gutterBottom>
                      Business Details
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      ID :{" "}
                      {this.props.serviceRequest.serviceRequestDetails &&
                      this.props.serviceRequest.serviceRequestDetails.business
                        ? this.props.serviceRequest.serviceRequestDetails
                            .business.businessId
                        : null}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      Name :{" "}
                      {this.props.serviceRequest.serviceRequestDetails &&
                      this.props.serviceRequest.serviceRequestDetails.business
                        ? this.props.serviceRequest.serviceRequestDetails
                            .business.businessName
                        : null}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2" gutterBottom>
                      Region :{" "}
                      {this.props.serviceRequest.serviceRequestDetails
                        .business &&
                        this.props.serviceRequest.serviceRequestDetails.business
                          .regionBelongsTo &&
                        this.props.serviceRequest.serviceRequestDetails.business
                          .regionBelongsTo.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2" gutterBottom>
                      Area :{" "}
                      {this.props.serviceRequest.serviceRequestDetails
                        .business &&
                        this.props.serviceRequest.serviceRequestDetails.business
                          .areaBelongsTo &&
                        this.props.serviceRequest.serviceRequestDetails.business
                          .areaBelongsTo.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {this.props.serviceRequest.serviceRequestDetails &&
                    this.props.serviceRequest.serviceRequestDetails.business ? (
                      <Typography variant="subtitle2" gutterBottom>
                        Address :
                        {this.props.serviceRequest.serviceRequestDetails
                          .business.address.shopNo
                          ? this.props.serviceRequest.serviceRequestDetails
                              .business.address.shopNo + ", "
                          : ""}
                        {this.props.serviceRequest.serviceRequestDetails
                          .business.address.street
                          ? this.props.serviceRequest.serviceRequestDetails
                              .business.address.street + ", "
                          : ""}
                        {this.props.serviceRequest.serviceRequestDetails
                          .business.address.landmark
                          ? this.props.serviceRequest.serviceRequestDetails
                              .business.address.landmark + ", "
                          : ""}
                        {this.props.serviceRequest.serviceRequestDetails
                          .business.address.area
                          ? this.props.serviceRequest.serviceRequestDetails
                              .business.address.area + ", "
                          : ""}
                        {this.props.serviceRequest.serviceRequestDetails
                          .business.address.city
                          ? this.props.serviceRequest.serviceRequestDetails
                              .business.address.city + ", "
                          : ""}
                        {this.props.serviceRequest.serviceRequestDetails
                          .business.address.state
                          ? this.props.serviceRequest.serviceRequestDetails
                              .business.address.state + ", "
                          : ""}
                        {this.props.serviceRequest.serviceRequestDetails
                          .business.address.pinCode
                          ? this.props.serviceRequest.serviceRequestDetails
                              .business.address.pinCode
                          : ""}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      Location Lat-Long :
                      {this.props &&
                      this.props.serviceRequest &&
                      this.props.serviceRequest.serviceRequestDetails &&
                      this.props.serviceRequest.serviceRequestDetails
                        .business &&
                      this.props.serviceRequest.serviceRequestDetails.business
                        .address &&
                      this.props.serviceRequest.serviceRequestDetails.business
                        .address.location &&
                      this.props.serviceRequest.serviceRequestDetails.business
                        .address.location.coordinates &&
                      this.props.serviceRequest.serviceRequestDetails.business
                        .address.location.coordinates.length > 0
                        ? this.props.serviceRequest.serviceRequestDetails
                            .business.address.location.coordinates[1]
                        : ""}
                      ,
                      {this.props &&
                      this.props.serviceRequest &&
                      this.props.serviceRequest.serviceRequestDetails &&
                      this.props.serviceRequest.serviceRequestDetails
                        .business &&
                      this.props.serviceRequest.serviceRequestDetails.business
                        .address &&
                      this.props.serviceRequest.serviceRequestDetails.business
                        .address.location &&
                      this.props.serviceRequest.serviceRequestDetails.business
                        .address.location.coordinates &&
                      this.props.serviceRequest.serviceRequestDetails.business
                        .address.location.coordinates.length > 0
                        ? this.props.serviceRequest.serviceRequestDetails
                            .business.address.location.coordinates[0]
                        : ""}
                    </Typography>
                  </Grid>
                  {this?.props?.serviceRequest?.serviceRequestDetails?.business
                    ?.businessContactName &&
                  this?.props?.serviceRequest?.serviceRequestDetails?.business
                    ?.businessContactNumber ? null : (
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        display="block"
                        gutterBottom
                      >
                        Mobile Number :{" "}
                        {this.props.serviceRequest.serviceRequestDetails &&
                        this.props.serviceRequest.serviceRequestDetails.business
                          ? this.props.serviceRequest.serviceRequestDetails
                              .business.primaryContactNumber
                          : null}
                      </Typography>
                    </Grid>
                  )}

                  {this?.props?.serviceRequest?.serviceRequestDetails?.business
                    ?.businessContactName ? (
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        display="block"
                        gutterBottom
                      >
                        Business Contact Name :{" "}
                        {this?.props?.serviceRequest?.serviceRequestDetails
                          ?.business?.businessContactName || null}
                      </Typography>
                    </Grid>
                  ) : null}
                  {this?.props?.serviceRequest?.serviceRequestDetails?.business
                    ?.businessContactNumber ? (
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        display="block"
                        gutterBottom
                      >
                        Business Contact Number :{" "}
                        {this?.props?.serviceRequest?.serviceRequestDetails
                          ?.business?.businessContactNumber || null}
                      </Typography>
                    </Grid>
                  ) : null}
                  {this.props &&
                  this.props.serviceRequest &&
                  this.props.serviceRequest.serviceRequestDetails &&
                  this.props.serviceRequest.serviceRequestDetails
                    .isPetpoojaEnabled !== null &&
                  this.props.serviceRequest.serviceRequestDetails
                    .isPetpoojaEnabled !== undefined ? (
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        display="block"
                        gutterBottom
                      >
                        Is Petpooja Enabled ? :{" "}
                        {this.props.serviceRequest.serviceRequestDetails
                          .isPetpoojaEnabled === true
                          ? "Yes"
                          : "NO"}{" "}
                      </Typography>
                    </Grid>
                  ) : null}
                  {/* {this.props && this.props.serviceRequest && this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.accountManagerDetails ?
                                   
                                   <Grid item>
                                       <Typography variant="caption" display="block" gutterBottom >
                                           Account Manager Details                                  
                                           
                                       </Typography>
                                      
                                   </Grid> : null} 
                                    {this.props && this.props.serviceRequest && this.props.serviceRequest.serviceRequestDetails && this.props.serviceRequest.serviceRequestDetails.accountManagerDetails ?
                                   
                                   <Grid item>
                                       <Typography variant="subtitle2" display="block" gutterBottom >
                                     Name : {
                                           (accountManagerOfBusiness.firstName).charAt(0).toUpperCase() + (accountManagerOfBusiness.firstName).slice(1) } { 
                                               (accountManagerOfBusiness.lastName).charAt(0).toUpperCase() + (accountManagerOfBusiness.lastName).slice(1) + ' '} 
                                           
                                       </Typography>
                                      
                                   </Grid> : null} */}
                  {this.props &&
                  this.props.serviceRequest &&
                  this.props.serviceRequest.serviceRequestDetails &&
                  this.props.serviceRequest.serviceRequestDetails
                    .accountManagerDetails ? (
                    <Grid item>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Account Manager Details
                      </Typography>
                    </Grid>
                  ) : null}
                  {this.props &&
                  this.props.serviceRequest &&
                  this.props.serviceRequest.serviceRequestDetails &&
                  this.props.serviceRequest.serviceRequestDetails
                    .accountManagerDetails ? (
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        display="block"
                        gutterBottom
                      >
                        Name :{" "}
                        {accountManagerOfBusiness.firstName
                          .charAt(0)
                          .toUpperCase() +
                          accountManagerOfBusiness.firstName.slice(1)}{" "}
                        {accountManagerOfBusiness.lastName
                          .charAt(0)
                          .toUpperCase() +
                          accountManagerOfBusiness.lastName.slice(1) +
                          " "}
                      </Typography>
                    </Grid>
                  ) : null}
                  {this.props &&
                  this.props.serviceRequest &&
                  this.props.serviceRequest.serviceRequestDetails &&
                  this.props.serviceRequest.serviceRequestDetails
                    .accountManagerDetails ? (
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        display="block"
                        gutterBottom
                      >
                        Mobile Number : {accountManagerOfBusiness.mobileNumber}
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>

                {/* Consumer Details */}
                <Grid item xs={4}>
                  <Grid item>
                    <Typography variant="caption" display="block" gutterBottom>
                      Consumer Details
                    </Typography>
                  </Grid>
                  <Grid item>
                    {this.props.serviceRequest.serviceRequestDetails &&
                    this.props.serviceRequest.serviceRequestDetails
                      .consumerDetails ? (
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        ID :{" "}
                        {this.props.serviceRequest.serviceRequestDetails
                          .consumerDetails?.consumer?.consumerId || null}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item>
                    {this.props.serviceRequest.serviceRequestDetails &&
                    this.props.serviceRequest.serviceRequestDetails
                      .consumerDetails ? (
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        Name :{" "}
                        <Avatar
                          alt="Name"
                          src={
                            this.props.serviceRequest.serviceRequestDetails
                              .consumerDetails?.consumer?.photo || null
                          }
                          className={classes.small}
                        />{" "}
                        &nbsp;
                        {this.props.serviceRequest.serviceRequestDetails
                          .consumerDetails?.consumer?.firstName || null}{" "}
                        {this.props.serviceRequest.serviceRequestDetails
                          .consumerDetails?.consumer?.lastName || null}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      Total order count at Business :{" "}
                      {this.props.serviceRequest.serviceRequestDetails &&
                      this.props.serviceRequest.serviceRequestDetails.orderCount
                        ? this.props.serviceRequest.serviceRequestDetails
                            .orderCount
                        : null}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {this.props.serviceRequest.serviceRequestDetails &&
                    this.props.serviceRequest.serviceRequestDetails
                      .consumerDetails ? (
                      <Typography variant="subtitle2" gutterBottom>
                        Address :{" "}
                        {this.props.serviceRequest.serviceRequestDetails
                          .consumerDetails.address.flatNumber
                          ? this.props.serviceRequest.serviceRequestDetails
                              .consumerDetails.address.flatNumber + ", "
                          : ""}
                        {this.props.serviceRequest.serviceRequestDetails
                          .consumerDetails.address.societyName
                          ? this.props.serviceRequest.serviceRequestDetails
                              .consumerDetails.address.societyName + ", "
                          : ""}
                        {this.props.serviceRequest.serviceRequestDetails
                          .consumerDetails.address.street
                          ? this.props.serviceRequest.serviceRequestDetails
                              .consumerDetails.address.street + ", "
                          : ""}
                        {this.props.serviceRequest.serviceRequestDetails
                          .consumerDetails.address.landmark
                          ? this.props.serviceRequest.serviceRequestDetails
                              .consumerDetails.address.landmark + ", "
                          : ""}
                        {this.props.serviceRequest.serviceRequestDetails
                          .consumerDetails.address.area
                          ? this.props.serviceRequest.serviceRequestDetails
                              .consumerDetails.address.area + ", "
                          : ""}
                        {this.props.serviceRequest.serviceRequestDetails
                          .consumerDetails.address.city
                          ? this.props.serviceRequest.serviceRequestDetails
                              .consumerDetails.address.city + ", "
                          : ""}
                        {this.props.serviceRequest.serviceRequestDetails
                          .consumerDetails.address.pinCode
                          ? this.props.serviceRequest.serviceRequestDetails
                              .consumerDetails.address.pinCode
                          : ""}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2" gutterBottom>
                      Location Lat-Long :
                      {this.props &&
                      this.props.serviceRequest &&
                      this.props.serviceRequest.serviceRequestDetails &&
                      this.props.serviceRequest.serviceRequestDetails
                        .consumerDetails &&
                      this.props.serviceRequest.serviceRequestDetails
                        .consumerDetails.address &&
                      this.props.serviceRequest.serviceRequestDetails
                        .consumerDetails.address.location &&
                      this.props.serviceRequest.serviceRequestDetails
                        .consumerDetails.address.location.coordinates &&
                      this.props.serviceRequest.serviceRequestDetails
                        .consumerDetails.address.location.coordinates.length > 0
                        ? this.props.serviceRequest.serviceRequestDetails
                            .consumerDetails.address.location.coordinates[1]
                        : ""}
                      ,
                      {this.props &&
                      this.props.serviceRequest &&
                      this.props.serviceRequest.serviceRequestDetails &&
                      this.props.serviceRequest.serviceRequestDetails
                        .consumerDetails &&
                      this.props.serviceRequest.serviceRequestDetails
                        .consumerDetails.address &&
                      this.props.serviceRequest.serviceRequestDetails
                        .consumerDetails.address.location &&
                      this.props.serviceRequest.serviceRequestDetails
                        .consumerDetails.address.location.coordinates &&
                      this.props.serviceRequest.serviceRequestDetails
                        .consumerDetails.address.location.coordinates.length > 0
                        ? this.props.serviceRequest.serviceRequestDetails
                            .consumerDetails.address.location.coordinates[0]
                        : ""}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      display="block"
                      gutterBottom
                    >
                      Mobile Number :{" "}
                      {this.props.serviceRequest.serviceRequestDetails &&
                      this.props.serviceRequest.serviceRequestDetails
                        .consumerDetails
                        ? this.props.serviceRequest.serviceRequestDetails
                            .consumerDetails?.consumer?.primaryMobileNumber
                        : null}
                    </Typography>
                  </Grid>
                </Grid>

                {/* Payment Details */}
                <Grid item xs={4}>
                  <Grid item>
                    <Typography variant="caption" display="block" gutterBottom>
                      Payment Details
                    </Typography>
                  </Grid>
                  <Grid item>
                    {this.props.serviceRequest.serviceRequestDetails
                      .discountOnDelivery &&
                    this.props.serviceRequest.serviceRequestDetails
                      .discountOnDelivery > 0 ? (
                      <Container style={{ marginLeft: "-25px" }}>
                        {this.props.serviceRequest.serviceRequestDetails
                          .paymentDetails &&
                        ((this.props.serviceRequest.serviceRequestDetails
                          .isAdvancePaymentToBusiness === true &&
                          this.props.serviceRequest.serviceRequestDetails
                            .paymentStatus === "ADVANCEPAID") ||
                          this.props.serviceRequest.serviceRequestDetails
                            .isAdvancePaymentDoneToBusiness === true) &&
                        this.props.serviceRequest.serviceRequestDetails
                          .paymentDetails.finalPayableToBusinessAtAdvancePay ? (
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            Advance Payment : Yes (₹
                            {Number(
                              this.props.serviceRequest.serviceRequestDetails.paymentDetails.finalPayableToBusinessAtAdvancePay.toFixed(
                                2
                              )
                            ) +
                              Number(
                                this.props.serviceRequest.serviceRequestDetails
                                  .discountOnDelivery
                              )}
                            )
                          </Typography>
                        ) : null}
                      </Container>
                    ) : (
                      <Container style={{ marginLeft: "-25px" }}>
                        {this.props.serviceRequest.serviceRequestDetails
                          .paymentDetails &&
                        ((this.props.serviceRequest.serviceRequestDetails
                          .isAdvancePaymentToBusiness === true &&
                          this.props.serviceRequest.serviceRequestDetails
                            .paymentStatus === "ADVANCEPAID") ||
                          this.props.serviceRequest.serviceRequestDetails
                            .isAdvancePaymentDoneToBusiness === true) &&
                        this.props.serviceRequest.serviceRequestDetails
                          .paymentDetails.finalPayableToBusinessAtAdvancePay ? (
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            Advance Payment : Yes (₹
                            {this.props.serviceRequest.serviceRequestDetails.paymentDetails.finalPayableToBusinessAtAdvancePay.toFixed(
                              2
                            )}
                            )
                          </Typography>
                        ) : null}
                      </Container>
                    )}
                  </Grid>

                  <Grid item>
                    {this.props.serviceRequest.serviceRequestDetails
                      .paymentDetails &&
                    ((this.props.serviceRequest.serviceRequestDetails
                      .isAdvancePaymentToBusiness === true &&
                      this.props.serviceRequest.serviceRequestDetails
                        .paymentStatus === "ADVANCEPAID") ||
                      this.props.serviceRequest.serviceRequestDetails
                        .isAdvancePaymentDoneToBusiness === true) &&
                    this.props.serviceRequest.serviceRequestDetails
                      .paymentDetails.advancePaidBy &&
                    this.props.serviceRequest.serviceRequestDetails
                      .paymentDetails.advancePaidTime ? (
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        Advance Payment Marked By :{" "}
                        {
                          this.props.serviceRequest.serviceRequestDetails
                            .paymentDetails.advancePaidBy
                        }{" "}
                        (
                        {moment(
                          new Date(
                            this.props.serviceRequest.serviceRequestDetails.paymentDetails.advancePaidTime
                          )
                        ).format("DD-MM-YYYY hh:mm:ss A")}
                        )
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item>
                    {this.props.serviceRequest.serviceRequestDetails
                      .paymentDetails &&
                    this.props.serviceRequest.serviceRequestDetails
                      .paymentDetails.resetPaymentBy ? (
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        Reset Payment By :{" "}
                        {
                          this.props.serviceRequest.serviceRequestDetails
                            .paymentDetails.resetPaymentBy
                        }{" "}
                        (
                        {moment(
                          new Date(
                            this.props.serviceRequest.serviceRequestDetails.paymentDetails.resetPaymentTime
                          )
                        ).format("DD-MM-YYYY hh:mm:ss A")}
                        )
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item>
                    {this.props.serviceRequest.serviceRequestDetails
                      .paymentStatus ? (
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        Status :{" "}
                        {this.props.serviceRequest.serviceRequestDetails
                          .paymentStatus === "PAID"
                          ? "Paid"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentStatus === "INPROGRESS"
                          ? "In Progress"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentStatus === "INITIATED"
                          ? "Awaiting Business Confirmation"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentStatus === "ADVANCEPAID" &&
                            this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.advancePaidTime
                          ? `Advance Paid (${moment(
                              new Date(
                                this.props.serviceRequest.serviceRequestDetails.paymentDetails.advancePaidTime
                              )
                            ).format("DD-MM-YYYY hh:mm:ss A")})`
                          : "Not Paid"}
                      </Typography>
                    ) : (
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        Status : --
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    {this.props.serviceRequest.serviceRequestDetails
                      .paymentDetails &&
                    this.props.serviceRequest.serviceRequestDetails
                      .paymentDetails.paymentMethod ? (
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        Payment Method :{" "}
                        {this.props.serviceRequest.serviceRequestDetails
                          .paymentDetails.paymentMethod === "BANKTRANSFER"
                          ? "Bank Transfer"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "CHEQUE"
                          ? "Cheque"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "CREDIT_CARD"
                          ? "Credit Card"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "DEBIT_CARD"
                          ? "Debit Card"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "CASH"
                          ? "Cash"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "COD"
                          ? "Cod"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "UPI"
                          ? "UPI"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "UPIDIRECT"
                          ? "UPI_Direct"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "OTHER"
                          ? "Other"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "WALLET"
                          ? "Wallet"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "NET_BANKING"
                          ? "Net Banking"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod ===
                            "COMPENSATEDBYXIRIFY"
                          ? "Compensated By Xirify"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "PAIDBYXIRIFY"
                          ? "Paid By Xirify"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "GIFTFROMXIRIFY"
                          ? "Gift From Xirify"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod ===
                            "ADVANCEPAYMENTTOBUSINESS"
                          ? "Advance Payment To Business"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "RESETPAYMENT"
                          ? "Reset Payment By Xirify"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod}
                      </Typography>
                    ) : (
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        Payment Method : --
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    {this.props.serviceRequest.serviceRequestDetails
                      .paymentDetails &&
                    this.props.serviceRequest.serviceRequestDetails
                      .paymentDetails.paymentMethod ? (
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        Payment Mode :{" "}
                        {this.props.serviceRequest.serviceRequestDetails
                          .paymentDetails.paymentMethod === "BANKTRANSFER"
                          ? "Bank Transfer"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "CHEQUE" ||
                            this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "CASH" ||
                            this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "BANKTRANSFER" ||
                            this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "COD" ||
                            this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "OTHER" ||
                            this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "UPIDIRECT"
                          ? "Direct Payment"
                          : (this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "UPI" &&
                              this.props.serviceRequest.serviceRequestDetails
                                .paymentDetails.pgMethod === "CashFree") ||
                            this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "NET_BANKING" ||
                            this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "WALLET" ||
                            this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "CREDIT_CARD" ||
                            this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "DEBIT_CARD"
                          ? "Online Payment"
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod ===
                              "COMPENSATEDBYXIRIFY" ||
                            this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "PAIDBYXIRIFY" ||
                            this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod ===
                              "GIFTFROMXIRIFY" ||
                            this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod === "RESETPAYMENT" ||
                            this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod ===
                              "ADVANCEPAYMENTTOBUSINESS"
                          ? `${brandName} Payments`
                          : this.props.serviceRequest.serviceRequestDetails
                              .paymentDetails.paymentMethod}
                      </Typography>
                    ) : (
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        Payment Mode : --
                      </Typography>
                    )}
                  </Grid>

                  {this.props.serviceRequest.serviceRequestDetails
                    .paymentDetails &&
                  this.props.serviceRequest.serviceRequestDetails
                    .paymentDetails &&
                  this.props.serviceRequest.serviceRequestDetails
                    .paymentDetails &&
                  this.props.serviceRequest.serviceRequestDetails.paymentDetails
                    .referenceId ? (
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        Reference ID :{" "}
                        {this.props.serviceRequest.serviceRequestDetails
                          .paymentDetails &&
                          this.props.serviceRequest.serviceRequestDetails
                            .paymentDetails.referenceId}
                      </Typography>
                    </Grid>
                  ) : null}
                  {this.props.serviceRequest.serviceRequestDetails
                    .paymentDetails &&
                  this.props.serviceRequest.serviceRequestDetails
                    .paymentDetails &&
                  this.props.serviceRequest.serviceRequestDetails
                    .paymentDetails &&
                  this.props.serviceRequest.serviceRequestDetails.paymentDetails
                    .xpId ? (
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        Xirify Payment ID :{" "}
                        {this.props.serviceRequest.serviceRequestDetails
                          .paymentDetails &&
                          this.props.serviceRequest.serviceRequestDetails
                            .paymentDetails.xpId}
                      </Typography>
                    </Grid>
                  ) : null}

                  <Grid item>
                    {this.props.serviceRequest.serviceRequestDetails
                      .paymentDetails &&
                    this.props.serviceRequest.serviceRequestDetails
                      .paymentDetails.paymentMethod &&
                    this.props.serviceRequest.serviceRequestDetails
                      .paymentDetails.dateOfTransaction ? (
                      <Typography variant="subtitle2" gutterBottom>
                        Paid On :{" "}
                        {this.props.serviceRequest.serviceRequestDetails
                          .paymentDetails
                          ? moment(
                              this.props.serviceRequest.serviceRequestDetails
                                .paymentDetails.dateOfTransaction
                            ).format("DD-MMM-YYYY")
                          : null}
                      </Typography>
                    ) : (
                      <Typography variant="subtitle2" gutterBottom>
                        Paid On : --
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    {this.props.serviceRequest.serviceRequestDetails
                      .paymentDetails &&
                    // && this.props.serviceRequest.serviceRequestDetails.paymentDetails.paymentMethod && this.props.serviceRequest.serviceRequestDetails.paymentDetails.dateOfTransaction
                    this.props.serviceRequest.serviceRequestDetails
                      .paymentDetails.receivedBy ? (
                      <Typography variant="subtitle2" gutterBottom>
                        Received by :{" "}
                        {
                          this.props.serviceRequest.serviceRequestDetails
                            .paymentDetails.receivedBy
                        }
                      </Typography>
                    ) : (
                      <Typography variant="subtitle2" gutterBottom>
                        Received by : --
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    {this.props.serviceRequest.serviceRequestDetails
                      .paymentDetails &&
                    // && this.props.serviceRequest.serviceRequestDetails.paymentDetails.paymentMethod && this.props.serviceRequest.serviceRequestDetails.paymentDetails.dateOfTransaction && this.props.serviceRequest.serviceRequestDetails.paymentDetails.receivedBy
                    this.props.serviceRequest.serviceRequestDetails
                      .paymentDetails.notes ? (
                      <Typography variant="subtitle2" gutterBottom>
                        Notes :{" "}
                        {
                          this.props.serviceRequest.serviceRequestDetails
                            .paymentDetails.notes
                        }
                      </Typography>
                    ) : (
                      <Typography variant="subtitle2" gutterBottom>
                        Notes : --
                      </Typography>
                    )}
                  </Grid>

                  {/* image section is not done which is to be displayed */}
                  <Grid item>
                    {
                      this.props.serviceRequest.serviceRequestDetails
                        .paymentDetails &&
                      // && this.props.serviceRequest.serviceRequestDetails.paymentDetails.paymentMethod && this.props.serviceRequest.serviceRequestDetails.paymentDetails.dateOfTransaction && this.props.serviceRequest.serviceRequestDetails.paymentDetails.receivedBy && this.props.serviceRequest.serviceRequestDetails.paymentDetails.notes
                      this.props.serviceRequest.serviceRequestDetails
                        .paymentDetails.fileName ? (
                        <Typography variant="subtitle2" gutterBottom>
                          Attachments :
                          {/* {this.getTransactionImages(this.props.serviceRequest.serviceRequestDetails.paymentDetails.paymentMethod)} */}
                          {
                            <a
                              href={this.state.transaction}
                              // target="_blank" rel="noopener noreferrer"
                            >
                              <img
                                // src={this.props.serviceRequest.serviceRequestDetails.paymentDetails.fileName}
                                // src={"data:image/png;base64,",this.getTransactionImages(this.props.serviceRequest.serviceRequestDetails.paymentDetails.paymentMethod)}
                                src={this.state.transaction}
                                alt="Transaction Details"
                                width="30"
                                height="30"
                                onClick={this.openAttachmentModal}
                              />
                            </a>
                          }
                        </Typography>
                      ) : (
                        <Typography variant="subtitle2" gutterBottom>
                          Attachments : --
                        </Typography>
                      )
                      // null
                    }
                  </Grid>
                </Grid>
              </Grid>
              {
                // !["REJECTED", "CANCELLED", "COMPLETED", "REJECTED_BY_CONSUMER"].includes(this.props.serviceRequest.serviceRequestDetails.currentStatus)

                !["REJECTED", "CANCELLED", "REJECTED_BY_CONSUMER"].includes(
                  this.props.serviceRequest.serviceRequestDetails.currentStatus
                ) &&
                (this.props.serviceRequest.serviceRequestDetails
                  .currentStatus !== "COMPLETED" ||
                  this.props.serviceRequest.serviceRequestDetails
                    .paymentStatus !== "PAID" ||
                  this.props.serviceRequest.serviceRequestDetails
                    .paymentStatus !== "INPROGRESS") &&
                this.props.user.role === "admin" ? (
                  this.props.serviceRequest.serviceRequestDetails.requestType ===
                  "Fixed" ? (
                    <Grid
                      container
                      spacing={3}
                      style={{ marginTop: "7px", background: "white" }}
                    >
                      <Grid item xs>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          color="black"
                          gutterBottom
                          style={{ float: "left", width: "100%" }}
                        >
                          Request Status Override
                        </Typography>
                        <Typography
                          variant="body2"
                          display="block"
                          color="black"
                          gutterBottom
                          style={{ float: "left", width: "100%" }}
                        >
                          You may update request status from Admin side
                        </Typography>
                      </Grid>
                      <Grid xs={12}> </Grid>
                      {/* <Grid item xs spacing={1}> </Grid> */}

                      <Grid
                        xs={2}
                        style={{ paddingRight: "50px", paddingLeft: "10px" }}
                        className={classes.buttonPadding}
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          margin="normal"
                          color="primary"
                          onClick={this.openAcceptRequestModal}
                          style={{ float: "left", width: "90%" }}
                          disabled={[
                            "ACCEPTED",
                            "QUOTED",
                            "QUOTE_DECLINED",
                            "IN_PROGRESS",
                            "OUT_FOR_DELIVERY",
                            "DELIVERY_DONE",
                            "READY_FOR_PICKUP",
                            "PICKUP_DONE",
                            "COMPLETED",
                          ].includes(
                            this.props.serviceRequest.serviceRequestDetails
                              .currentStatus
                          )}
                        >
                          ACCEPT
                        </Button>
                      </Grid>

                      <Grid
                        xs={2}
                        style={{ marginLeft: "-53px" }}
                        className={classes.buttonPadding}
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          margin="normal"
                          color="primary"
                          onClick={this.openCompleteRequestModal}
                          style={{ float: "left", width: "90%" }}
                          // disabled = {this.props.serviceRequest.serviceRequestDetails.currentStatus === "CREATED" || this.props.serviceRequest.serviceRequestDetails.currentStatus === "INITIATED"}
                          disabled={[
                            "CREATED",
                            "INITIATED",
                            "COMPLETED",
                          ].includes(
                            this.props.serviceRequest.serviceRequestDetails
                              .currentStatus
                          )}
                        >
                          COMPLETED
                        </Button>
                      </Grid>

                      <Grid
                        xs={1}
                        style={{ marginLeft: "-10px" }}
                        className={classes.buttonPadding}
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          margin="normal"
                          color="primary"
                          onClick={this.openPaidRequestModal}
                          style={{ float: "left", width: "90%" }}
                          disabled={
                            ["CREATED", "INITIATED"].includes(
                              this.props.serviceRequest.serviceRequestDetails
                                .currentStatus
                            ) ||
                            ["PAID"].includes(
                              this.props.serviceRequest.serviceRequestDetails
                                .paymentStatus
                            )
                          }
                        >
                          PAID
                        </Button>
                      </Grid>

                      <Grid xs={2} className={classes.buttonPadding}>
                        <Button
                          fullWidth
                          variant="contained"
                          margin="normal"
                          color="primary"
                          onClick={this.openResetPaymentModal}
                          style={{ float: "left", width: "90%" }}
                          // disabled={["UNPAID"].includes(this.props.serviceRequest.serviceRequestDetails.paymentStatus)}
                          disabled={
                            ["PAID", "INITIATED", "INPROGRESS"].includes(
                              this.props.serviceRequest.serviceRequestDetails
                                .paymentStatus
                            ) &&
                            [
                              "ACCEPTED",
                              "IN_PROGRESS",
                              "COMPLETED",
                              "OUT_FOR_DELIVERY",
                            ].includes(
                              this.props.serviceRequest.serviceRequestDetails
                                .currentStatus
                            )
                              ? false
                              : true
                          }
                        >
                          RESET PAYMENT
                        </Button>
                      </Grid>

                      <Grid
                        xs={2}
                        style={{ marginLeft: "-10px" }}
                        className={classes.buttonPadding}
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          margin="normal"
                          color="secondary"
                          onClick={this.openCancelRequestModal}
                          style={{
                            float: "left",
                            width: "90%",
                            backgroundColor:
                              this.props.serviceRequest.serviceRequestDetails
                                .currentStatus === "COMPLETED" &&
                              this.props.serviceRequest.serviceRequestDetails
                                .paymentStatus === "UNPAID"
                                ? "rgba(0, 0, 0, 0.12)"
                                : "rgb(245, 5, 5)",
                            color:
                              this.props.serviceRequest.serviceRequestDetails
                                .currentStatus === "COMPLETED" &&
                              this.props.serviceRequest.serviceRequestDetails
                                .paymentStatus === "UNPAID"
                                ? "rgba(0, 0, 0, 0.26)"
                                : "white",
                          }}
                          disabled={
                            ["COMPLETED"].includes(
                              this.props.serviceRequest.serviceRequestDetails
                                .currentStatus
                            ) ||  ([
                              "IN_PROGRESS",
                              "OUT_FOR_DELIVERY",
                              "DELIVERY_DONE",
                            ].includes(
                              this.props.serviceRequest.serviceRequestDetails
                                .currentStatus
                            ) &&
                              this.props.serviceRequest.serviceRequestDetails
                                .deliveryType === "porter")
                          }
                        >
                          CANCEL REQUEST
                        </Button>
                      </Grid>

                      <Grid
                        xs={2}
                        style={{ marginLeft: "-10px" }}
                        className={classes.buttonPadding}
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          margin="normal"
                          color="primary"
                          onClick={this.openManualDeliveryModal}
                          style={{ float: "left", width: "100%" }}
                          // disabled={["UNPAID"].includes(this.props.serviceRequest.serviceRequestDetails.paymentStatus)}
                          disabled={(
                            this.props.serviceRequest.serviceRequestDetails
                              .currentStatus !== "CREATED" &&
                            this.props.serviceRequest.serviceRequestDetails
                              .currentStatus !== "INITIATED" &&
                            this.props.serviceRequest.serviceRequestDetails
                              .serviceMode === "DELIVERY" &&
                            this.props.user.role === "admin" )|| (this.props.serviceRequest.serviceRequestDetails.partnerTaskId && this.props.serviceRequest.serviceRequestDetails.isCancelDeliveryPartnerTask !== null && this.props.serviceRequest.serviceRequestDetails.isCancelDeliveryPartnerTask !== undefined && this.props.serviceRequest.serviceRequestDetails.isCancelDeliveryPartnerTask) ? false : true 
                            }
                        >
                          MANUAL DELIVERY
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      spacing={3}
                      style={{ marginTop: "7px", background: "white" }}
                    >
                      <Grid item xs>
                        <Typography
                          variant="subtitle2"
                          display="block"
                          color="black"
                          gutterBottom
                          style={{ float: "left", width: "100%" }}
                        >
                          Request Status Override
                        </Typography>
                        <Typography
                          variant="body2"
                          display="block"
                          color="black"
                          gutterBottom
                          style={{ float: "left", width: "100%" }}
                        >
                          You may update request status from Admin side
                        </Typography>
                      </Grid>
                      <Grid xs={12}> </Grid>
                      {/* <Grid item xs spacing={1}> </Grid> */}

                      <Grid
                        xs={2}
                        style={{ paddingRight: "50px", paddingLeft: "10px" }}
                        className={classes.buttonPadding}
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          margin="normal"
                          color="primary"
                          onClick={this.openAcceptRequestModal}
                          style={{ float: "left", width: "90%" }}
                          disabled={[
                            "CREATED",
                            "ACCEPTED",
                            "INITIATED",
                            "QUOTED",
                            "QUOTE_DECLINED",
                            "IN_PROGRESS",
                            "OUT_FOR_DELIVERY",
                            "DELIVERY_DONE",
                            "READY_FOR_PICKUP",
                            "PICKUP_DONE",
                            "COMPLETED",
                          ].includes(
                            this.props.serviceRequest.serviceRequestDetails
                              .currentStatus
                          )}
                        >
                          ACCEPT
                        </Button>
                      </Grid>

                      <Grid
                        xs={2}
                        style={{ marginLeft: "-53px" }}
                        className={classes.buttonPadding}
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          margin="normal"
                          color="primary"
                          onClick={this.openCompleteRequestModal}
                          style={{ float: "left", width: "90%" }}
                          // disabled = {this.props.serviceRequest.serviceRequestDetails.currentStatus === "CREATED" || this.props.serviceRequest.serviceRequestDetails.currentStatus === "INITIATED"}
                          disabled={[
                            "CREATED",
                            "INITIATED",
                            "QUOTED",
                            "QUOTE_DECLINED",
                            "COMPLETED",
                          ].includes(
                            this.props.serviceRequest.serviceRequestDetails
                              .currentStatus
                          )}
                        >
                          COMPLETED
                        </Button>
                      </Grid>

                      <Grid
                        xs={1}
                        style={{ marginLeft: "-10px" }}
                        className={classes.buttonPadding}
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          margin="normal"
                          color="primary"
                          onClick={this.openPaidRequestModal}
                          style={{ float: "left", width: "90%" }}
                          disabled={
                            [
                              "CREATED",
                              "INITIATED",
                              "QUOTED",
                              "QUOTE_DECLINED",
                            ].includes(
                              this.props.serviceRequest.serviceRequestDetails
                                .currentStatus
                            ) ||
                            ["PAID"].includes(
                              this.props.serviceRequest.serviceRequestDetails
                                .paymentStatus
                            )
                          }
                        >
                          PAID
                        </Button>
                      </Grid>

                      <Grid xs={2} className={classes.buttonPadding}>
                        <Button
                          fullWidth
                          variant="contained"
                          margin="normal"
                          color="primary"
                          onClick={this.openResetPaymentModal}
                          style={{ float: "left", width: "90%" }}
                          disabled={
                            ["PAID", "INITIATED", "INPROGRESS"].includes(
                              this.props.serviceRequest.serviceRequestDetails
                                .paymentStatus
                            ) &&
                            [
                              "ACCEPTED",
                              "IN_PROGRESS",
                              "COMPLETED",
                              "OUT_FOR_DELIVERY",
                            ].includes(
                              this.props.serviceRequest.serviceRequestDetails
                                .currentStatus
                            )
                              ? false
                              : true
                          }
                        >
                          RESET PAYMENT
                        </Button>
                      </Grid>

                      <Grid
                        xs={2}
                        style={{ marginLeft: "-10px" }}
                        className={classes.buttonPadding}
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          margin="normal"
                          color="secondary"
                          onClick={this.openCancelRequestModal}
                          style={{
                            float: "left",
                            width: "90%",
                            backgroundColor: "#f50505",
                            color: "white",
                          }}
                          disabled={["COMPLETED"].includes(
                            this.props.serviceRequest.serviceRequestDetails
                              .currentStatus
                          )}
                        >
                          CANCEL REQUEST
                        </Button>
                      </Grid>
                      <Grid
                        xs={2}
                        style={{ marginLeft: "-10px" }}
                        className={classes.buttonPadding}
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          margin="normal"
                          color="primary"
                          onClick={this.openManualDeliveryModal}
                          style={{ float: "left", width: "100%" }}
                          // disabled={["UNPAID"].includes(this.props.serviceRequest.serviceRequestDetails.paymentStatus)}
                          disabled={(
                            this.props.serviceRequest.serviceRequestDetails
                              .currentStatus !== "CREATED" &&
                            this.props.serviceRequest.serviceRequestDetails
                              .currentStatus !== "INITIATED" &&
                            this.props.serviceRequest.serviceRequestDetails
                              .serviceMode === "DELIVERY" &&
                            this.props.user.role === "admin") || (this.props.serviceRequest.serviceRequestDetails.isCancelDeliveryPartnerTask !== null && this.props.serviceRequest.serviceRequestDetails.isCancelDeliveryPartnerTask !== undefined && this.props.serviceRequest.serviceRequestDetails.isCancelDeliveryPartnerTask) ? false : true 
                            }
                        >
                          MANUAL DELIVERY
                        </Button>
                      </Grid>
                    </Grid>
                  )
                ) : (
                  <Grid xs={2} className={classes.buttonPadding}>
                    {this.props.user.role === "admin" ? (
                      <Button
                        fullWidth
                        variant="contained"
                        margin="normal"
                        color="primary"
                        onClick={this.openResetPaymentModal}
                        style={{ float: "left", width: "90%" }}
                        // disabled={["UNPAID"].includes(this.props.serviceRequest.serviceRequestDetails.paymentStatus)}
                        disabled={
                          ["PAID", "INITIATED", "INPROGRESS"].includes(
                            this.props.serviceRequest.serviceRequestDetails
                              .paymentStatus
                          ) &&
                          [
                            "ACCEPTED",
                            "IN_PROGRESS",
                            "COMPLETED",
                            "OUT_FOR_DELIVERY",
                          ].includes(
                            this.props.serviceRequest.serviceRequestDetails
                              .currentStatus
                          ) &&
                          this.props.user.role === "admin"
                            ? false
                            : true
                        }
                      >
                        RESET PAYMENT
                      </Button>
                    ) : (this.props.user.role === "admin" ||
                        this.props.user.role === "areaManager") &&
                      this.props.serviceRequest.serviceRequestDetails
                        .currentStatus !== "COMPLETED" &&
                      this.props.serviceRequest.serviceRequestDetails
                        .preferredServiceMode === "DELIVERY" ? (
                      <Button
                        fullWidth
                        variant="contained"
                        margin="normal"
                        color="primary"
                        onClick={this.openManualDeliveryModal}
                        style={{ float: "left", width: "100%" }}
                        // disabled={["UNPAID"].includes(this.props.serviceRequest.serviceRequestDetails.paymentStatus)}
                        disabled={(
                        this.props.serviceRequest.serviceRequestDetails
                          .currentStatus !== "CREATED" &&
                        this.props.serviceRequest.serviceRequestDetails
                          .currentStatus !== "INITIATED" &&
                        this.props.serviceRequest.serviceRequestDetails
                          .serviceMode === "DELIVERY" &&
                        this.props.user.role === "admin" ) || (this.props.serviceRequest.serviceRequestDetails.isCancelDeliveryPartnerTask !== null && this.props.serviceRequest.serviceRequestDetails.isCancelDeliveryPartnerTask !== undefined && this.props.serviceRequest.serviceRequestDetails.isCancelDeliveryPartnerTask) ? false : true 
                        }
                      >
                        MANUAL DELIVERY
                      </Button>
                    ) : null}
                  </Grid>
                )
              }
            </Container>

            <SupportNoteView
              supportconversations={
                this.props.serviceRequest.serviceRequestDetails
                  .supportConversations
              }
              userRole={this.props.user}
              serviceId={this.props.match.params.serviceRequestId}
              open={this.state.supportNoteModalOpen}
              onClose={this.closeSupportNoteModal}
            />

            <MessageView
              conversations={
                this.props.serviceRequest.serviceRequestDetails.conversations
              }
              userRole={this.props.user}
              serviceId={this.props.match.params.serviceRequestId}
              open={this.state.messageModalOpen}
              onClose={this.closeMessageModal}
            />
            {/*  Xirify Adjustments */}
            <XirifyAdjustmentView
              userRole={this.props.user}
              serviceId={this.props.match.params.serviceRequestId}
              serviceRequest={this.props.serviceRequest}
              open={this.state.xirifyAdjustmentModalOpen}
              onClose={this.closeXirifyAdjustmentModalOpen}
            />

            <RefundsDetailsView
              userRole={this.props.user}
              serviceId={this.props.match.params.serviceRequestId}
              serviceRequest={this.props.serviceRequest}
              open={this.state.refundDetailsModal}
              onClose={this.closeRefundDetailsModal}
              isRefundApplied={
                this.props.serviceRequest?.serviceRequestDetails
                  ?.isRefundApplied
              }
            />
            {/* Dialog for manual delivery creation*/}
         {this.props?.serviceRequest?.serviceRequestDetails &&
               Object.keys(this.props.serviceRequest.serviceRequestDetails).length > 0 ? 
              <ManualDeliveryModal
                userRole={this.props.user}
                serviceId={this.props.match.params.serviceRequestId}
                serviceRequest={this.props.serviceRequest}
                open={this.state.openManualDeliveryModal}
                onClose={this.closeManualDeliveryModal}
                getLastManualDeliveryInfoObj={getLastManualDeliveryInfoObj}               
              /> : null
            }
            

            {/* Cancel SR Modal */}
            <Dialog
              open={this.state.cancelRequestModalOpen}
              onClose={this.closeCancelRequestModal}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle
                className="dialogTitle"
                style={{ backgroundColor: "#14B7DD" }}
              >
                Cancel Order
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to cancel this order?
                </DialogContentText>

                <DialogContentText style={{ color: "#53ABE8" }}>
                  Reasons for order cancellation
                </DialogContentText>

                {this.props.dashboard.getAdminOrderCancellationReasons
                  .reasonForAdminData &&
                this.props.dashboard.getAdminOrderCancellationReasons
                  .reasonForAdminData.length > 0 ? (
                  <RadioGroup
                    // defaultValue={this.props.dashboard.getAdminOrderCancellationReasons.reasonForAdminData[0].reason}
                    aria-label="position"
                    name="reason"
                    onChange={this.handleReasonChangeRadio}
                    value={this.state.reason}
                    style={{ paddingLeft: "10px" }}
                  >
                    {this.props.dashboard.getAdminOrderCancellationReasons
                      .reasonForAdminData &&
                    this.props.dashboard.getAdminOrderCancellationReasons
                      .reasonForAdminData.length > 0
                      ? this.props.dashboard.getAdminOrderCancellationReasons.reasonForAdminData.map(
                          (mode, index) => (
                            <FormControlLabel
                              value={mode.reasonsForCancellation.reason}
                              control={<Radio color="primary" />}
                              label={
                                <Typography style={{ fontSize: 14 }}>
                                  {mode.reasonsForCancellation.reason}
                                </Typography>
                              }
                              size="small"
                            />
                          )
                        )
                      : null}

                  
                  </RadioGroup>
                ) : null}

                <RadioGroup
                  // defaultValue={this.props.dashboard.getAdminOrderCancellationReasons.reasonForAdminData[0].reason}
                  aria-label="position"
                  name="reason"
                  onChange={this.changeHandler}
                  value={this.state.othersReason}
                  // checked={this.state.isRadioSelected==false}
                  // value="Others"
                  style={{ paddingLeft: "10px" }}
                >
                  <FormControlLabel
                    value="Others"
                    control={<Radio color="primary" />}
                    label={
                      <Typography style={{ fontSize: 14 }}>
                        {"Others"}
                      </Typography>
                    }
                    size="small"
                  />

                  {this.state.isRadioSelected ? null : (
                    <TextField
                      autoFocus={true}
                      selection={{ start: 0, end: 0 }}
                      multiline={true}
                      margin="dense"
                      id="reason"
                      fullWidth
                      placeholder="Your order cancellation reason"
                      onChange={this.handleReasonChange}
                      disabled={this.state.isRadioSelected}
                      style={{ paddingLeft: "30px", marginTop: "0px" }}
                      error={this.state.reasonError === true ? true : false}
                      helperText={
                        this.state.reasonError === true ? "Required" : null
                      }
                    />
                  )}
                </RadioGroup>

              
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.closeCancelRequestModal}
                  variant="contained"
                  color="secondary"
                >
                  No
                </Button>
                <Button
                  disabled={this.state.disableButton || !this.state.reason}
                  onClick={this.cancelRequest}
                  variant="contained"
                  color="primary"
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>

            {this.props &&
            this.props.serviceRequest &&
            this.props.serviceRequest.serviceRequestDetails &&
            this.props.serviceRequest.serviceRequestDetails.business &&
            this.props.serviceRequest.serviceRequestDetails.business
              .isBusinessPreparationTimerEnabled &&
            this.props &&
            this.props.serviceRequest &&
            this.props.serviceRequest.serviceRequestDetails &&
            this.props.serviceRequest.serviceRequestDetails.business &&
            this.props.serviceRequest.serviceRequestDetails.business
              .businessPreparationTime !== null &&
            this.props.serviceRequest.serviceRequestDetails.isASAPOrder ===
              true &&
            (((this.props.serviceRequest.serviceRequestDetails.serviceMode ===
              "DELIVERY" ||
              this.props.serviceRequest.serviceRequestDetails
                .preferredServiceMode === "DELIVERY") &&
              this.props.serviceRequest.serviceRequestDetails.deliveryType !==
                "Self") ||
              ((this.props.serviceRequest.serviceRequestDetails.serviceMode ===
                "PICKUP" ||
                this.props.serviceRequest.serviceRequestDetails
                  .preferredServiceMode === "PICKUP") &&
                this.props.serviceRequest.serviceRequestDetails.deliveryType ===
                  "self")) ? (
              <Dialog
                open={this.state.acceptRequestModalOpen}
                onClose={this.closeAcceptRequestModal}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle
                  className="dialogTitle"
                  style={{ backgroundColor: "#14B7DD" }}
                >
                  Set Preparation Time
                </DialogTitle>
                <DialogContent>
                  <Grid style={{ display: "flex" }}>
                    <Button
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      component="span"
                      style={{
                        width: "35px",
                        border: "1px solid #14b7dd",
                        height: "30px",
                        borderRadius: "4px",
                        display: "flex",
                        backgroundColor: "white",
                        color: "#14b7dd",
                        paddingLeft: "13px",
                      }}
                      onClick={this.decrementPrepTime}
                      color="primary"
                    >
                      -
                    </Button>

                    <Typography
                      style={{
                        width: "98px",
                        border: "1px solid gray",
                        height: "30px",
                        borderRadius: "4px",
                        display: "flex",
                        backgroundColor: "white",
                        color: "black",
                        marginLeft: "8px",
                        paddingLeft: "10px",
                      }}
                    >
                      {`${this.state.prepTime} Minutes`}
                    </Typography>

                    <Button
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      component="span"
                      style={{
                        width: "35px",
                        border: "1px solid #14b7dd",
                        height: "30px",
                        borderRadius: "4px",
                        display: "flex",
                        backgroundColor: "white",
                        color: "#14b7dd",
                        marginLeft: "8px",
                        paddingLeft: "13px",
                      }}
                      onClick={this.incrementPrepTime}
                      color="primary"
                    >
                      +
                    </Button>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={this.closeAcceptRequestModal}
                    variant="contained"
                    color="secondary"
                  >
                    CANCEL
                  </Button>
                  <Button
                    disabled={this.state.acceptBtn}
                    onClick={this.acceptRequest}
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "45px" }}
                  >
                    ACCEPT
                  </Button>
                </DialogActions>
              </Dialog>
            ) : (
              <Dialog
                open={this.state.acceptRequestModalOpen}
                onClose={this.closeAcceptRequestModal}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle
                  className="dialogTitle"
                  style={{ backgroundColor: "#14B7DD" }}
                >
                  Accept Order
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Do you want to Accept this order?
                  </DialogContentText>
                </DialogContent>

                <DialogActions>
                  <Button
                    onClick={this.closeAcceptRequestModal}
                    variant="contained"
                    color="secondary"
                  >
                    No
                  </Button>
                  <Button
                    disabled={this.state.acceptBtn}
                    onClick={this.acceptRequest}
                    variant="contained"
                    color="primary"
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            )}

            <Dialog
              open={this.state.completeAdvancePaymentModalOpen}
              onClose={this.closeAdvancePaymentModal}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle
                className="dialogTitle"
                style={{ backgroundColor: "#14B7DD" }}
              >
                Advance Payment To Business
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are You Sure, You want to make Advance Payment to Business ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.closeAdvancePaymentModal}
                  variant="contained"
                  color="secondary"
                >
                  No
                </Button>
                <Button
                  onClick={this.completeAdvancePayment}
                  variant="contained"
                  color="primary"
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>

            {/* Completed SR Modal*/}
            <Dialog
              open={this.state.completeRequestModalOpen}
              onClose={this.closeCompleteRequestModal}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle
                className="dialogTitle"
                style={{ backgroundColor: "#14B7DD" }}
              >
                Complete Order
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to Complete this order?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={this.closeCompleteRequestModal}
                  variant="contained"
                  color="secondary"
                >
                  No
                </Button>
                <Button
                  disabled={this.state.completeBtn}
                  onClick={this.completeRequest}
                  variant="contained"
                  color="primary"
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>

            {/* Paid SR Modal*/}
            {this.props.serviceRequest.serviceRequestDetails.paymentStatus ===
              "INITIATED" ||
            this.props.serviceRequest.serviceRequestDetails.paymentStatus ===
              "INPROGRESS" ? (
              <Dialog
                open={this.state.paidRequestModalOpen}
                onClose={this.closePaidRequestModal}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle
                  className="dialogTitle"
                  style={{ backgroundColor: "#14B7DD" }}
                >
                  Order Payment Confirmation
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to Confirm the payment this order?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={this.closePaidRequestModal}
                    variant="contained"
                    color="secondary"
                  >
                    No
                  </Button>
                  <Button
                    disabled={this.state.paidBtn}
                    onClick={this.paidRequest}
                    variant="contained"
                    color="primary"
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            ) : (
              <Dialog
                open={this.state.paidRequestModalOpen}
                onClose={this.closePaidRequestModal}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle
                  className="dialogTitle"
                  style={{ backgroundColor: "#14B7DD" }}
                >
                  Order Payment Confirmation
                </DialogTitle>
                <DialogContent>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" gutterBottom>
                      Payment Methods *
                    </Typography>
                  </Grid>
                  <Grid item xs={8} />
                  <Grid item xs={3}>
                    <FormControl
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      className={classes.formControl}
                      style={{ width: "225px" }}
                    >
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        // id="select-transaction-status-id"
                        value={this.state.paymentMethodsFromWeb}
                        name="paymentMethods"
                        onChange={this.handlePaymentMethodsFromWebChange}
                      >
                        <MenuItem value="other">Other</MenuItem>
                        <MenuItem value="UPIDirect">UPI_Direct</MenuItem>
                        <MenuItem value="cod">Cash</MenuItem>
                        <MenuItem value="cheque">Cheque</MenuItem>
                        <MenuItem value="compensatedByXirify">
                          Compensated By Xirify
                        </MenuItem>
                        <MenuItem value="paidByXirify">Paid By Xirify</MenuItem>
                        <MenuItem value="giftFromXirify">
                          Gift From Xirify
                        </MenuItem>
                        <MenuItem value="resetpayment">
                          Reset Payment By Xirify
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <DialogContentText>
                    Are you sure you want to confirm the payment for this order?
                  </DialogContentText>

                  <Typography variant="subtitle2" gutterBottom>
                    Received By *
                  </Typography>
                  <TextField
                    required
                    // autoFocus
                    margin="dense"
                    id="receivedBy"
                    placeholder="Name of the Person"
                    fullWidth
                    onChange={this.handlePaymentReceivedChange}
                  />

                  <DialogContentText></DialogContentText>
                  <Typography variant="subtitle2" gutterBottom>
                    Description *
                  </Typography>
                  <TextField
                    required
                    // autoFocus
                    margin="dense"
                    id="description"
                    placeholder="Enter the Payment mode used"
                    fullWidth
                    onChange={this.handlePaymentDescriptionChange}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={this.closePaidRequestModal}
                    variant="contained"
                    color="secondary"
                  >
                    No
                  </Button>
                  <Button
                    disabled={
                      !(
                        this.state.receivedBy &&
                        this.state.description &&
                        this.state.paymentMethodsFromWeb &&
                        /[a-zA-Z0-9_@./#&+-][a-zA-Z0-9_@./#&+\-\s]*$/.test(
                          this.state.receivedBy
                        ) &&
                        /[a-zA-Z0-9_@./#&+-][a-zA-Z0-9_@./#&+\-\s]*$/.test(
                          this.state.description
                        )
                      )
                    }
                    onClick={this.paidRequest}
                    variant="contained"
                    color="primary"
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            )}

            {/* Reset Paymnent Request Modal */}
            <Dialog
              open={this.state.acceptResetPaymentModalOpen}
              onClose={this.closeResetPaymentModal}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle
                className="dialogTitle"
                style={{ backgroundColor: "#14B7DD" }}
              >
                Reset Payment
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to "RESET PAYMENT" ?
                </DialogContentText>
              </DialogContent>
              {/* <Divider /> */}
              <DialogActions>
                <Button
                  onClick={this.closeResetPaymentModal}
                  variant="contained"
                  color="secondary"
                >
                  No
                </Button>
                <Button
                  disabled={this.state.resetBtn}
                  onClick={this.resetPayment}
                  variant="contained"
                  color="primary"
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>

            {/* For the Attachment Image */}
            <Dialog
              open={this.state.attachmentModalOpen}
              onClose={this.closeAttachmentModal}
              aria-labelledby="form-dialog-title"
              // className={classes.imgmodal}
              fullWidth
              maxWidth="md"
              // md sm xs xl lg
            >
              <DialogContent>
                <DialogContentText>
                  <img
                    src={this.state.transaction}
                    alt="Attachment"
                    // className={classes.hoverZoom}
                  />
                </DialogContentText>
              </DialogContent>
            </Dialog>

            {/* Dialog for Cancel Partner Task */}
            <Dialog
              open={this.state.cancelDeliveryModalOpenFlag}
              onClose={this.cancelDeliveryModalCloseFlag}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle
                className="dialogTitle"
                style={{ backgroundColor: "#14B7DD", marginBottom: "10px" }}
              >
                Cancel Delivery
                <CloseIcon
                  className={classes.headGridTwo}
                  onClick={this.cancelDeliveryModalCloseFlag}
                />
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to CANCEL DELIVERY?
                </DialogContentText>

                {this.props.serviceRequest &&
                this.props.serviceRequest.serviceRequestDetails &&
                this.props.serviceRequest.serviceRequestDetails.deliveryType &&
                this.props.serviceRequest.serviceRequestDetails.deliveryType ===
                  "dunzo" ? (
                  <RadioGroup
                    aria-label="position"
                    name="cancellationReason"
                    onChange={this.handleDunzoCancelReasons}
                    value={this.state.cancellationReason}
                    style={{ paddingLeft: "10px" }}
                  >
                    {dunzoreasons && dunzoreasons.length > 0
                      ? dunzoreasons.map((mode, index) => (
                          <FormControlLabel
                            value={mode.reason}
                            control={<Radio color="primary" />}
                            label={
                              <Typography style={{ fontSize: 14 }}>
                                {mode.reason}
                              </Typography>
                            }
                            size="small"
                          />
                        ))
                      : null}
                  </RadioGroup>
                ) : null}
              </DialogContent>

              <Divider
                color="textSecondary"
                style={{ marginBottom: "5px", marginTop: "5px" }}
                display="block"
              />

              <DialogActions>
                <Button
                  onClick={this.cancelDeliveryModalCloseFlag}
                  variant="contained"
                  color="secondary"
                >
                  No
                </Button>
                <Button
                  disabled={
                    this.state.cancelDeliveryFlag
                      ? true
                      : this.props.serviceRequest &&
                        this.props.serviceRequest.serviceRequestDetails &&
                        this.props.serviceRequest.serviceRequestDetails
                          .deliveryType &&
                        this.props.serviceRequest.serviceRequestDetails
                          .deliveryType === "dunzo"
                      ? this.state.isDunzoReasonSelected
                        ? false
                        : true
                      : false
                  }
                  onClick={this.cancelDeliveryPartnerTask}
                  variant="contained"
                  style={{
                    backgroundColor: "#FF5764",
                    color: "#ffffff",
                    opacity:
                      this.props.serviceRequest &&
                      this.props.serviceRequest.serviceRequestDetails &&
                      this.props.serviceRequest.serviceRequestDetails
                        .deliveryType &&
                      this.props.serviceRequest.serviceRequestDetails
                        .deliveryType === "dunzo"
                        ? this.state.isDunzoReasonSelected
                          ? "1"
                          : "0.5"
                        : "1",
                  }}
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={this.state.snackOpen}
              autoHideDuration={2000}
              onClose={this.handleSnackClose}
              ContentProps={{ "aria-describedby": "message-id" }}
            >
              {muiAlert}
            </Snackbar>

            <Backdrop
              className={classes.backdrop}
              open={this.props.serviceRequest.isFetching}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Container>
        );
}
}

const mapStateToProps = state => {
    return {
    user: state.auth.user,
    serviceRequest: state.serviceRequest,
    business: state.business,
    dashboard: state.dashboard,
    brand:state.auth.brand
    }
};

export default connect(mapStateToProps, {
    changeHeaderName,
    getServiceRequestDetailsById,
    requestServiceRequests,
    changeServiceStatus,
    changePaymentStatus,
    getRequestDocumentForPaymentDetails,
    getAllMasterOtp,
    fetchEnvs,
    getCancellationReasonForAdminBusiness,
    createDunzoId,
    callDunzoIdReset,
    callDunzoId,
    cancelDeliveryPartnerTask,
    clearMsg,
    changeInProgressPaymentStatus,
    createRefundRequest,
    createShadowfaxId,
    callBusinessDashboardCron,
    callBusinessDashboardCronReset,
    createPorterId
})(withStyles(styles)(Dashboard));
