import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: 'white'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
        textAlign: 'center'
    }
});

// Create Document Component
const MyDocument = (props) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                {/* <Text style={{ color: '#14B7DD', fontWeight: 900, fontSize: 22, paddingBottom: 5 }} >{props.businessName}</Text>
                <Text style={{ color: 'grey', fontWeight: 600, fontSize: 14, marginBottom: 7 }} >Business ID : {props.businessId}</Text> */}
                <Image src={props.qrCodeImage}></Image>
            </View>
        </Page>
    </Document>
);

export default MyDocument;