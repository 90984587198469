import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { loginUser, getAdminProfile } from "../../actions/authActions";
import setAuthToken from "../../utils/setAuthToken";
import { 
  Container,
  CssBaseline,
  Typography,
  TextField,
  Button,
  Grid,
  Link,
  Snackbar,
  Paper,
  Fade,
  Box
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Avatar from '@material-ui/core/Avatar';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#f5f7fa',
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
    h5: {
      fontWeight: 600,
    }
  }
});

const styles = (theme) => ({
  root: {
    minHeight: '100vh',
    background: 'linear-gradient(to right top,rgb(194, 226, 214),rgb(151, 208, 216),rgb(169, 191, 192))',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    background: 'linear-gradient(to right top,rgb(238, 238, 238),rgb(255, 255, 255),rgb(230, 230, 230))',
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    boxShadow: '0 8px 30px rgba(0,0,0,0.12)',
    width: '100%',
    maxWidth: 450,
    transition: 'all 0.3s ease',
    '&:hover': {
      boxShadow: '0 12px 40px rgba(0,0,0,0.15)',
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1.5),
    borderRadius: theme.spacing(1),
    fontWeight: 600,
    letterSpacing: 0.5,
    fontSize: '1rem',
    background: 'linear-gradient(90deg,rgb(143, 199, 206) 0%,rgb(62, 190, 199) 100%)',
  },
  inputField: {
    '& label.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: theme.spacing(1),
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
   // marginBottom: theme.spacing(3),
  },
  logo: {
    maxHeight: 60,
    width: 'auto',
  },
  footer: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
  },
  animatedBg: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    overflow: 'hidden',
  },
  bgCircle: {
    position: 'absolute',
    borderRadius: '50%',
    background: 'rgba(63, 81, 181, 0.08)',
    animation: '$float 15s infinite ease-in-out',
  },
});

class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      snackOpen: false,
    };
  }

  componentDidMount() {
    if (localStorage.jwtToken) {
      const token = localStorage.jwtToken;
      setAuthToken(token);
      this.props.getAdminProfile();
    } 
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      if(localStorage.lastVisitedPage && (localStorage.lastVisitedUser && localStorage.lastVisitedUser === nextProps.auth.user.role)) {
        this.props.history.push(localStorage.lastVisitedPage);     
      } else {
        this.props.history.push("/dashboard");
      }
    }

    if (nextProps.auth.isAuthenticated === false) {
      this.setState({ snackOpen: true });
    }
  }

  onChange = event => {
    this.setState({ [event.target.id]: event.target.value });
  };

  onSubmit = event => {
    event.preventDefault();
    const userData = {
      username: this.state.username,
      password: this.state.password
    };
    this.props.loginUser(userData);
  };

  clickForgotPassword = e => {
    e.preventDefault();
    this.props.history.push("/forgot");
  }
  
  handleSnackClose = () => {
    this.setState({ snackOpen: false });
  }

  render() {
    const { classes } = this.props;
    let webLogo = this.props?.dashboard?.data && this.props?.dashboard?.data.length>0 && this.props?.dashboard?.data[0]?.webLogo 
      ? this.props.dashboard.data[0].webLogo 
      : "../../images/xirify_logo.png";
     
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <div className={classes.animatedBg}>
            {[...Array(5)].map((_, i) => (
              <div 
                key={i} 
                className={classes.bgCircle}
                style={{
                  width: Math.random() * 300 + 100,
                  height: Math.random() * 300 + 100,
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 100}%`,
                  animationDelay: `${Math.random() * 5}s`,
                }}
              />
            ))}
          </div>
          
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Fade in={true} timeout={500}>
              <Paper className={classes.paper} elevation={3}>
                <div className={classes.logoContainer}>
                  <img 
                    src={webLogo} 
                    alt="E-Commerce Admin" 
                    className={classes.logo}
                  />
                </div>
                <Typography  align="center" gutterBottom>
                 <b style={{ color: 'gray'}}>Life Sorted Hai!</b>
                </Typography>
    
                <form className={classes.form} noValidate onSubmit={this.onSubmit}>
                  <TextField
                    className={classes.inputField}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="username"
                    autoFocus
                    value={this.state.username}
                    onChange={this.onChange}
                  />
                  <TextField
                    className={classes.inputField}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={this.state.password}
                    onChange={this.onChange}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    size="large"
                  >
                    Sign In
                  </Button>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Link 
                        href="#" 
                        variant="body2" 
                        onClick={this.clickForgotPassword}
                        color="textSecondary"
                      >
                        Forgot password?
                      </Link>
                    </Grid>
                  </Grid>
                </form>
                
                <Box mt={5} className={classes.footer}>
                  <Typography variant="body2">
                    © {new Date().getFullYear()} Xirify. All rights reserved.
                  </Typography>
                </Box>
              </Paper>
            </Fade>
            
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={this.state.snackOpen}
              autoHideDuration={6000}
              onClose={this.handleSnackClose}
            >
              <MuiAlert 
                elevation={6} 
                variant="filled" 
                onClose={this.handleSnackClose} 
                severity="error"
              >
                {this.props.auth.loginErr}
              </MuiAlert>
            </Snackbar>
          </Container>
        </div>
      </ThemeProvider>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  dashboard: state.dashboard.getMasterOTP,
});

export default connect(
  mapStateToProps, 
  { loginUser, getAdminProfile }
)(withStyles(styles)(Login));