import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { requestBusinesses, getActiveBusinessUserReports } from '../../actions/businessActions';
import { changeHeaderName } from '../../actions/dashboardActions';
import ActiveBusinessUserTable from "./activeBusinessUserTable"
import { getBusinessTagList} from "../../actions/businessTagAction";
import { MpinGenerationModal } from "./mpinGenerationModal";
import axios from 'axios'


const styles = theme => ({
    container: {
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    content: {
        flexGrow: 1,
        height: '100%',
        overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        minWidth: 225,
        maxWidth: 300,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    heading: {
        fontSize: "14px",
        fontWeight: 500
    }
});

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
class ActiveBusinessUsersReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: -1,
            orderBy: 'requestsCount',
            page: 0,
            rowsPerPage: 10,
            businessId: "",
            businessName: "",
            firstName: "",
            lastName: "",
            email: "",
            primaryMobileNumber: "",
            role: [],
            businessFilter: "",
            businessFilterError: "",
            preparexportToExcel: false,
            businessTags:[],
            filteredBusinessTags:[],
            showGeneratedPinModal : false,
            pinGenerationBusiness:{}
        };
    }

    componentDidMount() {
        this.props.changeHeaderName("Business User Report");
        this.fetchActiveBusinessUserReports();
        this.fetchBusinessTags();

    }

    fetchActiveBusinessUserReports = async () => {
        this.props.requestBusinesses();
        let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');

        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            businessId: this.state.businessId,
            businessName: this.state.businessName,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            primaryMobileNumber: this.state.primaryMobileNumber,
            role: this.state.role,
            businessFilter: this.state.businessFilter,
            exportToexcel: 0,
            filteredBusinessTags:filteredBusinessTagsVar
        }
        await this.props.getActiveBusinessUserReports(payload);
        if (this.state.preparexportToExcel) {
            await this.setState({ preparexportToExcel: false });
        }
        this.setState({ businessFilterError: payload.businessFilter });


    }



    fetchActiveBusinessUserReportsExcelFile = async () => {
        this.props.requestBusinesses();
        let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');
        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            businessId: this.state.businessId,
            businessName: this.state.businessName,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            primaryMobileNumber: this.state.primaryMobileNumber,
            role: this.state.role,
            businessFilter: this.state.businessFilter,
            exportToexcel: 1,
            filteredBusinessTags:filteredBusinessTagsVar

        }
        await this.props.getActiveBusinessUserReports(payload);
             this.setState({ preparexportToExcel: true });
        this.setState({ businessFilterError: payload.businessFilter });


    }

    fetchBusinessTags=async()=>{
        const response=await getBusinessTagList();
        if(response){
          this.setState({businessTags:response.data})
        }else{
          this.setState({businessTags:[]})
        }
        
    }

    setOrder = (order) => {
        this.setState({ order }, () => this.fetchActiveBusinessUserReports());
    }

    setOrderBy = (orderBy) => {
        this.setState({ orderBy }, () => this.fetchActiveBusinessUserReports());
    }

    setPage = (page) => {
        this.setState({ page }, () => this.fetchActiveBusinessUserReports());
    }

    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage });
    }

    handleFilterChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    resetFilter = () => {
        this.setState({
            order: -1,
            orderBy: 'requestsCount',
            page: 0,
            rowsPerPage: 10,
            businessId: "",
            businessName: "",
            firstName: "",
            lastName: "",
            email: "",
            primaryMobileNumber: "",
            role: [],
            businessFilter: "",
            businessFilterError: "",
            filteredBusinessTags:[],
            showGeneratedPinModal: false,
            pinGenerationBusiness:{}
        }, () => {
            this.fetchActiveBusinessUserReports();
        })
    }


    enterPressed = (event) => {
        let code = event.keyCode;
        if (code === 13) {
            event.preventDefault();
            this.fetchActiveBusinessUserReports();
        }
    }




    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
 
handlePinGeneration = async (details) => {
    try {
        this.setState({  showGeneratedPinModal: true})
        const { user } = details || {};
        if (!user?._id) {
            console.error("Service Provider ID is missing.");
            return;
        }

        const { data, status } = await axios.get('/api/serviceproviders/generatempin', {
            params: { serviceProviderId: user._id }
        });

        if (status === 200) {
            this.setState({
                pinGenerationBusiness: {
                    businessId: details?.businessId ?? null,
                    businessName: details?.businessName ?? "",
                    generatedPin: data?.mpin ?? "",
                    isSuccess: true
                },
                showGeneratedPinModal: true
            });
        } else {
            this.setState({
                pinGenerationBusiness: { isSuccess: false }
            });
        }
    } catch (error) {
        console.error("Error while generating business MPIN:", error?.response?.data || error?.message);
        this.setState({
            pinGenerationBusiness: { isSuccess: false }
        });
    }
};

 

    
    handlePinModalClose=()=>{
         this.setState({ showGeneratedPinModal: false});
          this.setState({
            pinGenerationBusiness: {
               
            }
        });
    }

    render() {
        const { classes } = this.props;
        return (

            <Container className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container className={classes.container} style={{ paddingBottom: '0px' }}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Search Active Business User</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth margin="dense"
                                        name="businessId"
                                        label="Business Id"
                                        value={this.state.businessId}
                                        variant="outlined"
                                        onChange={this.handleFilterChange}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth margin="dense"
                                        name="businessName"
                                        label="Business Name"
                                        value={this.state.businessName}
                                        variant="outlined"
                                        onChange={this.handleFilterChange}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    {/* <Typography variant="subtitle2" gutterBottom> Select Business Tags</Typography> */}
                                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                        <InputLabel id="demo-mutiple-checkbox-label">Select Business Tags</InputLabel>
                                        <Select
                                            labelId="demo-mutiple-checkbox-label"
                                            id="demo-mutiple-checkbox"
                                            multiple
                                            value={this.state.filteredBusinessTags}
                                            name="filteredBusinessTags"
                                            onChange={this.handleFilterChange}
                                            input={<Input />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                            >
                                            {this.state.businessTags.map((eachBusinessTagObj) => (
                                                <MenuItem key={eachBusinessTagObj._id} value={eachBusinessTagObj.name} style={{ minHeight: "28px", height: "28px" }}>
                                                    <Checkbox checked={this.state.filteredBusinessTags.indexOf(eachBusinessTagObj.name) > -1} />
                                                    <ListItemText primary={eachBusinessTagObj.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                </Grid>

                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        onClick={this.resetFilter}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        color="primary"
                                        onClick={
                                            ()=>this.setPage(0)
                                            // this.fetchActiveBusinessUserReports
                                        }
                                    >
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>

                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Container>
                <Container className={classes.container}>
                    {
                        this.props.activeBusinessUserReport && this.props.activeBusinessUserReport.data ?
                            <ActiveBusinessUserTable
                                data={this.props.activeBusinessUserReport}
                                exportToexcel={this.props.activeBusinessUserReport.exportToexcel}
                                businessFilter={this.state.businessFilterError}
                                order={this.state.order === 1 ? 'asc' : 'desc'}
                                orderBy={this.state.orderBy}
                                page={this.state.page}
                                rowsPerPage={this.state.rowsPerPage}
                                currentStatus={this.state.currentStatus}
                                setOrder={this.setOrder}
                                setOrderBy={this.setOrderBy}
                                setPage={this.setPage}
                                setRowsPerPage={this.setRowsPerPage}
                                handleFilterChange={this.handleFilterChange}
                                history={this.props.history}
                                fetchActiveBusinessUserReportsExcelFile={this.fetchActiveBusinessUserReportsExcelFile}
                                showDownloadIcon={this.state.preparexportToExcel}
                                handlePinGeneration={this.handlePinGeneration}
                            />
                            : "No Data To Display"
                    }
                </Container>

                
                <MpinGenerationModal  open={this.state.showGeneratedPinModal}  onClose={this.handlePinModalClose}  mpin={this.state.pinGenerationBusiness?.generatedPin} isSuccess={this.state.pinGenerationBusiness.isSuccess} businessId={this.state.pinGenerationBusiness?.businessId} businessName={this.state.pinGenerationBusiness?.businessName }/>
                
                <Backdrop className={classes.backdrop} open={this.props.isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        isFetching: state.dashboard.isFetching,
        activeBusinessUserReport: state.business.activeBusinessUserReport,


    }
};

export default connect(mapStateToProps, {
    changeHeaderName,
    requestBusinesses,
    getActiveBusinessUserReports
})(withStyles(styles)(ActiveBusinessUsersReport));