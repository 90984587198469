import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { changeHeaderName } from "../../actions/dashboardActions";
import {
  requestBusinessItemTagLoader,
  requestBusinessItemTagLoaderFalse,
} from "../../actions/businessItemTagAction";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getBusinessTagList } from "../../actions/businessTagAction";
import { getConsumerTagList } from "../../actions/consumerTagAction";
import axios from "axios";
import SearchHistoryTable from './searchHistoryTable';


const getScreenConstants = () => {
  const screenConstant = [
    "DASHBOARD",
    "CATEGORY",
    "FREQUENTLY_ORDERED",
    "TOP_RATED",
    "POPULAR",
    "ASSURED",
    "NEW_STORES",
    "BRB",
    "PRE_ORDER",
    "COUPON",
    "CATALOG"
  ]

  return screenConstant
}
const SearchHistoryReport = () => {


  const dispatch = useDispatch()
  const [filters, setFilters] = useState(
    JSON.parse(localStorage.getItem("searchHistory")) || {
      consumerId: "",
      searchPhrase: "",
      isSearchSuccess: "",
      timeDateRange: "",
      page: 0,
      rowsPerPage: 10,
      screenName: "",
      startDate: "",
      endDate: ""
    }
  );
  const [records , setSearchHistoryRecords] = useState([]);
  const [totalRecords , setTotalRecords] = useState(null);
  const [isExportToExcel, setIsExportToExcel] = useState(false);
  const [exportRecords, setExportRecords] = useState([]);
  const [isFetching, setIsFetching] = useState(false)


  useEffect(() => {
    dispatch(changeHeaderName("Search History"));
    fetchSearchHistoryReport();
    
    const handleBeforeUnload = () => localStorage.removeItem("searchHistory");
    window.addEventListener("beforeunload", handleBeforeUnload);
    
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);

  const fetchSearchHistoryReport = async () => {
   
    setIsFetching(true)
    dispatch(requestBusinessItemTagLoader());
    setIsExportToExcel(false)
    try {
      const response = await axios.get("api/analysisReport/searchHistroy", {
        params: {
          skip: filters.page * filters.rowsPerPage,
          limit: filters.rowsPerPage,
          startDate: filters.startDate,
          endDate: filters.endDate,
          consumerId: filters.consumerId,
          searchPhrase: filters.searchPhrase,
          isSearchSuccess: filters.isSearchSuccess,
          screenName: filters.screenName,
        },
      });
      setSearchHistoryRecords(response?.data?.records);
      setTotalRecords(response?.data?.totalCount)
      localStorage.setItem("searchHistory", JSON.stringify(filters));
      setIsFetching(false)
    } catch (error) {
      console.error("Error fetching search history:", error);
    } finally {
      dispatch(requestBusinessItemTagLoaderFalse());
    }
  };

  const exportSearchHistoryReport = async () => {
    dispatch(requestBusinessItemTagLoader());
    setIsExportToExcel(true)
    try {
      const response = await axios.get("api/analysisReport/searchHistroy", {
        params: {
          startDate: filters.startDate,
          endDate: filters.endDate,
          consumerId: filters.consumerId,
          searchPhrase: filters.searchPhrase,
          isSearchSuccess: filters.isSearchSuccess,
          screenName: filters.screenName,
          isExportToExcel: true,
        },
      });
      setExportRecords(response?.data?.exportToExcel);
    } catch (error) {
      console.error("Error fetching search history:", error);
    } finally {
      dispatch(requestBusinessItemTagLoaderFalse());
    }
  };



  const handleFilterChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  const handleSearchedDate = (event, picker) => {
    setFilters({
      ...filters,
      startDate: picker.startDate.format(),
      endDate: picker.endDate.format(),
      timeDateRange: picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : `${picker.startDate.format("DD MMM YYYY")} - ${picker.endDate.format("DD MMM YYYY")}`,
    });
  };

  const resetFilter = async() => {
    setFilters({
      consumerId: "",
      searchPhrase: "",
      isSearchSuccess: "",
      timeDateRange: "",
      page: 0,
      rowsPerPage: 10,
      screenName: "",
      startDate: "",
      endDate: ""
      
    });
    setIsExportToExcel(false);
    await fetchSearchHistoryReport()
    
  }


  useEffect(() => {
    fetchSearchHistoryReport();
  }, [filters.page, filters.rowsPerPage]); // Re-fetch when page or rowsPerPage change
  
  const setPage = (page) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: page,
    }));
  };
  
  const setRowsPerPage = (rowsPerPage) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      rowsPerPage: rowsPerPage,
    }));
  };


  const styles = {
    container: {
      width: "100%",
      paddingTop: '6%',
      paddingBottom: '10px',
    
    
    },
    formControl: {
      minWidth: 225,
      maxWidth: 300,
    },
    filterWrapper :{
      padding : '30px'
    }
  }

  return (
    <Container className="" style={styles.container}>
     <div className={styles.filterWrapper}>
     <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className="text-sm font-medium">Search/Filter</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <FormControl margin="dense" variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-standard-label">Search Success</InputLabel>
                <Select
                  labelId="demo-simple-select-standard"
                  name="isSearchSuccess"
                  value={filters.isSearchSuccess}
                  onChange={handleFilterChange}
                  id="demo-simple-select-standard"
                  label="Refund Applied"
                >
                  <MenuItem value={'Yes'} style={{ minHeight: "28px", height: "28px" }}>
                    Yes
                  </MenuItem>
                  <MenuItem value={'No'} style={{ minHeight: "28px", height: "28px" }}>
                    No
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                margin="dense"
                name="consumerId"
                label="Consumer Id / Name"
                value={filters.consumerId}
                variant="outlined"
                onChange={handleFilterChange}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                fullWidth
                margin="dense"
                name="searchPhrase"
                label="Search Phrase"
                value={filters.searchPhrase}
                variant="outlined"
                onChange={handleFilterChange}
              />
            </Grid>
            <Grid item xs={3}>
              <DateRangePicker
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
                  "Last 7 Days": [moment().subtract(6, "days"), moment()],
                  "Last 30 Days": [moment().subtract(29, "days"), moment()],
                  "This Month": [moment().startOf("month"), moment().endOf("month")],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "month").endOf("month"),
                  ],
                }}
                onApply={handleSearchedDate}
                maxDate={moment()}
                showDropdowns
              >
                <TextField
                  fullWidth
                  margin="dense"
                  name="timeDateRange"
                  label="Searched Date"
                  value={filters.timeDateRange}
                  variant="outlined"
                />
              </DateRangePicker>
            </Grid>

            <Grid item xs={3}>
              <FormControl margin="dense" variant="outlined" fullWidth className={styles.formControl} >
                <InputLabel id="demo-simple-select-standard-label">Screen</InputLabel>
                <Select
                  labelId="demo-simple-select-standard"
                  name="screenName"
                  value={filters.screenName}
                  onChange={handleFilterChange}
                  id="demo-simple-select-standard"
                  label="Adjustments"
                >
                  {
                    getScreenConstants().map((value, index) => (
                      <MenuItem key={index} value={value} style={{ minHeight: "28px", height: "28px" }}>
                        {value}
                      </MenuItem>
                    ))
                  }


                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}></Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                margin="normal"
                onClick={resetFilter}
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                margin="normal"
                color="primary"
                onClick={fetchSearchHistoryReport}
              >
                Search
              </Button>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>

      </ExpansionPanel>
     </div>

<div style={{ margin : '10px 0px 0px 0px'}}>
  
<SearchHistoryTable  
       data={records}
       totalCount={totalRecords}
       exportToExcel={exportRecords}
       page={filters.page}
       rowsPerPage={filters.rowsPerPage}
       setPage={setPage}
       setRowsPerPage={setRowsPerPage}
       showDownloadIcon={isExportToExcel}
       fetchExportToExcel={exportSearchHistoryReport}
   
/>
</div>
      <Backdrop open={isFetching}><CircularProgress color="inherit" /></Backdrop> 
    </Container>
  );
};

export default SearchHistoryReport;