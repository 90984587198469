import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import { CSVLink } from "react-csv";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Modal from "@material-ui/core/Modal";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getOneConsumerCartData } from "../../actions/dashboardActions";
import { ReactComponent as VegSVG } from "../../images/veg.svg";
import { ReactComponent as NonvegSVG } from "../../images/Nonveg.svg";
import { ReactComponent as EggSVG } from "../../images/Egg.svg";
import { isEmpty } from "underscore";
import { ReactComponent as CLOCK } from "../../images/clock.svg";
import { ReactComponent as CHECK } from "../../images/check.svg";
import Chip from "@material-ui/core/Chip";
import CancelIcon from "@material-ui/icons/Cancel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  let headCells = [];
  if (props.fromBusiness) {
    headCells = [
      { id: "consumerId", disablePadding: true, label: "Consumer Id" },
      { id: "photo", disablePadding: true, label: "" },
      { id: "firstName", disablePadding: false, label: "First Name" },
      { id: "lastName", disablePadding: false, label: "Last Name" },

      {
        id: "primaryMobileNumber",
        disablePadding: false,
        label: "Mobile Number",
      },
      { id: "createdAt", label: "Registered Date" },
      {
        id: "lastServiceRequestDate",
        disablePadding: false,
        label: "Last Service Request Date",
      },
      {
        id: "ordersAtXirify",
        disablePadding: false,
        label: `Orders On ${props?.dashboard?.setSelectedBusiness?.businessName}`,
      },

      { id: "lastActiveTime", label: "Last Active On" },
      { id: "consumerPaymentPreferance", label: "Payment Preferance" },
      { id: "foodPreference", label: "Food Preference" },

      { id: "billPendingAmount", label: "OutStanding Bill(₹)" },
      { id: "Tags", disablePadding: false, label: "Tags" },

      { id: "status", label: "Status" },
      { id: "activeVersions", label: "Active Versions" },
      { key: "source", label: "Source" },
    ];
  } else {
    headCells = [
      { id: "consumerId", disablePadding: true, label: "Consumer Id" },
      { id: "photo", disablePadding: true, label: "" },
      { id: "firstName", disablePadding: false, label: "First Name" },
      { id: "lastName", disablePadding: false, label: "Last Name" },
      { id: "email", disablePadding: false, label: "E-mail" },
      {
        id: "primaryMobileNumber",
        disablePadding: false,
        label: "Mobile Number",
      },
      { id: "createdAt", label: "Registered Date" },
      {
        id: "lastServiceRequestDate",
        disablePadding: false,
        label: "Last Service Request Date",
      },
      {
        id: "ordersAtXirify",
        disablePadding: false,
        label: "Orders on Xirify",
      },
      { id: "societyName", disablePadding: false, label: "Society Name" },
      { id: "pinCode", disablePadding: false, label: "Pin Code" },
      { id: "lastActiveTime", label: "Last Active On" },
      { id: "consumerPaymentPreferance", label: "Payment Preferance" },
      { id: "foodPreference", label: "Food Preference" },
      { id: "cartAutoSaveData", label: "Cart Data" },
      { id: "billPendingAmount", label: "OutStanding Bill(₹)" },
      { id: "Tags", disablePadding: false, label: "Tags" },
      {
        id: "paymentPopupActivated",
        disablePadding: false,
        label: "Payment Popup Activated",
      },
      { id: "status", label: "Status" },
      { id: "activeVersions", label: "Active Versions" },
      { key: "source", label: "Source" },
    ];
  }
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.tableCellSmall}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar style={{ paddingLeft: "16px" }}>
      <Typography
        className={classes.title}
        variant="subtitle2"
        id="tableTitle"
        component="div"
      >
        Consumers ({props.total})
      </Typography>

      <Typography component="div">
        {props.showDownloadIcon ? (
          <CSVLink
            data={props.data}
            headers={props.headers}
            filename={"all_consumers.csv"}
            className="btn btn"
            target="_blank"
          >
            <Tooltip title="Export to Excel">
              <img
                alt="Export To Excel"
                src="../../images/exportToExcel.png"
                width="25"
                height="25"
              />
            </Tooltip>
          </CSVLink>
        ) : (
          <Button
            variant="contained"
            margin="normal"
            style={{
              backgroundColor: "green",
              color: "white",
              fontFamily: "bold",
            }}
            onClick={props.fetchConsumersByQueryExcelFile}
          >
            Export
          </Button>
        )}
      </Typography>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  tableCellSmall: {
    fontSize: "12px",
    padding: "6px 0px 6px 16px",
  },
  tableRow: {
    // cursor: 'pointer'
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  button: {
    // width: "100px",
    borderRadius: "30px",
    fontSize: "12px",
    height: "30px",
    marginLeft: "5px",
    marginTop: "5px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "400px",
    paddingRight: "400px",
    position: "fixed",
  },
  consumerTagsContainer: {
    display: "flex",
    width: "max-content",
    maxWidth: "380px",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing(0.5),
    borderRadius: "20px",
    backgroundColor: "#F5F5F5",
  },
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  let editStatusFlag = false;
  let editConsumerPaymentPreferanceFlag = false;
  let selectedNewStatus = "";

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalCartData, setModalCartData] = React.useState([]);
  const [consumerId, setConsumerId] = React.useState("");
  const dispatch = useDispatch();
  const response = useSelector((state) => state.dashboard.consumerCartData);

  useEffect(() => {
    if (response) {
      setModalCartData(response);
    } else {
      setModalCartData({});
    }
  }, [response]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const fetchConsumerData = (consumerId) => {
    setConsumerId(consumerId);
    let payload = { id: consumerId };
    dispatch(getOneConsumerCartData(payload));
    setModalCartData(response);
    handleOpenModal();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = props.orderBy === property && props.order === "asc";
    props.setOrder(isAsc ? -1 : 1);
    props.setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
  };
  let headers = [];
  if (props.fromBusiness) {
    headers = [
      { key: "consumerId", label: "Consumer Id" },
      { key: "firstName", label: "First Name" },
      { key: "lastName", label: "Last Name" },
      { key: "email", label: "E-mail" },
      { key: "primaryMobileNumber", label: "Mobile Number" },
      { key: "createdAt", label: "Registered Date" },
      { key: "lastServiceRequestDate", label: "Last Service Request Date" },
      { key: "addressType", label: "Address Type" },
      {
        key: "ordersAtXirify",
        label: `Orders On ${props?.dashboard?.setSelectedBusiness?.businessName}`,
      },
      { key: "societyName", label: "Society Name" },
      { key: "flatNumber", label: "House/Flat Number" },
      { key: "street", label: "Street" },
      { key: "landmark", label: "Landmark" },
      { key: "area", label: "Area" },
      { key: "city", label: "City" },
      { key: "pinCode", label: "Pin Code" },
      { key: "location.coordinates[1]", label: "Latitude" },
      { key: "location.coordinates[0]", label: "Longitude" },
      { key: "lastActiveTime", label: "Last Active On" },
      { key: "consumerPaymentPreferance", label: "Payment Preferance" },
      { key: "foodPref", label: "Food Preference" },
      { key: "paymentPopupActivated", label: "Payment Popup Activated" },
      { key: "statusBusiness", label: "Status" },
      { key: "isCartAutoSaveData", label: "Cart Data" },
      { key: "billPendingAmount", label: "OutStanding Bill(₹)" },
      { key: "businessName", label: "Business Name" },
      { key: "BusinessId", label: "Business Id" },
      { key: "businessCategory", label: "Business Category" },
      { key: "noOfItems", label: "No. of items" },
      { key: "cartValue", label: "Cart Value" },
      { key: "ConsumerTags", label: "Consumer Tags" },
      { key: "activeVersions", label: "Active Versions" },
      { key: "source", label: "Source" },
    ];
  } else {
    headers = [
      { key: "consumerId", label: "Consumer Id" },
      { key: "firstName", label: "First Name" },
      { key: "lastName", label: "Last Name" },
      { key: "email", label: "E-mail" },
      { key: "primaryMobileNumber", label: "Mobile Number" },
      { key: "createdAt", label: "Registered Date" },
      { key: "lastServiceRequestDate", label: "Last Service Request Date" },
      { key: "addressType", label: "Address Type" },
      { key: "ordersAtXirify", label: `Orders On Xirify` },
      { key: "societyName", label: "Society Name" },
      { key: "flatNumber", label: "House/Flat Number" },
      { key: "street", label: "Street" },
      { key: "landmark", label: "Landmark" },
      { key: "area", label: "Area" },
      { key: "city", label: "City" },
      { key: "pinCode", label: "Pin Code" },
      { key: "location.coordinates[1]", label: "Latitude" },
      { key: "location.coordinates[0]", label: "Longitude" },
      { key: "lastActiveTime", label: "Last Active On" },
      { key: "consumerPaymentPreferance", label: "Payment Preferance" },
      { key: "foodPref", label: "Food Preference" },
      { key: "paymentPopupActivated", label: "Payment Popup Activated" },
      { key: "status", label: "Status" },
      { key: "isCartAutoSaveData", label: "Cart Data" },
      { key: "billPendingAmount", label: "OutStanding Bill(₹)" },
      { key: "businessName", label: "Business Name" },
      { key: "BusinessId", label: "Business Id" },
      { key: "businessCategory", label: "Business Category" },
      { key: "noOfItems", label: "No. of items" },
      { key: "cartValue", label: "Cart Value" },
      { key: "ConsumerTags", label: "Consumer Tags" },
      { key: "activeVersions", label: "Active Versions" },
      { key: "source", label: "Source" },
    ];
  }
  let data = props.data.exportToExcel;
  if (props.fromBusiness) {
    data &&
      data.forEach((consumer) => {
        consumer.lastServiceRequestDate =
          consumer.lastServiceRequestDate &&
          consumer.lastServiceRequestDate !== "Invalid date"
            ? moment(consumer.lastServiceRequestDate).format(
                "DD MMM YYYY hh:mm A"
              )
            : "NA";
        consumer.createdAt =
          consumer.createdAt && consumer.createdAt !== "Invalid date"
            ? moment(consumer.createdAt).format("DD MMM YYYY hh:mm A")
            : "NA";
        consumer.lastActiveTime =
          consumer.lastActiveTime &&
          consumer.lastActiveTime !== "Invalid date" &&
          moment(consumer.lastActiveTime)
            ? moment(consumer.lastActiveTime).format("DD MMM YYYY hh:mm A")
            : "NA";

        let defaultAddress = consumer?.address?.filter((add) => {
          return add.isDefaultAddress;
        })[0];
        if (defaultAddress) {
          consumer.addressType = defaultAddress?.addressType || "NA";
          consumer.societyName = defaultAddress?.societyName || "NA";
          consumer.flatNumber = defaultAddress?.flatNumber || "NA";
          consumer.street = defaultAddress?.street || "NA";
          consumer.landmark = defaultAddress?.landmark || "NA";
          consumer.area = defaultAddress?.area || "NA";
          consumer.city = defaultAddress?.city || "NA";
          consumer.pinCode = defaultAddress?.pinCode || "NA";
          consumer.location = defaultAddress?.location || "NA";
        }
        consumer.foodPref =
          consumer?.foodPreference?.length > 0 ? consumer.foodPreference : "NA";
        if (consumer?.cartAutoSaveData?.length) {
          consumer.isCartAutoSaveData = "Yes";
          consumer.businessName =
            consumer?.cartAutoSaveData[0].businessId?.businessName;
          consumer.BusinessId =
            consumer?.cartAutoSaveData[0].businessId?.businessId;
          consumer.businessCategory =
            consumer?.cartAutoSaveData[0].businessId?.category.name;
          consumer.noOfItems = consumer?.cartAutoSaveData.length;
          consumer.cartValue = consumer?.cartValue ?? 0;
        } else {
          consumer.isCartAutoSaveData = "No";
          consumer.businessName = "NA";
          consumer.BusinessId = "NA";
          consumer.businessCategory = "NA";
          consumer.noOfItems = "NA";
          consumer.cartValue = "NA";
        }
        consumer.statusBusiness =
          consumer &&
          consumer.status !== "" &&
          consumer.status !== null &&
          consumer.status !== undefined &&
          consumer.status === "BLOCK"
            ? "BLOCKED"
            : consumer &&
              consumer.status !== "" &&
              consumer.status !== null &&
              consumer !== undefined &&
              consumer.restrictedBusinesses &&
              consumer.restrictedBusinesses.length &&
              (props?.businessDetails?._id)
                .toString()
                .includes(
                  consumer.restrictedBusinesses.map((b) => b.toString())
                ) &&
              consumer.status !== "BLOCK"
            ? "RESTRICTED"
            : "ACTIVE";
        consumer.ordersAtXirify = consumer.orderPerBusiness
          ? consumer.orderPerBusiness
          : 0;
        consumer.billPendingAmount = consumer.unpaidOrdersAmount
          ? consumer.unpaidOrdersAmount
          : 0;
        if (consumer?.consumerTagIds?.length) {
          const consumerTagNames = consumer?.consumerTagIds?.map(
            (eachConsumer) => eachConsumer.name
          );
          consumer.ConsumerTags = consumerTagNames.join();
        } else {
          consumer.ConsumerTags = "Not Assisgned";
        }
        consumer.paymentPopupActivated =
          consumer.isConsumerBlockedForOSBill || consumer.isGracePeriodOn
            ? consumer.isGracePeriodOn
              ? consumer.gracePeroidChangedOn.changedByRoleFirstName +
                " " +
                consumer.gracePeroidChangedOn.changedByRoleLastName
              : "System"
            : "NA";
        consumer.billPendingAmount = consumer.billPendingAmount
          ? consumer.billPendingAmount
          : 0;
        let version1 = consumer.androidVersion ? consumer.androidVersion : null;
        let version2 =
          consumer.iosVersion && consumer.iosVersion
            ? consumer.iosVersion
            : null;
        consumer.activeVersions =
          version1 && version2
            ? version1 + "," + version2
            : version1
            ? version1
            : version2
            ? version2
            : "NA";
      });
  } else {
    data &&
      data.forEach((consumer) => {
        consumer.lastServiceRequestDate =
          consumer.lastServiceRequestDate &&
          consumer.lastServiceRequestDate !== "Invalid date"
            ? moment(consumer.lastServiceRequestDate).format(
                "DD MMM YYYY hh:mm A"
              )
            : "NA";
        consumer.createdAt =
          consumer.createdAt && consumer.createdAt !== "Invalid date"
            ? moment(consumer.createdAt).format("DD MMM YYYY hh:mm A")
            : "NA";
        consumer.lastActiveTime =
          consumer.lastActiveTime &&
          consumer.lastActiveTime !== "Invalid date" &&
          moment(consumer.lastActiveTime)
            ? moment(consumer.lastActiveTime).format("DD MMM YYYY hh:mm A")
            : "NA";

        let defaultAddress = consumer?.address?.filter((add) => {
          return add.isDefaultAddress;
        })[0];
        if (defaultAddress) {
          consumer.addressType = defaultAddress?.addressType || "NA";
          consumer.societyName = defaultAddress?.societyName || "NA";
          consumer.flatNumber = defaultAddress?.flatNumber || "NA";
          consumer.street = defaultAddress?.street || "NA";
          consumer.landmark = defaultAddress?.landmark || "NA";
          consumer.area = defaultAddress?.area || "NA";
          consumer.city = defaultAddress?.city || "NA";
          consumer.pinCode = defaultAddress?.pinCode || "NA";
          consumer.location = defaultAddress?.location || "NA";
        }
        consumer.foodPref =
          consumer?.foodPreference?.length > 0 ? consumer.foodPreference : "NA";
        if (consumer?.cartAutoSaveData?.length) {
          consumer.isCartAutoSaveData = "Yes";
          consumer.businessName =
            consumer?.cartAutoSaveData[0].businessId?.businessName;
          consumer.BusinessId =
            consumer?.cartAutoSaveData[0].businessId?.businessId;
          consumer.businessCategory =
            consumer?.cartAutoSaveData[0].businessId?.category.name;
          consumer.noOfItems = consumer?.cartAutoSaveData.length;
          consumer.cartValue = consumer?.cartValue ?? 0;
        } else {
          consumer.isCartAutoSaveData = "No";
          consumer.businessName = "NA";
          consumer.BusinessId = "NA";
          consumer.businessCategory = "NA";
          consumer.noOfItems = "NA";
          consumer.cartValue = "NA";
        }

        if (consumer?.consumerTagIds?.length) {
          const consumerTagNames = consumer?.consumerTagIds?.map(
            (eachConsumer) => eachConsumer.name
          );
          consumer.ConsumerTags = consumerTagNames.join();
        } else {
          consumer.ConsumerTags = "Not Assisgned";
        }
        consumer.paymentPopupActivated =
          consumer.isConsumerBlockedForOSBill || consumer.isGracePeriodOn
            ? consumer.isGracePeriodOn
              ? consumer.gracePeroidChangedOn.changedByRoleFirstName +
                " " +
                consumer.gracePeroidChangedOn.changedByRoleLastName
              : "System"
            : "NA";
        consumer.billPendingAmount = consumer.billPendingAmount
          ? consumer.billPendingAmount
          : 0;
        let version1 = consumer.androidVersion ? consumer.androidVersion : null;
        let version2 =
          consumer.iosVersion && consumer.iosVersion
            ? consumer.iosVersion
            : null;
        consumer.activeVersions =
          version1 && version2
            ? version1 + "," + version2
            : version1
            ? version1
            : version2
            ? version2
            : "NA";
      });
  }

  const redirectToConsumerAddressDetails = (consumerObj, fromBusiness) => {
    props.history.push({
      pathname: `/consumers/address-details`,
      state: {
        consumerObj: consumerObj,
        fromBusiness: fromBusiness,
        businessObjId: props?.businessDetails?._id,
      },
    });
  };

  const onEditButtonClick = (data, i) => {
    editStatusFlag = true;
    props.changeEditStatusFlag(editStatusFlag, i);
  };
  const onEditButtonOfPaymentClick = (data, i) => {
    editConsumerPaymentPreferanceFlag = true;
    props.changeEditConsumerPaymentPreferanceFlag(
      editConsumerPaymentPreferanceFlag,
      i
    );
  };

  const handleFilterChange = (event, row, i) => {
    selectedNewStatus =
      event.target.value === "Active" ? "ACTIVE" : event.target.value;
    props.changeNewStatus(selectedNewStatus, row, i);
    props.updateConsumerStatus(row, selectedNewStatus);
  };
  const handleChangeConsumerPaymentPreferance = (event, row, i) => {
    selectedNewStatus = event.target.value;
    props.changeNewConsumerPaymentPreferanceStatus(selectedNewStatus, row, i);
    props.updateConsumerPaymentPreferanceStatus(row, selectedNewStatus);
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const handlePanelChange = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={classes.root}>
      <Modal
        className={classes.modal}
        open={isModalOpen}
        onClose={handleCloseModal}
      >
        {/* <div style={{ marginTop: "10px" , backgroundColor: "white"}}> */}
        <Container>
          <div>
            <Table
              style={{
                border: "none",
                backgroundColor: "white",
                height: "100%",
                width: "100%",
              }}
            >
              <TableBody>
                {/* Header Section */}
                <TableRow style={{ height: "20px" }}>
                  <TableCell
                    colSpan={2}
                    style={{
                      backgroundColor: "#5dade2",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "0",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{ color: "white", fontSize: "14px", margin: "0" }}
                    >
                      &nbsp;&nbsp; &nbsp;&nbsp; Item
                      {modalCartData?.consumerDataWithCart?.length !== 1
                        ? "s"
                        : ""}{" "}
                      in the Cart (
                      {isEmpty(modalCartData?.consumerDataWithCart)
                        ? "0"
                        : (modalCartData?.consumerDataWithCart?.length).toString()
                            .length === 1
                        ? "0" + modalCartData?.consumerDataWithCart?.length
                        : modalCartData?.consumerDataWithCart?.length}
                      )
                    </Typography>
                    <IconButton
                      onClick={handleCloseModal}
                      disableRipple
                      style={{ outline: "none" }}
                    >
                      <CloseIcon style={{ color: "white" }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <TableContainer
              component={Paper}
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                backgroundColor: "#ffffff",
              }}
            >
              <Table style={{}}>
                <TableRow>
                  <TableCell
                    style={{ backgroundColor: "#F9F9F9", border: "none" }}
                  >
                    <Typography
                      component="div"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        border: "none",
                        verticalAlign: "top",
                        width: "90%",
                        textAlign: "justify",
                        font: "normal normal normal 12px/14px Roboto",
                        fontWeight: "bold",
                        fontSize: "12px",
                        paddingLeft: "0px",
                      }}
                    >
                      <span>
                        <Typography
                          style={{
                            fontWeight: "bold",
                            align: "right",
                            fontSize: "14px",
                          }}
                        >
                          {"Business Details"}
                        </Typography>
                      </span>
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      border: "none",
                      paddingTop: "0px",
                      paddingBottom: "0px",
                      backgroundColor: "#F9F9F9",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    <div style={{ padding: "0px", wordWrap: "break-word" }}>
                      <Typography
                        component="span"
                        color="textSecondary"
                        style={{
                          font: "Roboto",
                          verticalAlign: "top",
                          fontWeight: 500,
                          fontSize: "12px",
                        }}
                      >
                        {"Name :"}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </Typography>
                      <Typography
                        component="span"
                        style={{
                          display: "inline-block",
                          width: "250px",
                          whiteSpace: "pre-wrap",
                          fontWeight: 500,
                          color: "black",
                          fontSize: "12px",
                        }}
                      >
                        {modalCartData?.busines?.businessName}
                      </Typography>
                    </div>

                    <div style={{ padding: "0px" }}>
                      {modalCartData?.busines?.businesStatus === "Open" && (
                        <Typography
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                            color: "green",
                          }}
                        >
                          {modalCartData?.busines?.businesStatus}
                        </Typography>
                      )}
                      {modalCartData?.busines?.businesStatus === "Closed" && (
                        <Typography
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          {modalCartData?.busines?.businesStatus}
                        </Typography>
                      )}
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  {/* <TableCell style={{paddingTop:'10px',paddingBottom:'6px',backgroundColor:'#F9F9F9'}}>
           <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom:'8px'}}>
                <Typography color="textSecondary" align='left' style={{font :'Roboto', fontSize: '12px' }}>
                {"Category :"}&nbsp;&nbsp;
                <Typography component="span" style={{ fontWeight: 500, color: 'black', fontSize: '12px', marginLeft: 'auto' }}>
                  {modalCartData?.busines?.category}
                </Typography>
                </Typography>
                
                <Typography color="textSecondary"  style={{ font :'Roboto', fontSize: '12px' }}>
                {"ID :"}&nbsp;&nbsp;
                <Typography component="span" style={{ fontWeight: 500, color: 'black', fontSize: '12px', marginLeft: 'auto' }}>
                  {modalCartData?.busines?.businessId}
                </Typography>
                </Typography>
              </div >
            </TableCell> */}
                  <TableCell
                    style={{
                      border: "none",
                      paddingTop: "8px",
                      backgroundColor: "#F9F9F9",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    <div style={{ padding: "0px", wordWrap: "break-word" }}>
                      <Typography
                        component="span"
                        color="textSecondary"
                        style={{
                          font: "Roboto",
                          verticalAlign: "top",
                          fontWeight: 500,
                          fontSize: "12px",
                        }}
                      >
                        {"Category :"}&nbsp;&nbsp;
                      </Typography>
                      <Typography
                        component="span"
                        style={{
                          display: "inline-block",
                          width: "250px",
                          whiteSpace: "pre-wrap",
                          fontWeight: 500,
                          color: "black",
                          fontSize: "12px",
                        }}
                      >
                        {modalCartData?.busines?.category}
                      </Typography>
                    </div>

                    <div
                      style={{
                        padding: "0px",
                        wordWrap: "break-word",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Typography
                        component="span"
                        color="textSecondary"
                        style={{
                          font: "Roboto",
                          verticalAlign: "top",
                          fontSize: "12px",
                        }}
                      >
                        {"ID :"}&nbsp;&nbsp;
                      </Typography>
                      <div style={{ width: "60px", textAlign: "right" }}>
                        <Typography
                          component="span"
                          style={{
                            whiteSpace: "pre-wrap",
                            fontWeight: 500,
                            color: "black",
                            fontSize: "12px",
                          }}
                        >
                          {modalCartData?.busines?.businessId}
                        </Typography>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: "none" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingBottom: "10px",
                      }}
                    >
                      <Typography
                        align="left"
                        style={{ fontSize: "14px", fontWeight: "bold" }}
                      >
                        {"Order Details"}
                      </Typography>
                      <Typography
                        style={{ fontSize: "12px", alignContent: "center" }}
                      >
                        <CLOCK style={{ height: "16px", width: "16px" }} />
                        &nbsp;&nbsp;
                        <Typography
                          component="span"
                          style={{
                            verticalAlign: "top",
                            fontWeight: 500,
                            color: "black",
                            fontSize: "12px",
                            marginLeft: "auto",
                          }}
                        >
                          {modalCartData?.busines?.cartAutoUpdated
                            ? moment(
                                modalCartData?.busines?.cartAutoUpdated
                              ).format("DD MMM YYYY hh:mm A")
                            : ""}
                        </Typography>
                      </Typography>
                    </div>
                    {isEmpty(modalCartData.consumerDataWithCart) && (
                      <TableCell style={{ border: "none", align: "center" }}>
                        <Typography
                          style={{
                            fontSize: "12px",
                            fontWeight: 500,
                            color: "red",
                          }}
                        >
                          The items you selected are no longer exists
                        </Typography>
                      </TableCell>
                    )}
                    {/* Item table below */}
                    <Table
                      style={{
                        borderCollapse: "separate",
                        borderSpacing: "0 8px",
                        backgroundColor: "white",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <TableBody>
                        {Array.isArray(modalCartData.consumerDataWithCart) &&
                          modalCartData.consumerDataWithCart.map(
                            (item, index) => (
                              <TableRow key={index} style={{ border: "none" }}>
                                <TableCell
                                  style={{
                                    backgroundColor: "#F9F9F9",
                                    borderRadius: "10px",
                                    padding: "1px 15px 2px 0px",

                                    borderColor: "#F9F9F9",
                                  }}
                                >
                                  {/* Main Item */}
                                  <div style={{ marginBottom: "8px" }}>
                                    <TableRow>
                                      {item.selectedComboName && (
                                        <TableCell
                                          style={{
                                            paddingTop: "0px",
                                            border: "none",
                                            verticalAlign: "top",
                                            paddingRight: "0px",
                                            paddingLeft: "0px",
                                          }}
                                        >
                                          <Typography style={{}}>
                                            {" "}
                                            {item.foodType === "NonVeg" ? (
                                              <NonvegSVG />
                                            ) : item.foodType === "Veg" ? (
                                              <VegSVG />
                                            ) : item.foodType === "Egg" ? (
                                              <EggSVG />
                                            ) : item.foodType === "NA" ? (
                                              ""
                                            ) : item.foodType === "Blank" ? (
                                              " "
                                            ) : (
                                              " "
                                            )}
                                          </Typography>
                                        </TableCell>
                                      )}

                                      {!item.selectedComboName && (
                                        <>
                                          {item.mainItemStatus ===
                                            "Available" && (
                                            <TableCell
                                              style={{
                                                border: "none",
                                                paddingTop: "0px",
                                                paddingLeft: "10px",
                                                paddingRight: "3px",
                                                paddingBottom: "2px",
                                                verticalAlign: "top",
                                              }}
                                            >
                                              <Typography>
                                                {" "}
                                                {item.foodType === "NonVeg" ? (
                                                  <NonvegSVG />
                                                ) : item.foodType === "Veg" ? (
                                                  <VegSVG />
                                                ) : item.foodType === "Egg" ? (
                                                  <EggSVG />
                                                ) : item.foodType === "NA" ? (
                                                  ""
                                                ) : item.foodType ===
                                                  "Blank" ? (
                                                  " "
                                                ) : (
                                                  " "
                                                )}
                                              </Typography>
                                            </TableCell>
                                          )}
                                          {item.mainItemStatus !==
                                            "Available" && (
                                            <TableCell
                                              style={{
                                                opacity: "50%",
                                                border: "none",
                                                paddingTop: "0px",
                                                paddingLeft: "10px",
                                                paddingRight: "3px",
                                                paddingBottom: "2px",
                                                verticalAlign: "top",
                                              }}
                                            >
                                              <Typography>
                                                {" "}
                                                {item.foodType === "NonVeg" ? (
                                                  <NonvegSVG />
                                                ) : item.foodType === "Veg" ? (
                                                  <VegSVG />
                                                ) : item.foodType === "Egg" ? (
                                                  <EggSVG />
                                                ) : item.foodType === "NA" ? (
                                                  ""
                                                ) : item.foodType ===
                                                  "Blank" ? (
                                                  " "
                                                ) : (
                                                  " "
                                                )}
                                              </Typography>
                                            </TableCell>
                                          )}

                                          <TableCell
                                            style={{
                                              border: "none",
                                              width: "400px",
                                              padding: "0px",
                                              margin: "0px",
                                              paddingLeft: "4px",
                                              paddingTop: "0px",
                                              paddingright: "0px",
                                            }}
                                          >
                                            {["Blank", "NA"].includes(
                                              item.foodType
                                            ) && (
                                              <>
                                                {[
                                                  "Available",
                                                  "Unavailable",
                                                ].includes(
                                                  item.mainItemStatus
                                                ) && (
                                                  <>
                                                    {item.mainItemStatus ===
                                                      "Available" && (
                                                      <div
                                                        style={{
                                                          width: "300px",
                                                          wordWrap:
                                                            "break-word",
                                                          paddingLeft: "15px",
                                                        }}
                                                      >
                                                        <Typography
                                                          component="span"
                                                          style={{
                                                            verticalAlign:
                                                              "top",
                                                            whiteSpace:
                                                              "pre-wrap",
                                                            font: "Roboto",
                                                            fontWeight: "bold",
                                                            fontSize: "14px",
                                                          }}
                                                        >
                                                          {item.mainItem}
                                                        </Typography>
                                                      </div>
                                                    )}

                                                    {item.mainItemStatus ===
                                                      "Unavailable" && (
                                                      <div
                                                        style={{
                                                          width: "300px",
                                                          wordWrap:
                                                            "break-word",
                                                          paddingLeft: "15px",
                                                        }}
                                                      >
                                                        <Typography
                                                          component="span"
                                                          color="textSecondary"
                                                          style={{
                                                            verticalAlign:
                                                              "top",
                                                            whiteSpace:
                                                              "pre-wrap",
                                                            font: "Roboto",
                                                            fontWeight: "bold",
                                                            fontSize: "14px",
                                                          }}
                                                        >
                                                          {item.mainItem}
                                                        </Typography>
                                                      </div>
                                                    )}

                                                    {item.approxServiceQuantity ===
                                                      "" && (
                                                      <div
                                                        style={{
                                                          width: "300px",
                                                          wordWrap:
                                                            "break-word",
                                                          paddingLeft: "15px",
                                                        }}
                                                      >
                                                        <Typography
                                                          color="textSecondary"
                                                          style={{
                                                            wordWrap:
                                                              "break-word",
                                                            fontSize: "12px",
                                                            width: "70%",
                                                            padding: "0px",
                                                          }}
                                                        >
                                                          {item.itemDescription}{" "}
                                                          &nbsp;
                                                          {"₹ " +
                                                            item.mainItemSingleItemCost}{" "}
                                                          &nbsp;X{" "}
                                                          {
                                                            item.quantityOfService
                                                          }
                                                        </Typography>
                                                      </div>
                                                    )}
                                                    {item.approxServiceQuantity !==
                                                      "" && (
                                                      <div
                                                        style={{
                                                          width: "300px",
                                                          wordWrap:
                                                            "break-word",
                                                          paddingLeft: "15px",
                                                        }}
                                                      >
                                                        <Typography
                                                          color="textSecondary"
                                                          component="span"
                                                          style={{
                                                            fontSize: "12px",
                                                            whiteSpace:
                                                              "pre-wrap",
                                                            padding: "0px",
                                                          }}
                                                        >
                                                          {
                                                            item.approxServiceQuantity
                                                          }
                                                          {/* &nbsp;X {item.quantityOfService}  */}
                                                        </Typography>
                                                      </div>
                                                    )}
                                                    {item.mainItemDiscountname && (
                                                      <div
                                                        style={{
                                                          width: "300px",
                                                          wordWrap:
                                                            "break-word",
                                                          paddingLeft: "15px",
                                                        }}
                                                      >
                                                        <Typography
                                                          color="textSecondary"
                                                          style={{
                                                            width: "80%",
                                                            whiteSpace:
                                                              "pre-wrap",
                                                            fontSize: "12px",
                                                            padding: "0px",
                                                          }}
                                                        >
                                                          {
                                                            item.mainItemDiscountname
                                                          }
                                                        </Typography>
                                                      </div>
                                                    )}
                                                  </>
                                                )}

                                                {["Disable", "Hidden"].includes(
                                                  item.mainItemStatus
                                                ) && (
                                                  <>
                                                    <div
                                                      style={{
                                                        width: "300px",
                                                        wordWrap: "break-word",
                                                        paddingLeft: "15px",
                                                      }}
                                                    >
                                                      <Typography
                                                        component="span"
                                                        color="textSecondary"
                                                        style={{
                                                          verticalAlign: "top",
                                                          whiteSpace:
                                                            "pre-wrap",
                                                          font: "Roboto",
                                                          fontWeight: "bold",
                                                          fontSize: "14px",
                                                        }}
                                                      >
                                                        {item.mainItem}
                                                      </Typography>
                                                    </div>
                                                    {item.approxServiceQuantity ===
                                                      "" && (
                                                      <div
                                                        style={{
                                                          width: "300px",
                                                          wordWrap:
                                                            "break-word",
                                                          paddingLeft: "15px",
                                                        }}
                                                      >
                                                        <Typography
                                                          color="textSecondary"
                                                          style={{
                                                            wordWrap:
                                                              "break-word",
                                                            fontSize: "12px",
                                                            width: "70%",
                                                            padding: "0px",
                                                          }}
                                                        >
                                                          {item.itemDescription}{" "}
                                                          &nbsp;
                                                          {"₹ " +
                                                            item.mainItemSingleItemCost}
                                                          &nbsp;X{" "}
                                                          {
                                                            item.quantityOfService
                                                          }
                                                        </Typography>
                                                      </div>
                                                    )}
                                                    {item.approxServiceQuantity !==
                                                      "" && (
                                                      <div
                                                        style={{
                                                          width: "300px",
                                                          wordWrap:
                                                            "break-word",
                                                          paddingLeft: "15px",
                                                        }}
                                                      >
                                                        <Typography
                                                          color="textSecondary"
                                                          component="span"
                                                          style={{
                                                            fontSize: "12px",
                                                            whiteSpace:
                                                              "pre-wrap",
                                                            padding: "0px",
                                                          }}
                                                        >
                                                          {
                                                            item.approxServiceQuantity
                                                          }
                                                          {/* &nbsp;X {item.quantityOfService}  */}
                                                        </Typography>
                                                      </div>
                                                    )}
                                                    {/* {item.mainItemDiscountname && (
                                          <div style={{ width: '300px', wordWrap: 'break-word',paddingLeft:'15px'}}>
                                          <Typography color="textSecondary" style={{ width: '80%', whiteSpace: 'pre-wrap', fontSize: '12px',paddingTop:'0px'}}>
                                          {item.mainItemDiscountname}
                                         </Typography>
                                          </div>
                                        )} */}
                                                  </>
                                                )}
                                              </>
                                            )}

                                            {!["Blank", "NA"].includes(
                                              item.foodType
                                            ) && (
                                              <>
                                                {[
                                                  "Available",
                                                  "Unavailable",
                                                ].includes(
                                                  item.mainItemStatus
                                                ) && (
                                                  <>
                                                    {item.mainItemStatus ===
                                                      "Available" && (
                                                      <div
                                                        style={{
                                                          width: "300px",
                                                          wordWrap:
                                                            "break-word",
                                                        }}
                                                      >
                                                        <Typography
                                                          style={{
                                                            padding: "0px",
                                                            verticalAlign:
                                                              "top",
                                                            whiteSpace:
                                                              "pre-wrap",
                                                            font: "Roboto",
                                                            fontWeight: "bold",
                                                            fontSize: "14px",
                                                          }}
                                                        >
                                                          {item.mainItem}
                                                        </Typography>
                                                      </div>
                                                    )}

                                                    {item.mainItemStatus ===
                                                      "Unavailable" && (
                                                      <div
                                                        style={{
                                                          width: "300px",
                                                          wordWrap:
                                                            "break-word",
                                                        }}
                                                      >
                                                        <Typography
                                                          color="textSecondary"
                                                          style={{
                                                            padding: "0px",
                                                            verticalAlign:
                                                              "top",
                                                            whiteSpace:
                                                              "pre-wrap",
                                                            font: "Roboto",
                                                            fontWeight: "bold",
                                                            fontSize: "14px",
                                                          }}
                                                        >
                                                          {item.mainItem}
                                                        </Typography>
                                                      </div>
                                                    )}
                                                    {item.approxServiceQuantity ===
                                                      "" && (
                                                      <div
                                                        style={{
                                                          width: "300px",
                                                          wordWrap:
                                                            "break-word",
                                                          marginTop: "4px",
                                                        }}
                                                      >
                                                        <Typography
                                                          color="textSecondary"
                                                          style={{
                                                            fontSize: "12px",
                                                            whiteSpace:
                                                              "pre-wrap",
                                                            padding: "0px",
                                                          }}
                                                        >
                                                          {item.itemDescription}{" "}
                                                          &nbsp;
                                                          {"₹ " +
                                                            item.mainItemSingleItemCost}
                                                          &nbsp; X{" "}
                                                          {
                                                            item.quantityOfService
                                                          }
                                                        </Typography>
                                                      </div>
                                                    )}
                                                    {item.approxServiceQuantity !==
                                                      "" && (
                                                      <div
                                                        style={{
                                                          width: "300px",
                                                          wordWrap:
                                                            "break-word",
                                                          marginTop: "4px",
                                                        }}
                                                      >
                                                        <Typography
                                                          color="textSecondary"
                                                          style={{
                                                            fontSize: "12px",
                                                            whiteSpace:
                                                              "pre-wrap",
                                                            paddingTop: "0px",
                                                          }}
                                                        >
                                                          {
                                                            item.approxServiceQuantity
                                                          }
                                                          {/* &nbsp;X {item.quantityOfService} */}
                                                        </Typography>
                                                      </div>
                                                    )}
                                                    {item.mainItemDiscountname && (
                                                      <div
                                                        style={{
                                                          width: "300px",
                                                          wordWrap:
                                                            "break-word",
                                                        }}
                                                      >
                                                        <Typography
                                                          color="textSecondary"
                                                          style={{
                                                            width: "80%",
                                                            whiteSpace:
                                                              "pre-wrap",
                                                            fontSize: "12px",
                                                            paddingTop: "3px",
                                                          }}
                                                        >
                                                          {
                                                            item.mainItemDiscountname
                                                          }
                                                        </Typography>
                                                      </div>
                                                    )}
                                                  </>
                                                )}

                                                {["Disable", "Hidden"].includes(
                                                  item.mainItemStatus
                                                ) && (
                                                  <>
                                                    <div
                                                      style={{
                                                        width: "300px",
                                                        wordWrap: "break-word",
                                                      }}
                                                    >
                                                      <Typography
                                                        color="textSecondary"
                                                        style={{
                                                          padding: "0px",
                                                          verticalAlign: "top",
                                                          whiteSpace:
                                                            "pre-wrap",
                                                          font: "Roboto",
                                                          fontWeight: "bold",
                                                          fontSize: "14px",
                                                        }}
                                                      >
                                                        {item.mainItem}
                                                      </Typography>
                                                    </div>

                                                    {item.approxServiceQuantity ===
                                                      "" && (
                                                      <div
                                                        style={{
                                                          width: "300px",
                                                          wordWrap:
                                                            "break-word",
                                                          marginTop: "4px",
                                                        }}
                                                      >
                                                        <Typography
                                                          color="textSecondary"
                                                          style={{
                                                            fontSize: "12px",
                                                            whiteSpace:
                                                              "pre-wrap",
                                                            padding: "0px",
                                                          }}
                                                        >
                                                          {item.itemDescription}{" "}
                                                          &nbsp;
                                                          {"₹ " +
                                                            item.mainItemSingleItemCost}
                                                          &nbsp;X{" "}
                                                          {
                                                            item.quantityOfService
                                                          }
                                                        </Typography>
                                                      </div>
                                                    )}
                                                    {item.approxServiceQuantity !==
                                                      "" && (
                                                      <div
                                                        style={{
                                                          width: "300px",
                                                          wordWrap:
                                                            "break-word",
                                                          marginTop: "4px",
                                                        }}
                                                      >
                                                        <Typography
                                                          color="textSecondary"
                                                          style={{
                                                            fontSize: "12px",
                                                            whiteSpace:
                                                              "pre-wrap",
                                                            paddingTop: "0px",
                                                          }}
                                                        >
                                                          {
                                                            item.approxServiceQuantity
                                                          }
                                                          {/* &nbsp;X {item.quantityOfService} */}
                                                        </Typography>
                                                      </div>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </TableCell>
                                        </>
                                      )}

                                      {item.selectedComboName !== "" && (
                                        <TableCell
                                          style={{
                                            border: "none",
                                            width: "500px",
                                            padding: "0",
                                            margin: "0",
                                          }}
                                        >
                                          <Typography
                                            style={{
                                              width: "80%",
                                              display: "inline-block",
                                              textAlign: "justify",
                                              font: "normal normal normal 12px/14px Roboto",
                                              fontWeight: "bold",
                                              fontSize: "12px",
                                              paddingLeft: "6px",
                                            }}
                                          >
                                            {item.mainItem}
                                            <Typography
                                              color="textSecondary"
                                              component="span"
                                              style={{ fontSize: "12px" }}
                                            >
                                              &nbsp;X {item.quantityOfService}
                                            </Typography>
                                          </Typography>
                                          {item.selectedComboName !== "" && (
                                            <>
                                              <Typography
                                                style={{
                                                  width: "80%",
                                                  display: "inline-block",
                                                  textAlign: "justify",
                                                  fontSize: "12px",
                                                  paddingTop: "10px",
                                                  paddingLeft: "6px",
                                                }}
                                              >
                                                {item.selectedComboName}
                                              </Typography>
                                            </>
                                          )}
                                        </TableCell>
                                      )}

                                      {item.selectedComboName === "" && (
                                        <>
                                          <TableCell
                                            style={{
                                              border: "none",
                                              verticalAlign: "top",
                                              padding: "0px",
                                              margin: "0",
                                            }}
                                          >
                                            {["Available"].includes(
                                              item.mainItemStatus
                                            ) && (
                                              <>
                                                {item.mainItemDiscountname &&
                                                  !item.approxServiceQuantity && (
                                                    <Typography
                                                      style={{
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      {/* Display cost without discount */}
                                                      <span
                                                        style={{
                                                          textDecoration:
                                                            "line-through",
                                                        }}
                                                      >
                                                        &#8377;&nbsp;
                                                        {
                                                          item.mainItemCostToDispaly
                                                        }
                                                      </span>
                                                      {/* <div style={{}}></div> */}
                                                      <Typography
                                                        component="span"
                                                        style={{
                                                          fontSize: "14px",
                                                          fontWeight: "bold",
                                                          textAlign: "right",
                                                          paddingLeft: "8px",
                                                        }}
                                                      >
                                                        {/* Display discount discounted cost */}
                                                        &#8377;&nbsp;
                                                        {
                                                          item.mainItemCostToDisplayWithDiscount
                                                        }
                                                      </Typography>
                                                    </Typography>
                                                  )}
                                                {item.mainItemDiscountname &&
                                                  item.approxServiceQuantity && (
                                                    <Typography
                                                      style={{
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      {/* Display cost without discount */}
                                                      <span style={{}}>
                                                        &nbsp;{"Added"}
                                                      </span>
                                                      {/* <div style={{}}></div> */}
                                                      <Typography
                                                        component="span"
                                                        style={{
                                                          fontSize: "14px",
                                                          fontWeight: "bold",
                                                          textAlign: "right",
                                                          paddingLeft: "8px",
                                                        }}
                                                      >
                                                        {/* Display discount discounted cost */}
                                                        &nbsp;
                                                      </Typography>
                                                    </Typography>
                                                  )}
                                                {!item.mainItemDiscountname &&
                                                  !item.approxServiceQuantity && (
                                                    <Typography
                                                      style={{
                                                        fontSize: "11px",
                                                        fontWeight: "bold",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      {/* Display cost without discount */}
                                                      <span
                                                        style={{
                                                          fontSize: "14px",
                                                          fontWeight: "bold",
                                                          textAlign: "right",
                                                          paddingLeft: "8px",
                                                        }}
                                                      >
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                      </span>
                                                      <Typography
                                                        component="span"
                                                        style={{
                                                          fontSize: "14px",
                                                          fontWeight: "bold",
                                                          textAlign: "right",
                                                          paddingLeft: "8px",
                                                        }}
                                                      >
                                                        {/* Display discount discounted cost */}
                                                        &#8377;&nbsp;
                                                        {
                                                          item.mainItemCostToDispaly
                                                        }
                                                      </Typography>
                                                    </Typography>
                                                  )}
                                                {!item.mainItemDiscountname &&
                                                  item.approxServiceQuantity && (
                                                    <Typography
                                                      style={{
                                                        fontSize: "11px",
                                                        fontWeight: "bold",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      {/* Display cost without discount */}
                                                      <span
                                                        style={{
                                                          fontSize: "14px",
                                                          fontWeight: "bold",
                                                          textAlign: "right",
                                                          paddingLeft: "8px",
                                                        }}
                                                      >
                                                        &nbsp;{"Added"}
                                                      </span>
                                                      <Typography
                                                        component="span"
                                                        style={{
                                                          fontSize: "14px",
                                                          fontWeight: "bold",
                                                          textAlign: "right",
                                                          paddingLeft: "8px",
                                                        }}
                                                      >
                                                        {/* Display discount discounted cost */}
                                                        &nbsp;
                                                      </Typography>
                                                    </Typography>
                                                  )}
                                              </>
                                            )}

                                            {["Unavailable"].includes(
                                              item.mainItemStatus
                                            ) && (
                                              <>
                                                {item.mainItemDiscountname &&
                                                  !item.approxServiceQuantity && (
                                                    <Typography
                                                      color="textSecondary"
                                                      style={{
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      {/* Display cost without discount */}
                                                      <span
                                                        style={{
                                                          textDecoration:
                                                            "line-through",
                                                        }}
                                                      >
                                                        &#8377;&nbsp;
                                                        {
                                                          item.mainItemCostToDispaly
                                                        }
                                                      </span>
                                                      <Typography
                                                        component="span"
                                                        color="textSecondary"
                                                        style={{
                                                          fontSize: "14px",
                                                          fontWeight: "bold",
                                                          textAlign: "right",
                                                          paddingLeft: "8px",
                                                        }}
                                                      >
                                                        {/* Display discount discounted cost */}
                                                        &#8377;&nbsp;
                                                        {
                                                          item.mainItemCostToDisplayWithDiscount
                                                        }
                                                        {/* {"jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj"} */}
                                                      </Typography>
                                                    </Typography>
                                                  )}
                                                {item.mainItemDiscountname &&
                                                  item.approxServiceQuantity && (
                                                    <Typography
                                                      style={{
                                                        fontSize: "14px",
                                                        fontWeight: "bold",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      {/* Display cost without discount */}
                                                      <span style={{}}>
                                                        &nbsp;{"Added"}
                                                      </span>
                                                      {/* <div style={{}}></div> */}
                                                      <Typography
                                                        component="span"
                                                        style={{
                                                          fontSize: "14px",
                                                          fontWeight: "bold",
                                                          textAlign: "right",
                                                          paddingLeft: "8px",
                                                        }}
                                                      >
                                                        {/* Display discount discounted cost */}
                                                        &nbsp;
                                                      </Typography>
                                                    </Typography>
                                                  )}
                                                {!item.mainItemDiscountname &&
                                                  !item.approxServiceQuantity && (
                                                    <Typography
                                                      style={{
                                                        fontSize: "11px",
                                                        fontWeight: "bold",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      {/* Display cost without discount */}
                                                      <span
                                                        style={{
                                                          fontSize: "14px",
                                                          fontWeight: "bold",
                                                          textAlign: "right",
                                                          paddingLeft: "8px",
                                                        }}
                                                      >
                                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                      </span>
                                                      <Typography
                                                        color="textSecondary"
                                                        component="span"
                                                        style={{
                                                          fontSize: "14px",
                                                          fontWeight: "bold",
                                                          textAlign: "right",
                                                          paddingLeft: "8px",
                                                        }}
                                                      >
                                                        {/* Display discount discounted cost */}
                                                        &#8377;&nbsp;
                                                        {
                                                          item.mainItemCostToDispaly
                                                        }
                                                      </Typography>
                                                    </Typography>
                                                  )}

                                                {!item.mainItemDiscountname &&
                                                  item.approxServiceQuantity && (
                                                    <Typography
                                                      style={{
                                                        fontSize: "11px",
                                                        fontWeight: "bold",
                                                        textAlign: "right",
                                                      }}
                                                    >
                                                      {/* Display cost without discount */}
                                                      <span
                                                        style={{
                                                          fontSize: "14px",
                                                          fontWeight: "bold",
                                                          textAlign: "right",
                                                          paddingLeft: "8px",
                                                        }}
                                                      >
                                                        &nbsp;{"Added"}
                                                      </span>
                                                      <Typography
                                                        component="span"
                                                        style={{
                                                          fontSize: "14px",
                                                          fontWeight: "bold",
                                                          textAlign: "right",
                                                          paddingLeft: "8px",
                                                        }}
                                                      >
                                                        {/* Display discount discounted cost */}
                                                        &nbsp;
                                                      </Typography>
                                                    </Typography>
                                                  )}
                                              </>
                                            )}

                                            {["Hidden", "Disable"].includes(
                                              item.mainItemStatus
                                            ) && (
                                              <>
                                                <Typography
                                                  style={{
                                                    fontSize: "11px",
                                                    fontWeight: "bold",
                                                    textAlign: "right",
                                                  }}
                                                >
                                                  {/* Display cost without discount */}
                                                  <span
                                                    style={{
                                                      fontSize: "14px",
                                                      fontWeight: "bold",
                                                      textAlign: "right",
                                                      paddingLeft: "8px",
                                                    }}
                                                  >
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                  </span>
                                                  <Typography
                                                    color="textSecondary"
                                                    component="span"
                                                    style={{
                                                      fontSize: "14px",
                                                      fontWeight: "bold",
                                                      textAlign: "right",
                                                      paddingLeft: "8px",
                                                    }}
                                                  >
                                                    {/* Display discount discounted cost */}
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    {/* {item.mainItemCostToDispaly} */}
                                                  </Typography>
                                                </Typography>
                                              </>
                                            )}

                                            {item.mainItemStatus ===
                                              "Available" && (
                                              <Typography
                                                style={{
                                                  fontWeight: "bold",
                                                  color: "green",
                                                  fontSize: "12px",
                                                  paddingTop: "0px",
                                                  textAlign: "right",
                                                }}
                                              >
                                                {item.mainItemStatus}
                                              </Typography>
                                            )}
                                            {item.mainItemStatus ===
                                              "Unavailable" && (
                                              <Typography
                                                style={{
                                                  fontWeight: "bold",
                                                  color: "red",
                                                  fontSize: "12px",
                                                  paddingTop: "0px",
                                                  textAlign: "right",
                                                }}
                                              >
                                                {item.mainItemStatus}
                                              </Typography>
                                            )}
                                            {item.mainItemStatus ===
                                              "Disable" && (
                                              <Typography
                                                style={{
                                                  color: "#FFAA00",
                                                  fontWeight: "bold",
                                                  fontSize: "12px",
                                                  paddingTop: "0px",
                                                  textAlign: "right",
                                                }}
                                              >
                                                {item.mainItemStatus}
                                              </Typography>
                                            )}
                                            {item.mainItemStatus ===
                                              "Hidden" && (
                                              <Typography
                                                style={{
                                                  color: "#76747F",
                                                  fontWeight: "bold",
                                                  fontSize: "12px",
                                                  paddingTop: "0px",
                                                  textAlign: "right",
                                                }}
                                              >
                                                {item.mainItemStatus}
                                              </Typography>
                                            )}
                                          </TableCell>
                                        </>
                                      )}

                                      {item.selectedComboName !== "" && (
                                        <>
                                          <TableCell
                                            style={{
                                              border: "none",
                                              verticalAlign: "top",
                                              padding: "0",
                                              margin: "0",
                                            }}
                                          >
                                            <Typography
                                              align="right"
                                              color="textSecondary"
                                              style={{
                                                fontWeight: "bold",
                                                align: "right",
                                                fontSize: "12px",
                                              }}
                                            >
                                              &#8377;&nbsp;
                                              {
                                                item.selectedComboTotalDiscountedPrice
                                              }
                                            </Typography>
                                            {item.selectedComboTotalPrice !==
                                              0 && (
                                              <>
                                                <Typography
                                                  align="right"
                                                  style={{
                                                    textDecoration:
                                                      "line-through",
                                                    fontSize: "11px",
                                                  }}
                                                >
                                                  &#8377;&nbsp;
                                                  {item.selectedComboTotalPrice}
                                                </Typography>
                                              </>
                                            )}
                                          </TableCell>
                                        </>
                                      )}
                                    </TableRow>
                                  </div>

                                  {item.selectedComboName !== "" && (
                                    <>
                                      <div
                                        style={{
                                          paddingBottom: "12px",
                                          paddingLeft: "14px",
                                        }}
                                      >
                                        {/* Expansion Panel for addon group items */}
                                        <ExpansionPanel
                                          style={{
                                            backgroundColor: "transparent",
                                            boxShadow: "none",
                                            border: "none",
                                          }}
                                          onChange={handlePanelChange}
                                        >
                                          <ExpansionPanelSummary
                                            aria-controls={`panel${index}a-content`}
                                            id={`panel${index}a-header`}
                                            style={{
                                              width: "35%",
                                              height: "10px",
                                              minHeight: "0px",
                                              border: "none",
                                              boxShadow: "none",
                                              margin: 0,
                                              paddingleft: "12px",
                                            }}
                                          >
                                            <Typography
                                              style={{
                                                color: "#0088cc",
                                                fontSize: "12px",
                                                padding: "0px",
                                                margin: "0px",
                                              }}
                                            >
                                              View Details
                                              <ExpandMoreIcon
                                                style={{
                                                  color: "#0088cc",
                                                  padding: "0px",
                                                  margin: "0px",
                                                  verticalAlign: "middle",
                                                  fontSize: "16px",
                                                  transform: isExpanded
                                                    ? "none"
                                                    : "rotate(270deg)",
                                                }}
                                              />
                                            </Typography>
                                          </ExpansionPanelSummary>
                                          <ExpansionPanelDetails
                                            style={{ width: "100%" }}
                                          >
                                            {/* Content of the Expansion Panel */}
                                            <TableContainer
                                              component={Paper}
                                              style={{
                                                backgroundColor: "#f9f9f9",
                                              }}
                                            >
                                              <Table>
                                                <TableBody>
                                                  {/* Combo Items */}
                                                  {item.selectedComboItems.map(
                                                    (comboItem, comboIndex) => (
                                                      <TableRow
                                                        key={comboIndex}
                                                      >
                                                        <TableCell>
                                                          <Typography
                                                            style={{
                                                              fontSize: "12px",
                                                            }}
                                                          >
                                                            {comboItem.foodType ===
                                                            "NonVeg" ? (
                                                              <NonvegSVG />
                                                            ) : comboItem.foodType ===
                                                              "Veg" ? (
                                                              <VegSVG />
                                                            ) : comboItem.foodType ===
                                                              "Egg" ? (
                                                              <EggSVG />
                                                            ) : comboItem.foodType ===
                                                              "NA" ? (
                                                              "--"
                                                            ) : comboItem.foodType ===
                                                              "Blank" ? (
                                                              " "
                                                            ) : (
                                                              " "
                                                            )}
                                                            &nbsp;&nbsp;
                                                            {
                                                              comboItem.itemName
                                                            }{" "}
                                                            X{" "}
                                                            {
                                                              item.quantityOfCombo
                                                            }
                                                          </Typography>
                                                          <Typography
                                                            color="textSecondary"
                                                            style={{
                                                              fontSize: "12px",
                                                            }}
                                                          >
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            {
                                                              comboItem.discountToDisplay
                                                            }
                                                          </Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                          <Typography
                                                            color="textSecondary"
                                                            style={{
                                                              fontWeight:
                                                                "bold",
                                                              align: "right",
                                                              fontSize: "12px",
                                                            }}
                                                          >
                                                            &#8377;&nbsp;
                                                            {
                                                              comboItem.itemDiscountedCost
                                                            }
                                                          </Typography>
                                                          {comboItem.discountToDisplay !==
                                                            "" && (
                                                            <Typography
                                                              color="textSecondary"
                                                              style={{
                                                                textDecoration:
                                                                  "line-through",
                                                                align: "right",
                                                                fontSize:
                                                                  "12px",
                                                              }}
                                                            >
                                                              &#8377;&nbsp;
                                                              {
                                                                comboItem.itemOrigialCost
                                                              }
                                                            </Typography>
                                                          )}
                                                        </TableCell>
                                                      </TableRow>
                                                    )
                                                  )}
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                      </div>
                                    </>
                                  )}
                                </TableCell>
                              </TableRow>
                            )
                          )}
                      </TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
                {!isEmpty(modalCartData.consumerDataWithCart) && (
                  <TableRow>
                    <TableCell align="center" style={{ padding: "0px" }}>
                      <CHECK />
                      <Typography style={{ fontWeight: 500, fontSize: "12px" }}>
                        {"You're all cought up"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </Table>

              {/* original table space */}
            </TableContainer>
          </div>
        </Container>
        {/* </div> */}
      </Modal>

      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          total={props.data.total}
          headers={headers}
          data={data}
          showDownloadIcon={props.showDownloadIcon}
          fetchConsumersByQueryExcelFile={props.fetchConsumersByQueryExcelFile}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={props.order}
              orderBy={props.orderBy}
              onRequestSort={handleRequestSort}
              rowCount={props.data.total}
              fromBusiness={props.fromBusiness}
              dashboard={props?.dashboard}
            />
            <TableBody>
              {stableSort(
                props.data.data,
                getComparator(props.order, props.orderBy)
              )
                // .slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage)

                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let societyName = "";
                  let pinCode = "";
                  let defaultAddress = row?.address?.filter((add) => {
                    return add.isDefaultAddress;
                  })[0];
                  if (defaultAddress) {
                    societyName = defaultAddress.societyName;
                    pinCode = defaultAddress.pinCode;
                  }
                  let selectedStatus =
                    props.selectedStatus &&
                    props.selectedStatus !== "" &&
                    props.selectedStatus !== null &&
                    props.selectedStatus !== undefined
                      ? props.selectedStatus
                      : row.status;

                  let selectedBusinessConsumerStatus =
                    row.status &&
                    row.status !== "" &&
                    row.status !== null &&
                    row.status !== undefined &&
                    row.restrictedBusinesses &&
                    row.restrictedBusinesses.length &&
                    props?.businessDetails?._id && // Ensure _id exists before calling includes
                    row.restrictedBusinesses
                      .map((b) => b)
                      .includes(props.businessDetails._id) &&
                    row.status !== "BLOCK"
                      ? "RESTRICTED"
                      : "ACTIVE";
                  console.log(
                    "selectedBusinessConsumerStatus",
                    selectedBusinessConsumerStatus
                  );
                  let selectedConsumerPaymentPreferance =
                    props.selectedConsumerPaymentPreferance &&
                    props.selectedConsumerPaymentPreferance !== "" &&
                    props.selectedConsumerPaymentPreferance !== null &&
                    props.selectedConsumerPaymentPreferance !== undefined
                      ? props.selectedConsumerPaymentPreferance
                      : row.consumerPaymentPreferance;
                  let foodp = "";
                  let versions1 =
                    row.androidVersion && row.androidVersion.version
                      ? "A" + row.androidVersion.version
                      : null;
                  let versions2 =
                    row.iosVersion && row.iosVersion.version
                      ? "I" + row.iosVersion.version
                      : null;

                  if (row.foodPreference && row.foodPreference.length > 0) {
                    row.foodPreference.forEach((food, index) => {
                      if (index === 0) {
                        foodp = food;
                      } else {
                        foodp = foodp + ", " + food;
                      }
                    });
                  }
                  row.fromBusiness = props.fromBusiness
                    ? props.fromBusiness
                    : false;
                  let billPA = row.billPendingAmount
                    ? row.billPendingAmount.toFixed(2)
                    : "NA";

                  // let statusDisable = ["UNINSTALL","LOGOUT", "DORMANT"].includes(row.status) ?true :false;
                  return (
                    <TableRow
                      hover
                      className={classes.tableRow}
                      role="checkbox"
                      tabIndex={-1}
                      key={row.serviceRequestId}

                      // onClick={()=>redirectToConsumerAddressDetails(row)}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        className={classes.tableCellSmall}
                        onClick={() => redirectToConsumerAddressDetails(row)}
                      >
                        {row.consumerId ? row.consumerId : "NA"}
                      </TableCell>
                      {props.fromBusiness ? (
                        <TableCell
                          align="left"
                          className={classes.tableCellSmall}
                          onClick={() =>
                            redirectToConsumerAddressDetails(
                              row,
                              props.fromBusiness
                            )
                          }
                        >
                          <Avatar
                            alt="Name"
                            src={row.photo}
                            className={classes.small}
                          />
                        </TableCell>
                      ) : (
                        <TableCell
                          align="left"
                          className={classes.tableCellSmall}
                          onClick={() => redirectToConsumerAddressDetails(row)}
                        >
                          <Avatar
                            alt="Name"
                            src={row.photo}
                            className={classes.small}
                          />
                        </TableCell>
                      )}
                      {/* {props.fromBusiness ? (
                        <TableCell
                          align="left"
                          className={classes.tableCellSmall}
                          onClick={() =>
                            redirectToConsumerAddressDetails(
                              row,
                              props.fromBusiness
                            )
                          }
                        >
                          <Avatar
                            alt="Name"
                            src={row.photo}
                            className={classes.small}
                          />
                        </TableCell>
                      ) : (
                        <TableCell
                          align="left"
                          className={classes.tableCellSmall}
                          onClick={() => redirectToConsumerAddressDetails(row)}
                        >
                          <Avatar
                            alt="Name"
                            src={row.photo}
                            className={classes.small}
                          />
                        </TableCell>
                      )} */}
                      {props.fromBusiness ? (
                        <TableCell
                          align="left"
                          className={classes.tableCellSmall}
                          onClick={() =>
                            redirectToConsumerAddressDetails(
                              row,
                              props.fromBusiness
                            )
                          }
                        >
                          {row.firstName ? row.firstName : "NA"}
                        </TableCell>
                      ) : (
                        <TableCell
                          align="left"
                          className={classes.tableCellSmall}
                          onClick={() => redirectToConsumerAddressDetails(row)}
                        >
                          {row.firstName ? row.firstName : "NA"}
                        </TableCell>
                      )}
                      <TableCell
                        align="left"
                        className={classes.tableCellSmall}
                        onClick={() =>
                          redirectToConsumerAddressDetails(
                            row,
                            props.fromBusiness
                          )
                        }
                      >
                        {row.lastName ? row.lastName : "NA"}
                      </TableCell>
                      {!props.fromBusiness ? (
                        <TableCell
                          align="left"
                          className={classes.tableCellSmall}
                          onClick={() => redirectToConsumerAddressDetails(row)}
                        >
                          {row.email ? row.email : "NA"}
                        </TableCell>
                      ) : null}
                      {props.fromBusiness ? (
                        <TableCell
                          align="left"
                          className={classes.tableCellSmall}
                          onClick={() =>
                            redirectToConsumerAddressDetails(
                              row,
                              props.fromBusiness
                            )
                          }
                        >
                          {row.primaryMobileNumber
                            ? row.primaryMobileNumber
                            : "NA"}
                        </TableCell>
                      ) : (
                        <TableCell
                          align="left"
                          className={classes.tableCellSmall}
                          onClick={() => redirectToConsumerAddressDetails(row)}
                        >
                          {row.primaryMobileNumber
                            ? row.primaryMobileNumber
                            : "NA"}
                        </TableCell>
                      )}
                      {props.fromBusiness ? (
                        <TableCell
                          align="left"
                          className={classes.tableCellSmall}
                          onClick={() =>
                            redirectToConsumerAddressDetails(
                              row,
                              props.fromBusiness
                            )
                          }
                        >
                          {row.createdAt
                            ? moment(row.createdAt).format(
                                "DD MMM YYYY hh:mm A"
                              )
                            : "NA"}
                        </TableCell>
                      ) : (
                        <TableCell
                          align="left"
                          className={classes.tableCellSmall}
                          onClick={() =>
                            redirectToConsumerAddressDetails(
                              row,
                              props.fromBusiness
                            )
                          }
                        >
                          {row.createdAt
                            ? moment(row.createdAt).format(
                                "DD MMM YYYY hh:mm A"
                              )
                            : "NA"}
                        </TableCell>
                      )}
                      {props.fromBusiness ? (
                        <TableCell
                          align="left"
                          className={classes.tableCellSmall}
                          onClick={() =>
                            redirectToConsumerAddressDetails(
                              row,
                              props.fromBusiness
                            )
                          }
                        >
                          {row.lastServiceRequestDate
                            ? moment(row.lastServiceRequestDate).format(
                                "DD MMM YYYY hh:mm A"
                              )
                            : "NA"}
                        </TableCell>
                      ) : (
                        <TableCell
                          align="left"
                          className={classes.tableCellSmall}
                          onClick={() => redirectToConsumerAddressDetails(row)}
                        >
                          {row.lastServiceRequestDate
                            ? moment(row.lastServiceRequestDate).format(
                                "DD MMM YYYY hh:mm A"
                              )
                            : "NA"}
                        </TableCell>
                      )}
                      {!props.fromBusiness ? (
                        <TableCell
                          align="left"
                          className={classes.tableCellSmall}
                          onClick={() =>
                            redirectToConsumerAddressDetails(
                              row,
                              props.fromBusiness
                            )
                          }
                        >
                          {row.ordersAtXirify || "NA"}
                        </TableCell>
                      ) : (
                        <TableCell
                          align="left"
                          className={classes.tableCellSmall}
                          onClick={() => redirectToConsumerAddressDetails(row)}
                        >
                          {row.orderPerBusiness || 0}
                        </TableCell>
                      )}
                      {!props.fromBusiness ? (
                        <TableCell
                          align="left"
                          className={classes.tableCellSmall}
                        >
                          {societyName ? societyName : "NA"}
                        </TableCell>
                      ) : null}
                      {!props.fromBusiness ? (
                        <TableCell
                          align="left"
                          className={classes.tableCellSmall}
                          onClick={() => redirectToConsumerAddressDetails(row)}
                        >
                          {pinCode ? pinCode : "NA"}
                        </TableCell>
                      ) : null}
                      {props.fromBusiness ? (
                        <TableCell
                          align="left"
                          className={classes.tableCellSmall}
                          onClick={() =>
                            redirectToConsumerAddressDetails(
                              row,
                              props.fromBusiness
                            )
                          }
                        >
                          {row.lastActiveTime
                            ? moment(row.lastActiveTime).format(
                                "DD MMM YYYY hh:mm A"
                              )
                            : "NA"}
                        </TableCell>
                      ) : (
                        <TableCell
                          align="left"
                          className={classes.tableCellSmall}
                          onClick={() => redirectToConsumerAddressDetails(row)}
                        >
                          {row.lastActiveTime
                            ? moment(row.lastActiveTime).format(
                                "DD MMM YYYY hh:mm A"
                              )
                            : "NA"}
                        </TableCell>
                      )}
                      {!props.fromBusiness ? (
                        props.editConsumerPaymentPreferanceFlag &&
                        props.editIndex === index ? (
                          <TableCell
                            align="left"
                            className={classes.tableCellSmall}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-end",
                              }}
                            >
                              {selectedConsumerPaymentPreferance ===
                              "NOT-ELIGIBLE" ? (
                                <FormControl
                                  fullWidth
                                  margin="dense"
                                  variant="outlined"
                                >
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectedConsumerPaymentPreferance}
                                    name="consumerPaymentPreferance"
                                    onChange={(e) =>
                                      handleChangeConsumerPaymentPreferance(
                                        e,
                                        row,
                                        index
                                      )
                                    }
                                    // disabled={selectedConsumerPaymentPreferance === "NOT-ELIGIBLE"} // Disable if "NOT-ELIGIBLE"
                                    style={{
                                      width: `${
                                        selectedConsumerPaymentPreferance.length *
                                        10
                                      }px`,
                                      fontSize: "14px", // Dynamic width based on text length
                                    }}
                                  >
                                    <MenuItem
                                      value="ACTIVE"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      ACTIVE
                                    </MenuItem>
                                    <MenuItem
                                      value="OPT-OUT"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      OPT-OUT
                                    </MenuItem>
                                    <MenuItem
                                      value="BLOCKED"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      BLOCKED
                                    </MenuItem>
                                    {/* Add the missing "NOT-ELIGIBLE" option */}
                                    <MenuItem
                                      value="NOT-ELIGIBLE"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      NOT-ELIGIBLE
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              ) : (
                                <FormControl
                                  fullWidth
                                  margin="dense"
                                  variant="outlined"
                                >
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectedConsumerPaymentPreferance}
                                    name="consumerPaymentPreferance"
                                    onChange={(e) =>
                                      handleChangeConsumerPaymentPreferance(
                                        e,
                                        row,
                                        index
                                      )
                                    }
                                    //    disabled={ props?.user?.role === "regionalHead" || props?.user?.role === "areaManager" ? true : false}
                                  >
                                    <MenuItem
                                      value="ACTIVE"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      ACTIVE
                                    </MenuItem>
                                    <MenuItem
                                      value="OPT-OUT"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      OPT-OUT
                                    </MenuItem>
                                    <MenuItem
                                      value="BLOCKED"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      BLOCKED
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              )}
                            </div>
                          </TableCell>
                        ) : (
                          <TableCell
                            align="left"
                            // disabled={statusDisable?true:false}
                            className={classes.tableCellSmall}
                            // style={{opacity:statusDisable ? "0.4":"1" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-end",
                              }}
                            >
                              {row.consumerPaymentPreferance
                                ? row.consumerPaymentPreferance
                                : "BLOCKED"}
                              {props?.user?.role === "admin" ? (
                                <EditIcon
                                  color="primary"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() => {
                                    onEditButtonOfPaymentClick(row, index);
                                  }}
                                ></EditIcon>
                              ) : (
                                " "
                              )}
                            </div>
                          </TableCell>
                        )
                      ) : props.fromBusiness ? (
                        props.editConsumerPaymentPreferanceFlag &&
                        props.editIndex === index ? (
                          <TableCell
                            align="left"
                            className={classes.tableCellSmall}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-end",
                              }}
                            >
                              {selectedConsumerPaymentPreferance ===
                                "NOT-ELIGIBLE" ||
                              selectedConsumerPaymentPreferance === "OPT-OUT" ||
                              selectedConsumerPaymentPreferance ===
                                "BLOCKED" ? (
                                <FormControl
                                  fullWidth
                                  margin="dense"
                                  variant="outlined"
                                >
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectedConsumerPaymentPreferance}
                                    name="consumerPaymentPreferance"
                                    onChange={(e) =>
                                      handleChangeConsumerPaymentPreferance(
                                        e,
                                        row,
                                        index
                                      )
                                    }
                                    // disabled={selectedConsumerPaymentPreferance === "NOT-ELIGIBLE"} // Disable if "NOT-ELIGIBLE"
                                    style={{
                                      width: `${
                                        selectedConsumerPaymentPreferance.length *
                                        10
                                      }px`,
                                      fontSize: "14px", // Dynamic width based on text length
                                    }}
                                  >
                                    <MenuItem
                                      value="ACTIVE"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      ACTIVE
                                    </MenuItem>

                                    {/* Add the missing "NOT-ELIGIBLE" option */}
                                    <MenuItem
                                      value="NOT-ELIGIBLE"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      NOT-ELIGIBLE
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              ) : (
                                <FormControl
                                  fullWidth
                                  margin="dense"
                                  variant="outlined"
                                >
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectedConsumerPaymentPreferance}
                                    name="consumerPaymentPreferance"
                                    onChange={(e) =>
                                      handleChangeConsumerPaymentPreferance(
                                        e,
                                        row,
                                        index
                                      )
                                    }
                                    //    disabled={ props?.user?.role === "regionalHead" || props?.user?.role === "areaManager" ? true : false}
                                  >
                                    <MenuItem
                                      value="NOT-ELIGIBLE"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      NOT-ELIGIBLE
                                    </MenuItem>
                                    <MenuItem
                                      value="ACTIVE"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      ACTIVE
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              )}
                            </div>
                          </TableCell>
                        ) : (
                          <TableCell
                            align="left"
                            // disabled={statusDisable?true:false}
                            className={classes.tableCellSmall}
                            // style={{opacity:statusDisable ? "0.4":"1" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-end",
                              }}
                            >
                              {row.consumerPaymentPreferance
                                ? row.consumerPaymentPreferance
                                : "BLOCKED"}
                              {/* { (
                                                <EditIcon
                                                  color="primary"
                                                  style={{ marginLeft: "10px" }}
                                                  onClick={() => {
                                                    onEditButtonOfPaymentClick(
                                                      row,
                                                      index
                                                    );
                                                  }}
                                                ></EditIcon>
                                              )  
                                              } */}
                            </div>
                          </TableCell>
                        )
                      ) : null}
                      <TableCell
                        align="left"
                        className={classes.tableCellSmall}
                        onClick={() => redirectToConsumerAddressDetails(row)}
                      >
                        {row?.foodPreference?.length > 0 ? foodp : "NA"}
                      </TableCell>
                      {!props.fromBusiness ? (
                        <TableCell
                          align="left"
                          className={classes.tableCellSmall}
                        >
                          {row.cartAutoSaveData &&
                          row.cartAutoSaveData.length > 0 ? (
                            <Button
                              variant="text"
                              size="small"
                              style={{
                                fontSize: "0.9rem",
                                backgroundColor: "transparent",
                                padding: "0px 0px",
                                minWidth: "unset",
                                color: "#5dade2",
                                fontFamily: "bold",
                                textTransform: "none",
                                border: "none",
                                outline: "none",
                              }}
                              onClick={
                                () => fetchConsumerData(row._id)
                                // handleOpenModal(() => getOneConsumerCartData(row.consumerId))
                              }
                            >
                              Yes
                            </Button>
                          ) : (
                            <span>No</span>
                          )}
                        </TableCell>
                      ) : null}
                      {!props.fromBusiness ? (
                        <TableCell
                          align="left"
                          className={classes.tableCellSmall}
                          onClick={() => redirectToConsumerAddressDetails(row)}
                        >
                          {row.billPendingAmount &&
                          row.isConsumerOSBillGenerated
                            ? billPA
                            : "NA"}
                        </TableCell>
                      ) : (
                        <TableCell
                          align="left"
                          className={classes.tableCellSmall}
                          onClick={() =>
                            redirectToConsumerAddressDetails(
                              row,
                              props.fromBusiness
                            )
                          }
                        >
                          {row.unpaidOrdersAmount}
                        </TableCell>
                      )}
                      <TableCell
                        align="left"
                        className={classes.tableCellSmall}
                      >
                        <Grid className={classes.consumerTagsContainer}>
                          {row.consumerTagIds &&
                            row.consumerTagIds.map((eachSelectedConsumerTag) =>
                              props.user &&
                              props.user?.role !== "regionalHead" ? (
                                <Chip
                                  className={classes.chip}
                                  label={eachSelectedConsumerTag.name}
                                  key={eachSelectedConsumerTag._id}
                                  variant="outlined"
                                  deleteIcon={<CancelIcon color="primary" />}
                                  onDelete={() =>
                                    props.handleDeleteConsumerTag(
                                      row._id,
                                      eachSelectedConsumerTag._id,
                                      row.consumerTagIds
                                    )
                                  }
                                />
                              ) : (
                                <Chip
                                  className={classes.chip}
                                  label={eachSelectedConsumerTag.name}
                                  key={eachSelectedConsumerTag._id}
                                  variant="outlined"
                                />
                              )
                            )}
                          {props.user &&
                          props.user?.role === "regionalHead" &&
                          (row?.consumerTagIds?.length === 0 ||
                            row?.consumerTagIds === undefined ||
                            row?.consumerTagIds === null) ? (
                            <Typography className={classes.tableCellSmall}>
                              No Tags Assigned
                            </Typography>
                          ) : null}

                          {props.user && props.user.role !== "regionalHead" ? (
                            <Button
                              variant="contained"
                              color="primary"
                              className={classes.button}
                              // startIcon={<AddIcon />}
                              onClick={() =>
                                props.handleClickAddTag(
                                  row._id,
                                  row.consumerTagIds
                                )
                              }
                            >
                              <AddIcon style={{ fontSize: "18px" }} />
                              <Typography style={{ fontSize: "12px" }}>
                                ADD TAG
                              </Typography>
                            </Button>
                          ) : null}
                        </Grid>
                      </TableCell>
                      {!props.fromBusiness ? (
                        <TableCell
                          className={classes.tableCellSmall}
                          align="left"
                        >
                          {row.isConsumerBlockedForOSBill ||
                          row.isGracePeriodOn ? (
                            <FormControlLabel
                              control={
                                <Switch
                                  size="small"
                                  key={`xirifySwitch_${row?.consumerId}_${index}`}
                                  checked={row?.isConsumerBlockedForOSBill}
                                  onChange={(e) =>
                                    props.handleXirifyPaymentPopupSwitchChange(
                                      row._id,
                                      e.target.checked
                                    )
                                  }
                                  color="primary"
                                />
                              }
                              style={{
                                fontSize: "10px",
                                color: row.isConsumerBlockedForOSBill
                                  ? "black"
                                  : "red",
                              }}
                              gutterBottom
                              label={
                                row.gracePeroidChangedOn &&
                                row.gracePeroidChangedOn.length
                                  ? row.gracePeroidChangedOn
                                      .changedByRoleFirstName +
                                    " " +
                                    row.gracePeroidChangedOn
                                      .changedByRoleLastName
                                  : "System"
                              }
                            />
                          ) : (
                            "NA"
                          )}
                          {row.isGracePeriodOn ? (
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "10px !important", opacity: 0.8 }}
                            >
                              Till {props.blockDate ? props.blockDate : null}
                            </Typography>
                          ) : null}
                        </TableCell>
                      ) : null}

                      {!props.fromBusiness ? (
                        props.editStatusFlag && props.editIndex === index ? (
                          <TableCell
                            align="left"
                            className={classes.tableCellSmall}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-end",
                              }}
                            >
                              <FormControl
                                fullWidth
                                margin="dense"
                                variant="outlined"
                              >
                                {row.status === "ACTIVE" ? (
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectedStatus}
                                    name="status"
                                    onChange={(e) =>
                                      handleFilterChange(e, row, index)
                                    }
                                    //    disabled={ props?.user?.role === "regionalHead" || props?.user?.role === "areaManager" ? true : false}
                                  >
                                    <MenuItem
                                      value="ACTIVE"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      ACTIVE
                                    </MenuItem>
                                    <MenuItem
                                      value="DELETE"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      DELETE
                                    </MenuItem>
                                    <MenuItem
                                      value="BLOCK"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      BLOCK
                                    </MenuItem>
                                    <MenuItem
                                      value="LOGOUT"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      LOGOUT
                                    </MenuItem>
                                  </Select>
                                ) : row.status === "DELETE" ? (
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectedStatus}
                                    name="status"
                                    onChange={(e) =>
                                      handleFilterChange(e, row, index)
                                    }
                                    //    disabled={ props?.user?.role === "regionalHead" || props?.user?.role === "areaManager" ? true : false}
                                  >
                                    <MenuItem
                                      value="DELETE"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      DELETE
                                    </MenuItem>
                                    <MenuItem
                                      value="ACTIVE"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      ACTIVE
                                    </MenuItem>
                                    <MenuItem
                                      value="BLOCK"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      BLOCK
                                    </MenuItem>
                                  </Select>
                                ) : row.status === "UNINSTALL" ? (
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectedStatus}
                                    name="status"
                                    onChange={(e) =>
                                      handleFilterChange(e, row, index)
                                    }
                                    //    disabled={ props?.user?.role === "regionalHead" || props?.user?.role === "areaManager" ? true : false}
                                  >
                                    <MenuItem
                                      value="UNINSTALL"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      UNINSTALL
                                    </MenuItem>
                                    <MenuItem
                                      value="DELETE"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      DELETE
                                    </MenuItem>
                                    <MenuItem
                                      value="BLOCK"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      BLOCK
                                    </MenuItem>
                                  </Select>
                                ) : row.status === "LOGOUT" ? (
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectedStatus}
                                    name="status"
                                    onChange={(e) =>
                                      handleFilterChange(e, row, index)
                                    }
                                    //    disabled={ props?.user?.role === "regionalHead" || props?.user?.role === "areaManager" ? true : false}
                                  >
                                    <MenuItem
                                      value="LOGOUT"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      LOGOUT
                                    </MenuItem>
                                    <MenuItem
                                      value="DELETE"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      DELETE
                                    </MenuItem>
                                    <MenuItem
                                      value="BLOCK"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      BLOCK
                                    </MenuItem>
                                  </Select>
                                ) : row.status === "BLOCK" ? (
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectedStatus}
                                    name="status"
                                    onChange={(e) =>
                                      handleFilterChange(e, row, index)
                                    }
                                    //    disabled={ props?.user?.role === "regionalHead" || props?.user?.role === "areaManager" ? true : false}
                                  >
                                    <MenuItem
                                      value="BLOCK"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      BLOCK
                                    </MenuItem>
                                    <MenuItem
                                      value="ACTIVE"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      ACTIVE
                                    </MenuItem>
                                    <MenuItem
                                      value="DELETE"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      DELETE
                                    </MenuItem>
                                  </Select>
                                ) : row.status === "DORMANT" ? (
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectedStatus}
                                    name="status"
                                    onChange={(e) =>
                                      handleFilterChange(e, row, index)
                                    }
                                    //    disabled={ props?.user?.role === "regionalHead" || props?.user?.role === "areaManager" ? true : false}
                                  >
                                    <MenuItem
                                      value="DORMANT"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      DORMANT
                                    </MenuItem>
                                    <MenuItem
                                      value="ACTIVE"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      ACTIVE
                                    </MenuItem>
                                    <MenuItem
                                      value="DELETE"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      DELETE
                                    </MenuItem>
                                    <MenuItem
                                      value="BLOCK"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      BLOCK
                                    </MenuItem>
                                  </Select>
                                ) : (
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectedStatus}
                                    name="status"
                                    onChange={(e) =>
                                      handleFilterChange(e, row, index)
                                    }
                                    //    disabled={ props?.user?.role === "regionalHead" || props?.user?.role === "areaManager" ? true : false}
                                  >
                                    <MenuItem
                                      value="ACTIVE"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      ACTIVE
                                    </MenuItem>
                                    <MenuItem
                                      value="DELETE"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      DELETE
                                    </MenuItem>
                                    <MenuItem
                                      value="BLOCK"
                                      style={{
                                        minHeight: "28px",
                                        height: "28px",
                                      }}
                                    >
                                      BLOCK
                                    </MenuItem>
                                  </Select>
                                )}
                              </FormControl>
                            </div>
                          </TableCell>
                        ) : (
                          <TableCell
                            align="left"
                            // disabled={statusDisable?true:false}
                            className={classes.tableCellSmall}
                            // style={{opacity:statusDisable ? "0.4":"1" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "flex-end",
                              }}
                            >
                              {row.status === "DORMANT"
                                ? "DORMANT (>90 D)"
                                : row.status
                                ? row.status
                                : "--"}
                              {props?.user?.role === "admin" ? (
                                <EditIcon
                                  color="primary"
                                  style={{ marginLeft: "10px" }}
                                  onClick={() => {
                                    onEditButtonClick(row, index);
                                  }}
                                ></EditIcon>
                              ) : (
                                " "
                              )}
                            </div>
                          </TableCell>
                        )
                      ) : props.editStatusFlag && props.editIndex === index ? (
                        <TableCell
                          align="left"
                          className={classes.tableCellSmall}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "flex-end",
                            }}
                          >
                            <FormControl
                              fullWidth
                              margin="dense"
                              variant="outlined"
                            >
                              {row.status === "ACTIVE" ||
                              row.status === "DELETE" ||
                              row.status === "DORMANT" ||
                              row.status === "UNINSTALL" ||
                              row.status === "LOGOUT" ? (
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  value={selectedBusinessConsumerStatus}
                                  name="status"
                                  onChange={(e) =>
                                    handleFilterChange(e, row, index)
                                  }
                                  //    disabled={ props?.user?.role === "regionalHead" || props?.user?.role === "areaManager" ? true : false}
                                >
                                  <MenuItem
                                    value="RESTRICTED"
                                    style={{
                                      minHeight: "28px",
                                      height: "28px",
                                    }}
                                  >
                                    RESTRICTED
                                  </MenuItem>
                                  <MenuItem
                                    value="ACTIVE"
                                    style={{
                                      minHeight: "28px",
                                      height: "28px",
                                    }}
                                  >
                                    ACTIVE
                                  </MenuItem>
                                </Select>
                              ) : null}
                            </FormControl>
                          </div>
                        </TableCell>
                      ) : (
                        <TableCell
                          align="left"
                          // disabled={statusDisable?true:false}
                          className={classes.tableCellSmall}
                          // style={{opacity:statusDisable ? "0.4":"1" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "flex-end",
                            }}
                          >
                            {row.status &&
                            row.status !== "" &&
                            row.status !== null &&
                            row.status !== undefined &&
                            row.status === "BLOCK"
                              ? "BLOCKED"
                              : row.status &&
                                row.status !== "" &&
                                row.status !== null &&
                                row.status !== undefined &&
                                row.restrictedBusinesses &&
                                row.restrictedBusinesses.length &&
                                (props?.businessDetails?._id)
                                  .toString()
                                  .includes(
                                    row.restrictedBusinesses.map((b) =>
                                      b.toString()
                                    )
                                  ) &&
                                row.status !== "BLOCK"
                              ? "RESTRICTED"
                              : "ACTIVE"}

                            {row.status !== "BLOCK" ? (
                              <EditIcon
                                color="primary"
                                style={{ marginLeft: "10px" }}
                                onClick={() => {
                                  onEditButtonClick(row, index);
                                }}
                              ></EditIcon>
                            ) : (
                              " "
                            )}
                          </div>
                        </TableCell>
                      )}
                      <TableCell
                        align="left"
                        className={classes.tableCellSmall}
                        onClick={() =>
                          redirectToConsumerAddressDetails(
                            row,
                            props.fromBusiness
                          )
                        }
                      >
                        <Typography variant="body1">
                          {!versions1 && !versions2 ? "NA" : null}
                        </Typography>
                        <Typography variant="body1">{versions1}</Typography>
                        <Typography variant="body1">{versions2}</Typography>
                      </TableCell>
                      <TableCell>

                        {row?.source?.type === "WEB" ? `Web(${row?.source?.businessName ||  "NA"})` : "App"}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          component="div"
          count={props.data.total}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
