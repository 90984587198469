import axios from "axios";
import {
  FETCH_BUSINESS_AWAITING_APPROVAL,
  FETCH_BUSINESS_APPROVED,
  FETCH_REGIONS_RECORDS,
  FETCH_USERS_RECORDS,
  FETCH_BUSINESS_APPROVED_CAMPAIGN,
  FETCH_BUSINESS_REJECTED,
  FETCH_BUSINESS_DISABLED,
  FETCH_REPORTED_BUSINESS,
  PATCH_REPORTED_SUCCESS_MESSAGE,
  PATCH_REPORTED_ERROR_MESSAGE,
  SET_REPORTED_BUSINESS_DETAILS,
  SHOW_REPORTED_BUSINESS_DETAILS,
  SET_PAN_CARD,
  UPDATE_PAN_CARD,  
  SET_AADHAR_CARD,
  UPDATE_AADHAR_CARD,
  SET_SHOP_ACT,
  SET_QR_CODE_IMAGE,
  BUSI_STATE_CHG_SUCCESS,
  BUSI_STATE_CHG_ERR,
  SET_BUSINESS_STATS,
  SET_BUSINESS_SUCCESS_MESSAGE,
  SET_BUSINESS_ERROR_MESSAGE,
  SET_TOP_PERFORMING_BUSINESSES,
  REQUEST_BUSINESSES,
  SET_BUSINESS_REPORTS,
  SET_BUSINESS_PERFORMANCE_REPORTS,
  BUSINESS_MEMBERS_DETAILS,
  BUSI_DET_UPDATE_SUCCESS,
  BUSI_DET_UPDATE_ERR,
  BUSI_PERSONAL_DET_UPDATE_SUCCESS,
  BUSI_PERSONAL_DET_UPDATE_ERR,
  SHOW_APPROVED_BUSINESS_DETAILS,
  SET_ENV,
  UPDATE_SHOPACT_ERROR_MESSAGE,
  UPDATE_SHOPACT_SUCCESS_MESSAGE,
  ADD_HEAD_ERROR,
  ADD_HEAD_SUCCESS,
  GET_HEAD_BY_BUSINESSID,
  GET_HEADANDSUBHEAD_BY_BUSINESSID,
  UPDATE_HEAD_SUBHEAD,
  ADD_SERVICE_FAILURE,
  ADD_SERVICE_SUCCESS,
  GET_ALL_SERVICES,
  GET_SUBHEAD_BY_HEAD_ID,
  UPDATE_SERVICE,
  SERVICES_BULK_UPLOAD_SUCCESS,
  SERVICES_BULK_UPLOAD_ERROR,
  GET_ACTIVEBUSINESSUSER_REPORTS,
  SEND_PUSH_NOTIFICATION,
  GET_PUSH_NOTIFICATION,
  GET_PUSH_NOTIFICATION_CONSUMERDATA,
  SET_PUSH_NOTIFICATION,
  DELETE_PUSH_NOTIFICATION,
  DELETE_SUBCATEGORY,
  FETCH_BUSINESS_EXCEL,
  GET_HEAD_WITH_SERVICES_BY_BUSINESSID,
  SERVICES_MENU_DOWNLOAD_EXCEL,
  SERVICES_MENU_DOWNLOAD_EXCEL_COMPLETED,
  PETPOOJA_MENU_DOWNLOAD_EXCEL,
  PETPOOJA_MENU_DOWNLOAD_EXCEL_COMPLETED,
  SET_ALL_BUSINESS_VIDEO_LINK_LIST_SUCCESS_MESSAGE,
  SET_ALL_BUSINESS_VIDEO_LINK_LIST_ERROR_MESSAGE,
  EDIT_BUSINESS_VIDEO_LINK_LIST_SUCCESS_MESSAGE,
  EDIT_BUSINESS_VIDEO_LINK_LIST_ERROR_MESSAGE,
  DELETE_BUSINESS_VIDEO_LINK_LIST_SUCCESS_MESSAGE,
  DELETE_BUSINESS_VIDEO_LINK_LIST_ERROR_MESSAGE,
  AADHAR_CARD_VALUE,
  PAN_CARD_VALUE,
  REQUEST_APPROVED_BUSINESS_RECORDS,
  REQUEST_APPROVED_BUSINESS_RECORDS_FALSE,
  REQUEST_BUSINESS_DETAILS_RECORDS,
  REQUEST_BUSINESS_DETAILS_RECORDS_FALSE,
  REQUEST_VENDOR_SETTLEMENTS,
  GET_ALL_VENDOR_SETTLEMENT_DATA,
  REQUEST_VENDOR_SETTLEMENTS_RESET,
  DELETE_SUCCESS_MESSAGE,
  DELETE_ERROR_MESSAGE,
  ADD_VENDOR_SETTLEMENTS,
  SET_ALL_EXOTEL_NUMBERS_ERROR_MESSAGE,
  SET_ALL_EXOTEL_NUMBERS_SUCCESS_MESSAGE,
  REMOVE_DUPLICATE_VENDOR_SETTLEMENTS,
  REMOVE_DUPLICATE_VENDOR_SETTLEMENTS_ERROR_MESSAGE,
  UNSET_LINKED_ADDONS_SERVICESEC_SUCCESS,
  UNSET_LINKED_ADDONS_SERVICESEC_ERROR,
  DELETE_ALLSERVICES_SUCCESS_MESSAGE,
  DELETE_ALLSERVICES_ERROR_MESSAGE,
  SUCCESS_MESSAGE_EMAIL_SENT,
  ERROR_MESSAGE_EMAIL_SENT,
  GET_BUSINESS_AGREEMENT_DETAILS,
  GET_BUSINESS_AGREEMENT_UPDATE_HISTORY_DETAILS_BY_BUSINESSID,
  ADD_BUSINESS_AGREEMENT,
  ADD_BUSINESS_AGREEMENT_ERROR,
  REQUEST_BUSINESS_AGREEMENT,
  REQUEST_BUSINESS_AGREEMENT_RESET,
  SHOW_CONSUMER_COUNT,
  ADD_PENDING_BUSINESS_TAGS,
  ADD_DISABLED_BUSINESS_TAGS,
  ADD_APPROVED_BUSINESS_TAGS
} from "./types";

export const clearMsg = () => async (dispatch) => {
  let data = {};
  data.msg = null;
  data.ops = null;
  dispatch({ type: SET_BUSINESS_SUCCESS_MESSAGE, payload: data });
  dispatch({ type: SET_BUSINESS_ERROR_MESSAGE, payload: data });
  dispatch({ type: SERVICES_BULK_UPLOAD_SUCCESS, payload: data });
  dispatch({ type: SERVICES_BULK_UPLOAD_ERROR, payload: data });
  dispatch({ type: DELETE_SUBCATEGORY, payload: data.msg });
  dispatch({ type: SET_ALL_BUSINESS_VIDEO_LINK_LIST_SUCCESS_MESSAGE, payload: data });
  dispatch({ type: SET_ALL_BUSINESS_VIDEO_LINK_LIST_ERROR_MESSAGE, payload: data });
  dispatch({ type: EDIT_BUSINESS_VIDEO_LINK_LIST_SUCCESS_MESSAGE, payload: data });
  dispatch({ type: EDIT_BUSINESS_VIDEO_LINK_LIST_ERROR_MESSAGE, payload: data });
  dispatch({ type: DELETE_BUSINESS_VIDEO_LINK_LIST_SUCCESS_MESSAGE, payload: data });
  dispatch({ type: DELETE_BUSINESS_VIDEO_LINK_LIST_ERROR_MESSAGE, payload: data });
  dispatch({ type: DELETE_SUCCESS_MESSAGE, payload: null });
  dispatch({ type: DELETE_ERROR_MESSAGE, payload: null });
  dispatch({ type: ADD_VENDOR_SETTLEMENTS, payload: null });
  dispatch({ type: REMOVE_DUPLICATE_VENDOR_SETTLEMENTS, payload: null });
  dispatch({ type: REMOVE_DUPLICATE_VENDOR_SETTLEMENTS_ERROR_MESSAGE, payload: null });
  dispatch({ type: UNSET_LINKED_ADDONS_SERVICESEC_SUCCESS, payload: data });
  dispatch({ type: UNSET_LINKED_ADDONS_SERVICESEC_ERROR, payload: data });
  dispatch({ type: SUCCESS_MESSAGE_EMAIL_SENT, payload: null });
  dispatch({ type: ERROR_MESSAGE_EMAIL_SENT, payload: null });
  dispatch({ type: SEND_PUSH_NOTIFICATION, payload: data });
  dispatch({ type: ADD_BUSINESS_AGREEMENT, payload: null });
  dispatch({ type: ADD_BUSINESS_AGREEMENT_ERROR, payload: null });
};

export const requestBusinesses = () => {
  return {
    type: REQUEST_BUSINESSES,
    payload: true,
  };
};

export const isFetching = () => {
  return {
    type: REQUEST_BUSINESS_AGREEMENT,
    payload: true,
  };
};

export const isFetchingReset = () => {
  return {
    type: REQUEST_BUSINESS_AGREEMENT_RESET,
    payload: false,
  };
};

export const requestVendorSettlement = () => {
  return {
    type: REQUEST_VENDOR_SETTLEMENTS,
    payload: true,
  };
};

export const requestVendorSettlementReset = () => {
  return {
    type: REQUEST_VENDOR_SETTLEMENTS_RESET,
    payload: false,
  };
};

export const requestApprovedBusinessRecords = () => {
	return {
		type: REQUEST_APPROVED_BUSINESS_RECORDS,
		payload: true
	};
};

export const requestApprovedBusinessRecordsFalse = () => {
	return {
		type: REQUEST_APPROVED_BUSINESS_RECORDS_FALSE,
		payload: false
	};
};
export const requestBusinessDetailsRecords = () => {
	return {
		type: REQUEST_BUSINESS_DETAILS_RECORDS,
		payload: true
	};
};

export const requestBusinessDetailsRecordsFalse = () => {
	return {
		type: REQUEST_BUSINESS_DETAILS_RECORDS_FALSE,
		payload: false
	};
};

export const getBusinessAgreementDetails = (payload) => async (dispatch) => {
  try {
    // console.log("=== inside getBusinessAgreementDetails action ===");
    // console.log(payload);

    let response = await axios.get(`/api/businessAgreement/${payload.businessId}`);
    // console.log("Response from getBusinessAgreementDetails ===> ", response);

    dispatch({ type: GET_BUSINESS_AGREEMENT_DETAILS, payload: response.data });
    

    if(response && response !== undefined && response !== null && response.data && response.data !== undefined && response.data !== null && response.data.length > 0) {
      console.log("Get History API to be called");
      let responseHist = await axios.get(`/api/businessAgreement/updateHistory/${payload.businessId}`);
      // console.log("Response from updateHistory ===> ", responseHist);

      dispatch({ type: GET_BUSINESS_AGREEMENT_UPDATE_HISTORY_DETAILS_BY_BUSINESSID, payload: responseHist.data });
    } else {
      console.log("No need to call Get History API");
    }

    
    

    
  } catch (error) {
    console.log("Error while getting business agreement details : ", error);
  }
};

export const createBusinessAgreement = (payload) => async (dispatch) => {
  try {
    
    await axios.post(`/api/businessAgreement`, payload); 

    dispatch({ type: ADD_BUSINESS_AGREEMENT, payload: "Business Agreement Created Successfully"});
  } catch (error) {
    dispatch({ type: ADD_BUSINESS_AGREEMENT_ERROR, payload: "Error while creating Business Agreement"});
  }
};

export const editBusinessAgreement = (payload) => async (dispatch) => {
  try {   
    await axios.patch(`/api/businessAgreement/edit/${payload.businessId}`, payload);   

    dispatch({ type: ADD_BUSINESS_AGREEMENT, payload: "Business Agreement Updated Successfully"});

  } catch (error) {
    dispatch({ type: ADD_BUSINESS_AGREEMENT_ERROR, payload: "Error while updating Business Agreement"});
  }
};

export const getVendorSettlementData = (payload) => async (dispatch) => {
  try {
    
    let response = await axios.get(`/api/business/getVendorSplitSettlementDataByBusiness/${payload.businessObjId}`, {params: payload});
    // console.log("==== response of get data====",response);
    dispatch({ type: GET_ALL_VENDOR_SETTLEMENT_DATA, payload: response.data });
  } catch (err) {
    console.log("Error while adding : ", err);
  }
};

export const deleteVendorSettlement = (payload) => async (dispatch) => {
  try {
    // console.log("------ payload in action of delete-----",payload);

    let response = await axios.delete(`/api/business/deleteVendorSplitSettlementsBySettlementId/${payload.businessId}/${payload.settlementId}`);
    // console.log("==== response of delete data====",response);
    dispatch({ type: DELETE_SUCCESS_MESSAGE, payload: response.data.message});
  } catch (err) {
    console.log("Error while deleting : ", err);
    dispatch({ type: DELETE_ERROR_MESSAGE, payload: err.response.data.error})
  }
};

export const addVendorSettlements = (payload) => async (dispatch) => {
  try {
    await axios.post(`/api/business/vendorsplitsettlements/${payload.businessNumber}`,payload);
   
    dispatch({ type: ADD_VENDOR_SETTLEMENTS, payload: "Vendor Settlement Data added successfully"});
  } catch (err) {
    console.log("Error while adding : ", err);
    dispatch({ type: DELETE_ERROR_MESSAGE, payload: err.response.data.error})
  }
};

export const updateVendorSettlements = (payload) => async (dispatch) => {
  try {
    // console.log("------ payload in action of edit-----",payload);

    await axios.patch(`/api/business/vendorSplitSettlementsEditBySettlementId/${payload.businessNumber}/${payload.settlementNumber}`,payload.data);
    // console.log("==== response of edit vendor settlement data====",response);
    dispatch({ type: ADD_VENDOR_SETTLEMENTS, payload: "Vendor Settlement Data updated successfully"});
  } catch (err) {
    console.log("Error while updating : ", err);
    dispatch({ type: DELETE_ERROR_MESSAGE, payload: err.response.data.error})
  }
};

export const removeDuplicateVendorSplitSettlementData = (payload) => async (dispatch) => {
  try {
    // console.log("------ payload in action of remove duplicate vendor settlement entries-----",payload);

    let response = await axios.request(`/api/script/removeDuplicateVendorSettlementData`, { params: payload });
    // console.log("==== response of remove duplicate vendor settlement entries====",response);
    dispatch({ type: REMOVE_DUPLICATE_VENDOR_SETTLEMENTS, payload: response.data.message});
  } catch (err) {
    console.log("Error while removing duplicates : ", err);
    dispatch({ type: REMOVE_DUPLICATE_VENDOR_SETTLEMENTS_ERROR_MESSAGE, payload: err.response.data.error})
  }
};

export const emailReportNotificationCall = (payload) => async (dispatch) => {
  try {
    // console.log("------ payload in action of emailReportNotificationCall-----",payload);
    let response = await axios.get(`/api/notifications/emailReportNotificationCall/${payload.businessObjId}`, { params: payload });
    // console.log("==== response of emailReportNotificationCall====",response);
    dispatch({ type: SUCCESS_MESSAGE_EMAIL_SENT, payload: response.data.message});
  } catch (err) {
    console.log("Error while sending email notification to busines..  ", err);
    dispatch({ type: ERROR_MESSAGE_EMAIL_SENT, payload: err.response.data.error });
  }
};

// Login - get user token
export const getBusinessListAwaitingApproval =
  (payload) => async (dispatch) => {
    const response = await axios.get(
      "/api/business/businessstatus/awaitingapproval",
      {
        params: 
         payload
    
      }
    );
    dispatch({
      type: FETCH_BUSINESS_AWAITING_APPROVAL,
      payload: response.data,
    });
  };

// Search Approved Business given the search tag
export const searchAwaitingApproval = (request) => async (dispatch) => {
  const response = await axios.get(
    "/api/business/adminsearch?searchtags=" +
    request.searchtags +
    "&status=" +
    request.status,
    {
      params: {
        limit: request.limit,
        skip: request.skip,
      },
    }
  );
  dispatch({ type: FETCH_BUSINESS_AWAITING_APPROVAL, payload: response.data });
};

// Get the list of Approved Business
export const getBusinessListApproved = (payload) => async (dispatch) => {
  const response = await axios.get("/api/business/searchbyfilter", {
    params: payload
  });
  dispatch({ type: FETCH_BUSINESS_APPROVED, payload: response.data });
  const envData = await axios.get(`/api/xirify/env`);
  dispatch({ type: SET_ENV, payload: envData.data });
};
// get all regions anf users records
export const getRegionsRecords = (payload) => async (dispatch) => {
  const response = await axios.get("/api/business/getRegionsRecords", {
    params: payload
  });
  dispatch({ type: FETCH_REGIONS_RECORDS, payload: response.data });
  const envData = await axios.get(`/api/xirify/env`);
  dispatch({ type: SET_ENV, payload: envData.data });
};
export const getUsersRecords = (payload) => async (dispatch) => {
  // console.log("in actions,,,,",payload)
  const response = await axios.get("/api/business/getAccountManagerUsers", {
    params: payload,
  });
  dispatch({ type: FETCH_USERS_RECORDS, payload: response.data });
  const envData = await axios.get(`/api/xirify/env`);
  dispatch({ type: SET_ENV, payload: envData.data });
};

// Get the list of Approved Business for campaigns
export const getBusinessListApprovedForCampaigns = (payload) => async (dispatch) => {
  const response = await axios.get("/api/business/getApprovedBusinesses", {
    params: payload
  });
  dispatch({ type: FETCH_BUSINESS_APPROVED_CAMPAIGN, payload: response.data });
  const envData = await axios.get(`/api/xirify/env`);
  dispatch({ type: SET_ENV, payload: envData.data });
};

// Search Approved Business given the search tag
export const searchApprovedBusiness = (request) => async (dispatch) => {
  const response = await axios.get(
    "/api/business/searchbyfilter",
    {
      params: request
    }
  );
  dispatch({ type: FETCH_BUSINESS_APPROVED, payload: response.data });
};

// Get the list of Disabled Business
export const getBusinessListDisabled = (skip, limit) => async (dispatch) => {
  const response = await axios.get("/api/business/businessstatus/disabled", {
    params: {
      limit: limit,
      skip: skip,
    },
  });
  dispatch({ type: FETCH_BUSINESS_DISABLED, payload: response.data });
};

// Search Disabled Business given the search tag
export const searchDisabledBusiness = (request) => async (dispatch) => {
  const response = await axios.get("/api/business/searchbyfilter",
    {
      params: request
    }
  );
  dispatch({ type: FETCH_BUSINESS_DISABLED, payload: response.data });
};

// Get the list of Rejected Business
export const getBusinessListRejected = (skip, limit) => async (dispatch) => {
  const response = await axios.get("/api/business/businessstatus/rejected", {
    params: {
      limit: limit,
      skip: skip,
    },
  });
  dispatch({ type: FETCH_BUSINESS_REJECTED, payload: response.data });
};

// Search Rejected Business given the search tag
export const searchRejectedBusiness = (request) => async (dispatch) => {
  const response = await axios.get(
    "/api/business/adminsearch?searchtags=" +
    request.searchtags +
    "&status=" +
    request.status,
    {
      params: {
        limit: request.limit,
        skip: request.skip,
      },
    }
  );
  dispatch({ type: FETCH_BUSINESS_REJECTED, payload: response.data });
};

export const clearBusiSucessMsg = () => {
  return {
    type: BUSI_STATE_CHG_SUCCESS,
    payload: null,
  };
};

export const clearBusiErrorMsg = () => {
  return {
    type: BUSI_STATE_CHG_ERR,
    payload: null,
  };
};

export const clearAadharCardMsg = () => {
  return {
    type: AADHAR_CARD_VALUE,
    payload: null,
  };
};

export const clearPanCardMsg = () => {
  return {
    type: PAN_CARD_VALUE,
    payload: null,
  };
};


/* APPROVE a PENDING BUSINESS(STATE --> AWAITING_APPROVAL BUSINESS TO APPROVED)*/
export const approveBusiness =
  (businessId, businessName, contractId, businessStatus) => async (dispatch) => {
    try {
      let query = {
        businessStatus: businessStatus &&  businessStatus === "DISABLED" ? "DISABLED" : "APPROVED",
      };

      if (contractId && contractId !== null && contractId !== undefined) {
        query.contractId = contractId;
      }

      console.log("query before calling patch call ====>", query);
      let response = await axios.patch(
        "/api/business/" + businessId + "/businessstatus",
        query
      );
      console.log("response---->",response)
      if(response.data.message !== 'Changed Business status successfully'){
        console.log("if condition")
        dispatch({
          type: BUSI_STATE_CHG_SUCCESS,
          payload: "New contract added successfully.", //commented LOC as per new functionality of business agreement. This code remains to be commented only. Not to uncommented in future or ever till requested to revert changes
          // payload: "New Business Agreement added successfully.",
        });
      }
      else{
        console.log("else condition")
        dispatch({
          type: BUSI_STATE_CHG_SUCCESS,
          payload: businessName + " approved successfully.",
        });
      }
      
    } catch (err) {
      console.log("FAILURE approveBusiness", err.response);
      dispatch({
        type: BUSI_STATE_CHG_ERR,
        payload: businessName + " failed to approve",
      });
    }
  };

/* REJECT a PENDING BUSINESS (STATE --> AWAITING_APPROVAL BUSINESS TO REJECTED) */
export const rejectBusiness =
  (businessId, businessName) => async (dispatch) => {
    try {
      await axios.patch("/api/business/" + businessId + "/businessstatus", {
        businessStatus: "REJECTED",
      });
      dispatch({
        type: BUSI_STATE_CHG_SUCCESS,
        payload: businessName + " rejected successfully.",
      });
    } catch (err) {
      console.log("FAILURE rejectedBusiness", err.response);
      dispatch({
        type: BUSI_STATE_CHG_ERR,
        payload: businessName + " failed to reject",
      });
    }
  };

/* DISABLE a APPROVED BUSINESS (STATE --> APRROVED TO DISABLED) */
export const disableBusiness =
  (businessId, businessName) => async (dispatch) => {
    try {
      await axios.patch("/api/business/" + businessId + "/businessstatus", {
        businessStatus: "DISABLED",
      });
      dispatch({
        type: BUSI_STATE_CHG_SUCCESS,
        payload: businessName + " disabled successfully.",
      });
    } catch (err) {
      console.log("FAILURE disableBusiness", err.response);
      dispatch({
        type: BUSI_STATE_CHG_ERR,
        payload: businessName + " failed to disable",
      });
    }
  };

/*
 * Get the reported "Action Taken" business
 */
export const getRptAtBusiness = (pagi) => async (dispatch) => {
  let response;

  if (pagi.businessName) {
    response = await axios.get("/api/reportedbusiness", {
      params: {
        isactiontaken: true,
        businessName: pagi.businessName,
        limit: pagi.limit,
        skip: pagi.skip,
      },
    });

    response.data.pagi = pagi;
  } else {
    response = await axios.get("/api/reportedbusiness", {
      params: {
        isactiontaken: true,
        limit: pagi.limit,
        skip: pagi.skip,
      },
    });
    response.data.pagi = pagi;
  }

  dispatch({ type: FETCH_REPORTED_BUSINESS, payload: response.data });
};

/*
 * Get the reported "Action Pending" business
 */
export const getRptApBusiness = (pagi) => async (dispatch) => {
  let response;

  if (pagi.businessName) {
    response = await axios.get("/api/reportedbusiness", {
      params: {
        isactiontaken: false,
        businessName: pagi.businessName,
        limit: pagi.limit,
        skip: pagi.skip,
      },
    });

    response.data.pagi = pagi;
  } else {
    response = await axios.get("/api/reportedbusiness", {
      params: {
        isactiontaken: false,
        limit: pagi.limit,
        skip: pagi.skip,
      },
    });
    response.data.pagi = pagi;
  }

  dispatch({ type: FETCH_REPORTED_BUSINESS, payload: response.data });
};

export const resolveReportedBusiness = (request) => async (dispatch) => {
  try {
    let actionComment = null;
    if (request.actionComment) {
      actionComment = request.actionComment;
    }
    await axios.patch(
      "/api/reportedbusiness/" + request.id + "/close",
      actionComment
    );
    dispatch({
      type: PATCH_REPORTED_SUCCESS_MESSAGE,
      payload: "RESOLVED_REPORT",
    });
  } catch (err) {
    dispatch({
      type: PATCH_REPORTED_ERROR_MESSAGE,
      payload: "RESOLVED_REPORT_ERROR",
    });
  }
};

export const addActionComment = (request) => async (dispatch) => {
  try {
    await axios.post(
      "/api/reportedbusiness/" + request.id + "/actioncomment",
      request.actionComment
    );
    dispatch({
      type: PATCH_REPORTED_SUCCESS_MESSAGE,
      payload: "COMMENT_ADDED",
    });
  } catch (err) {
    dispatch({
      type: PATCH_REPORTED_ERROR_MESSAGE,
      payload: "COMMENT_ADDED_ERROR",
    });
  }
};

export const clearSucessMsg = () => {
  return {
    type: PATCH_REPORTED_SUCCESS_MESSAGE,
    payload: null,
  };
};

export const clearErrorMsg = () => {
  return {
    type: PATCH_REPORTED_ERROR_MESSAGE,
    payload: null,
  };
};

export const getReportedBusinessDetails = (id) => async (dispatch) => {
  try {
    const response = await axios.get("/api/reportedbusiness/" + id);
    dispatch({
      type: SET_REPORTED_BUSINESS_DETAILS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({ type: PATCH_REPORTED_ERROR_MESSAGE, payload: null });
  }
};


export const getShopActServPro = (payload) => async (dispatch) => {
  let response;
  try {
    response = await axios.get(
      "/api/serviceproviders/" +
      payload.serviceProviderId +
      "/document/shopact",
      {
        params: {
          business: payload.businessId,
        },
        responseType: "blob",
      }
    );

    dispatch({ type: SET_SHOP_ACT, payload: response.data });
  } catch (err) {
    console.log("Error while fetching shop act - ", err);
    dispatch({ type: SET_SHOP_ACT, payload: null });
  }
};

export const getPanServPro = (id) => async (dispatch) => {
  let response;
  try {   
    response = await axios.get(
      "/api/serviceproviders/" + id + "/document/pan",
      {responseType: "blob"} 
    );
    dispatch({ type: SET_PAN_CARD, payload: response.data });
  } catch (err) {
    console.log("getPanServiceProvider error", err);
    dispatch({ type: SET_PAN_CARD, payload: null });
  }
};

export const getAadharServPro = (id) => async (dispatch) => {
  let response;
  try {  
    response = await axios.get(
      "/api/serviceproviders/" + id + "/document/aadhar",
       {responseType: "blob" }
    );
    dispatch({ type: SET_AADHAR_CARD, payload: response.data });
  } catch (err) {
    console.log("getAadharServPro error", err);  
    dispatch({ type: SET_AADHAR_CARD, payload: null });
  }
};
export const uploadShopActImage = (payload) => async (dispatch) => {
  try {

    const formData = new FormData();
    formData.append("file", payload.file);
    await axios.put(
      `api/serviceproviders/${payload.serviceProviderId}/document/${payload.type}`,
      formData,
      {
        params: {
          filename: payload.fileName,
        },
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    dispatch({
      type: UPDATE_SHOPACT_SUCCESS_MESSAGE,
      payload: "ShopAct uploaded successfully.",
    });
  } catch (err) {
    dispatch({
      type: UPDATE_SHOPACT_ERROR_MESSAGE,
      payload: "Error while uploading ShopAct image.",
    });
  }
};

export const updatePanCard = (payload) => async (dispatch) => {
  
  try {
    // console.log("required payload for update pancard---------",payload);
    const formData = new FormData();
    formData.append("file", payload.file);
    await axios.put(
      `/api/serviceproviders/${payload.serviceProviderId}/document/pan`,formData,
      { 
        params: {
          filename: payload.fileName,
        },
        headers: {
          "content-type": "multipart/form-data",
        },
        }
    );
    dispatch({ type: UPDATE_PAN_CARD, payload: "PANCard updated successfully" });
  } catch (err) {
    console.log("update PANCard error", err);
  }
};

export const updateAadharCard = (payload) => async (dispatch) => {
  
  try {
    // console.log("required payload for update aadharcard---------",payload);

    const formData = new FormData();
    formData.append("file", payload.file);
    await axios.put(
      `/api/serviceproviders/${payload.serviceProviderId}/document/aadhar`,formData,
      {  params: {
        filename: payload.fileName,
      },    
    headers: {
      "content-type": "multipart/form-data",
    } }     
    );
    dispatch({ type: UPDATE_AADHAR_CARD, payload: "AADHARCard updated successfully" });
  } catch (err) {
    console.log("update AadharCard error",err);
  }
};

export const getQRCodeImage = (payload) => async (dispatch) => {
  let response;
  try {
    console.log("payload==>",payload);
    response = await axios.get(`/api/business/generateQRCodeForBusiness/${payload.businessName}/${payload._id}`, {
      responseType: "blob",
    });
    console.log("response.data==>",response.data);
    dispatch({ type: SET_QR_CODE_IMAGE, payload: response.data });
  } catch (err) {
    console.log("Error while fetching QR code image - ", err);
  }
};

export const setReportedBusinessDetails = (res) => {
  return {
    type: SET_REPORTED_BUSINESS_DETAILS,
    payload: res,
  };
};

export const showRpd = (res) => {
  return {
    type: SHOW_REPORTED_BUSINESS_DETAILS,
    payload: res,
  };
};

export const getRevenueByBusiness = (payload) => async (dispatch) => {
  try {
    const response = await axios.post(
      `/api/business/revenue/${payload.businessId}`,
      {
        adminId: payload.adminId,
        orderstatus: "COMPLETED",
        consumer: null,
        // paymentstatus: 'PAID',
        revenuetype: payload.revenueType,
      },
      {
        params: {
          startdate: payload.startDate,
          enddate: payload.endDate,
        },
      }
    );
    dispatch({
      type: SET_BUSINESS_STATS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({ type: PATCH_REPORTED_ERROR_MESSAGE, payload: null });
  }
};

export const getLastOrderReceivedByBusiness = (payload) => async (dispatch) => {
  try {
    // const response = await axios.get(
    //   `/api/business/${payload.businessId}/lastorder`
    // );
    const response = await axios.get(
      `/api/business/${payload.businessId}/lastorderweb`
    );
    dispatch({
      type: SET_BUSINESS_STATS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({ type: PATCH_REPORTED_ERROR_MESSAGE, payload: null });
  }
};

export const updateBusinessWithArea = (payload) => async (dispatch) => {
  try {
    let businessId = payload.businessId;
    delete payload.businessId;
    await axios.patch(
      `api/business/updatearea/business/${businessId}`,
      payload
    );
    dispatch({
      type: SET_BUSINESS_SUCCESS_MESSAGE,
      payload: { ops: "UPDATE", msg: "Area assigned successfully." },
    });
  } catch (err) {
    console.log("err==========>", err);
    dispatch({
      type: SET_BUSINESS_ERROR_MESSAGE,
      payload: {
        ops: "UPDATE",
        msg: "Error while assigning area to business.",
      },
    });
  }
};

export const getTopPerformingBusinesses = (payload) => async (dispatch) => {
  try {
    let topPerformingBusinesses = await axios.get(
      `api/business/performance/top`,
      { params: payload }
    );
    dispatch({
      type: SET_TOP_PERFORMING_BUSINESSES,
      payload: topPerformingBusinesses.data,
    });
  } catch (err) {
    console.log("getTopPerformingBusinesses Error==========>", err);
    dispatch({
      type: SET_BUSINESS_ERROR_MESSAGE,
      payload: {
        ops: "UPDATE",
        msg: "Error while fetching top performing businesses.",
      },
    });
  }
};

export const getMonthlyBusinessReport = (payload) => async (dispatch) => {
  try {
    let businessReport = await axios.get(`api/business/report/monthly`, {
      params: payload,
    });
    dispatch({
      type: SET_BUSINESS_REPORTS,
      payload: businessReport.data,
    });
  } catch (err) {
    console.log("getMonthlyBusinessReport Error==========>", err);
    dispatch({
      type: SET_BUSINESS_ERROR_MESSAGE,
      payload: { ops: "GET", msg: "Error while fetching business reports." },
    });
  }
};

export const getBusinessPerformanceReport = (payload) => async (dispatch) => {
  try {
    if (payload.reset) {
      dispatch({
        type: SET_BUSINESS_PERFORMANCE_REPORTS,
        payload: {},
      });
    } else {
      let businessPerformanceReport = await axios.get(
        `/api/analysisReport/businessPerformanceReport`,
        {
          params: payload,
        }
      );
      dispatch({
        type: SET_BUSINESS_PERFORMANCE_REPORTS,
        payload: businessPerformanceReport.data,
      });
    }
  } catch (err) {
    console.log("getBusinessPerformanceReport Error==========>", err);
    dispatch({
      type: SET_BUSINESS_ERROR_MESSAGE,
      payload: {
        ops: "GET",
        msg: "Error while fetching business performance reports.",
      },
    });
  }
};

export const updateBusinessPhotos = (payload) => async (dispatch) => {
  try {
    await axios.patch(`api/business/${payload.businessId}`, payload.data);
  } catch (err) {
    console.log("Error while updating business photos : ", err);
  }
};

export const setBusinessConfigLevel = (payload) => async (dispatch) => {
  //  console.log('payload: ', payload);
  try {
    await axios.patch(`api/business/updateBusinessExotelConfig/${payload.businessId}`, payload.data);
  } catch (err) {
    console.log("Error while updating business photos : ", err);
  }
};

export const updateBusinessVideo = (payload) => async (dispatch) => {
  try {
    await axios.patch(`api/business/${payload.businessId}`, payload.data);
    dispatch({type: SET_ALL_BUSINESS_VIDEO_LINK_LIST_SUCCESS_MESSAGE, payload: { ops: "ADD", msg: "Business Video Link added Successfully." }});
  } catch (err) {
    console.log("Error while adding business video link : ", err);
    dispatch({type: SET_ALL_BUSINESS_VIDEO_LINK_LIST_ERROR_MESSAGE, payload: { ops: "ADD", msg: err.response.data.error ? err.response.data.error : "Failed to add Business Video Link." }});
  }
};
export const updateExotelNumber = (payload) => async (dispatch) => {
  try {
    await axios.patch(`api/business/${payload.businessId}`, payload.data);
    dispatch({type: SET_ALL_EXOTEL_NUMBERS_SUCCESS_MESSAGE, payload: { ops: "ADD", msg: "Exotel number added Successfully." }});
  } catch (err) {
    console.log("Error while adding business video link : ", err);
    dispatch({type: SET_ALL_EXOTEL_NUMBERS_ERROR_MESSAGE, payload: { ops: "ADD", msg: err.response.data.error ? err.response.data.error : "Failed to add exotel number." }});
  }
};

export const deleteBusinessPhotos = (payload) => async (dispatch) => {
  try {
    axios.delete(`api/business/${payload.businessId}/delete-image`, {
      params: {
        public_id: payload.public_id,
      },
    });
  } catch (err) {
    console.log("Error while updating business photos : ", err);
  }
};

export const deleteBusinessVideo = (payload) => async (dispatch) => {
  try {
    axios.delete(`api/business/${payload.businessId}/delete-video`, {
      params: {
        _id: payload.businessId,
      },
    });
    dispatch({type: DELETE_BUSINESS_VIDEO_LINK_LIST_SUCCESS_MESSAGE, payload: { ops: "DELETE", msg: "Business Video Link deleted Successfully." }});
  } catch (err) {
    // console.log("Error while deleting business video link : ", err);
    dispatch({type: DELETE_BUSINESS_VIDEO_LINK_LIST_ERROR_MESSAGE, payload: { ops: "DELETE", msg: err.response.data.error ? err.response.data.error : "Failed to delete Business Video Link." }});
  }
};

export const editBusinessVideo = (payload) => async (dispatch) => {
  try {
    await axios.patch(`api/business/editbusinessvideolink/${payload.businessId}`, payload);
    // console.log("response after the link is edited----->", reponse)
    dispatch({type: EDIT_BUSINESS_VIDEO_LINK_LIST_SUCCESS_MESSAGE, payload: { ops: "UPDATE", msg: "Business Video Link updated Successfully." }});
  } catch (error) {
    console.log("Error while editing business video link : ", error);
    dispatch({type: EDIT_BUSINESS_VIDEO_LINK_LIST_ERROR_MESSAGE, payload: { ops: "UPDATE", msg: error.response.data.error ? error.response.data.error : "Failed to update Business Video Link." }});
  }
};

export const getMembersList = (payload) => async (dispatch) => {
  const response = await axios.get(
    `api/serviceproviders/${payload.businessId}/contacts`,
    {
      params: payload,
    }
  );
  dispatch({ type: BUSINESS_MEMBERS_DETAILS, payload: response.data });
};

export const updateBusinessDetails = (payload) => async (dispatch) => {
  try {
    // console.log("from businessAction",payload)
    var businessId = payload._id;
    let query = payload;
    await axios.patch("/api/business/" + businessId, query);
    dispatch({
      type: BUSI_DET_UPDATE_SUCCESS,
      payload: payload.businessName + " updated successfully.",
    });
  } catch (err) {
    console.log("FAILURE UPDATING", err.response);
    dispatch({
      type: BUSI_DET_UPDATE_ERR,
      payload: payload.businessName + " failed to update",
    });
  }
};
export const deleteExistingAreaManagerBusiness = (payload) => async (dispatch) => {
  try {
    // console.log("payload in Action of deleteExistingAreaManagerBusiness---->",payload)
    let result = await axios.delete(
      `/api/business/deleteExistingAreaManagerBusiness/${payload.businessId}`, {
        params: payload,
      }      
    );
    dispatch({  payload: result.data });
  } catch (err) {
    console.log("ERROR WHILE DELETING " + err);
  }
};
export const updateBusinessPersonalDetails = (payload) => async (dispatch) => {
  try {
    var id = payload._id;
    let query = payload;
    await axios.patch("/api/serviceproviders/updateServiceProviderFromWeb/" + id , query);
    dispatch({
      type: BUSI_PERSONAL_DET_UPDATE_SUCCESS,
      payload: payload.businessName + " updated successfully.",
    });
  } catch (err) {
    console.log("FAILURE UPDATING", err.response);
    dispatch({
      type: BUSI_PERSONAL_DET_UPDATE_ERR,
      payload: payload.businessName + " failed to update",
    });
  }
};
export const updateServiceProviderForUserRoleFromWeb = (payload) => async (dispatch) => {
  try {
    // console.log("payload of updateServiceProviderForUserRoleFromWeb ----------------------->",payload)
    var id = payload.updatedObj._id;
    let query = payload;
    await axios.patch("/api/serviceproviders/updateServiceProviderForUserRoleFromWeb/" + id , query);
    dispatch({
      type: BUSI_PERSONAL_DET_UPDATE_SUCCESS,
      payload: payload.businessName + " updated successfully.",
    });
  } catch (err) {
    console.log("FAILURE UPDATING", err.response);
    dispatch({
      type: BUSI_PERSONAL_DET_UPDATE_ERR,
      payload: payload.businessName + " failed to update",
    });
  }
};

export const getBusinessById = (payload) => async (dispatch) => {
  try {
    // console.log("......in ACTION........payload.businessId..........",payload);
    let result = await axios.get(`api/business/${payload.businessId}`);
    dispatch({ type: SHOW_APPROVED_BUSINESS_DETAILS, payload: result.data });
  } catch (err) {
    console.log("Error while fetching business by id: ", err);
  }
};
export const getConsumerCount = (payload) => async (dispatch) => {
  try {
    let query = payload;
    // console.log("payload ---->", payload)
    // console.log("query ---->", query)
    // console.log("......in ACTION........payload.businessId..........",payload);
    let result = await axios.patch(`api/business/consumersCount/${payload.businessId}`,query);
    // console.log("result.data --->",result)
    dispatch({ type: SHOW_CONSUMER_COUNT, payload: result.data });
  } catch (err) {
    console.log("Error while fetching consumerCount: ", err);
  }
};

export const updateBusiness = (payload) => async (dispatch) => {
  console.log("payload============,",payload)
  try {
    await axios.patch(`api/business/${payload.businessId}`, payload.data);
  } catch (err) {
    console.log("Error while updating business : ", err);
  }
};
export const updateExotelForBusinesses = (payload) => async (dispatch) => {
  try {
    await axios.patch(`api/business/updateExotel/${payload.businessId}`, payload.data);
  } catch (err) {
    console.log("Error while updating business : ", err);
  }
};


export const addHeadSubhead = (payload) => async (dispatch) => {
  try {
    await axios.post("/api/services/head", payload);
    dispatch({
      type: ADD_HEAD_SUCCESS,
      payload: "HEAD_ADDED",
    });
  } catch (err) {
    dispatch({
      type: ADD_HEAD_ERROR,
      payload: "ERROR_ADDING_HEAD",
    });
  }
};
export const getHeadByBusinessId = (id) => async (dispatch) => {
  try {
    const response = await axios.get("/api/services/head/business/" + id);
    dispatch({
      type: GET_HEAD_BY_BUSINESSID,
      payload: response.data,
    });
  } catch (err) {
    dispatch({ type: GET_HEAD_BY_BUSINESSID, payload: null });
  }
};
export const getHeadSubheadHierarchy = (payload) => async (dispatch) => {
  try {
    dispatch({ type: REQUEST_BUSINESSES, payload: true });
    const response = await axios.get(
      "/api/services/heads/heirarchy/business/" + payload.id,
      { params: payload.data }
    );
    dispatch({
      type: GET_HEADANDSUBHEAD_BY_BUSINESSID,
      payload: response.data,
    });
    dispatch({ type: REQUEST_BUSINESSES, payload: false });
  } catch (err) {
    dispatch({ type: GET_HEADANDSUBHEAD_BY_BUSINESSID, payload: null });
  }
};
export const deleteHeadSubheadService = (payload) => async (dispatch) => {
  try {
    await axios.delete(
      `/api/services/${payload.businessId}/${payload.type}/${payload.id}`
    );
  } catch (err) {
    console.log("ERROR WHILE DELETING " + payload.type);
  }
};
export const updateHeadAndSubhead = (payload) => async (dispatch) => {
  let response = {};
  // console.log("Inside updateHeadAndSubhead action----", payload);
  try {
    response = await axios.patch(
      `/api/services/head/subhead/${payload.id}`,
      payload.data
    );
    dispatch({
      type: UPDATE_HEAD_SUBHEAD,
      payload: response.data,
    });
  } catch (err) {
    console.log("err==========>", err);
    dispatch({
      type: UPDATE_HEAD_SUBHEAD,
      payload: response.data,
    });
  }
};
export const addService = (payload) => async (dispatch) => {
  try {
    await axios.post("/api/services/" + payload.id, payload.data);
    dispatch({
      type: ADD_SERVICE_SUCCESS,
      payload: true,
    });
  } catch (err) {
    dispatch({
      type: ADD_SERVICE_FAILURE,
      payload: false,
    });
  }
};
export const getServicesByBusinessId = (payload) => async (dispatch) => {
  try {
    // console.log("payload=============",payload)
    dispatch({ type: REQUEST_BUSINESSES, payload: true });
    const response = await axios.get(
      "/api/services/services/business/" + payload.id,
      { params: payload.data }
    );
    dispatch({
      type: GET_ALL_SERVICES,
      payload: response.data,
    });
    dispatch({ type: REQUEST_BUSINESSES, payload: false });
  } catch (err) {
    dispatch({ type: GET_ALL_SERVICES, payload: null });
  }
};

export const getSubHeadsByHeadId = (id) => async (dispatch) => {
  try {
    const response = await axios.get("/api/services/subhead/head/" + id);
    dispatch({
      type: GET_SUBHEAD_BY_HEAD_ID,
      payload: response.data,
    });
  } catch (err) {
    dispatch({ type: GET_SUBHEAD_BY_HEAD_ID, payload: null });
  }
};

export const updateService = (payload) => async (dispatch) => {
  let response = {};
  try {
    response = await axios.patch(
      `/api/services/${payload.serviceId}`,
      payload.data
    );
    dispatch({
      type: UPDATE_SERVICE,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_SERVICE,
      payload: response.data,
    });
  }
};
export const uploadServices = (payload) => async (dispatch) => {
  try {
    dispatch({ type: REQUEST_BUSINESSES, payload: true });

    const formData = new FormData();
    formData.append("file", payload.file);
    let res = await axios.post(
      `api/services/excel/upload/${payload.businessId}`,
      formData,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    dispatch({ type: REQUEST_BUSINESSES, payload: false });
    dispatch({
      type: SERVICES_BULK_UPLOAD_SUCCESS,
      payload: { ops: "Bulk Upload", msg: res && res.data && res.data.message ? res.data.message : "" },
    });
  } catch (err) {
    dispatch({ type: REQUEST_BUSINESSES, payload: false });
    dispatch({
      type: SERVICES_BULK_UPLOAD_ERROR,
      payload: { ops: "Bulk Upload", msg: "Failed to upload services." },
    });
  }
};

export const deleteMultipleServices = (payload) => async (dispatch) => {
  try {
   
    dispatch({ type: REQUEST_BUSINESSES, payload: true });
    await axios.delete("/api/services/" + payload.businessId, {
      params: payload.data,
    });
    dispatch({ type: REQUEST_BUSINESSES, payload: false });
    dispatch({
      type: SERVICES_BULK_UPLOAD_SUCCESS,
      payload: {
        ops: "Delete Services",
        msg: "Services deleted successfully.",
      },
    });
  } catch (err) {
    console.log("Error while deleting services " + err);
    dispatch({
      type: SERVICES_BULK_UPLOAD_ERROR,
      payload: { ops: "Bulk Upload", msg: "Failed to delete services." },
    });
  }
};

export const disableMultipleServices = (payload)=>async(dispatch) => {
  try{
    dispatch({ type: REQUEST_BUSINESSES, payload: true });
    await axios.patch("/api/services/disableserviceoffers/business/" + payload.businessId, {
      params: payload.data,
    });
    dispatch({ type: REQUEST_BUSINESSES, payload: false });
    dispatch({
      type: SERVICES_BULK_UPLOAD_SUCCESS,
      payload: {
        ops: "Disabled Discounts",
        msg: "Discounts for Selected Services disabled successfully.",
      },
    });
  } catch(err) {
    console.log("Error while disabling discounts " + err);
    dispatch({
      type: SERVICES_BULK_UPLOAD_ERROR,
      payload: { ops: "Disabled Discounts", msg: "Failed to disable discounts." },
    });
  }
}

export const setCartLevelDiscount = (payload)=>async(dispatch) => {
  try{
    dispatch({ type: REQUEST_BUSINESSES, payload: true });
    await axios.patch("/api/business/" + payload.businessId+"/offers/discount/web", {
      discount: payload.discount,
    });
    dispatch({ type: REQUEST_BUSINESSES, payload: false });
    dispatch({
      type: SERVICES_BULK_UPLOAD_SUCCESS,
      payload: {
        ops: "Set Cart Level Discount",
        msg: "Discounts for Selected Business set successfully.",
      },
      });
  } catch(err) {
    console.log("Error while setting cart level discounts " + err);
    dispatch({
      type: SERVICES_BULK_UPLOAD_ERROR,
      payload: { ops: "Set Cart Level Discount", msg: "Failed to set discounts." },
    });
  }
}

export const getActiveBusinessUserReports = (payload) => async (dispatch) => {
  try {
    const activeBusinessUserReport = await axios.get("/api/analysisReport/businessUserReport", {
      params: payload,

    });
    dispatch({
      type: GET_ACTIVEBUSINESSUSER_REPORTS,
      payload: activeBusinessUserReport.data,

    });
  } catch (err) {
    dispatch({ type: GET_ACTIVEBUSINESSUSER_REPORTS, payload: null });
  }
};

export const pushNotification = (payload) => async (dispatch) => {
try {
  await axios.post("/api/pushNotifications", { params: payload });
  
  dispatch({ type: SEND_PUSH_NOTIFICATION, payload: { ops: "ADD", msg: "Notifications Sent Successfully" } });
  
  } catch (err) {
  dispatch({ type: SEND_PUSH_NOTIFICATION, payload: err.response.data.error });
  }
}
  
export const getpushNotifications = (payload) => async (dispatch) => {
  try {
    const response = await axios.get("/api/pushNotifications/getAll", { params: payload });
    dispatch({
      type: GET_PUSH_NOTIFICATION,
      payload: response.data
    });
  } catch (err) {
    dispatch({ type: GET_PUSH_NOTIFICATION, payload: null });
  }
};
export const getPushNotificationConsumerData = (pushNotificationId) => async (dispatch) => {
  try {
    const response = await axios.get("/api/pushNotifications/consumerInfo/" + pushNotificationId);
    dispatch({
      type: GET_PUSH_NOTIFICATION_CONSUMERDATA,
      payload: response.data.data,
    });
  } catch (err) {
    dispatch({ type: GET_PUSH_NOTIFICATION_CONSUMERDATA, payload: null });
  }
};
//Delivery Partner Availability
export const updateDeliveryPartnerAvailability = (payload) => async (dispatch) => {
  try {
    await axios.patch(`/api/business/${payload.businessId}`, payload.data);
    return null;
  } catch (err) {
    console.log("Error while updating Delivery Partner Availability : ", err);
  }
};

export const updateDeliveryPartnerPriority = (payload) => async (dispatch) => {
  try {
    await axios.patch(`/api/business/${payload.businessId}`, payload.data);
    return true;
  } catch (err) {
    console.log("Error while updating Delivery Partner Priority : ", err);
    return false;
  }
}

export const clearPushNotificationMsg = () => async (dispatch) => {
  dispatch({
    type: SEND_PUSH_NOTIFICATION,
    payload: ""
  });
};
export const updatePushNotification = (payload) => async (dispatch) => {
  try {
    const response = await axios.patch("/api/pushNotifications/updateNotification/" + payload.id, payload.data);
    dispatch({
      type: SET_PUSH_NOTIFICATION,
      payload: response.msg
    });
  } catch (err) {
    dispatch({ type: SET_PUSH_NOTIFICATION, payload: err.response });
  }
};
export const deletePushNotification = (payload) => async (dispatch) => {
  try {
    const response = await axios.delete("/api/pushNotifications/deleteNotification/" + payload.id);
    dispatch({
      type: DELETE_PUSH_NOTIFICATION,
      payload: response.msg
    });
  } catch (err) {
    dispatch({ type: DELETE_PUSH_NOTIFICATION, payload: err.response });
  }
};
export const deleteSubcategoryButton = (payload) => async (dispatch) => {
  try {
    const response = await axios.put(`/api/business/removesubcategories`, payload);
    dispatch({
      type: DELETE_SUBCATEGORY,
      payload: response.data.message,

    });
  } catch (err) {
    dispatch({ type: DELETE_SUBCATEGORY, payload: err });
  }

};
export const getBusinessExportToExcel = (payload) => async (dispatch) => {
  const response = await axios.get("/api/business/exporttoexcel",  
   {
    params: payload,
  }
  );
  dispatch({ type: FETCH_BUSINESS_EXCEL, payload: response.data.exportToExcel });
};

export const getServicesMenuExportToExcel = (payload) => async (dispatch) => {
  dispatch({ type: SERVICES_MENU_DOWNLOAD_EXCEL });
  const response = await axios.get("/api/services/excel/download/" + payload.businessId,
  { params: payload.data });
  // console.log(response);
  dispatch({ type: SERVICES_MENU_DOWNLOAD_EXCEL_COMPLETED, payload: response.data.exportToExcel });
};

export const getPetPoojaMenuExportToExcel = (payload) => async (dispatch) => {
  dispatch({ type: PETPOOJA_MENU_DOWNLOAD_EXCEL });
  const response = await axios.get("/api/services/fetchpetpoojamenu/" + payload.businessId);
  
  dispatch({ type: PETPOOJA_MENU_DOWNLOAD_EXCEL_COMPLETED, payload: response.data.exportToExcel });
};

export const updateReview = (payload) => async (dispatch) => {
  try {
    await axios.patch(`api/analysisReport/updateReview`, payload);
  } catch (err) {
    console.log("Error while updating business Review : ", err);
  }
};

export const updateBusinessStatus = (payload) => async (dispatch) => {
  try {
    await axios.patch(`/api/business/${payload.businessId}/availibilitystatus`, payload.data);
    return null;
  } catch (err) {
    console.log("Error while updating business status : ", err);
  }
};
export const getHeadWithServicesByBusinessId = (id) => async (dispatch) => {
  try {
    dispatch({ type: REQUEST_BUSINESSES, payload: true });
    const response = await axios.get("/api/services/head/services/business/" + id);
    dispatch({
      type: GET_HEAD_WITH_SERVICES_BY_BUSINESSID,
      payload: response.data,
    });
    dispatch({ type: REQUEST_BUSINESSES, payload: false });
  } catch (err) {
    dispatch({ type: GET_HEAD_WITH_SERVICES_BY_BUSINESSID, payload: null });
  }
};

export const updateSubHeadOrServiceSequence = (id,body) => async (dispatch) => {
  try {
    await axios.patch(
      "/api/services/sequence/head/" + id,
      body
    );
  } catch (err) {
    console.log("Error while updating sequence : ", err);
  }
};

export const updateHeadSequenceByBusinessId = (id,body) => async (dispatch) => {
  try {
    await axios.patch(
      "/api/services/sequence/business/" + id,
      body
    );
  } catch (err) {
    console.log("Error while updating sequence : ", err);
  }
};

export const deleteAllServices = (payload) => async (dispatch) => {
  try {
    //  console.log("------ payload in action of delete-----",payload);

    let response = await axios.delete(`/api/services/deleteservices/${payload.busId}`);
    // console.log("==== response of delete data====",response);
    dispatch({ type: DELETE_ALLSERVICES_SUCCESS_MESSAGE, payload: response.data.message});
  } catch (err) {
    console.log("Error while deleting : ", err);
    dispatch({ type: DELETE_ALLSERVICES_ERROR_MESSAGE, payload: err.response.data.error})
  }
};



export const unlinkLinkedItemsServiceSection = (payload) => async (dispatch) => {
  
  try {
    await axios.patch(`/api/addons/unlinkLinkedItems`, payload
    );
    dispatch({type: UNSET_LINKED_ADDONS_SERVICESEC_SUCCESS,  payload: { ops: "ADD", msg: "Addon Unlinked Succesfully." }});
  } catch (err) {
    dispatch({ type: UNSET_LINKED_ADDONS_SERVICESEC_ERROR, payload: { ops: "ADD", msg: "Failed to Unlink Addon" } });
  }
};

export const addPendingBusinessTag = (id,payload) => async (dispatch) => {
  try {
    const response = await axios.patch(`/api/business/businessTags/${id}`, payload);
    dispatch({ type: ADD_PENDING_BUSINESS_TAGS, payload: response.data}); 
  } catch (err) {
    console.log("Error while adding Business Tag : ", err);
  }
};


export const addDisabledBusinessTag = (id,payload) => async (dispatch) => {
  try {
    const response = await axios.patch(`/api/business/businessTags/${id}`, payload);
    dispatch({ type: ADD_DISABLED_BUSINESS_TAGS, payload: response.data}); 
  } catch (err) {
    console.log("Error while adding Business Tag : ", err);
  }
};

export const addApprovedBusinessTag = (id,payload) => async (dispatch) => {
  try {
    const response = await axios.patch(`/api/business/businessTags/${id}`, payload);
    dispatch({ type: ADD_APPROVED_BUSINESS_TAGS, payload: response.data}); 
  } catch (err) {
    console.log("Error while adding Business Tag : ", err);
  }
};






