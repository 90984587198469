const DunzoStyleDIcon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="64" height="64" rx="12" fill="#00C853" />
    {/* D shape */}
    <path
      d="M16 16H26C35 16 40 24 40 32C40 40 35 48 26 48H16V16Z"
      fill="black"
    />
    {/* Lightning bolt inside D */}
    <path
      d="M28 22L24 32H30L26 42"
      stroke="#00C853"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ShadowfaxStyleSIcon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Orange background for Shadowfax theme */}
    <rect width="64" height="64" rx="12" fill="#FF6A00" />
    
    {/* Stylized "S" shape in white */}
    <path
      d="M40 18C36 16 28 16 24 20C20 24 26 26 30 28C34 30 38 32 36 36C34 40 28 40 24 38"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    
    {/* Motion lines for speed in light orange */}
    <path
      d="M20 24H14M22 44H16"
      stroke="#FF9C3B"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

const PorterStylePIcon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* Blue background for Porter theme */}
    <rect width="64" height="64" rx="12" fill="#007BFF" />
    
    {/* Clear and bold "P" in white */}
    <text
      x="32"
      y="42"
      font-family="Arial, sans-serif"
      font-size="32"
      fill="white"
      text-anchor="middle"
      dominant-baseline="middle"
    >
      P
    </text>

    {/* Orange motion lines for speed */}
    <path
      d="M20 38L24 44"
      stroke="#FF6A00"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 28L24 34"
      stroke="#FF6A00"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

// Exporting all the icons
export {
  DunzoStyleDIcon,
  ShadowfaxStyleSIcon,
  PorterStylePIcon,
};
