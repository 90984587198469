import React, { Component } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListSideBar from './ListSideBar';
import MenuItem from '@material-ui/core/MenuItem';
import { logoutUser } from "../../actions/authActions";
import { showPendingBusinessDetails, getAllMasterOtp } from "../../actions/dashboardActions";
import { changeAdminPassword } from '../../actions/adminActions';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import Modal from '@material-ui/core/Modal';
import Container from '@material-ui/core/Container';
import ChangePassword from '../Admin/ChangePassword';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';

const drawerWidth = 265;
const collapsedWidth = 73;

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: 'black'
  },
  header: {
    position: 'relative',
    width: '100%',
    minHeight: '64px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 6px',
    flexWrap: 'wrap',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
    }
  },
  appBarShift: {
    marginLeft: collapsedWidth,
    width: `calc(100% - ${collapsedWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: collapsedWidth,
    [theme.breakpoints.up('sm')]: {
      width: collapsedWidth,
    },
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
  },
  title: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '17px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px'
  },
  modalContainer: {
    width: '30%',
    height: '80%',
    backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    width: "145px",
    height: "30px",
    transition: theme.transitions.create(['width', 'height'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  avatarCollapsed: {
    display: 'none',
  },
  brandName: {
    color: '#fff',
    fontSize: '0.7rem',
    marginLeft: '10px',
    textAlign: 'center'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...theme.mixins.toolbar,
  },
  collapseButton: {
    color: 'white',
    backgroundColor: '#424242',
    borderRadius: '50%',
    padding: '6px',
    '&:hover': {
      backgroundColor: '#616161',
    },
  }
});

class Header extends Component {
  constructor() {
    super();
    this.state = {
      anchorEl: null,
      modalCPOpen: false,
      snackOpen: false,
      mobileOpen: false,
      sidebarCollapsed: false
    };
  }

  async componentDidMount() {
    await this.props.getAllMasterOtp();
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  toggleSidebar = () => {
    this.setState({ sidebarCollapsed: !this.state.sidebarCollapsed });
  };

  handleSnackClose = () => {
    this.setState({ snackOpen: false, modalCPOpen: false, anchorEl: null });
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onLogoutClick = e => {
    e.preventDefault();
    this.setState({ anchorEl: null });
    this.props.logoutUser();
    localStorage.clear();
    this.props.history.push("/");
  };

  handleNavigateBack = () => {
    this.props.showPendingBusinessDetails(null);
    this.props.history.push("/pendingBusiness");
  };

  openCPModal = () => {
    this.setState({ modalCPOpen: true });
  }

  closeCPModal = () => {
    this.setState({ modalCPOpen: false });
  }

  changePassword = (props) => {
    props.id = this.props.auth.user.id;
    this.props.changeAdminPassword(props);
    this.setState({ snackOpen: true });
  }

  render() {
    const { classes, theme } = this.props;
    const { anchorEl, sidebarCollapsed } = this.state;

    let webLogo = this.props?.dashboard?.data && this.props?.dashboard?.data.length>0 && this.props?.dashboard?.data[0]?.webLogo 
    ? this.props.dashboard.data[0].webLogo 
    : "../../images/xirify_logo.png";
    const brandName = this.props?.dashboard?.getMasterOTP?.data?.[0]?.brandName ?? "XIRIFY";

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: sidebarCollapsed,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={this.toggleSidebar}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap style={{ flexGrow: "1" }}>
              {this.props.dashboard.headerName}
            </Typography>
            <div>
              <Button aria-controls="simple-menu" aria-haspopup="true" color="inherit" onClick={this.handleClick}>
                {this.props.auth.user.firstName + " " + this.props.auth.user.lastName}
                <MoreVertRoundedIcon color="inherit" />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
              >
                <MenuItem onClick={() => this.openCPModal()}>Change Password</MenuItem>
                <MenuItem onClick={this.onLogoutClick}>Logout</MenuItem>
              </Menu>
              <div className={classes.brandInfo}>
                <span className={classes.brandName}>
                  {(this.props.auth.user.role === "admin") ? "ADMIN" :
                    (this.props.auth.user.role === "areaManager") ? "AREA MANAGER" :
                      (this.props.auth.user.role === "regionalHead") ? "REGIONAL HEAD" : ""}
                </span>
              </div>
            </div>
          </Toolbar>
        </AppBar>

        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: !sidebarCollapsed,
            [classes.drawerClose]: sidebarCollapsed,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: !sidebarCollapsed,
              [classes.drawerClose]: sidebarCollapsed,
            }),
          }}
        >
          <div className={classes.header}>
  {      !sidebarCollapsed ? ( 
              <img
                className={classes.avatar}
                alt="XIRIFY"
                src={webLogo}
              /> )
            :  null }
            <IconButton onClick={this.toggleSidebar} className={classes.collapseButton}>
              {sidebarCollapsed ? <ChevronRightIcon fontSize="large" /> : <ChevronLeftIcon fontSize="large" />}
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListSideBar
              sidebarCollapsed={sidebarCollapsed}
              navigateBack={this.handleNavigateBack}
            />
          </List>
        </Drawer>

        <Modal
          open={this.state.modalCPOpen}
          onClose={this.closeCPModal}
          className={classes.modal}
        >
          <Container className={classes.modalContainer}>
            <ChangePassword onSave={this.changePassword} />
          </Container>
        </Modal>

        <Snackbar open={this.state.snackOpen} autoHideDuration={2000} onClose={this.handleSnackClose}>
          <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
            Password Changed Successfully
          </MuiAlert>
        </Snackbar>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  dashboard: state.dashboard
});

export default connect(mapStateToProps, { logoutUser, showPendingBusinessDetails, changeAdminPassword, getAllMasterOtp })(
  withStyles(styles, { withTheme: true })(withRouter(Header))
);
