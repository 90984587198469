import React from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import moment from "moment";
import { CSVLink } from "react-csv";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import { lightBlue } from '@material-ui/core/colors';





const tableHeaders = [
    { id: "consumerId", disablePadding: true, label: "Consumer Id" },
    { id: "consumerName", disablePadding: true, label: "Consumer Name" },
    { id: "searchPhrase", disablePadding: true, label: "Search Phrase" },
    { id: "isSearchSuccess", disablePadding: true, label: "Search Success" },
    { id: "screenName", disablePadding: true, label: "Screen Name" },
    { id: "serviceCount", disablePadding: true, label: "Service Count / Business Count" },
    { id: "stores", disablePadding: true, label: "Stores Count" },
    { id: "date", disablePadding: true, label: "Date & Time" },

];

function EnhancedTableHead(props) {
    const { classes } = props;

    return (
        <TableHead>
            <TableRow>
                {tableHeaders.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        className={classes.tableCellSmall}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: "1 1 100%",
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    return (
        <Toolbar style={{ paddingLeft: "16px" }}>
            <Typography
                className={classes.title}
                variant="subtitle2"
                id="tableTitle"
                component="div"
            >
                Records ({props.totalCount})
            </Typography>

            <Typography component="div">
                {props.showDownloadIcon ? (
                    <CSVLink
                        data={props.data}
                        headers={props.headers}
                        filename={"SearchHistoryReport.csv"}
                        className="btn btn"
                        target="_blank"
                    >
                        <Tooltip title="Export to Excel">
                            <img
                                alt="Export To Excel"
                                src="../../images/exportToExcel.png"
                                width="25"
                                height="25"
                            />
                        </Tooltip>
                    </CSVLink>
                ) : (
                    <Button
                        variant="contained"
                        margin="normal"
                        style={{
                            backgroundColor: "green",
                            color: "white",
                            fontFamily: "bold",
                        }}
                        onClick={props.fetchExportToExcel}
                    >
                        Export
                    </Button>
                )}
            </Typography>
        </Toolbar>
    );
};
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },

    table: {
        width: "auto"
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    tableCellSmall: {
        fontSize: "12px",
        padding: "25px 25px 25px 25px",
        overflow: "hidden",
        wordWrap: "break-word!important",
    },
    tableCellSmall1: {
        fontSize: "12px",
        inlineSize: "170px",

    },
    tableCellSmall111: {
        fontSize: "10px",
        inlineSize: "101px",

    },
    tableRow: {
        // cursor: 'pointer'
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    customImageContainerPreview: {
        height: "100%",
        width: "100%"
    },
    lightBlue: {
        // color: theme.palette.getContrastText(lightBlue[50]),
        backgroundColor: lightBlue[50],
        width: theme.spacing(4),
        height: theme.spacing(4),
        cursor: 'pointer'
    },
    tableCellSmall11: {
        fontSize: "13px",
        padding: "4px 4px 4px 10px", //TRBL
        textAlign: 'center',
        marginRight: "25px",
        marginLeft: "45px",
        maxWidth: 50,
        minWidth: 20,
        width: 50,
    },
    tableCellSmall21: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px", //TRBL
        height: "100%",
        textAlign: 'left',
        marginRight: "25px",
        marginLeft: "25px",
        maxWidth: 700,
        minWidth: 300,
        width: 700,
        position: "relative"
    },


}));

export default function SearchHistoryTable(props) {
    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };



    const excelHeaders = [
        { key: "consumerId", disablePadding: true, label: "Consumer Id" },
        { key: "consumerName", disablePadding: true, label: "Consumer Name" },
        { key: "searchPhrase", disablePadding: true, label: "Search Phrase" },
        { key: "isSearchSuccess", disablePadding: true, label: "Search Success" },
        { key: "screenName", disablePadding: true, label: "Screen Name" },
        { key: "serviceCount", disablePadding: true, label: "Service Count / Business Count" },
        { key: "store", disablePadding: true, label: "Stores Count" },
        { key: "date", disablePadding: true, label: "Date & Time" },

    ];

    const emptyRows =
        props.rowsPerPage -
        Math.min(
            props.rowsPerPage,
            props.totalCount - props.page * props.rowsPerPage
        );

    return (

        <div className={classes.root}>

            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                    headers={excelHeaders}
                    totalCount={props.totalCount}
                    data={props.exportToExcel}
                    showDownloadIcon={props.showDownloadIcon}
                    fetchExportToExcel={props.fetchExportToExcel}

                />
                {props.data && props.data.length > 0 ?
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size="small"
                            aria-label="enhanced table"
                            totalCount={props.totalCount}
                          
                        >
                            <EnhancedTableHead
                                classes={classes}
                                order={props.order}
                                orderBy={props.orderBy}
                                rowCount={props.totalCount}

                            />

                            <TableBody>
                                {props.data && props.data.map((row, index) => {

                                    return (
                                        <TableRow
                                            hover
                                            className={classes.tableRow}
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            <TableCell>{row?.consumer?.consumerId ?? "NA"} </TableCell>
                                            <TableCell>{row?.consumer?.firstName}  {row?.consumer?.lastName}</TableCell>
                                            <TableCell>{row?.searchPhrase ?? "NA"}</TableCell>
                                            <TableCell align="center">{row?.isSearchSuccess ?? "NA"}</TableCell>
                                            <TableCell>{row?.screenName ?? "NA"}</TableCell>
                                            <TableCell align="center">{row?.serviceCount} /  {row?.businessCount}</TableCell>
                                            <TableCell align="center">{row?.storeCount ?? "NA"}</TableCell>
                                            <TableCell>
                                                {row?.createdAt
                                                    ? new Date(row.createdAt).toLocaleDateString('en-GB', {
                                                        day: '2-digit',
                                                        month: 'long',
                                                        year: 'numeric',
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        second: '2-digit',
                                                        hour12: true,
                                                    })
                                                    : "NA"}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 33 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>


                        </Table>
                    </TableContainer>
                    : <Typography style={{ margin: "0px 400px 0px 400px" }}>No Records Available</Typography>}
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50]}
                    component="div"
                    count={props.totalCount}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}

                />
            </Paper>
        </div>
    );

}