import axios from "axios";
import {
	SET_SERVICE_REQUESTS,
	SET_SR_SUCCESS_MESSAGE,
	SET_SR_ERROR_MESSAGE,
	SET_SR_PAYMENT_SUCCESS_MESSAGE,
	SET_SR_PAYMENT_ERROR_MESSAGE,
	REQUEST_SERVICE_REQUESTS,
	SET_SR_DETAILS,
	SR_EXPORTTOEXCEL,
	SR_ITEM_REPORT_TO_EXCEL,
	GET_REVIEW_REPORT,
	ADD_SERVICE_REQUESTS_CONVERSATION,
	ADD_SERVICE_REQUESTS_XIRIFYADJUSTMENT,
	CREATE_DUNZOID_SUCCESS_MESSAGE,
	CREATE_DUNZOID_ERROR_MESSAGE,
	CREATE_DUNZO_ID,
	RESET_DUNZO_IDS,
	ADD_SERVICE_REQUESTS_SUPPORT_CONVERSATION,
	CANCEL_DELIVERY_PARTNER_TASKID_MESSAGE,
	CANCEL_DELIVERY_PARTNER_TASKID_MESSAGE_ERROR_MESSAGE,
	FIND_ALL_CONVERSATIONS,
	UPDATE_MANUAL_DELIVERY,
	CREATE_REFUND_REQUEST,

} from "./types";

export const requestServiceRequests = () => {
	return {
		type: REQUEST_SERVICE_REQUESTS,
		payload: true
	};
};

export const callDunzoId = () => {
    return {
        type: CREATE_DUNZO_ID,
        payload: true
    };
};

export const callDunzoIdReset = () => {
    return {
        type: RESET_DUNZO_IDS,
        payload: false
    };
};


export const clearMsg = () => async dispatch => {
	let data = {};
	data.msg = null;
	data.ops = null;
	dispatch({ type: SET_SR_SUCCESS_MESSAGE, payload: data });
	dispatch({ type: SET_SR_ERROR_MESSAGE, payload: data });
	dispatch({ type: SET_SR_PAYMENT_SUCCESS_MESSAGE, payload: data });
	dispatch({ type: SET_SR_PAYMENT_ERROR_MESSAGE, payload: data })
	dispatch({ type: CANCEL_DELIVERY_PARTNER_TASKID_MESSAGE, payload: null })
	dispatch({ type: CANCEL_DELIVERY_PARTNER_TASKID_MESSAGE_ERROR_MESSAGE, payload: null })
};

//Get all the Service requests.
export const getAllServiceRequests = (payload) => async dispatch => {
	const response = await axios.get("/api/servicerequests", { params: payload });
	dispatch({ type: SET_SERVICE_REQUESTS, payload: response.data });
};
export const getAllPrepaymentOrders = (payload) => async dispatch => {
	const response = await axios.get("/api/servicerequests/getAllPrepaymentOrders", { params: payload });
	dispatch({ type: SET_SERVICE_REQUESTS, payload: response.data });
};

export const exportToExcel = (payload) => async dispatch => {
	const response = await axios.get("/api/servicerequests/findAll", { params: payload });
	dispatch({ type: SR_EXPORTTOEXCEL, payload: response.data });
};
export const itemReportToExcel = (payload) => async dispatch => {
	const response = await axios.get("/api/servicerequests/findAllForItemReport", { params: payload });
	dispatch({ type: SR_ITEM_REPORT_TO_EXCEL, payload: response.data });
};

export const exportToExcelPrepayment = (payload) => async dispatch => {
	const response = await axios.get("/api/servicerequests/exportToExcelPrepayment", { params: payload });
	dispatch({ type: SR_EXPORTTOEXCEL, payload: response.data });
};

//Get Service request details by id.
export const getServiceRequestDetailsById = (serviceRequestId) => async dispatch => {
	try {
	  const response = await axios.get(`/api/servicerequests/${serviceRequestId}`);
	  console.log("..response.", response);
	  dispatch({ type: SET_SR_DETAILS, payload: response.data });
	} catch (error) {
	  console.error("Error fetching service request details: ", error);
	}
  };

export const changeServiceStatus = (payload) => async dispatch => {
	try {
		if(payload.flag === 'cancel') {
			let query = {
				"currentStatus": "CANCELLED",
				reason: payload.reason
			}
			await axios.patch('/api/servicerequests/' + payload.serviceRequestId + '/status', query);
			dispatch({ type: SET_SR_SUCCESS_MESSAGE, payload: { msg: "SUCCESSFULLY CANCELLED THE SERVICE REQUEST." } });
		}

		if(payload.flag === 'accept'){
			let query = {
				"currentStatus": "ACCEPTED",
				"serviceMode": payload.serviceMode,
				"deliveryType": payload.deliveryType,
				"cartDiscount": payload.cartDiscount,
				"isDeliveryDiscountedAmount" : payload.isDeliveryDiscountedAmount,
				"prepTime": payload.prepTime
			}
			await axios.patch('/api/servicerequests/' + payload.serviceRequestId + '/status', query);
			dispatch({ type: SET_SR_SUCCESS_MESSAGE, payload: { msg: "SUCCESSFULLY ACCEPTED THE SERVICE REQUEST." } });
		}

		if(payload.flag === 'complete'){
			let query = {
				"currentStatus": "COMPLETED",
				"directcomplete": payload.directcomplete
			}
			await axios.patch('/api/servicerequests/' + payload.serviceRequestId + '/status', query);
			dispatch({ type: SET_SR_SUCCESS_MESSAGE, payload: { msg: "SUCCESSFULLY COMPLETED THE SERVICE REQUEST." } });
		}
		
	} catch (err) {
		dispatch({ type: SET_SR_ERROR_MESSAGE, payload: { msg: "FAILURE CHANGING VALUE" } });
	}
};

export const changePaymentStatus = (payload) => async (dispatch) => {
	try {
		if (payload.paymentMethod === "ADVANCEPAYMENTTOBUSINESS"){
			let query = {	
				"advancePaidBy" : payload.advancePaidBy,
			}	
			await axios.patch('/api/servicerequests/' + payload.serviceRequestId + '/pay/' + payload.paymentMethod, query);
		}
		
		if(payload.flag === 'initiated' && !payload.button){

			let query = {
				// "paymentStatus": "PAID"
				"dateOfTransaction": payload.dateOfTransaction,				
			}
			await axios.patch('/api/servicerequests/' + payload.serviceRequestId + '/pay/' + payload.paymentMethod, query);		
			// dispatch({ type: SET_SR_PAYMENT_SUCCESS_MESSAGE, payload: { msg: "PAYMENT SUCCESSFULLY DONE FOR THE SERVICE REQUEST." } });
		} 
		if(payload.flag === 'initiated' && payload.button === "resetPayment"){			
		  let query = {			 
			  "dateOfTransaction": payload.dateOfTransaction,
			  "resetPaymentBy" : payload.resetPaymentBy,
			  "button": 'resetPayment'				
		  }
		  await axios.patch('/api/servicerequests/' + payload.serviceRequestId + '/pay/' + payload.paymentMethod, query);
		  // dispatch({ type: SET_SR_PAYMENT_SUCCESS_MESSAGE, payload: { msg: "PAYMENT SUCCESSFULLY DONE FOR THE SERVICE REQUEST." } });
	  }
		
		if(payload.flag === 'unpaid'){
			let query = {
				// "paymentStatus": "PAID",
				"receivedBy": payload.receivedBy,
				"notes": payload.notes,
				// "paymentMethod" : payload.paymentMethod
			}
			await axios.patch('/api/servicerequests/' + payload.serviceRequestId + '/pay/' + payload.paymentMethod , query);
		}
	} catch (error) {
		dispatch({ type: SET_SR_PAYMENT_ERROR_MESSAGE, payload: { msg: "FAILURE IN PAYMENT"}})
	}
};

export const changeInProgressPaymentStatus = (payload) => async (dispatch) => {
	try {
		console.log("==== in changeInProgressPaymentStatus ====");
		
		if(payload.flag === 'inprogress') {
			let query = {
				flag: payload.flag,
				xpId: payload.xpId
			}
			await axios.patch('/api/servicerequests/' + payload.serviceRequestId + '/paymentSuccessNewCashFreeIntegration', query);
		}
	} catch (error) {
		dispatch({ type: SET_SR_PAYMENT_ERROR_MESSAGE, payload: { msg: "FAILURE IN PAYMENT"}});
	}
};

export const getRequestDocumentForPaymentDetails = (payload) => async dispatch => {
	try {
		// console.log("Payload--->",payload);
		let response = await axios.get('/api/servicerequests/' + payload.serviceRequestId + '/document/' + payload.transactionMethod.toLowerCase());
		// console.log("Response----->", response);
		// console.log("response.bodyBytes----->", response.data);
		return response.data;
		// console.log(Buffer.from(response.data).toString('base64'));
		// let ans = atob(Buffer.from(response.data).toString('base64'));
		// let ans = Buffer.from(response.data, 'utf-8').toString('base64');
		// console.log(ans);
		// let ansnew = Buffer.from(ans, 'binary').toString('base64');
		// console.log(ansnew);
		// // dispatch({ type: SET_SR_PAYMENT_IMAGE_SUCCESS_MESSAGE, payload: { msg: "SUCCESSFULLY RETRIVED PAYMENT DETAILS FOR ATTACHMENTS." } });
		// let data = 'stackabuse.com';
		// let buff = Buffer(data);
		// let base64data = buff.toString('base64');

		// console.log('"' + data + '" converted to Base64 is "' + base64data + '"');

	} catch (error) {
		console.log("error ---------------->",error);
		// dispatch({ type: SET_SR_ERROR_MESSAGE, payload: { msg: "ERROR GETTING PAYMENT DETAILS" } });
	}
}

export const reviewReport = (payload) => async (dispatch) => {
	try {
    const response = await axios.get("/api/analysisReport/displayReviewRatingReport", {
    params: payload,
    });
    dispatch({
    type: GET_REVIEW_REPORT,
    payload: response.data,
    });
} catch (err) {
    dispatch({ type: GET_REVIEW_REPORT, payload: null });
}
};


export const addServiceRequestConversation = (conversation,id, userRole, history) => async dispatch => {
	try {
		  var url = "/api/servicerequests/"+id+"/conversations";
		//   console.log('addServiceRequestConversation success', conversation + " " + url);
			await axios.post(url, conversation, userRole);
			dispatch({ type: ADD_SERVICE_REQUESTS_CONVERSATION, payload: "success" });
	} catch (err) {
	  	console.log('addServiceRequestConversation failed', conversation + " " + url);
			dispatch({ type: ADD_SERVICE_REQUESTS_CONVERSATION, payload: "Fail"});
	}
};
export const addServiceRequestXirifyAdjustment = (payload) => async dispatch => {
	// console.log("-=--=-inside addServiceRequestXirifyAdjustment actions-=--=--");
	try {
		// console.log("-=--=-inside addServiceRequestXirifyAdjustment actions-=--=--",payload);
		// let response = 
		await axios.post("/api/servicerequests/"+payload.id+"/additionalChargesByAdmin",payload);
		//console.log("-----------response------",response);
		dispatch({
		  type: ADD_SERVICE_REQUESTS_XIRIFYADJUSTMENT,
		  payload: "Xirify Adjustments added succesfully",
		});
	//	return response
	  } catch (err) {
		dispatch({
		  type: ADD_SERVICE_REQUESTS_XIRIFYADJUSTMENT,
		  payload: "Xirify Adjustments failed",
		});
	  }
};

export const createDunzoId = (payload) => async (dispatch) => {
	try {
	//    console.log("------ payload in action of delete-----",payload);
  
	  let response = await axios.patch(`/api/servicerequests/createDunzoId/${payload._id}`);
	  // console.log("==== response of delete data====",response);
	  dispatch({ type: CREATE_DUNZOID_SUCCESS_MESSAGE, payload: response.data.message});
	} catch (err) {
	  console.log("Error while deleting : ", err);
	  dispatch({ type: CREATE_DUNZOID_ERROR_MESSAGE, payload: err.response.data.error})
	}
  };

export const addServiceRequestSupportConversation = (supportconversations,id, userRole) => async dispatch => {
	let url;
	try {
		   url = "/api/servicerequests/"+id+"/supportconversations";
		//   console.log('addServiceRequestConversation success', conversation + " " + url);
			await axios.post(url, supportconversations, userRole);
			dispatch({ type: ADD_SERVICE_REQUESTS_SUPPORT_CONVERSATION, payload: "Support message send success" });
	} catch (err) {
	  	console.log('addServiceRequestSupportConversation failed', supportconversations + " " + url);
			dispatch({ type: ADD_SERVICE_REQUESTS_SUPPORT_CONVERSATION, payload: "Support message send Fail"});
	}
};

export const cancelDeliveryPartnerTask = (data) => async (dispatch) => {
  try {
	console.log("==== inside the actions of cancelDeliveryPartnerTask =====");
	console.log("Payload data ==>", data);

	let response = await axios.post(`/api/servicerequests/${data.serviceRequestId}/cancelDeliveryPartnerTask`, data);
	console.log("Response from actions ===>", response);
	dispatch({ type: CANCEL_DELIVERY_PARTNER_TASKID_MESSAGE, payload: response.data.message});
	return response.status;
  } catch (error) {
	console.log("Cannot cancel the delivery partner task", error);
	console.log("Cannot cancel the delivery partner task", error.response);
	dispatch({ type: CANCEL_DELIVERY_PARTNER_TASKID_MESSAGE_ERROR_MESSAGE, payload: error.response.data.error});
	return error.response.data.status;
  }
};

export const findAllConversations = (payload) => async dispatch => {
	 console.log("in actions",payload);
	// let query  = {count : payload};
	// console.log("queryyyyy",query);
	const response = await axios.get(`/api/servicerequests/findAllConversations/${payload}`);
	// console.log("response==>",response);
	dispatch({ type: FIND_ALL_CONVERSATIONS, payload: response.data });
};

export const updateManualDelivery = (payload) => async (dispatch) => {
	try {  

	  let response = await axios.patch(`/api/servicerequests/updateManualDelivery/${payload.id}`, payload);
	  console.log("==== updateManualDelivery====",response);

	  dispatch({ type: UPDATE_MANUAL_DELIVERY, payload: response.data.message});
	} catch (err) {
	  console.log("Error while deleting : ", err);
	  dispatch({ type: UPDATE_MANUAL_DELIVERY, payload: err.data.error})
	}
  };

export const createRefundRequest = (payload) => async dispatch => {
	
	try {
	
		await axios.post(`/api/servicerequests/requestForRefund/${payload.id}`,payload);
		
		dispatch({
		  type: CREATE_REFUND_REQUEST,
		  payload: "Refund added succesfully",
		});
	
	  } catch (err) {
		dispatch({
		  type: CREATE_REFUND_REQUEST,
		  payload: "Refund failed",
		});
	  }
};
  
export const createPorterId = (payload) => async (dispatch) => {
	try {
	//    console.log("------ payload in action of delete-----",payload);
  
	  let response = await axios.patch(`/api/servicerequests/createPorterId/${payload._id}`);
	  // console.log("==== response of delete data====",response);
	  dispatch({ type: CREATE_DUNZOID_SUCCESS_MESSAGE, payload: response.data.message});
	} catch (err) {
	  console.log("Error while deleting : ", err);
	  dispatch({ type: CREATE_DUNZOID_ERROR_MESSAGE, payload: err.response.data.error})
	}
  };
export const createShadowfaxId = (payload) => async (dispatch) => {
	try {
	//    console.log("------ payload in action of delete-----",payload);
  
	  let response = await axios.patch(`/api/servicerequests/createShadowfaxId/${payload._id}`);
	  // console.log("==== response of delete data====",response);
	  dispatch({ type: CREATE_DUNZOID_SUCCESS_MESSAGE, payload: response.data.message});
	} catch (err) {
	  console.log("Error while deleting : ", err);
	  dispatch({ type: CREATE_DUNZOID_ERROR_MESSAGE, payload: err.response.data.error})
	}
  };

