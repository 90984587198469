import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';



const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    marginTop: "100px",
    width: "100%",
    height: "100%",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: "0px 0px",
    display: "flex",
    alignItems: "center",
    height: "60px",
    marginBottom: "25px",
  },
  paper2: {
    padding: "0px 0px",
    display: "flex",
    alignItems: "center",
    height: "150px",
    marginBottom: "25px",
  },
  snackClose: {
    padding: theme.spacing(0.5),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
    flexDirection: "column",
    width: "80%",
    height: "150%",
  },
  form: {
    //   width: '70%',
    //   height: '170%',
    // width: '100%',
    // height: '100%',
    marginTop: "300px",
    width: "45%",
    height: "200%",
  },
  header: {
    //   height: '9%',
    // height: "7%",
    margin: "0px 0px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
  },
  headerWhite: {
    margin: "0px 0px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    padding: "10px",
  },
  headGridTwo: {
    float: "right",
    marginRight: "2px",
  },
  content: {
    // height: "60%",
    // margin: '10px 0px',
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: "100%",
    height: "100%",
  },

  gridElement: {
    margin: "10px 5px",
  },

  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  icon: {
    color: "white",
  },
  buttonGroup: {
    // "height": "40px",
    // "marginTop": "2px",
    // "width": "0px"
    height: "39px",
    marginTop: "8px",
    width: "0px",
    // "marginRight":"1px"
    marginLeft: "8px",
  },

  gridborder: {
    border: "1px solid black",
    borderRadius: "25px",
    alignSelf: "center",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginRight: "10px",
  },

  headGridThree: {
    float: "right",
    alignSelf: "center",
    alignItems: "center",
    marginLeft: "8px",
    marginRight: "4px",
    fontSize: "20px",
  },
});

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function EditConsumerAddress(props) {
  const { classes } = props;
  if(props.fromBusiness){
    console.log("Inside Business")
  }
  const [AddressType, setAddressType] = useState("");
  const [SocietyName, setSocietyName] = useState("");
  const [FlatNo, setFlatNo] = useState("");
  const [Street, setStreet] = useState("");
  const [Landmark, setLandmark] = useState("");
  const [Area, setArea] = useState("");
  const [City, setCity] = useState("");
  const [Pincode, setPincode] = useState("");
  const [Latitude, setLatitude] = useState("");
  const [Longitude, setLongitude] = useState("");
  const [IsDefaultAddress, setIsDefaultAddress] = useState(false);
  const [deliveryOptions,setDeliveryOptions] = useState("3PL");
  const [selfDeliveryChargesForConsumer,setselfDeliveryChargesForConsumer] = useState("");
  const [error, setError]= useState({})
  const selectedAddress =
    props.selectedAddressArray[props.selectedAddressIndex];
   console.log("in edit address====props====>",props);
   let deliveryCharges = null;
   let deliveryOp = "3PL";
   if(props.fromBusiness && selectedAddress?.selfDeliveryBusinesses?.length>0){
    let  businessObj = selectedAddress?.selfDeliveryBusinesses.filter(
      item => item.businessObjId.toString() == props?.businessObjId.toString());
      deliveryCharges = businessObj[0]?.deliveryCharges ? businessObj[0]?.deliveryCharges : null ;
      console.log("deliveryCharges==>",deliveryCharges);
      console.log("businessObj==>",businessObj);
      deliveryOp =  businessObj[0]?.deliveryCharges ? "self" : "3PL";
   }

  const {
    addressType,
    societyName,
    flatNumber,
    street,
    landmark,
    area,
    city,
    pinCode,
    location,
    isDefaultAddress
  } = selectedAddress;
  
  useEffect(() => {
    setAddressType(addressType || "");
    setSocietyName(societyName);
    setFlatNo(flatNumber);
    setStreet(street);
    setLandmark(landmark);
    setArea(area);
    setCity(city);
    setPincode(pinCode);
    setLatitude(location?.coordinates[0] || "");
    setLongitude(location?.coordinates[1] || "");
    setIsDefaultAddress(isDefaultAddress);
    setselfDeliveryChargesForConsumer(deliveryCharges);
    setDeliveryOptions(deliveryOp);
  }, [
    addressType,
    societyName,
    flatNumber,
    street,
    landmark,
    area,
    city,
    pinCode,
    location?.coordinates,
    isDefaultAddress,
    deliveryCharges,
    deliveryOp 
  ]);
  

  const onSaveButtonClick = async () => {
    let error = false;

    if(deliveryOptions === "self" && ( selfDeliveryChargesForConsumer === null   ||  Number(selfDeliveryChargesForConsumer)  < 1 )){     
       error = true
       const  selfDeliveryErrorMessage = "Amount should be greater than 0"
       setError({ ...error , selfDeliveryError  :  selfDeliveryErrorMessage})
    }
    if(error){
      return
    }
    
    let updatedArray = JSON.parse(JSON.stringify(props.selectedAddressArray));
    if(props.fromBusiness && props.businessObjId){
      console.log("updatedArray[props.selectedAddressIndex]==>",updatedArray[props.selectedAddressIndex]);
      console.log("deliveryOptions==>",deliveryOptions);
         if(deliveryOptions === "self"){

                let getSelfDeliveryBusinesses = updatedArray[props.selectedAddressIndex].selfDeliveryBusinesses ?updatedArray[props.selectedAddressIndex].selfDeliveryBusinesses : [];
                console.log("getSelfDeliveryBusinesses===>",getSelfDeliveryBusinesses);
                let obj = {};
                obj.businessObjId = props.businessObjId;
                obj.deliveryCharges =selfDeliveryChargesForConsumer; 
                if(getSelfDeliveryBusinesses.length >0 ){
                  console.log("already business are there");
                  let index = getSelfDeliveryBusinesses.findIndex(item => item.businessObjId === obj.businessObjId);

                      if (index !== -1) {
                          // If found, replace the object
                          getSelfDeliveryBusinesses[index] = obj;
                      } else {
                          // If not found, push the new object
                          getSelfDeliveryBusinesses.push(obj);
                      }
                 
                  updatedArray[props.selectedAddressIndex].selfDeliveryBusinesses = getSelfDeliveryBusinesses;
                } else {                
                    updatedArray[props.selectedAddressIndex].selfDeliveryBusinesses =[];
                    updatedArray[props.selectedAddressIndex].selfDeliveryBusinesses.push(obj)

                }
            }
           else{
            console.log("Inside 3pl")
            if (deliveryOptions === "3PL" && updatedArray[props.selectedAddressIndex].selfDeliveryBusinesses) {
              console.log("Inside 3pl if selfdelivery")
              updatedArray[props.selectedAddressIndex].selfDeliveryBusinesses = updatedArray[props.selectedAddressIndex].selfDeliveryBusinesses.filter(
                  item => item.businessObjId !== props.businessObjId
              );
          }
          }
      } 

   
    console.log("updatedArray",updatedArray[props.selectedAddressIndex]);
    updatedArray[props.selectedAddressIndex].addressType = AddressType;
    updatedArray[props.selectedAddressIndex].societyName = SocietyName;
    updatedArray[props.selectedAddressIndex].flatNumber = FlatNo;
    updatedArray[props.selectedAddressIndex].street = Street;
    updatedArray[props.selectedAddressIndex].landmark = Landmark;
    updatedArray[props.selectedAddressIndex].area = Area;
    updatedArray[props.selectedAddressIndex].city = City;
    updatedArray[props.selectedAddressIndex].pinCode = Pincode;
    updatedArray[props.selectedAddressIndex].location.coordinates[0] = Latitude;
    updatedArray[props.selectedAddressIndex].location.coordinates[1] = Longitude;
    if (selectedAddress.isDefaultAddress !== IsDefaultAddress) {
      updatedArray = updatedArray.map((ele, index) => {
        if (props.selectedAddressIndex === index) {
          ele.isDefaultAddress = true;
        } else ele.isDefaultAddress = false;
        return ele;
      });
    }
    props.onSave(updatedArray);

    props.onClose();
  };
  const handleFilterChange = (event) => {  
    setDeliveryOptions(event.target.value);
};
  return (
    <div className={classes.modal}>
      <Grid container className={classes.header} spacing={3}>
        <Grid item xs={8}>
          <WhiteTextTypography variant="h6" color="inherit">
            Edit Address
          </WhiteTextTypography>
        </Grid>
        <Grid item xs={4}>
          <WhiteTextTypography variant="h6" color="inherit">
            <CloseIcon
              className={classes.headGridTwo}
              onClick={() => {
                props.onClose();
              }}
            />
          </WhiteTextTypography>
        </Grid>
      </Grid>
      <Container className={classes.content}>
        <Grid
          container
          className={classes.grid}
          spacing={1}
          // justify="left"
          // alignItems="left"
          style={{ marginTop: "10px" }}
        >
          <Grid item xs={4}>
            <Typography variant="subtitle2">Address Type</Typography>
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={4}>
            <FormControl
              variant="outlined"
              fullWidth
              style={{ paddingRight: "5px" }}
              className={classes.formControl}
            >
              <Select
                id="subcategory"
                value={AddressType}
                name="subcategory"
                onChange={(e) => {
                  setAddressType(e.target.value);
                }}
                MenuProps={MenuProps}
                className={classes.select}
                style={{ height: "40px" }}
                displayEmpty
                renderValue={() => AddressType || <span style={{color:"#999"}}>Select Address Type</span>}
              >
                <MenuItem
                  value="Select Address Type"
                  key="Select Address Type"
                  disabled
                >
                  <ListItemText
                    className={classes.listItemText}
                    primary={"Select Address Type"}
                  />
                </MenuItem>
                <MenuItem
                  key={"Home"}
                  value={"Home"}
                  style={{ minHeight: "28px", height: "28px" }}
                >
                  <ListItemText
                    className={classes.listItemText}
                    primary={"Home"}
                  />
                </MenuItem>
                <MenuItem
                  key={"Office"}
                  value={"Office"}
                  style={{ minHeight: "28px", height: "28px" }}
                >
                  <ListItemText
                    className={classes.listItemText}
                    primary={"Office"}
                  />
                </MenuItem>
                <MenuItem
                  key={"Other"}
                  value={"Other"}
                  style={{ minHeight: "28px", height: "28px" }}
                >
                  <ListItemText
                    className={classes.listItemText}
                    primary={"Other"}
                  />
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={8}></Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">Society Name*</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="subtitle2">House/Flat No.*</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="subtitle2">Street</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="dense"
              name="SocietyName"
              placeholder="Society Name"
              variant="outlined"
              fullWidth
              style={{ paddingRight: "5px" }}
              value={SocietyName}
              error={SocietyName === ""}
              helperText={""}
              onChange={(e) => {
                setSocietyName(e.target.value);
              }}
            />
            {SocietyName === "" ? (
              <FormHelperText error>{"Society Name Required"}</FormHelperText>
            ) : null}
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="dense"
              name="FlatNo"
              placeholder="House/Flat No."
              variant="outlined"
              fullWidth
              style={{ paddingRight: "5px" }}
              value={FlatNo}
              error={FlatNo === ""}
              helperText={""}
              onChange={(e) => {
                setFlatNo(e.target.value);
              }}
            />
            {FlatNo === "" ? (
              <FormHelperText error>{"House/Flat No. Required"}</FormHelperText>
            ) : null}
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="dense"
              name="Street"
              placeholder="Street"
              variant="outlined"
              fullWidth
              style={{ paddingRight: "5px" }}
              value={Street}
              // error={""}
              helperText={""}
              onChange={(e) => {
                setStreet(e.target.value);
              }}
            />
          </Grid>

          <Grid item xs={4}>
            <Typography variant="subtitle2">Landmark</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="subtitle2">Area*</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="subtitle2">City*</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="dense"
              name="Landmark"
              placeholder="Landmark"
              variant="outlined"
              fullWidth
              style={{ paddingRight: "5px" }}
              value={Landmark}
              // error={""}
              helperText={""}
              onChange={(e) => {
                setLandmark(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="dense"
              name="Area"
              placeholder="Area"
              variant="outlined"
              fullWidth
              style={{ paddingRight: "5px" }}
              value={Area}
              error={Area === ""}
              helperText={""}
              onChange={(e) => {
                setArea(e.target.value);
              }}
            />
            {Area === "" ? (
              <FormHelperText error>{"Area Required"}</FormHelperText>
            ) : null}
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="dense"
              name="City"
              placeholder="City"
              variant="outlined"
              fullWidth
              style={{ paddingRight: "5px" }}
              value={City}
              error={City === ""}
              helperText={""}
              onChange={(e) => {
                setCity(e.target.value);
              }}
            />
            {City === "" ? (
              <FormHelperText error>{"City Required"}</FormHelperText>
            ) : null}
          </Grid>

          <Grid item xs={4}>
            <Typography variant="subtitle2">Pincode*</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="subtitle2">Latitude*</Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="subtitle2">Longitude*</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="dense"
              name="Pincode"
              placeholder="Pincode"
              variant="outlined"
              fullWidth
              style={{ paddingRight: "5px" }}
              value={Pincode}
              error={Pincode === ""}
              helperText={""}
              type={"number"}
              onChange={(e) => {
                setPincode(e.target.value);
              }}
            />
            {Pincode === "" ? (
              <FormHelperText error>{"Pincode Required"}</FormHelperText>
            ) : null}
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="dense"
              name="Latitude"
              placeholder="Latitude"
              variant="outlined"
              fullWidth
              style={{ paddingRight: "5px" }}
              value={Latitude}
              error={Latitude === ""}
              helperText={""}
              type={"number"}
              onChange={(e) => {
                setLatitude(e.target.value);
              }}
            />
            {Latitude === "" ? (
              <FormHelperText error>{"Latitude Required"}</FormHelperText>
            ) : null}
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="dense"
              name="Longitude"
              placeholder="Longitude"
              variant="outlined"
              fullWidth
              style={{ paddingRight: "5px" }}
              value={Longitude}
              error={Longitude === ""}
              helperText={""}
              type={"number"}
              onChange={(e) => {
                setLongitude(e.target.value);
              }}
            />
            {Longitude === "" ? (
              <FormHelperText error>{"Longitude Required"}</FormHelperText>
            ) : null}
          </Grid>
          {
  props.fromBusiness ? (
    <Grid container item xs={12} alignItems="center" spacing={2} style={{ display: "flex", justifyContent: "space-between" }}>
      {/* Delivery Options */}
      <Grid item>
        <Typography variant="subtitle2" gutterBottom>
          Delivery Options
        </Typography>
        <RadioGroup row aria-label="position" name="Delivery Options" onChange={handleFilterChange} value={deliveryOptions}>
          <FormControlLabel value="3PL" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>3PL</Typography>} size="small" />
          <FormControlLabel value="self" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Self Delivery</Typography>} size="small" />
        </RadioGroup>
      </Grid>

      {/* Make Default Checkbox (aligned to right) */}
      {!selectedAddress.isDefaultAddress && (
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={IsDefaultAddress}
                onChange={(e) => setIsDefaultAddress(!IsDefaultAddress)}
              />
            }
            label="Make Default"
          />
        </Grid>
      )}
    </Grid>
  ) :   (!selectedAddress.isDefaultAddress && (
    <Grid item xs={12} container justifyContent="flex-end">
      <FormControlLabel
        control={
          <Checkbox
            checked={IsDefaultAddress}
            onChange={(e) => setIsDefaultAddress(!IsDefaultAddress)}
          />
        }
        label="Make Default"
      />
    </Grid>
  ))
}

        </Grid>
       {props.fromBusiness && deliveryOptions === "self" ?
        <Grid item xs={2}>
            <TextField
              margin="dense"
              name="selfDeliveryChargesForConsumer"
              placeholder="Enter self delivery charges!"
              variant="outlined"
              fullWidth
              style={{ paddingRight: "5px" }}
              value={selfDeliveryChargesForConsumer}
              // error={Longitude === ""}
              helperText={""}
              type={"number"}
              onChange={(e) => {
                setError({ ...error , selfDeliveryError  :  null })
                setselfDeliveryChargesForConsumer(e.target.value);
              }}
            />
            { error?.selfDeliveryError ? (
              <FormHelperText error>{error?.selfDeliveryError}</FormHelperText>
            ) : null}
          </Grid>
          :null
}
        <Grid container spacing={2} style={{ marginTop: "5px" }}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              margin="normal"
              onClick={() => {
                props.onClose();
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              margin="normal"
              color="primary"
              disabled={
                Pincode === "" ||
                SocietyName === "" ||
                FlatNo === "" ||
                Area === "" ||
                City === "" ||
                Latitude === "" ||
                Longitude === ""
              }
              onClick={() => {
                onSaveButtonClick();
                
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Grid container className={classes.headerWhite} spacing={3}>
        {/* <Grid container spacing={2} xs={12}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              margin="normal"
              onClick={() => {
                props.onClose();
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              margin="normal"
              color="primary"
              onClick={() => {
                props.onSave();
                props.onClose();
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid> */}
      </Grid>
    </div>
  );
}

export default withStyles(styles)(EditConsumerAddress);
